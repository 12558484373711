import React from 'react';
import {Title, Wrapper, Input, Button, Icons} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

const Params = () => {
  const [field, , helpers] = useField('params');
  const {t} = useTranslation();

  const handleChange = (i: number, entry: 'key' | 'value', value: string) =>
    helpers.setValue(
      update(field.value, {
        [i]: {
          [entry == 'key' ? 0 : 1]: {$set: value}
        }
      })
    );

  const handleAdd = () =>
    helpers.setValue(
      update(field.value, {
        $push: [['', '']]
      })
    );

  const handleRemove = (i: number) =>
    helpers.setValue(
      update(field.value, {
        $splice: [[i, 1]]
      })
    );

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Parameters`}</Title.H3>

      <Wrapper
        gap={8}
        isColumn
      >
        {field.value?.map((item: string[], i: number) => (
          <Wrapper
            key={i}
            noWrap
          >
            <Wrapper gap={8}>
              <Input
                name={`param-key-${i}`}
                value={item[0]}
                placeholder={t`Key`}
                onChange={value => handleChange(i, 'key', value)}
              />

              <Input
                name={`param-value-${i}`}
                value={item[1]}
                placeholder={t`Value`}
                onChange={value => handleChange(i, 'value', value)}
              />
            </Wrapper>

            <Button
              color="transparent"
              onClick={() => handleRemove(i)}
            >
              <Icons.Trash />
            </Button>
          </Wrapper>
        ))}

        <Button
          color="white"
          onClick={handleAdd}
        >
          <Icons.Plus />
          {t`Add a parameter`}
        </Button>
      </Wrapper>
    </div>
  );
};

export default Params;
