import React, {useState} from 'react';
import {
  Button,
  getCountryName,
  Icons,
  TableCell,
  Text,
  Wrapper
} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import IntegrationFinder, {
  Values
} from 'pages/billing/integration-finder/integration-finder';
import {useAppContext} from 'app-context';
import {usePaymentListContext} from '../../payment-list-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Domain {
  payment: AppEnv.Payment;
}

const Domain = ({payment}: Domain) => {
  const {pushNotification} = useAppContext();
  const {getPaymentList} = usePaymentListContext();

  const [isIntegrationFinderActive, setIsIntegrationFinderActive] =
    useState(false);

  const {i18n, t} = useTranslation();
  const {putBillings} = useRequest();

  const handleSubmit = async (values: Values) => {
    if (values.integration_id) {
      const {data} = await putBillings(payment.id, {
        integration_id: values.integration_id
      });

      if (data) {
        setIsIntegrationFinderActive(false);
        pushNotification(t`The integration has been changed`);
        getPaymentList();
      }
    }
    return true;
  };

  return (
    <TableCell>
      {payment.domain ? (
        <Wrapper alignItems="center" noWrap>
          <Link
            to={`/connections?&filter_domain=${payment.domain}&filter_crm_id=${payment.integration_id}`}
            state={{
              referrer: window.location.pathname + window.location.search
            }}
          >
            <Button height={16} color="transparent" tip={t`Connections`} isLink>
              {payment.domain}
            </Button>
          </Link>

          <Button
            height={16}
            color="transparent"
            tip={t`Change`}
            onClick={() => setIsIntegrationFinderActive(true)}
          >
            <Icons.Edit />
          </Button>
        </Wrapper>
      ) : (
        <Button
          color="transparent"
          onClick={() => setIsIntegrationFinderActive(true)}
        >
          {t`Select`}
        </Button>
      )}

      {payment.country && (
        <Text size="s" isParagraph>
          {getCountryName(payment.country, i18n.resolvedLanguage)}
        </Text>
      )}

      <IntegrationFinder
        domain={payment.domain}
        integration_id={payment.integration_id}
        isActive={isIntegrationFinderActive}
        onClick={() => setIsIntegrationFinderActive(false)}
        onSubmit={handleSubmit}
      />
    </TableCell>
  );
};

export default Domain;
