import React from 'react';
import {Checkbox} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const IsIntegration = () => {
  const [field, , helpers] = useField('is_integration');
  const {t} = useTranslation();

  return (
    <Checkbox {...field} label={t`Integrator`} onChange={helpers.setValue} />
  );
};

export default IsIntegration;
