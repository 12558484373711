import React, {useState} from 'react';
import {Button, Confirmation, Icons, TableCell, TableRow} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useCrmRequest} from 'common/hooks';
import {useIntegrationContext} from 'pages/integrations/integration/integration-context';
import * as AppEnv from 'app-env';

interface Queue {
  isDeleteShown: boolean;
  queue: AppEnv.Queue;
}

const Queue = ({isDeleteShown, queue}: Queue) => {
  const {pushNotification} = useAppContext();
  const {crm} = useIntegrationContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {fetchQueueСlear} = useCrmRequest();
  const {i18n, t} = useTranslation();

  const clearQueue = async () => {
    if (!crm?.title) return true;

    const res = await fetchQueueСlear(crm.title, queue.queue_key);
    if (!res) return true;

    setIsConfirmationActive(false);
    pushNotification(queue.queue_name, t`The queue has been cleared.`);

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return clearQueue();
    setIsConfirmationActive(false);
  };

  const {tasks_count} = queue;
  const isHtmlDeleteShown = isDeleteShown && queue.tasks_count > 0;

  return (
    <TableRow isActive={isConfirmationActive}>
      <TableCell>{queue.queue_name}</TableCell>
      <TableCell>{tasks_count.toLocaleString(i18n.resolvedLanguage)}</TableCell>

      {isHtmlDeleteShown && (
        <TableCell isIcon>
          <Button
            color="transparent"
            tip={t`Clear`}
            onClick={() => setIsConfirmationActive(true)}
          >
            <Icons.Trash />
          </Button>

          <Confirmation
            title={t`Are you sure you want to clear this queue?`}
            isActive={isConfirmationActive}
            onClick={handleClick}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default Queue;
