import React, {useState} from 'react';
import {Button, Confirmation, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';

const Reward = () => {
  const {pushNotification} = useAppContext();
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {fetchPartnersDisbursements} = useRequest();
  const {t} = useTranslation();

  const sendRewards = async () => {
    const {data} = await fetchPartnersDisbursements();
    if (!data?.success) return true;

    setIsConfirmationActive(false);
    pushNotification(t`Rewards have been sent.`);

    return true;
  };

  const handleClick = async (res: boolean) => {
    if (res) return sendRewards();

    setIsConfirmationActive(false);
    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsConfirmationActive(true)}>
        <Icons.Crown />
        {t`Send rewards`}
      </Button>

      <Confirmation
        title={t`Are you sure you want to send rewards?`}
        isActive={isConfirmationActive}
        onClick={handleClick}
      />
    </>
  );
};

export default Reward;
