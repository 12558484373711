import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

import Event from './event/event';

interface EventList {
  eventList: AppEnv.Event[];
}

const EventList = ({eventList}: EventList) => {
  const {timezone} = useAppContext();
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell>{`${t`Date`} (${timezone})`}</TableCell>
        <TableCell>{t`Category`}</TableCell>
        <TableCell>{t`Category ID`}</TableCell>
        <TableCell>{t`Integration ID`}</TableCell>
        <TableCell>{t`User`}</TableCell>
        <TableCell>{t`IP address`}</TableCell>
        <TableCell>{t`Changes`}</TableCell>
        <TableCell />
      </TableHead>

      <TableBody>
        {eventList.map(item => (
          <Event
            key={item.id}
            event={item}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default EventList;
