import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

type AutomationList = AppEnv.Automation[] | undefined;

interface AutomationsContext {
  automationList: AutomationList;
  isFilterActive: boolean;
  setAutomationList: AppEnv.SetState<AutomationList>;
  setIsFilterActive: AppEnv.SetState<boolean>;
  getAutomationList: (arg: number) => Promise<void>;
}

const AutomationsContext = React.createContext<AutomationsContext>(
  {} as AutomationsContext
);

export const useAutomationsContext = () => useContext(AutomationsContext);
export default AutomationsContext;
