import React from 'react';
import {Radio, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Version = () => {
  const [field, , helpers] = useField<string>('version');
  const {t} = useTranslation();

  const options = [
    {label: t`All systems`, value: ''},
    {label: 'CloudPayments', value: 'cloudpayment'},
    {label: 'Stripe', value: 'stripe'},
    {label: t`Telphin`, value: 'telphin'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 12}}>{t`Payment system`}</Title.H3>

      <Radio
        {...field}
        options={options}
        value={field.value || ''}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Version;
