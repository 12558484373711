import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Email = () => {
  const [field, meta, helpers] = useField<string>('email');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Email`}</Title.H3>

      <Input
        {...field}
        type="email"
        isValid={isValid}
        placeholder={t`Email`}
        width="max"
        autoCapitalize="off"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Email;
