import React, {useEffect, useState} from 'react';
import {Chips, EmptyBox, Input, Preloader, Title, Wrapper} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useRequest, useSearchParams, useVersionParam} from 'common/hooks';
import * as AppEnv from 'app-env';

import HomeContext from './home-context';
import ServerList from './server-list/server-list';

interface Option {
  label: string;
  value: string | undefined;
}

const Home = () => {
  const {version} = useSearchParams();

  const [isLoading, setIsLoading] = useState<boolean>();
  const [serverList, setServerList] = useState<AppEnv.Server[]>();
  const [type, setType] = useState<string | undefined>(version);
  const [search, setSearch] = useState('');

  const {fetchServers} = useRequest();
  const {t} = useTranslation();
  useVersionParam(type);

  const getServerList = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);
    const {data} = await fetchServers(type);

    setServerList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getServerList();
  }, [type]);

  const options: Option[] = [
    {label: 'WhatsApp', value: 'whatcrm'},
    {label: 'Telegram', value: 'telegram'},
    {label: t`All servers`, value: undefined}
  ];

  return (
    <HomeContext.Provider value={{setServerList}}>
      <Title.H1 style={{marginBottom: 16}}>{t`Servers`}</Title.H1>

      <Wrapper alignItems="center" gap={16} style={{marginBottom: 16}}>
        <Chips<string | undefined>
          options={options}
          value={type}
          isDisabled={!serverList}
          onChange={setType}
        />

        <Input
          name="s"
          placeholder={t`Search`}
          type="search"
          value={search}
          isDisabled={!serverList}
          onChange={setSearch}
        />
      </Wrapper>

      {serverList ? (
        serverList.length ? (
          <ServerList search={search} serverList={serverList} />
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}

      {isLoading && <Preloader isFullScreen />}
    </HomeContext.Provider>
  );
};

export default Home;
