import React, {useState} from 'react';
import {
  TableRow,
  TableCell,
  Wrapper,
  Text,
  Button,
  Mark,
  Icons,
  Confirmation
} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import update from 'immutability-helper';
import moment from 'moment';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../../editor/editor';
import {useHomeContext} from '../../../home-context';

interface Partner {
  partner: AppEnv.Partner;
}

const Partner = ({partner}: Partner) => {
  const {preferences, pushNotification} = useAppContext();
  const {partnerList, setPartnerList} = useHomeContext();

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {putPartners} = useRequest();
  const {t} = useTranslation();

  const updatePartnerList = (data: AppEnv.Partner) => {
    const index = partnerList?.findIndex(item => item.id === data.id);

    if (typeof index == 'number' && index != -1) {
      setPartnerList(prevValue =>
        update(prevValue, {
          [index]: {$set: data}
        })
      );
    }
  };

  const handleSubmit = async (values: AppEnv.PartnerForm) => {
    const {data} = await putPartners(partner.id, {
      ...values,
      date_update: Math.floor(+new Date() / 1000)
    });

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.fullname, t`The partner has been updated`);
      updatePartnerList(data);
    }

    return true;
  };

  const deletePartner = async () => {
    const {data} = await putPartners(partner.id, {
      date_update: Math.floor(+new Date() / 1000),
      is_deleted: 1
    });

    if (data) {
      setIsConfirmationActive(false);
      pushNotification(data.fullname, t`The partner has been deleted`);
      updatePartnerList(data);
    }

    return true;
  };

  const returnPartner = async () => {
    const {data} = await putPartners(partner.id, {
      date_update: Math.floor(+new Date() / 1000),
      is_deleted: 0
    });

    if (data) {
      pushNotification(data.fullname, t`The partner has been restored`);
      updatePartnerList(data);
    }
  };

  const handleConfirm = async (res: boolean) => {
    if (res) {
      return deletePartner();
    }

    setIsConfirmationActive(false);
    return true;
  };

  const {customers_count, integrations_count} = partner;

  const date_add = moment
    .unix(partner.date_add)
    .utcOffset(preferences.utcOffset);

  const date_update = partner.date_update
    ? moment.unix(partner.date_update).utcOffset(preferences.utcOffset)
    : null;

  const isAcitve = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isAcitve}>
      <TableCell>{partner.fullname}</TableCell>
      <TableCell>{partner.email}</TableCell>
      <TableCell>{partner.phone && `+${partner.phone}`}</TableCell>

      <TableCell>
        {partner.type == 0 ? t`Individual` : t`Juridical person`}
      </TableCell>

      <TableCell>{partner.telphin_code}</TableCell>

      <TableCell>
        <Wrapper gap={4} isColumn>
          <Text isParagraph>
            <Link
              to={`/partners/${partner.id}/integrations`}
              state={{
                referrer: window.location.pathname + window.location.search
              }}
            >
              <Button color="transparent" isLink>
                <Trans count={integrations_count}>
                  {{integrations_count}}
                  {' integration'}
                </Trans>
              </Button>
            </Link>
          </Text>

          <Text isParagraph>
            <Link
              to={`/partners/${partner.id}/clients`}
              state={{
                referrer: window.location.pathname + window.location.search
              }}
            >
              <Button color="transparent" isLink>
                <Trans count={customers_count}>
                  {{customers_count}}
                  {' client'}
                </Trans>
              </Button>
            </Link>
          </Text>
        </Wrapper>
      </TableCell>

      <TableCell>{partner.comment}</TableCell>

      <TableCell>
        {date_add.format('L')}
        <br />
        {date_add.format('LT')}
      </TableCell>

      <TableCell>
        {date_update && (
          <>
            {date_update.format('L')}
            <br />
            {date_update.format('LT')}
          </>
        )}
      </TableCell>

      <TableCell isIcon>
        <Mark mark={partner.is_deleted} />
      </TableCell>

      <TableCell>
        <Link
          to={`/events?filter_category=partners&filter_category_id=${partner.id}`}
          state={{referrer: window.location.pathname + window.location.search}}
        >
          <Button color="transparent">{t`Events`}</Button>
        </Link>
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={partner}
          isActive={isEditorActive}
          isUpdate
          onClick={() => setIsEditorActive(false)}
          onSubmit={handleSubmit}
        />
      </TableCell>

      <TableCell isIcon>
        {partner.is_deleted ? (
          <Button
            color="transparent"
            tip={t`Restore`}
            onClick={() => returnPartner()}
          >
            <Icons.Return />
          </Button>
        ) : (
          <Button
            color="transparent"
            tip={t`Remove`}
            onClick={() => setIsConfirmationActive(true)}
          >
            <Icons.Trash />
          </Button>
        )}

        <Confirmation
          title={t`Are you sure you want to remove this partner?`}
          isActive={isConfirmationActive}
          onClick={handleConfirm}
        />
      </TableCell>
    </TableRow>
  );
};

export default Partner;
