import React, {useState} from 'react';
import {Wrapper, Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {usePaymentListContext} from '../payment-list-context';
import * as AppEnv from 'app-env';

import Editor from './editor/editor';
import Importer from './importer/importer';
import IntegrationChanger from './integration-changer/integration-changer';

interface Header {
  isFilterActive: boolean;
  setIsFilterActive: AppEnv.SetState<boolean>;
}

const Header = ({isFilterActive, setIsFilterActive}: Header) => {
  const {selectedPaymentIdList} = usePaymentListContext();

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isImporterActive, setIsImporterActive] = useState(false);

  const [isIntegrationChangerActive, setIsIntegrationChangerActive] =
    useState(false);

  const {t} = useTranslation();

  return (
    <>
      <Wrapper
        justifyContent="space-between"
        gap={16}
      >
        <Wrapper gap={8}>
          <Button
            color="white"
            onClick={() => setIsEditorActive(true)}
          >
            <Icons.Plus />
            {t`Add`}
          </Button>

          <Button
            color="white"
            onClick={() => setIsImporterActive(true)}
          >
            <Icons.Download />
            {t`Import Telphin payments`}
          </Button>

          <Button
            color={isFilterActive ? 'active' : 'white'}
            onClick={() => setIsFilterActive(prevValue => !prevValue)}
          >
            <Icons.Filter />
            {t`Filter`}
          </Button>
        </Wrapper>

        <Button
          color="white"
          isDisabled={!selectedPaymentIdList.length}
          onClick={() => setIsIntegrationChangerActive(true)}
        >
          {t`Change the integration`}
        </Button>
      </Wrapper>

      <Editor
        isEditorActive={isEditorActive}
        setIsEditorActive={setIsEditorActive}
      />

      <Importer
        isImporterActive={isImporterActive}
        setIsImporterActive={setIsImporterActive}
      />

      <IntegrationChanger
        isIntegrationChangerActive={isIntegrationChangerActive}
        setIsIntegrationChangerActive={setIsIntegrationChangerActive}
      />
    </>
  );
};

export default Header;
