import React from 'react';
import {Toggle} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';

const IsAltTableView = () => {
  const {preferences, setPreferences} = useAppContext();
  const {t} = useTranslation();

  const handleChange = (value: boolean) =>
    setPreferences(prevValue =>
      update(prevValue, {isAltTableView: {$set: value}})
    );

  return (
    <Toggle
      label={t`Alternative table view`}
      value={!!preferences?.isAltTableView}
      onChange={handleChange}
    />
  );
};

export default IsAltTableView;
