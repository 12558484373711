import React from 'react';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {Title as CoreTitle, Input} from 'whatcrm-core';

const WebhookPay = () => {
  const [field, , helpers] = useField<string>('webhook_pay');
  const {t} = useTranslation();

  return (
    <div>
      <CoreTitle.H3 style={{marginBottom: 8}}>
        {t`Payment webhook address`}
      </CoreTitle.H3>

      <Input
        {...field}
        type="url"
        placeholder={t`Payment webhook address`}
        width="max"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default WebhookPay;
