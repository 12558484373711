import React from 'react';
import {Button, TableCell, TableRow} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {useAppContext} from 'app-context';
import {usePaymentsContext} from '../../payments-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Payment {
  payment: AppEnv.Payment;
}

const Payment = ({payment}: Payment) => {
  const {pushNotification} = useAppContext();
  const {connection, rateList} = usePaymentsContext();

  const {i18n, t} = useTranslation();
  const {putInstances} = useRequest();

  const handleClick = async () => {
    const date_period = new Date(payment.date_yslug * 1000);
    date_period.setUTCHours(23, 59, 59);

    const {data} = await putInstances(connection.id, {
      date_pay: payment.date_yslug,
      date_period: parseInt(moment(date_period).format('X')),
      tariff_id: payment.tariff_id
    });

    if (data) pushNotification(t`The connection has been paid for`);
    return true;
  };

  const date_yslug = moment.unix(payment.date_yslug);
  const plan = rateList.find(item => item.value == payment.tariff_id);

  return (
    <TableRow>
      <TableCell>
        {date_yslug.format('L')}
        <br />
        {date_yslug.format('LT')}
      </TableCell>

      <TableCell>
        {payment.column == 'cloudpayment'
          ? 'CloudPayments'
          : payment.column == 'stripe'
          ? 'Stripe'
          : payment.column == 'telphin'
          ? t`Telphin`
          : payment.column == 'tinkoff'
          ? t`Tinkoff`
          : payment.column == 'whatcrm'
          ? 'Whatcrm'
          : payment.column}
      </TableCell>

      <TableCell>{payment.tariff_title}</TableCell>
      <TableCell>{plan?.label.split(',')[0]}</TableCell>
      <TableCell>{payment.quantity}</TableCell>

      <TableCell>
        {parseFloat(payment.summa).toLocaleString(i18n.language, {
          style: 'currency',
          currency: payment.currency || 'RUB'
        })}
      </TableCell>

      <TableCell>
        <Button
          color="transparent"
          isDisabled={!connection.integration}
          onClick={handleClick}
        >
          {t`Make the payment`}
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default Payment;
