import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';
import Payment from './payment/payment';

interface PaymentList {
  paymentList: AppEnv.Payment[];
}

const PaymentList = ({paymentList}: PaymentList) => {
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell>{t`Date`}</TableCell>
        <TableCell>{t`Payment system`}</TableCell>
        <TableCell>{t`Rate`}</TableCell>
        <TableCell>{t`Rate plan`}</TableCell>
        <TableCell>{t`Quantity`}</TableCell>
        <TableCell>{t`Amount`}</TableCell>
        <TableCell />
      </TableHead>

      <TableBody>
        {paymentList.map(item => (
          <Payment key={item.id} payment={item} />
        ))}
      </TableBody>
    </Table>
  );
};

export default PaymentList;
