import React from 'react';
import {Input, isolateNumbers, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Number = () => {
  const [field, , helpers] = useField<number | undefined>('number');
  const {t} = useTranslation();

  const handleChange = (value: string) => {
    const numbers = isolateNumbers(value);
    helpers.setValue(numbers ? +numbers : undefined);
  };

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Quantity`}</Title.H3>

      <Input
        {...field}
        placeholder={t`Quantity`}
        type="number"
        width="max"
        onChange={handleChange}
      />
    </div>
  );
};

export default Number;
