import React from 'react';

import {Sidebar} from 'components';
import {useEventsContext} from '../events-context';
import * as AppEnv from 'app-env';

import LogTimeAfter from './log-time-after/log-time-after';
import LogTimeBefore from './log-time-before/log-time-before';
import Category from './category/category';
import CategoryId from './category-id/category-id';
import IntegrationId from './integration-id/integration-id';
import UserId from './user-id/user-id';
import IP from './ip/ip';
import Old from './old/old';
import New from './new/new';

interface Filter {
  filter: AppEnv.EventFilter;
  isDisabled: boolean;
  setFilter: AppEnv.SetState<AppEnv.EventFilter>;
}

const Filter = ({filter, isDisabled, setFilter}: Filter) => {
  const {isFilterActive, setIsFilterActive} = useEventsContext();

  return (
    <Sidebar
      initialValues={filter}
      isActive={isFilterActive}
      isDisabled={isDisabled}
      onClick={() => setIsFilterActive(false)}
      onSubmit={async (values: AppEnv.EventFilter) => setFilter(values)}
      onCancel={() => setFilter({})}
    >
      <LogTimeAfter />
      <LogTimeBefore />
      <Category />
      <CategoryId />
      <IntegrationId />
      <UserId />
      <IP />
      <Old />
      <New />
    </Sidebar>
  );
};

export default Filter;
