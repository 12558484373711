import React from 'react';
import {getCountryName, Text} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';

import * as AppEnv from 'app-env';

interface Payload {
  payload: AppEnv.CountryStats;
}

interface RechartsTooltip {
  active?: boolean;
  payload?: Payload[];
  total: number;
}

const RechartsTooltip = ({active, payload, total}: RechartsTooltip) => {
  const {i18n, t} = useTranslation();

  const {c} = payload?.[0]?.payload || {c: 0};
  const isActive = active && payload?.length;
  const iso = payload?.[0]?.payload.country;
  const flag = iso ? getUnicodeFlagIcon(iso) : undefined;
  const name = iso ? getCountryName(iso, i18n.resolvedLanguage) : undefined;
  const percents = (c / total) * 100;

  return (
    <>
      {isActive ? (
        <div className="recharts-tooltip">
          <Text size="s" isParagraph style={{marginBottom: 4}}>
            {flag} {name}
          </Text>

          <Text color="dark" weight="bold">
            {t`Total payments`}
            {` — `}
            {payload[0]?.payload.c}{' '}
            {`(${percents.toLocaleString(i18n.language, {
              maximumFractionDigits: 2
            })}%)`}
          </Text>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default RechartsTooltip;
