import React from 'react';
import {Input} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

const Password = () => {
  const {t} = useTranslation();

  const [field, meta, helpers] = useField<string>('password');
  const isValid = !(meta.touched && meta.error);

  return (
    <>
      <Input
        {...field}
        type="password"
        isValid={isValid}
        placeholder={t`Password`}
        autoCapitalize="off"
        onChange={helpers.setValue}
      />
    </>
  );
};

export default Password;
