import React from 'react';
import {Table, TableBody, TableCell, TableHead} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useInvoicesContext} from 'pages/invoices/invoices-context';
import Invoice from './invoice/invoice';

const InvoiceList = () => {
  const {invoiceList} = useInvoicesContext();
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell>
          {t`Date`}
          {' (UTC+3)'}
        </TableCell>

        <TableCell>{t`Integration ID`}</TableCell>
        <TableCell>{t`Connection ID`}</TableCell>
        <TableCell>{t`Quantity`}</TableCell>
        <TableCell>{t`Amount`}</TableCell>
        <TableCell>{t`Deleted`}</TableCell>
        <TableCell />
        <TableCell />
      </TableHead>

      <TableBody>
        {invoiceList?.map(item => (
          <Invoice invoice={item} key={item.id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default InvoiceList;
