import React from 'react';
import {Input, isolateNumbers, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Discount = () => {
  const [field, , helpers] = useField('discount');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Discount in percent`}</Title.H3>

      <Input
        {...field}
        inputMode="numeric"
        placeholder={t`Discount in percent`}
        width="max"
        onChange={value => helpers.setValue(isolateNumbers(value))}
      />
    </div>
  );
};

export default Discount;
