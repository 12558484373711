import React from 'react';

import {Sidebar} from 'components';
import * as AppEnv from 'app-env';

import DataEventAfter from './data-event-after/data-event-after';
import DataEventBefore from './data-event-before/data-event-before';
import ActionType from './action-type/action-type';
import CodeClient from './code-client/code-client';
import BonusId from './bonus-id/bonus-id';
import Bonuses from './bonuses/bonuses';
import Line from './line/line';

interface TelphinFilter {
  filter: AppEnv.TelphinFilter;
  isActive: boolean;
  isDisabled: boolean;
  setFilter: AppEnv.SetState<AppEnv.TelphinFilter>;
  setIsFilterActive: AppEnv.SetState<boolean>;
}

const Filter = ({
  filter,
  isActive,
  isDisabled,
  setFilter,
  setIsFilterActive
}: TelphinFilter) => (
  <Sidebar
    initialValues={filter}
    isActive={isActive}
    isDisabled={isDisabled}
    onSubmit={async (values: AppEnv.TelphinFilter) => setFilter(values)}
    onCancel={() => setFilter({})}
    onClick={() => setIsFilterActive(false)}
  >
    <DataEventAfter />
    <DataEventBefore />
    <ActionType />
    <CodeClient />
    <BonusId />
    <Bonuses />
    <Line />
  </Sidebar>
);

export default Filter;
