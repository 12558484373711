import React from 'react';
import {Title, Chips} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const IsDeleted = () => {
  const [field, , helpers] = useField('is_deleted');
  const {t} = useTranslation();

  const options = [
    {
      value: '-1',
      label: t`Any status`
    },
    {
      value: '0',
      label: t`Active`
    },
    {
      value: '1',
      label: t`Deleted`
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 12}}>{t`Status`}</Title.H3>

      <Chips
        {...field}
        options={options}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default IsDeleted;
