import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

import {useWorkspaceContext} from 'workspace/workspace-context';

const TokenId = () => {
  const {tokenList} = useWorkspaceContext();

  const [field, , helpers] = useField('token_id');
  const {t} = useTranslation();

  const options =
    tokenList?.map(item => ({
      label: item.name,
      value: item.id
    })) || [];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Token`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Token`}
        isMaxWidth
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default TokenId;
