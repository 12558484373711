import React, {useState} from 'react';
import {
  TableRow,
  TableCell,
  Mark,
  Button,
  Icons,
  Confirmation,
  useGetTariffName
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useRatesContext} from 'pages/rates/rates-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../../editor/editor';

interface Rate {
  rate: AppEnv.Rate;
  rateList: AppEnv.Rate[];
}

const Rate = ({rate, rateList}: Rate) => {
  const {pushNotification} = useAppContext();
  const {setRateList, getRateList} = useRatesContext();

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {i18n, t} = useTranslation();
  const getTariffName = useGetTariffName();
  const {putTariffs} = useRequest();

  const handleSubmit = async (values: AppEnv.RateForm) => {
    const {data} = await putTariffs(rate.id, values);

    if (data) {
      setIsEditorActive(false);

      pushNotification(
        getTariffName(data.quantity, data.period, i18n.language),
        t`The rate has been updated`
      );

      const index = rateList.findIndex(item => item.id == data.id);

      setRateList(prevValue =>
        update(prevValue, {
          [index]: {$set: data}
        })
      );
    }

    return true;
  };

  const rateName = getTariffName(rate.quantity, rate.period, i18n.language);

  const archiveRate = async (is_deleted: 0 | 1) => {
    const {data} = await putTariffs(rate.id, {
      is_deleted
    });

    if (data) {
      pushNotification(
        data.is_deleted
          ? t`The rate has been archived.`
          : t`The rate has been returned from the archive.`
      );

      getRateList();
    }

    return true;
  };

  const formatSum = (sum: string) =>
    parseFloat(sum).toLocaleString(i18n.language, {
      style: 'currency',
      currency: rate.country
    });

  const handleClick = async (res: boolean) => {
    if (res) {
      await archiveRate(1);
      setIsConfirmationActive(false);
      return true;
    }

    setIsConfirmationActive(false);
    return true;
  };

  const isActive = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>{rate.id}</TableCell>
      <TableCell>{rate.plane}</TableCell>
      <TableCell>{rateName}</TableCell>
      <TableCell>{formatSum(rate.summa)}</TableCell>
      <TableCell>{formatSum(rate.summa_review)}</TableCell>
      <TableCell>{rate.type?.title}</TableCell>

      <TableCell isIcon>
        <Mark mark={rate.is_deleted == 1 ? true : false} />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={rate}
          isActive={isEditorActive}
          isUpdate
          onClick={() => setIsEditorActive(false)}
          onSubmit={handleSubmit}
        />
      </TableCell>

      <TableCell isIcon>
        {rate.is_deleted ? (
          <Button
            color="transparent"
            tip={t`Restore`}
            onClick={() => archiveRate(0)}
          >
            <Icons.Return />
          </Button>
        ) : (
          <>
            <Button
              color="transparent"
              tip={t`Archive`}
              isDisabled={rate.is_deleted != 0}
              onClick={() => setIsConfirmationActive(true)}
            >
              <Icons.Trash />
            </Button>

            <Confirmation
              title={t`Are you sure you want to archive this rate?`}
              isActive={isConfirmationActive}
              onClick={handleClick}
            />
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Rate;
