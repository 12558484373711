import React, {useState, useEffect} from 'react';
import {Wrapper, Substrate, Preloader, EmptyBox} from 'whatcrm-core';
import {Routes, Route, Navigate, useParams} from 'react-router-dom';

import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Header from './header/header';
import Clients from './clients/clients';
import Integrations from './integrations/integrations';
import PartnerContext from './partner-context';

const Partner = () => {
  const [partner, setPartner] = useState<AppEnv.Partner | null>();

  const {id} = useParams();
  const {fetchPartners} = useRequest();

  const getPartner = async () => {
    if (id) {
      const {data} = await fetchPartners<AppEnv.Partner>({
        id: parseInt(id)
      });

      setPartner(data || null);
      return;
    }

    setPartner(null);
  };

  useEffect(() => {
    getPartner();
  }, []);

  return (
    <div className="content">
      <Wrapper gap={8} isColumn>
        <Header />

        <Substrate>
          {partner != undefined ? (
            partner ? (
              <PartnerContext.Provider value={{partner, setPartner}}>
                <Routes>
                  <Route path="clients" element={<Clients />} />

                  <Route path="integrations" element={<Integrations />} />

                  <Route path="/" element={<Navigate to="clients" />} />
                </Routes>
              </PartnerContext.Provider>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )}
        </Substrate>
      </Wrapper>
    </div>
  );
};

export default Partner;
