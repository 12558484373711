import React from 'react';
import {useTranslation} from 'react-i18next';

import {PieGraph} from 'components';
import {useConnectionsContext} from '../connections-context';

const Messengers = () => {
  const {stats} = useConnectionsContext();
  const {t} = useTranslation();

  const data = [
    {color: '#56caf0', label: 'Telegram', value: stats.telegram.count},
    {color: '#83d120', label: 'WhatsApp', value: parseInt(stats.whatsapp.count)}
  ];

  return <PieGraph data={data} title={t`Total`} />;
};

export default Messengers;
