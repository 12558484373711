import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

const DateAdd = () => {
  const [field, , helpers] = useField<number>('date_add');
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    helpers.setValue(+moment(value).utcOffset(3).format('X'));

  const value = moment
    .unix(field.value)
    .utcOffset(3)
    .format('yyyy-MM-DD hh:mm');

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>
        {t`Date`}
        {' (UTC+3)'}
      </Title.H3>

      <Input
        {...field}
        type="datetime-local"
        value={value}
        width="max"
        onChange={handleChange}
      />
    </div>
  );
};

export default DateAdd;
