import React from 'react';
import {Chips, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Report = () => {
  const [field, , helpers] = useField<string>('report');
  const {t} = useTranslation();

  const options = [
    {label: t`Day`, value: 'day'},
    {label: t`Month`, value: 'month'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Term`}</Title.H3>

      <Chips
        {...field}
        options={options}
        value={field.value || 'day'}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Report;
