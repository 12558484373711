import React, {useState} from 'react';
import {
  Button,
  Confirmation,
  formatPhoneNumber,
  Icons,
  Mark,
  TableCell,
  TableRow
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Keyword} from 'components';
import {useAppContext} from 'app-context';
import {useStaffContext} from 'pages/staff/staff-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../../editor/editor';

interface Employee {
  employee: AppEnv.Employee;
}

const Employee = ({employee}: Employee) => {
  const {pushNotification} = useAppContext();
  const {getEmployeeList, setEmployeeList} = useStaffContext();

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {putManagers} = useRequest();
  const {t} = useTranslation();

  const updateEmployee = (data: AppEnv.Employee) => {
    setEmployeeList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == data.id);

      if (typeof index == 'number' && index != -1) {
        return update(prevValue, {
          [index]: {$set: data}
        });
      }
    });
  };

  const handleSubmit = async (values: AppEnv.EmployeeForm) => {
    const {data} = await putManagers(employee.id, values);

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.fullname, t`The employee has been updated`);
      getEmployeeList();
    }

    return true;
  };

  const deleteEmployee = async () => {
    const {data} = await putManagers(employee.id, {is_deleted: 1});

    if (data) {
      setIsConfirmationActive(false);
      pushNotification(employee.fullname, t`The employee has been removed`);
      updateEmployee(data);
    }

    return true;
  };

  const handleReturn = async () => {
    const {data} = await putManagers(employee.id, {is_deleted: 0});

    if (data) {
      setIsConfirmationActive(false);
      pushNotification(employee.fullname, t`The employee has been restored`);
      updateEmployee(data);
    }
  };

  const handleConfirm = async (res: boolean) => {
    if (res) {
      return deleteEmployee();
    }

    setIsConfirmationActive(false);
    return true;
  };

  const isActive = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>{employee.fullname}</TableCell>
      <TableCell>{employee.email}</TableCell>

      <TableCell>
        <Keyword keyword={employee.phone}>
          {formatPhoneNumber(employee.phone)}
        </Keyword>
      </TableCell>

      <TableCell>{employee.uuid}</TableCell>

      <TableCell isIcon>
        <Mark mark={employee.is_deleted} />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={employee}
          isActive={isEditorActive}
          isUpdate
          onClick={() => setIsEditorActive(false)}
          onSubmit={handleSubmit}
        />
      </TableCell>

      <TableCell isIcon>
        {employee.is_deleted ? (
          <Button tip={t`Restore`} color="transparent" onClick={handleReturn}>
            <Icons.Return />
          </Button>
        ) : (
          <>
            <Button
              color="transparent"
              tip={t`Remove`}
              onClick={() => setIsConfirmationActive(true)}
            >
              <Icons.Trash />
            </Button>

            <Confirmation
              title={t`Are you sure you want to remove this employee?`}
              isActive={isConfirmationActive}
              onClick={handleConfirm}
            />
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default Employee;
