import * as AppEnv from 'app-env';

const filterToRequest = (filter: AppEnv.RateFilter): AppEnv.RateRequest => ({
  country: filter.country ? {value: filter.country} : undefined,
  integration_type_id: filter.integration_type_id
    ? {value: filter.integration_type_id}
    : undefined,
  is_deleted:
    filter.is_deleted && filter.is_deleted != '-1'
      ? {value: filter.is_deleted}
      : undefined,
  plane: filter.plane ? {value: filter.plane} : undefined,
  quantity: filter.quantity ? {value: filter.quantity} : undefined,
  summa_review:
    filter.price && filter.isWithFeedback ? {value: filter.price} : undefined,
  summa:
    filter.price && !filter.isWithFeedback ? {value: filter.price} : undefined
});

export default filterToRequest;
