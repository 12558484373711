import React from 'react';
import {Title, Button, Icons} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useEventsContext} from 'pages/events/events-context';
import * as AppEnv from 'app-env';

interface State {
  referrer: string;
}

const Header = () => {
  const {state}: AppEnv.Location<State> = useLocation();
  const {isFilterActive, setIsFilterActive} = useEventsContext();
  const {t} = useTranslation();

  return (
    <>
      {state?.referrer && (
        <Link to={state.referrer}>
          <Button color="white">
            <Icons.AngleLeft />
            {t`Back`}
          </Button>
        </Link>
      )}

      <Title.H1>{t`Events`}</Title.H1>

      <Button
        color={isFilterActive ? 'active' : 'white'}
        onClick={() => setIsFilterActive(prevValue => !prevValue)}
      >
        <Icons.Filter />
        {t`Filter`}
      </Button>
    </>
  );
};

export default Header;
