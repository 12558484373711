import React, {useState, useEffect} from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useConnectionContext} from '../../../../connection-context';
import {useGetRateList} from 'common/hooks';

interface Rate {
  label: string;
  value: number;
}

const TariffId = () => {
  const {connection} = useConnectionContext();
  const [rateList, setRateList] = useState<Rate[]>();

  const [field, , helpers] = useField('tariff_id');
  const getRateList = useGetRateList();
  const {t} = useTranslation();

  const fetchOptions = async () => {
    if (connection.integration) {
      const {options} = await getRateList(
        connection.integration?.integration_type_id
      );

      setRateList(options);
      return;
    }

    setRateList([]);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Rate`}</Title.H3>

      <SingleSelect
        {...field}
        options={rateList || []}
        placeholder={t`Rate`}
        isClearable
        isMaxWidth
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default TariffId;
