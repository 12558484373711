import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';

import Integration from './integration/integration';
import {usePartnerContext} from '../../partner-context';

const IntegrationList = () => {
  const {partner} = usePartnerContext();
  const {t} = useTranslation();

  return (
    <>
      <EntriesNumber
        number={partner.integrations?.length || 0}
        style={{marginBottom: 8}}
      />

      <Table>
        <TableHead>
          <TableCell>{t`Domain`}</TableCell>
          <TableCell>CRM</TableCell>
          <TableCell>{t`Entities`}</TableCell>
          <TableCell isIcon />
        </TableHead>

        <TableBody>
          {partner.integrations?.map(item => (
            <Integration key={item.id} integration={item} />
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default IntegrationList;
