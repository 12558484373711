import React from 'react';
import {Button, Line, Popup, SaveButton, Title, Wrapper} from 'whatcrm-core';
import {Formik, Form, FormikHelpers} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Comment from './comment/comment';
import Email from './email/email';
import Fullname from './fullname/fullname';
import Phone from './phone/phone';
import TelphinCode from './telphin-code/telphin-code';
import Type from './type/type';

interface Editor {
  initialValues?: AppEnv.PartnerForm;
  isActive: boolean;
  isUpdate?: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.PartnerForm>;
}

const Editor = ({
  initialValues = {
    comment: undefined,
    email: undefined,
    fullname: undefined,
    is_deleted: undefined,
    phone: undefined,
    telphin_code: undefined,
    type: undefined
  },
  isActive,
  isUpdate,
  onClick,
  onSubmit
}: Editor) => {
  const {fetchPartners} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (
    values: AppEnv.PartnerForm,
    helpers: FormikHelpers<AppEnv.PartnerForm>
  ) => {
    if (isUpdate) return onSubmit(values, helpers);
    else if (!values.email) return true;

    const {data} = await fetchPartners<AppEnv.Partner[]>({
      filter: {email: {value: values.email}}
    });

    if (!data?.length) return onSubmit(values, helpers);
    helpers.setFieldError('email', 'exist');

    return true;
  };

  const validationSchema = yup.object().shape({
    email: yup.string().trim().required().email(),
    fullname: yup.string().trim().required()
  });

  return (
    <Popup isActive={isActive} onClick={onClick}>
      <Title.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Update the partner` : t`Create a partner`}
      </Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <Fullname />
            <Email />
            <Phone />
            <Type />
            <TelphinCode />
            <Comment />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={onClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
