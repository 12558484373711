import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useHomeContext} from 'pages/connections/home/home-context';
import {useConnectionContext} from '../../connection-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import * as ActionMenuEnv from './action-menu-env';
import takeConnectionStatus from './take-connection-status';

const useActions = () => {
  const {pushNotification} = useAppContext();

  const {connectionList, setConnectionList, getConnectionList} =
    useHomeContext();

  const {connection} = useConnectionContext();

  const {
    deleteInstances,
    freeInstance,
    putInstances,
    fetchInstanceResuscitate,
    updateInstance
  } = useRequest();

  const {t} = useTranslation();

  const connectionLabel = `${t`Connection`} ${connection.id}`;

  const connectionIndex = connectionList?.findIndex(
    item => item.id == connection.id
  );

  const updateConnection = (data: AppEnv.Instance) => {
    if (typeof connectionIndex == 'number' && connectionIndex != -1) {
      setConnectionList(prevValue =>
        update(prevValue, {
          [connectionIndex]: {$set: data}
        })
      );
    }
  };

  interface CloseSuccess {
    _: 'ok';
  }

  interface CloseError {
    error_code: number;
    error_text: string;
  }

  const closeConnection = async () => {
    const {data} = await updateInstance<CloseSuccess | CloseError>(
      connection.chat_key,
      connection.version == 'whatcrm' ? 'destroy' : 'close'
    );

    if (data) {
      if ('_' in data) {
        pushNotification(connectionLabel, t`The connection has been closed`);
        return;
      }

      pushNotification(`${t`Error`} ${data.error_code}`, data.error_text);
    }
  };

  const deleteConnection = async (
    res: boolean,
    setIsConfirmationActive: AppEnv.SetState<boolean>
  ) => {
    if (!res) {
      setIsConfirmationActive(false);
      return true;
    }

    const {data} = await deleteInstances(connection.id);
    if (typeof data != 'string') return true;

    pushNotification(connectionLabel, t`The connection has been deleted`);
    setIsConfirmationActive(false);
    getConnectionList();

    return true;
  };

  const enableConnection = async () => {
    const {data} = await putInstances(connection.id, {
      is_deleted: connection.is_deleted ? 0 : 1
    });

    if (data) {
      pushNotification(
        connectionLabel,
        data.is_deleted == 0
          ? t`The connection has been enabled`
          : data.is_deleted
            ? t`The connection has been disabled`
            : t`The connection is temporarily unavailable`
      );

      updateConnection(data);
    }
  };

  const freeConnection = async () => {
    const {data} = await freeInstance(connection.chat_key);

    if (data) {
      pushNotification(connectionLabel, t`The connection has been released`);
      updateConnection(data);
    }
  };

  const getConnectionStatus = async () => {
    const {data} = await updateInstance<
      ActionMenuEnv.StatusSuccess | ActionMenuEnv.StatusError
    >(connection.chat_key, 'status');

    if (data) {
      return takeConnectionStatus(data);
    }

    pushNotification(t`Error`, t`Could not get the status`);
    return null;
  };

  interface WebVersionSuccess {
    result: string;
  }

  interface WebVersionError {
    error: number | null;
    error_code: number | null;
    error_text: string;
  }

  const getConnectionVersion = async () => {
    const {data} = await updateInstance<WebVersionSuccess | WebVersionError>(
      connection.chat_key,
      'WWebVersion'
    );

    if (data) {
      if ('result' in data) {
        return data.result;
      }

      pushNotification(
        `${t`Error`}${data.error_code ? ` ${data.error_code}` : ''}`,
        data.error_text
      );
    }

    return null;
  };

  interface LogoutSuccess {
    success: 1;
  }

  interface LogoutError {
    error: string;
    error_code: string;
    error_text: string;
  }

  const logoutConnection = async (
    res: boolean,
    setIsConfirmationActive: AppEnv.SetState<boolean>
  ) => {
    if (res) {
      const {data} = await updateInstance<LogoutSuccess | LogoutError>(
        connection.chat_key,
        'logout'
      );

      pushNotification(
        connectionLabel,
        data && 'error_text' in data
          ? data.error_text
          : t`The logout has been completed`
      );
    }

    setIsConfirmationActive(false);
    return true;
  };

  const resuscitateConnection = async () => {
    const {data} = await fetchInstanceResuscitate(connection.chat_key);

    if (data) {
      updateConnection(data);

      pushNotification(
        connectionLabel,
        t`The connection has been resuscitated.`
      );
    }
  };

  return {
    closeConnection,
    deleteConnection,
    enableConnection,
    freeConnection,
    getConnectionStatus,
    getConnectionVersion,
    logoutConnection,
    resuscitateConnection
  };
};

export default useActions;
