import React, {useState} from 'react';
import {
  Button,
  isolateNumbers,
  Line,
  Popup,
  SaveButton,
  Title,
  Wrapper
} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import * as AppEnv from 'app-env';

import Phone from './phone/phone';
import * as EditorEnv from './editor-env';

interface Editor {
  initialValues: AppEnv.BlockedForm;
  isActive: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.BlockedForm>;
}

const Editor = ({initialValues, isActive, onClick, onSubmit}: Editor) => {
  const [type, setType] = useState<EditorEnv.Type>('text');
  const {t} = useTranslation();

  const handleSubmit: AppEnv.Submit<AppEnv.BlockedForm> = (values, helpers) =>
    onSubmit(
      {
        phone: values.phone
          ? type == 'tel'
            ? isolateNumbers(values.phone)
            : values.phone.replaceAll('@', '')
          : ''
      },
      helpers
    );

  const validationSchema = yup.object().shape({
    phone: yup.string().trim().required()
  });

  return (
    <Popup isActive={isActive} onClick={onClick}>
      <Title.H2 style={{marginBottom: 16}}>
        {t`Update the blocked contact`}
      </Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <Phone type={type} setType={setType} />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={onClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
