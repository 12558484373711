import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {usePromoCodesContext} from 'pages/promo-codes/promo-codes-context';

import PromoCode from './promo-code/promo-code';

const PromoCodeList = () => {
  const {timezone} = useAppContext();
  const {promoCodeList, setPromoCodeList} = usePromoCodesContext();

  const {t} = useTranslation();

  return (
    <Table style={{marginBottom: 8}}>
      <TableHead>
        <TableCell>{t`Promo code`}</TableCell>
        <TableCell>{t`Discount`}</TableCell>
        <TableCell>{`${t`Start date`} (${timezone})`}</TableCell>
        <TableCell>{`${t`End date`} (${timezone})`}</TableCell>
        <TableCell>{t`Integration`}</TableCell>
        <TableCell>{t`Usage counter`}</TableCell>
        <TableCell>{t`Deleted`}</TableCell>
        <TableCell isIcon />
        <TableCell isIcon />
      </TableHead>

      <TableBody>
        {promoCodeList &&
          promoCodeList.map(item => (
            <PromoCode
              key={item.id}
              promoCode={item}
              promoCodeList={promoCodeList}
              setPromoCodeList={setPromoCodeList}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default PromoCodeList;
