import React from 'react';
import {Popup, Title, Line, Wrapper, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {useAppContext} from 'app-context';
import {usePaymentListContext} from '../../payment-list-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import File from './file/file';

interface Values {
  file: File | undefined;
}

interface Importer {
  isImporterActive: boolean;
  setIsImporterActive: AppEnv.SetState<boolean>;
}

const Importer = ({isImporterActive, setIsImporterActive}: Importer) => {
  const {pushNotification} = useAppContext();
  const {getPaymentList} = usePaymentListContext();

  const {postPaymentsTelphin} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    if (values.file) {
      const res = await postPaymentsTelphin(values.file);

      if (res.data) {
        const {count} = res.data;

        getPaymentList();
        setIsImporterActive(false);

        pushNotification(
          t('{{count}} Telphin payment has been imported', {count})
        );
      }
    }

    return true;
  };

  const validationSchema = yup.object().shape({file: yup.mixed().required()});

  return (
    <Popup
      isActive={isImporterActive}
      onClick={() => setIsImporterActive(false)}
    >
      <Title.H2 style={{marginBottom: 16}}>
        {t`Import Telphin payments`}
      </Title.H2>

      <Formik
        initialValues={{file: undefined}}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <File />
          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Import`}</SaveButton>

            <Button color="white" onClick={() => setIsImporterActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Importer;
