import React from 'react';
import {Title, Wrapper, Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useNotificationsContext} from 'pages/notifications/notifications-context';
import Creator from './creator/creator';

const Header = () => {
  const {isFilterActive, setIsFilterActive} = useNotificationsContext();
  const {t} = useTranslation();

  return (
    <>
      <Title.H1>{t`Notifications`}</Title.H1>

      <Wrapper gap={8}>
        <Creator />

        <Button
          color={isFilterActive ? 'active' : 'white'}
          onClick={() => setIsFilterActive(prevValue => !prevValue)}
        >
          <Icons.Filter />
          {t`Filter`}
        </Button>
      </Wrapper>
    </>
  );
};

export default Header;
