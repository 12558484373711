import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import moment from 'moment';

const DataEvent = () => {
  const [field, , helpers] = useField<string>('data_event');
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    helpers.setValue(moment(value).utcOffset(3).format('YYYY-MM-DD HH:mm:ss'));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{`${t`Date`} (UTC+3)`}</Title.H3>

      <Input
        {...field}
        type="datetime-local"
        width="max"
        onChange={handleChange}
      />
    </div>
  );
};

export default DataEvent;
