import React from 'react';
import {useTranslation} from 'react-i18next';

import {PieGraph} from 'components';
import {useConnectionsContext} from '../connections-context';

const Telegram = () => {
  const {stats} = useConnectionsContext();
  const {t} = useTranslation();

  const data = [
    {value: stats.telegram.busy, label: t`In use`, color: '#56caf0'},
    {value: stats.telegram.free, label: t`Free`, color: '#83d11f'},
    {value: stats.telegram.timeout, label: t`Hanging`, color: '#ff8242'}
  ];

  return <PieGraph title="Telegram" data={data} />;
};

export default Telegram;
