import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import GraphTooltip, {Keys} from './graph-tooltip/graph-tooltip';

interface BarGraph {
  children?: React.ReactNode;
  currency?: string;
  data: object[];
  keys: Keys;
}

const BarGraph = ({children, currency, data, keys}: BarGraph) => {
  const isDarkTheme = document.querySelector('.dark-theme');
  const stroke = isDarkTheme ? '#939b9f' : '#d4e6ed';

  const axisParams = {
    stroke,
    tick: {fill: '#c0c2c4', fontSize: 12},
    tickMargin: 4
  };

  return (
    <div className="bar-graph">
      <ResponsiveContainer height={200}>
        <BarChart
          barCategoryGap={8}
          data={data}
          margin={{top: 0, right: 0, left: 0, bottom: 0}}
          maxBarSize={20}
        >
          <Tooltip
            animationDuration={1000}
            animationEasing="ease-in-out"
            content={<GraphTooltip currency={currency} keys={keys} />}
            cursor={{fill: isDarkTheme ? '#4a6972' : '#f3fafc'}}
          />

          <CartesianGrid stroke={stroke} strokeDasharray="4" vertical={false} />

          <XAxis {...axisParams} dataKey="date_period" tickLine={false} />

          <YAxis {...axisParams} orientation="right" tickLine={true} />

          {Object.entries(keys).map(([key, value], i) => (
            <Bar
              key={i}
              animationDuration={1000}
              animationEasing="ease-in-out"
              dataKey={key}
              fill={value.color}
              stackId={i}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>

      {children}
    </div>
  );
};

export default BarGraph;
