import React from 'react';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {Title as CoreTitle, Input} from 'whatcrm-core';

const Link = () => {
  const [field, , helpers] = useField<string>('link');
  const {t} = useTranslation();

  return (
    <div>
      <CoreTitle.H3 style={{marginBottom: 8}}>{t`Link`}</CoreTitle.H3>

      <Input
        {...field}
        type="url"
        placeholder={t`Link`}
        width="max"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Link;
