import React from 'react';
import {Checkbox} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const IsPremium = () => {
  const [field, , helpers] = useField('is_premium');
  const {t} = useTranslation();

  return <Checkbox {...field} label={t`Premium`} onChange={helpers.setValue} />;
};

export default IsPremium;
