import moment from 'moment';
import * as AppEnv from 'app-env';

const filterToRequest = (
  filter: AppEnv.IntegrationFilter
): AppEnv.IntegrationRequest => ({
  crm_param_id: filter.crm_param_id ? {value: filter.crm_param_id} : undefined,
  date_add_after: filter.date_add_after
    ? {value: moment.utc(filter.date_add_after).format('X'), type: '>'}
    : undefined,
  date_add_before: filter.date_add_before
    ? {
        value: moment.utc(`${filter.date_add_before} 23:59:59`).format('X'),
        type: '<'
      }
    : undefined,
  domain: filter.domain ? {value: filter.domain, type: 'like'} : undefined,
  id: filter.id ? {value: filter.id} : undefined,
  integration_type_id: filter.integration_type_id
    ? {value: filter.integration_type_id}
    : undefined,
  is_deleted:
    filter.is_deleted && filter.is_deleted != '-1'
      ? {value: filter.is_deleted}
      : undefined,
  is_partner:
    filter.is_partner && filter.is_partner != '-1'
      ? {value: filter.is_partner}
      : undefined,
  is_premium:
    filter.is_premium && filter.is_premium != '-1'
      ? {value: filter.is_premium}
      : undefined,
  lang: filter.lang ? {value: filter.lang} : undefined,
  telphin_code: filter.telphin_code
    ? {value: filter.telphin_code, type: 'like'}
    : undefined,
  widget_code: filter.widget_code ? {value: filter.widget_code} : undefined
});

export default filterToRequest;
