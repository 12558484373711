import React, {useState, useEffect} from 'react';
import {Title, Wrapper, Input, Preloader, EmptyBox} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {BarGraph, Legend} from 'components';
import {useRequest} from 'common/hooks';

interface Stats {
  clients: number;
  date_period: string;
  partners: number;
}

interface Partners {
  c: number;
  date_period: string;
}

const NewClients = () => {
  const [stats, setStats] = useState<Stats[]>();
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();

  const {fetchStatistics} = useRequest();
  const {t} = useTranslation();

  const getStats = async () => {
    const [{data: partners}, {data: clients}] = await Promise.all([
      fetchStatistics<Partners[]>('partners', {start, end}),
      fetchStatistics<Partners[]>('partners-clients', {start, end})
    ]);

    if (partners) {
      const res: Stats[] = partners?.map(item => ({
        clients: 0,
        date_period: item.date_period,
        partners: item.c
      }));

      clients?.forEach(item => {
        const index = res.findIndex(
          entity => item.date_period == entity.date_period
        );

        if (index > -1) {
          res[index].clients = item.c;
          return;
        }

        res.push({clients: item.c, date_period: item.date_period, partners: 0});
      });

      res.sort(
        (a, b) =>
          parseInt(moment(`01.${a.date_period}`).format('X')) -
          parseInt(moment(`01.${b.date_period}`).format('X'))
      );

      setStats(res);
      return;
    }

    setStats([]);
  };

  useEffect(() => {
    if (start && end) {
      setStats(undefined);
      getStats();
    }
  }, [start, end]);

  useEffect(() => {
    getStats();
  }, []);

  const {clients = 0, partners = 0} = stats?.length
    ? stats.reduce(
        (acc, item) => {
          acc.clients += item.clients;
          acc.partners += item.partners;
          return acc;
        },
        {clients: 0, partners: 0}
      )
    : {};

  const keys = {
    clients: {color: '#83d11f', label: t`New clients`, value: clients},
    partners: {color: '#5bc6ea', label: t`New partners`, value: partners}
  };

  return (
    <div>
      <Title.H2 style={{marginBottom: 8}}>
        {t`New clients and partners`}
      </Title.H2>

      <Wrapper gap={8} style={{marginBottom: 32}}>
        <div>
          <Title.H3 style={{marginBottom: 8}}>{t`Start`}</Title.H3>

          <Input
            name="start"
            type="date"
            value={start}
            max={moment().format('yyyy-MM-DD')}
            onChange={setStart}
          />
        </div>

        <div>
          <Title.H3 style={{marginBottom: 8}}>{t`End`}</Title.H3>

          <Input
            name="end"
            type="date"
            value={end}
            max={moment().format('yyyy-MM-DD')}
            onChange={setEnd}
          />
        </div>
      </Wrapper>

      {stats ? (
        stats.length ? (
          <BarGraph data={stats} keys={keys}>
            <Legend data={Object.values(keys)} />
          </BarGraph>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default NewClients;
