import React from 'react';
import {Button, Icons, EmptyBox} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

interface State {
  referrer?: string;
}

interface Firewall {
  element: React.ReactNode;
}

const Firewall = ({element}: Firewall) => {
  const {pages} = useWorkspaceContext();

  const location: AppEnv.Location<State> = useLocation();
  const {t} = useTranslation();

  const isAccessGranted =
    location.state?.referrer ||
    !!Object.values(pages).find(item =>
      location.pathname.includes(item.pathname)
    );

  return (
    <>
      {isAccessGranted ? (
        element
      ) : (
        <EmptyBox title="403 Forbidden">
          <Link to="/">
            <Button color="transparent">
              <Icons.ArrowLeft />
              {t('Home', {context: '403'})}
            </Button>
          </Link>
        </EmptyBox>
      )}
    </>
  );
};

export default Firewall;
