import React, {useEffect, useState} from 'react';
import {EmptyBox, Preloader, Substrate, Title, Wrapper} from 'whatcrm-core';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';

import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import Header from './header/header';
import IntegrationContext from './integration-context';
import Queues from './queues/queues';
import Sources from './sources/sources';

const Integration = () => {
  const {crmList} = useWorkspaceContext();

  const [integration, setIntegration] = useState<AppEnv.Integration | null>();

  const {fetchIntegrations} = useRequest();
  const {id} = useParams();

  const getIntegration = async () => {
    if (!id) return setIntegration(null);

    const {data} = await fetchIntegrations<AppEnv.Integration>({
      id: parseInt(id)
    });

    setIntegration(data || null);
  };

  useEffect(() => {
    getIntegration();
  }, []);

  const crm = crmList.find(item => item.id == integration?.integration_type_id);

  return (
    <div className="content">
      <Wrapper gap={8} isColumn>
        <Header />

        <Substrate>
          {typeof integration == 'object' ? (
            integration ? (
              <IntegrationContext.Provider value={{crm, integration}}>
                <Title.H1 style={{marginBottom: 8}}>
                  {integration.domain}
                </Title.H1>

                <Routes>
                  <Route path="/" element={<Navigate to="/integrations" />} />
                  <Route path="sources" element={<Sources />} />
                  <Route path="queues" element={<Queues />} />
                </Routes>
              </IntegrationContext.Provider>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )}
        </Substrate>
      </Wrapper>
    </div>
  );
};

export default Integration;
