import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';

import {useWorkspaceContext} from 'workspace/workspace-context';

const IntegrationTypeId = () => {
  const {getFusedCrmList} = useWorkspaceContext();
  const [field, , helpers] = useField('integration_type_id');

  const fusedCrmList = getFusedCrmList(1);

  const options = fusedCrmList.map(item => ({
    comment: item.description || undefined,
    label: item.title,
    value: item.id
  }));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>CRM</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder="CRM"
        isClearable
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default IntegrationTypeId;
