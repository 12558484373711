import React from 'react';
import {Popup, Title, Wrapper, Line, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useAutomationsContext} from 'pages/automations/automations-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Question from './question/question';
import Answer from './answer/answer';
import Link from './link/link';
import Sleep from './sleep/sleep';

interface Editor {
  id: number;
  initialValues: AppEnv.AutomationForm;
  isActive: boolean;
  onClick: () => void;
}

const Editor = ({id, initialValues, isActive, onClick}: Editor) => {
  const {pushNotification} = useAppContext();
  const {automationList, setAutomationList} = useAutomationsContext();

  const {putAutomations} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.AutomationForm) => {
    const {data} = await putAutomations(id, values);

    if (data) {
      const index = automationList?.findIndex(item => item.id == data.id);

      if (typeof index == 'number') {
        setAutomationList(prevValue =>
          update(prevValue, {
            [index]: {$set: data}
          })
        );

        pushNotification(t`The automation has been updated`);
        onClick();
      }
    }

    return true;
  };

  return (
    <Popup isActive={isActive} onClick={onClick}>
      <Title.H2 style={{marginBottom: 16}}>{t`Update the automation`}</Title.H2>

      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <Question />
            <Answer />
            <Link />
            <Sleep />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={onClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
