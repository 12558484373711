import React, {useState} from 'react';
import {Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import Editor from '../../editor/editor';
import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const Creator = () => {
  const {pushNotification} = useAppContext();
  const {setUserList} = useWorkspaceContext();

  const [isEditorActive, setIsEditorActive] = useState(false);

  const {postUsers} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.UserForm) => {
    const {data} = await postUsers(values as AppEnv.UserForm);

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.username, t`A user has been created`);

      setUserList(prevValue =>
        update(prevValue, {
          $push: [data]
        })
      );
    }

    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsEditorActive(true)}>
        <Icons.Plus />
        {t`Create`}
      </Button>

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default Creator;
