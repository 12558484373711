import React, {useState} from 'react';
import {TableRow, TableCell, Button, Icons, Confirmation} from 'whatcrm-core';
// import {Link, useLocation} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import {usePartnerContext} from '../../../partner-context';

interface Integration {
  integration: AppEnv.Integration;
}

const Integration = ({integration}: Integration) => {
  const {pushNotification} = useAppContext();
  const {crmList} = useWorkspaceContext();
  const {partner, setPartner} = usePartnerContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  // const {pathname} = useLocation();
  const {putIntegrations} = useRequest();
  const {t} = useTranslation();

  const crm = crmList.find(item => item.id == integration.integration_type_id);

  const handleClick = async (res: boolean) => {
    if (res) {
      const {data} = await putIntegrations(integration.id, {is_partner: 0});

      if (data) {
        pushNotification(
          integration.domain,
          t`The integration has been detached`
        );

        const index = partner.integrations?.findIndex(
          item => item.id == data.id
        );

        setPartner(prevValue => {
          if (typeof index == 'number' && index != -1) {
            return update(prevValue, {
              integrations: {$splice: [[index, 1]]}
            });
          }
        });
      }
    }

    setIsConfirmationActive(false);
    return true;
  };

  const {instances_count} = integration;

  return (
    <TableRow isActive={isConfirmationActive}>
      <TableCell>{integration.domain}</TableCell>
      <TableCell>{crm?.title}</TableCell>

      <TableCell>
        {/* {integration.instances_count > 0 ? (
          <Link
            to="/connections"
            state={{
              filter: {domain: {value: integration.domain}},
              referrer: {path: pathname}
            }}
          >
            <Button
              color="transparent"
              isLink
            >
              {integration.instances_count}
            </Button>
          </Link>
        ) : (
          integration.instances_count
        )} */}

        <Trans count={instances_count}>
          {{instances_count}}
          {' connection'}
        </Trans>
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Detach`}
          onClick={() => setIsConfirmationActive(true)}
        >
          <Icons.Trash />
        </Button>

        <Confirmation
          title={t`Are you sure you want to detach this integration?`}
          isActive={isConfirmationActive}
          onClick={handleClick}
        />
      </TableCell>
    </TableRow>
  );
};

export default Integration;
