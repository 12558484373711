import React, {useState} from 'react';
import {Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../../editor/editor';
import {useStaffContext} from 'pages/staff/staff-context';

const Creator = () => {
  const {pushNotification} = useAppContext();
  const {getEmployeeList} = useStaffContext();
  const [isEditorActive, setIsEditorActive] = useState(false);

  const {postManagers} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.EmployeeForm) => {
    const {data} = await postManagers(values);

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.fullname, t`An employee has been created`);
      getEmployeeList();
    }

    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsEditorActive(true)}>
        <Icons.Plus />
        {t`Create`}
      </Button>

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default Creator;
