import React from 'react';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {Title as CoreTitle, Input} from 'whatcrm-core';

const DescriptionShort = () => {
  const [field, , helpers] = useField<string>('description_short');
  const {t} = useTranslation();

  return (
    <div>
      <CoreTitle.H3 style={{marginBottom: 8}}>{t`Name`}</CoreTitle.H3>

      <Input
        {...field}
        placeholder={t`Name`}
        width="max"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default DescriptionShort;
