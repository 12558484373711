import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface UsersContext {
  isFilterActive: boolean;
  setIsFilterActive: AppEnv.SetState<boolean>;
}

const UsersContext = React.createContext<UsersContext>({} as UsersContext);

export const useUsersContext = () => useContext(UsersContext);
export default UsersContext;
