import moment from 'moment';
import * as AppEnv from 'app-env';

const filterToRequest = (filter: AppEnv.EventFilter): AppEnv.EventRequest => ({
  category_id: filter.category_id ? {value: filter.category_id} : undefined,
  category: filter.category ? {value: filter.category} : undefined,
  integration_id: filter.integration_id
    ? {value: filter.integration_id}
    : undefined,
  ip: filter.ip ? {value: filter.ip} : undefined,
  log_time_after: filter.log_time_after
    ? {value: moment.utc(filter.log_time_after).format('X'), type: '>'}
    : undefined,
  log_time_before: filter.log_time_before
    ? {
        value: moment.utc(`${filter.log_time_before} 23:59:59`).format('X'),
        type: '<'
      }
    : undefined,
  new: filter.new ? {value: filter.new, type: 'like'} : undefined,
  old: filter.old ? {value: filter.old, type: 'like'} : undefined,
  user_id: filter.user_id ? {value: filter.user_id} : undefined
});

export default filterToRequest;
