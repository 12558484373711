import React, {useEffect} from 'react';
import {useFormikContext} from 'formik';

interface SetValues {
  filter: object;
}

const SetValues = ({filter}: SetValues) => {
  const {setFieldValue} = useFormikContext();

  useEffect(() => {
    Object.entries(filter).map(([key, value]) => setFieldValue(key, value));
  }, []);

  return <></>;
};

export default SetValues;
