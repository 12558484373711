import React, {useContext} from 'react';
import {CoreEnv} from 'whatcrm-core';

import * as AppEnv from 'app-env';

interface AppContext {
  client?: AppEnv.Client | null;
  preferences: AppEnv.Preferences;
  theme: CoreEnv.Theme;
  timezone: AppEnv.Timezone;
  isNavActive: boolean;
  setClient: AppEnv.SetState<AppEnv.Client | null | undefined>;
  setPreferences: AppEnv.SetState<AppEnv.Preferences>;
  setTheme: AppEnv.SetState<CoreEnv.Theme>;
  pushNotification: (arg1: string, arg2?: string) => void;
  setIsNavActive: AppEnv.SetState<boolean>;
}

const AppContext = React.createContext<AppContext>({} as AppContext);

export const useAppContext = () => useContext(AppContext);
export default AppContext;
