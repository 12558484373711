import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface HomeContext {
  connectionList: AppEnv.Instance[] | undefined;
  selectedConnectionIdList: number[] | undefined;
  isFilterActive: boolean;
  setConnectionList: AppEnv.SetState<AppEnv.Instance[] | undefined>;
  setSelectedConnectionIdList: AppEnv.SetState<number[] | undefined>;
  setIsFilterActive: AppEnv.SetState<boolean>;
  getConnectionList: (page?: number) => Promise<void>;
}

const HomeContext = React.createContext<HomeContext>({} as HomeContext);

export const useHomeContext = () => useContext(HomeContext);
export default HomeContext;
