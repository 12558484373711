import React from 'react';
import {Wrapper} from 'whatcrm-core';

import {Sidebar} from 'components';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Column from './column/column';
import Comment from './comment/comment';
import Country from './country/country';
import DateYslugAfter from './date-yslug-after/date-yslug-after';
import DateYslugBefore from './date-yslug-before/date-yslug-before';
import Domain from './domain/domain';
import DomainFree from './domain-free/domain-free';
import IsDeleted from './is-deleted/is-deleted';
import TariffId from './tariff-id/tariff-id';
import TransactionId from './transaction-id/transaction-id';

interface PaymentFilter {
  filter: AppEnv.PaymentFilter;
  isDisabled: boolean;
  isFilterActive: boolean;
  setFilter: AppEnv.SetState<AppEnv.PaymentFilter>;
  setIsFilterActive: AppEnv.SetState<boolean>;
}

const Filter = ({
  filter,
  isDisabled,
  isFilterActive,
  setFilter,
  setIsFilterActive
}: PaymentFilter) => {
  const {fetchIntegrations} = useRequest();

  const handleSubmit = async (values: AppEnv.PaymentFilter) => {
    let integration_id: string[] | undefined;

    if (values.domain) {
      const {data} = values.domain
        ? await fetchIntegrations<AppEnv.Integration[]>({
            filter: {domain: {value: values.domain, type: 'like'}}
          })
        : {data: undefined};

      integration_id = data?.length
        ? data.map(item => item.id.toString())
        : ['-1'];
    } else if (values.without_domain) {
      integration_id = ['0'];
    }

    setFilter({...values, integration_id});
  };

  const initialValues = {
    is_deleted: filter.is_deleted || '-1'
  };

  return (
    <Sidebar
      filter={filter}
      initialValues={initialValues}
      isActive={isFilterActive}
      isDisabled={isDisabled}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
      onSubmit={handleSubmit}
    >
      <DateYslugAfter />
      <DateYslugBefore />

      <Wrapper gap={8} isColumn>
        <Domain />
        <DomainFree />
      </Wrapper>

      <Country />
      <Column />
      <TariffId />
      <Comment />
      <TransactionId />
      <IsDeleted />
    </Sidebar>
  );
};

export default Filter;
