import React from 'react';
import {Wrapper, Dropzone, Text} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const File = () => {
  const [field, meta, helpers] = useField<File>('file');
  const {t} = useTranslation();

  const isError = !!(meta.touched && meta.error);

  return (
    <Wrapper
      gap={16}
      isColumn
    >
      <Dropzone
        accept={{'text/csv': ['.csv']}}
        text=".csv"
        onChange={value => helpers.setValue(value[0])}
      />

      {field.value?.name ? (
        <Text
          color="dark"
          whiteSpace="hide-overflow"
        >
          {field.value.name}
        </Text>
      ) : (
        isError && <Text style={{color: '#ff8242'}}>{t`Select a file`}</Text>
      )}
    </Wrapper>
  );
};

export default File;
