import React from 'react';
import {useTranslation} from 'react-i18next';

import IntegrationFinder, {
  Values
} from 'pages/billing/integration-finder/integration-finder';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useSubscriptionListContext} from '../../subscription-list-context';
import * as AppEnv from 'app-env';

interface IntegrationChanger {
  isIntegrationChangerActive: boolean;
  setIsIntegrationChangerActive: AppEnv.SetState<boolean>;
}

const IntegrationChanger = ({
  isIntegrationChangerActive,
  setIsIntegrationChangerActive
}: IntegrationChanger) => {
  const {pushNotification} = useAppContext();

  const {
    getSubscriptionList,
    paymentSystem,
    selectedSubscriptionIdList,
    setSelectedSubscriptionIdList
  } = useSubscriptionListContext();

  const {putSubscriptions} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    if (!values.integration_id) return true;

    const promises = selectedSubscriptionIdList.map(async item => {
      await putSubscriptions(paymentSystem, item, values);
    });

    await Promise.all(promises);

    setIsIntegrationChangerActive(false);
    pushNotification(t`The integration has been changed`);
    setSelectedSubscriptionIdList([]);
    getSubscriptionList();

    return true;
  };

  return (
    <IntegrationFinder
      isActive={isIntegrationChangerActive}
      onClick={() => setIsIntegrationChangerActive(false)}
      onSubmit={handleSubmit}
    />
  );
};

export type {Values} from 'pages/billing/integration-finder/integration-finder';
export default IntegrationChanger;
