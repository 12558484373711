import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';

import {useTranslation} from 'react-i18next';

import Home from './home/home';
import Containers from './containers/containers';

const Servers = () => {
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Servers`} - Whatcrm Console`;
  }, []);

  return (
    <div className="content">
      <Routes>
        <Route
          path="/"
          element={<Home />}
        />

        <Route
          path=":chat_id"
          element={<Containers />}
        />
      </Routes>
    </div>
  );
};

export default Servers;
