import React from 'react';
import {
  Button,
  Line,
  Popup,
  SaveButton,
  Title as CoreTitle,
  Wrapper
} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {useAppContext} from 'app-context';
import {Roles} from 'app-enums';
import * as AppEnv from 'app-env';

import Description from './description/description';
import DescriptionShort from './description-short/description-short';
import Image from './image/image';
import IsMain from './is-main/is-main';
import IsRegistration from './is-registration/is-registration';
import Link from './link/link';
import Title from './title/title';
import TokenId from './token-id/token-id';
import Webhook from './webhook/webhook';
import WebhookChat from './webhook-chat/webhook-chat';
import WebhookPay from './webhook-pay/webhook-pay';

interface Editor {
  initialValues?: AppEnv.CrmForm;
  isActive: boolean;
  isUpdate?: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.CrmForm>;
}

const Editor = ({
  initialValues = {
    description_short: undefined,
    description: undefined,
    image: undefined,
    is_main: 0,
    is_registration: undefined,
    link: undefined,
    title: undefined,
    token_id: undefined,
    webhook_chat: undefined,
    webhook_pay: undefined,
    webhook: undefined
  },
  isActive,
  isUpdate,
  onClick,
  onSubmit
}: Editor) => {
  const {client} = useAppContext();
  const {t} = useTranslation();

  const isIsMainShown = !!client && client.access_level >= Roles.ADMIN;

  const validationSchema = yup
    .object()
    .shape({title: yup.string().trim().required()});

  return (
    <Popup isActive={isActive} onClick={onClick}>
      <CoreTitle.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Update the CRM` : t`Create a CRM`}
      </CoreTitle.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <Title />
            <DescriptionShort />
            <Description />
            <IsRegistration />
            <TokenId />
            <Image />
            <Link />
            <Webhook />
            <WebhookPay />
            <WebhookChat />
            {isIsMainShown && <IsMain />}
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={onClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
