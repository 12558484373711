import React, {useState} from 'react';
import {ContextMenu, Confirmation} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../../connection-context';
import {Roles} from 'app-enums';

import Api from './api/api';
import Popup from './popup/popup';
import Editor from './editor/editor';
import useActions from './actions';

const ActionMenu = () => {
  const {client} = useAppContext();
  const {connection} = useConnectionContext();

  const [status, setStatus] = useState<string>();
  const [version, setVersion] = useState<string>();
  const [isApiActive, setIsApiActive] = useState(false);
  const [isPropsActive, setIsPropsActive] = useState(false);
  const [isEditorActive, setIsEditorActive] = useState(false);

  const [isDeleteConfirmationActive, setIsDeleteConfirmationActive] =
    useState(false);

  const [isLogoutConfirmationActive, setIsLogoutConfirmationActive] =
    useState(false);

  const {
    closeConnection,
    deleteConnection,
    enableConnection,
    freeConnection,
    getConnectionStatus,
    getConnectionVersion,
    logoutConnection,
    resuscitateConnection
  } = useActions();

  const {t} = useTranslation();

  const handleStatus = async () => {
    const res = await getConnectionStatus();

    if (res) {
      setStatus(res);
    }
  };

  const handleViewVersion = async () => {
    const res = await getConnectionVersion();

    if (res) {
      setVersion(res);
    }
  };

  const handleClick = () => {
    if (status) {
      setStatus(undefined);
    } else if (version) {
      setVersion(undefined);
    } else if (isPropsActive) {
      setIsPropsActive(false);
    }
  };

  const options: {
    value: React.ReactNode;
    isDisabled?: boolean;
    onClick: (() => void) | string;
  }[] = [
    {value: t`Change`, isDisabled: true, onClick: () => {}},
    {value: t`Close`, onClick: closeConnection},
    {
      value: t`Delete`,
      isDisabled: connection.is_deleted != 1,
      onClick: () => setIsDeleteConfirmationActive(true)
    },
    {
      value: connection.is_deleted == 0 ? t`Disable` : t`Enable`,
      isDisabled: connection.is_deleted == 2,
      onClick: enableConnection
    },
    {
      value: t`Execute a method`,
      isDisabled: client?.access_level && client.access_level < Roles.ADMIN,
      onClick: () => setIsApiActive(true)
    },
    {
      value: t`Logout`,
      isDisabled: connection.status != 'READY',
      onClick: () => setIsLogoutConfirmationActive(true)
    },
    {
      value: t`Open the events`,
      onClick: `/events?filter_category=instances&filter_category_id=${connection.id}`
    },
    {
      value: t`Release`,
      isDisabled: !connection.integration,
      onClick: freeConnection
    },
    {value: t`Resuscitate`, onClick: resuscitateConnection},
    {value: t`Update`, onClick: () => setIsEditorActive(true)},
    {value: t`View properties`, onClick: () => setIsPropsActive(true)},
    {value: t`View status`, onClick: handleStatus},
    {
      value: t`View version`,
      isDisabled: connection.version != 'whatcrm',
      onClick: handleViewVersion
    }
  ].filter(item => !item.isDisabled);

  return (
    <>
      <ContextMenu options={options} />

      <Api
        isApiActive={isApiActive}
        setIsApiActive={setIsApiActive}
      />

      <Popup
        status={status}
        version={version}
        isPropsActive={isPropsActive}
        onClick={handleClick}
      />

      <Editor
        isEditorActive={isEditorActive}
        setIsEditorActive={setIsEditorActive}
      />

      <Confirmation
        title={t`Are you sure you want to delete this connection?`}
        isActive={isDeleteConfirmationActive}
        onClick={res => deleteConnection(res, setIsDeleteConfirmationActive)}
      />

      <Confirmation
        title={t`Are you sure you want to logout this connection?`}
        isActive={isLogoutConfirmationActive}
        onClick={res => logoutConnection(res, setIsLogoutConfirmationActive)}
      />
    </>
  );
};

export default ActionMenu;
