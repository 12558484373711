import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

const DatePay = () => {
  const [field, , helpers] = useField('date_pay');
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    helpers.setValue(parseInt(moment(new Date(value)).format('X')));

  const value = field.value
    ? moment.unix(field.value).utcOffset(3).format('YYYY-MM-DD HH:mm')
    : undefined;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>
        {`${t`Payment date`} (UTC+3)`}
      </Title.H3>

      <Input
        {...field}
        type="datetime-local"
        value={value}
        width="max"
        onChange={handleChange}
      />
    </div>
  );
};

export default DatePay;
