import React from 'react';
import {Wrapper, Button} from 'whatcrm-core';
import {useFormikContext} from 'formik';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useWorkspaceContext} from 'workspace/workspace-context';

interface Footer {
  isDisabled: boolean;
  onCancel: () => void;
  onClick: () => void;
}

const Footer = ({isDisabled, onCancel, onClick}: Footer) => {
  const {pages} = useWorkspaceContext();
  const {resetForm} = useFormikContext();

  const location = useLocation();
  const {t} = useTranslation();

  const handleClick = () => {
    resetForm();
    onCancel();
    if (window.screen.width <= 425) onClick();
  };

  const isAccessGranted = !!Object.values(pages).find(item =>
    location.pathname.includes(item.pathname)
  );

  return (
    <div className="sidebar__footer">
      <Wrapper gap={8}>
        <Button type="submit" isDisabled={isDisabled}>{t`Apply`}</Button>

        {isAccessGranted && (
          <Button color="white" onClick={handleClick}>
            {t`Reset`}
          </Button>
        )}
      </Wrapper>
    </div>
  );
};

export default Footer;
