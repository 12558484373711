import React from 'react';
import {Button, Icons, Title, Wrapper} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useHomeContext} from '../../home-context';
import * as AppEnv from 'app-env';

import Creator from './creator/creator';
import ExportCsv from './export-csv/export-csv';
import Reward from './reward/reward';

interface State {
  referrer?: string;
}

const Header = () => {
  const {isFilterActive, setIsFilterActive} = useHomeContext();

  const {state}: AppEnv.Location<State> = useLocation();
  const {t} = useTranslation();

  return (
    <>
      {state?.referrer && (
        <Link to={state.referrer}>
          <Button color="white">
            <Icons.AngleLeft />
            {t`Back`}
          </Button>
        </Link>
      )}

      <Title.H1>{t`Partners`}</Title.H1>

      <Wrapper gap={8}>
        <Creator />

        <Button
          color={isFilterActive ? 'active' : 'white'}
          onClick={() => setIsFilterActive(prevValue => !prevValue)}
        >
          <Icons.Filter />
          {t`Filter`}
        </Button>

        <Reward />
        <ExportCsv />
      </Wrapper>
    </>
  );
};

export default Header;
