import React, {useState} from 'react';
import {Button, Confirmation, Icons, TableCell, TableRow} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import update from 'immutability-helper';

import Editor from '../../editor/editor';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useTelphinContext} from '../../telphin-context';
import * as AppEnv from 'app-env';

import Rates from './rates/rates';
import Transaction from './transaction/transaction';

interface Subscription {
  subscription: AppEnv.Telphin;
}

const Subscription = ({subscription}: Subscription) => {
  const {pushNotification} = useAppContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);
  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isTransactionActive, setIsTransactionActive] = useState(false);

  const {deleteTelphins, putTelphins} = useRequest();
  const {getSubscriptionList, setSubscriptionList} = useTelphinContext();
  const {t} = useTranslation();

  const deleteSubscription = async () => {
    const res = await deleteTelphins(subscription.id);
    if (res == null) return true;

    setIsConfirmationActive(false);
    pushNotification(t`The subscription has been deleted`);
    setTimeout(() => getSubscriptionList(), 200);

    return true;
  };

  const handleClick = (res: boolean) =>
    res ? deleteSubscription() : setIsConfirmationActive(false);

  const handleSubmit = async (values: AppEnv.TelphinForm) => {
    const {data} = await putTelphins(subscription.id, values);
    if (!data) return true;

    setSubscriptionList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == data.id);

      if (index == undefined) return prevValue;
      return update(prevValue, {[index]: {$set: data}});
    });

    pushNotification(t`The subscription has been updated`);
    setIsEditorActive(false);

    return true;
  };

  const data_event = subscription.data_event
    ? moment(subscription.data_event)
    : null;

  const isActive =
    isTransactionActive || isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>
        {data_event && (
          <>
            {data_event.format('L')}
            <br />
            {data_event.format('LT')}
          </>
        )}
      </TableCell>

      <TableCell>
        {subscription.action_type == 'connection'
          ? t`Connecting`
          : subscription.action_type == 'delete' && t`Deleting`}
      </TableCell>

      <TableCell>
        <Link
          state={{referrer: window.location.pathname + window.location.search}}
          to={`/integrations?filter_telphin_code=${subscription.code_client}`}
        >
          <Button color="transparent" isLink>
            {subscription.code_client}
          </Button>
        </Link>
      </TableCell>

      <TableCell>{subscription.bonus_id}</TableCell>
      <Rates subscription={subscription} />
      <TableCell>{subscription.line}</TableCell>

      <TableCell>
        <Button
          color="transparent"
          onClick={() => setIsTransactionActive(true)}
        >
          {t`Transaction`}
        </Button>

        <Transaction
          id={subscription.id}
          isTransactionActive={isTransactionActive}
          setIsTransactionActive={setIsTransactionActive}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={subscription}
          isActive={isEditorActive}
          isUpdate
          onClick={() => setIsEditorActive(false)}
          onSubmit={handleSubmit}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Delete`}
          onClick={() => setIsConfirmationActive(true)}
        >
          <Icons.Trash />
        </Button>

        <Confirmation
          title={t`Are you sure you want to delete this subscription?`}
          isActive={isConfirmationActive}
          onClick={handleClick}
        />
      </TableCell>
    </TableRow>
  );
};

export default Subscription;
