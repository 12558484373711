import React from 'react';
import {Wrapper} from 'whatcrm-core';

import LegendItem from './legend-item/legend-item';

interface Data {
  color: string;
  label: string;
  value: number;
}

type LegendData = Data[];

interface LegendDataAlt {
  [index: string]: Data;
}

interface Legend<T> {
  data: LegendData | LegendDataAlt;
  value?: string;
  isIndependent?: boolean;
  onClick?: (arg: T) => void;
}

const Legend = <T extends string>({
  data,
  value,
  isIndependent,
  onClick
}: Legend<T>) => {
  const max = Object.entries(data).reduce(
    (res, [, item]) => res + item.value,
    0
  );

  return (
    <Wrapper
      gap={4}
      isColumn
    >
      {Object.entries(data).map((item, i) => (
        <LegendItem
          value={value}
          item={item[1]}
          itemKey={item[0]}
          key={i}
          max={max}
          isIndependent={isIndependent}
          onClick={onClick}
        />
      ))}
    </Wrapper>
  );
};

export default Legend;
