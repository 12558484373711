import React, {useState, useEffect} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import Content from './content/content';
import Filter from './filter/filter';
import HomeContext from './home-context';
import filterToRequest from './filter-to-request';

const Home = () => {
  const [integrationList, setIntegrationList] =
    useState<AppEnv.Integration[]>();

  const [isLoading, setIsLoading] = useState<boolean>();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.IntegrationFilter> = useFilter();

  const {t} = useTranslation();
  const {page = '1'} = useSearchParams();
  const {fetchIntegrations} = useRequest();
  const pagination = usePagination(parseInt(page));

  usePaginationParam(pagination.currentPage);

  useEffect(() => {
    document.title = `${t`Integrations`} - Whatcrm Console`;
  }, []);

  const getIntegrationList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchIntegrations<AppEnv.Integration[]>({
      filter: filterToRequest(filter),
      page: pagination.currentPage
    });

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setIntegrationList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getIntegrationList();
  }, [filter, pagination.currentPage]);

  return (
    <HomeContext.Provider
      value={{
        integrationList,
        isFilterActive,
        getIntegrationList,
        setIntegrationList,
        setIsFilterActive
      }}
    >
      <Content pagination={pagination} />
      <Filter filter={filter} setFilter={setFilter} />
      {isLoading && <Preloader isFullScreen />}
    </HomeContext.Provider>
  );
};

export default Home;
