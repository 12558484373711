import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

const Plane = () => {
  const [field, meta, helpers] = useField('plane');
  const {t} = useTranslation();

  const options = [
    {value: 'Start', label: 'Start'},
    {value: 'Pro', label: 'Pro'},
    {value: 'Enterprise', label: 'Enterprise'}
  ];

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Plan`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Plan`}
        isMaxWidth
        isValid={isValid}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Plane;
