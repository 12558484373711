import React, {useState} from 'react';
import {Wrapper, Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useSubscriptionListContext} from '../subscription-list-context';
import * as AppEnv from 'app-env';

import IntegrationChanger from './integration-changer/integration-changer';

interface Header {
  isFilterActive: boolean;
  setIsFilterActive: AppEnv.SetState<boolean>;
}

const Header = ({isFilterActive, setIsFilterActive}: Header) => {
  const {selectedSubscriptionIdList} = useSubscriptionListContext();

  const [isIntegrationChangerActive, setIsIntegrationChangerActive] =
    useState(false);

  const {t} = useTranslation();

  return (
    <>
      <Wrapper
        justifyContent="space-between"
        gap={16}
      >
        <Button
          color={isFilterActive ? 'active' : 'white'}
          onClick={() => setIsFilterActive(prevValue => !prevValue)}
        >
          <Icons.Filter />
          {t`Filter`}
        </Button>

        <Button
          color="white"
          isDisabled={!selectedSubscriptionIdList.length}
          onClick={() => setIsIntegrationChangerActive(true)}
        >
          {t`Change the integration`}
        </Button>
      </Wrapper>

      <IntegrationChanger
        isIntegrationChangerActive={isIntegrationChangerActive}
        setIsIntegrationChangerActive={setIsIntegrationChangerActive}
      />
    </>
  );
};

export default Header;
