import {useLocation} from 'react-router-dom';
import {getSearchParams} from 'common/actions';

interface SearchParams {
  page?: string;
  version?: string;
}

const useSearchParams = (): SearchParams => {
  const location = useLocation();
  const {searchParams} = getSearchParams(location.search);
  return searchParams;
};

export default useSearchParams;
