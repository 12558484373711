import React from 'react';
import {Title, Input, Wrapper, Button} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

const DateTrial = () => {
  const [field, , helpers] = useField('date_trial');
  const {t} = useTranslation();

  const handleClick = () => {
    const today = new Date();
    today.setUTCHours(0, 0, 0, 0);
    helpers.setValue(Date.parse(String(today)) / 1000);
  };

  const day = 86400;

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Trial`}</Title.H3>

      <Input
        {...field}
        type="date"
        value={
          field.value
            ? moment.unix(field.value).utc().format('yyyy-MM-DD')
            : undefined
        }
        width="max"
        style={{marginBottom: 8}}
        onChange={value =>
          helpers.setValue(value ? moment.utc(value).format('X') : undefined)
        }
      />

      <Wrapper gap={8}>
        <Button
          color="transparent"
          onClick={() => helpers.setValue(field.value + day)}
        >
          {t`+1 day`}
        </Button>

        <Button
          color="transparent"
          onClick={() => helpers.setValue(field.value + day * 7)}
        >
          {t`+7 days`}
        </Button>

        <Button
          color="transparent"
          onClick={() => helpers.setValue(field.value + day * 30)}
        >
          {t`+30 days`}
        </Button>

        <Button color="transparent" onClick={handleClick}>
          {t`Reset`}
        </Button>

        <Button
          color="transparent"
          isDisabled={!field.value}
          onClick={() => helpers.setValue(0)}
        >
          {t`Turn off`}
        </Button>
      </Wrapper>
    </div>
  );
};

export default DateTrial;
