import React from 'react';
import {Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {IntegrationSearchSelect} from 'components';

const Id = () => {
  const [field, meta, helpers] = useField('id');
  const {t} = useTranslation();

  const isValid = !(meta.error && meta.touched);

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>{t`Integration`}</Title.H3>

      <IntegrationSearchSelect
        {...field}
        isPartner={false}
        isValid={isValid}
        onChange={value => helpers.setValue(value.id)}
      />
    </>
  );
};

export default Id;
