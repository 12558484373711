import moment from 'moment';
import * as AppEnv from 'app-env';

const filterToRequest = (
  filter: AppEnv.AutomationFilter
): AppEnv.AutomationRequest => ({
  answer: filter.answer ? {value: filter.answer, type: 'like'} : undefined,
  date_add_after: filter.date_add_after
    ? {
        value: moment.utc(filter.date_add_after).format('X'),
        type: '>'
      }
    : undefined,
  date_add_before: filter.date_add_before
    ? {
        value: moment.utc(`${filter.date_add_before} 23:59:59`).format('X'),
        type: '<'
      }
    : undefined,
  integration_id: filter.integration_id
    ? {value: filter.integration_id}
    : undefined,
  is_deleted:
    filter.is_deleted && filter.is_deleted != '-1'
      ? {value: filter.is_deleted}
      : undefined,
  question: filter.question ? {value: filter.question, type: 'like'} : undefined
});

export default filterToRequest;
