import React from 'react';
import {Button, Line, Popup, SaveButton, Title, Wrapper} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useInvoicesContext} from 'pages/invoices/invoices-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import DateAdd from './date-add/date-add';
import Number from './number/number';
import Summa from './summa/summa';

interface Editor {
  invoice: AppEnv.Invoice;
  isEditorActive: boolean;
  setIsEditorActive: AppEnv.SetState<boolean>;
}

const Editor = ({invoice, isEditorActive, setIsEditorActive}: Editor) => {
  const {pushNotification} = useAppContext();
  const {invoiceList, setInvoiceList} = useInvoicesContext();

  const {putInvoices} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.InvoiceForm) => {
    const {data} = await putInvoices(invoice.id, values);
    if (!data) return;

    setIsEditorActive(false);
    pushNotification(t`The invoice has been updated`);

    const index = invoiceList?.findIndex(item => item.id == invoice.id);
    if (index == undefined || index < 0) return;

    setInvoiceList(prevValue => update(prevValue, {[index]: {$set: data}}));
    return true;
  };

  return (
    <Popup isActive={isEditorActive} onClick={() => setIsEditorActive(false)}>
      <Title.H2 style={{marginBottom: 16}}>{t`Update the invoice`}</Title.H2>

      <Formik initialValues={invoice} onSubmit={handleSubmit}>
        <Form>
          <Wrapper gap={16} isColumn>
            <DateAdd />
            <Number />
            <Summa />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsEditorActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
