import React from 'react';

import {Sidebar} from 'components';
import * as AppEnv from 'app-env';

import Title from './title/title';
import ShortDescription from './short-description/short-description';
import Description from './description/description';
import IntegrationTypeId from './integration-type-id/integration-type-id';
import DateStartAfter from './date-start-after/date-start-after';
import DateStartBefore from './date-start-before/date-start-before';
import DateEndAfter from './date-end-after/date-end-after';
import DateEndBefore from './date-end-before/date-end-before';
import {useNotificationsContext} from '../notifications-context';

interface Filter {
  filter: AppEnv.NotificationFilter;
  setFilter: AppEnv.SetState<AppEnv.NotificationFilter>;
}

const Filter = ({filter, setFilter}: Filter) => {
  const {isFilterActive, notificationList, setIsFilterActive} =
    useNotificationsContext();

  return (
    <Sidebar
      initialValues={filter}
      isActive={isFilterActive}
      isDisabled={!notificationList}
      onSubmit={async (values: AppEnv.NotificationFilter) => setFilter(values)}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
    >
      <Title />
      <ShortDescription />
      <Description />
      <IntegrationTypeId />
      <DateStartAfter />
      <DateStartBefore />
      <DateEndAfter />
      <DateEndBefore />
    </Sidebar>
  );
};

export default Filter;
