import React, {useEffect, useState} from 'react';
import {
  Button,
  EmptyBox,
  Line,
  Popup,
  Preloader,
  Title,
  useCopyToClipboard,
  Wrapper
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Code} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useSubscriptionContext} from '../subscription-context';
import {useSubscriptionListContext} from '../../subscription-list-context';
import * as AppEnv from 'app-env';

interface Transaction {
  isTransactionActive: boolean;
  setIsTransactionActive: AppEnv.SetState<boolean>;
}

const Transaction = ({
  isTransactionActive,
  setIsTransactionActive
}: Transaction) => {
  const {pushNotification} = useAppContext();
  const {paymentSystem} = useSubscriptionListContext();
  const {subscription} = useSubscriptionContext();

  const [transaction, setTransaction] = useState<object | null>();

  const {fetchSubscriptions} = useRequest();
  const {t} = useTranslation();
  const copyToClipboard = useCopyToClipboard();

  const getTransaction = async () => {
    const {data} = await fetchSubscriptions<AppEnv.SubscriptionWithTransaction>(
      paymentSystem,
      {id: subscription.id}
    );

    setTransaction(
      typeof data?.subscription == 'object' ? data.subscription : null
    );
  };

  useEffect(() => {
    if (isTransactionActive) getTransaction();
  }, [isTransactionActive]);

  const handleClick = async () => {
    const res = await copyToClipboard(JSON.stringify(transaction));
    pushNotification(res.message);
  };

  return (
    <Popup
      width={790}
      isActive={isTransactionActive}
      onClick={() => setIsTransactionActive(false)}
    >
      <Title.H2 style={{marginBottom: 16}}>{t`Transaction`}</Title.H2>

      {transaction !== undefined ? (
        transaction ? (
          <Code code={JSON.stringify(transaction)} />
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}

      <Line />

      <Wrapper gap={8}>
        <Button isDisabled={!transaction} onClick={handleClick}>
          {t`Copy`}
        </Button>

        <Button color="white" onClick={() => setIsTransactionActive(false)}>
          {t`Close`}
        </Button>
      </Wrapper>
    </Popup>
  );
};

export default Transaction;
