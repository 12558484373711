import React, {useState, useEffect} from 'react';
import {Title, SingleSelect, useGetTariffName} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useWorkspaceContext} from 'workspace/workspace-context';
import {useGetRateList} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Rate {
  value: number;
  label: string;
  currency: AppEnv.RateCountry;
}

interface TariffId {
  crmId: number | undefined;
}

const TariffId = ({crmId}: TariffId) => {
  const {crmList} = useWorkspaceContext();
  const [rateList, setRateList] = useState<Rate[]>([]);

  const [field, meta, helpers] = useField<number | undefined>('tariff_id');
  const [, , currencyHelpers] = useField<AppEnv.RateCountry>('currency');
  const getTariffName = useGetTariffName();
  const getRateList = useGetRateList();
  const {i18n, t} = useTranslation();

  const fetchOptions = async () => {
    const crm = crmList.find(item => item.id == crmId);

    if (crm) {
      const {rateList} = await getRateList(crm.id);

      const options = rateList.map(item => ({
        value: item.id,
        label: `${item.plane}, ${getTariffName(
          item.quantity,
          item.period,
          i18n.language
        )}, ${item.country}`,
        currency: item.country
      }));

      setRateList(options);
      return;
    }

    setRateList([]);
  };

  useEffect(() => {
    fetchOptions();
  }, [crmId]);

  const handleChange = (value: number | undefined) => {
    helpers.setValue(value);

    const {currency} = rateList.find(item => item.value == value) || {
      currency: null
    };

    if (currency) {
      currencyHelpers.setValue(currency);
    }
  };

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Rate`}</Title.H3>

      <SingleSelect
        {...field}
        options={rateList}
        placeholder={t`Rate`}
        isDisabled={!crmId}
        isMaxWidth
        isValid={isValid}
        onChange={handleChange}
      />
    </div>
  );
};

export default TariffId;
