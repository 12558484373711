import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const DateYslugAfter = () => {
  const [field, , helpers] = useField<string>('date_yslug_after');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Date (after)`}</Title.H3>

      <Input
        {...field}
        type="date"
        placeholder={t`Date (after)`}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default DateYslugAfter;
