import React, {useEffect} from 'react';
import {useWorkspaceContext} from 'workspace/workspace-context';
import HomeItem from './home-item/home-item';

const Home = () => {
  const {pages} = useWorkspaceContext();

  useEffect(() => {
    document.title = 'Whatcrm Console';
  }, []);

  return (
    <div className="home">
      <div className="home__wrapper">
        {Object.values(pages).map((item, i) => (
          <HomeItem key={i} homeItem={item} />
        ))}
      </div>
    </div>
  );
};

export default Home;
