import React from 'react';
import {Popup, Title, Wrapper, Line, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import * as AppEnv from 'app-env';

import Name from './name/name';
import Description from './description/description';
import Plane from './plane/plane';
import QuantityPeriod from './quantity-period/quantity-period';
import Summa from './summa/summa';
import SummaReview from './summa-review/summa-review';
import Country from './country/country';
import IntegrationTypeId from './integration_type_id/integration_type_id';

interface Editor {
  initialValues?: AppEnv.RateForm;
  isActive: boolean;
  isUpdate?: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.RateForm>;
}

const Editor = ({
  initialValues = {
    country: undefined,
    description: undefined,
    integration_type_id: undefined,
    name: undefined,
    period: undefined,
    plane: undefined,
    quantity: undefined,
    summa_review: undefined,
    summa: undefined
  },
  isActive,
  isUpdate,
  onClick,
  onSubmit
}: Editor) => {
  const {t} = useTranslation();

  const validationSchema = yup.object().shape({
    country: yup.string().required(),
    integration_type_id: yup.number().required().positive(),
    name: yup.string().trim().required(),
    period: yup.string().trim().required(),
    plane: yup.string().required(),
    quantity: yup.string().trim().required(),
    summa_review: yup.string().trim().required(),
    summa: yup.string().trim().required()
  });

  return (
    <Popup
      isActive={isActive}
      onClick={onClick}
    >
      <Title.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Update the rate` : t`Create a rate`}
      </Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        <Form>
          <Wrapper
            gap={16}
            isColumn
          >
            <Name />
            <Description />
            <Plane />
            <QuantityPeriod />
            <Summa />
            <SummaReview />
            <Country />
            <IntegrationTypeId />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button
              color="white"
              onClick={onClick}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
