import moment from 'moment';
import * as AppEnv from 'app-env';

const filterToRequest = (
  filter: AppEnv.NotificationFilter
): AppEnv.NotificationRequest => ({
  date_end_after: filter.date_end_after
    ? {value: moment.utc(filter.date_end_after).format('X'), type: '>'}
    : undefined,
  date_end_before: filter.date_end_before
    ? {
        value: moment.utc(`${filter.date_end_before} 23:59:59`).format('X'),
        type: '<'
      }
    : undefined,
  date_start_after: filter.date_start_after
    ? {value: moment.utc(filter.date_start_after).format('X'), type: '>'}
    : undefined,
  date_start_before: filter.date_start_before
    ? {
        value: moment.utc(`${filter.date_start_before} 23:59:59`).format('X'),
        type: '<'
      }
    : undefined,
  description: filter.description
    ? {value: filter.description, type: 'like'}
    : undefined,
  integration_type_id: filter.integration_type_id
    ? {value: filter.integration_type_id}
    : undefined,
  short_description: filter.short_description
    ? {value: filter.short_description, type: 'like'}
    : undefined,
  title: filter.title ? {value: filter.title, type: 'like'} : undefined
});

export default filterToRequest;
