import * as AppEnv from 'app-env';

interface InstanceTypes {
  [index: string]: AppEnv.InstanceVersion | null;
}

const instanceTypes: InstanceTypes = {
  AMO: 'whatcrm',
  AVITO: 'avito',
  B24: 'whatcrm',
  LK: null,
  MONDAY: 'whatcrm',
  PIPEDRIVE: 'whatcrm',
  TELEGRAM: 'telegram',
  TELPHGRAM: 'telegram'
};

export default instanceTypes;
