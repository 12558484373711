import React from 'react';
import {Button, Line, Popup, SaveButton, Title, Wrapper} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useSubscriptionContext} from '../subscription-context';
import {useSubscriptionListContext} from '../../subscription-list-context';
import * as AppEnv from 'app-env';

import Quantity from './quantity/quantity';
import TariffId from './tariff-id/tariff-id';

interface Editor {
  initialValues: AppEnv.SubscriptionForm;
  isEditorActive: boolean;
  setIsEditorActive: AppEnv.SetState<boolean>;
}

const Editor = ({initialValues, isEditorActive, setIsEditorActive}: Editor) => {
  const {pushNotification} = useAppContext();
  const {paymentSystem, setSubscriptionList} = useSubscriptionListContext();
  const {subscription} = useSubscriptionContext();

  const {putSubscriptions} = useRequest();
  const {t} = useTranslation();

  const validationSchema = yup.object().shape({
    quantity: yup.number().positive(),
    tariff_id: yup.number()
  });

  const handleSubmit = async (values: AppEnv.SubscriptionForm) => {
    const {data} = await putSubscriptions(
      paymentSystem,
      subscription.id,
      values
    );

    if (!data) return true;

    setSubscriptionList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == data.id);

      if (typeof index == 'number' && index > -1)
        return update(prevValue, {[index]: {$set: data}});
    });

    pushNotification(t`The subscription has been updated`);
    setIsEditorActive(false);

    return true;
  };

  return (
    <Popup isActive={isEditorActive} onClick={() => setIsEditorActive(false)}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Title.H2 style={{marginBottom: 16}}>
            {t`Update the subscription`}
          </Title.H2>

          <Wrapper gap={16} isColumn>
            <TariffId />
            <Quantity />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsEditorActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
