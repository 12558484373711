import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Type = () => {
  const [field, , helpers] = useField('type');
  const {t} = useTranslation();

  const options = [
    {value: 0, label: t`Individual`},
    {value: 1, label: t`Juridical person`}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Type`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Type`}
        isMaxWidth
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Type;
