import React from 'react';
import {Text, Anchor, useCopyToClipboard} from 'whatcrm-core';

import {useAppContext} from 'app-context';

interface Result {
  link: string;
}

const Result = ({link}: Result) => {
  const {pushNotification} = useAppContext();

  const copyToClipboard = useCopyToClipboard();

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    const res = await copyToClipboard(link);
    pushNotification(res.message);
  };

  return (
    <Text style={{wordBreak: 'break-all'}}>
      <Anchor href={link} onClick={handleClick}>
        {link}
      </Anchor>
    </Text>
  );
};

export default Result;
