import React from 'react';
import {Checkbox} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const IsSubscribed = () => {
  const [field, , helpers] = useField('is_subscribed');
  const {t} = useTranslation();

  return (
    <Checkbox
      {...field}
      label={t`Subscription`}
      onChange={helpers.setValue}
    />
  );
};

export default IsSubscribed;
