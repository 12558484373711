import React, {useEffect, useState} from 'react';
import {
  Popup as CorePopup,
  Title,
  Text,
  Line,
  Wrapper,
  Button,
  useCopyToClipboard
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Code} from 'components';
import {useAppContext} from 'app-context';
import {useConnectionContext} from '../../../connection-context';
import {useRequest} from 'common/hooks';

import takeConnectionStatus from '../take-connection-status';
import * as ActionMenuEnv from '../action-menu-env';

interface Popup {
  status?: string;
  version?: string;
  isPropsActive?: boolean;
  onClick: () => void;
}

const Popup = ({status, version, isPropsActive, onClick}: Popup) => {
  const {pushNotification} = useAppContext();
  const {connection} = useConnectionContext();

  const [isActive, setIsActive] = useState(false);

  const copyToClipboard = useCopyToClipboard();
  const {updateInstance} = useRequest();
  const {t} = useTranslation();

  useEffect(() => {
    if (status || version || isPropsActive) {
      setIsActive(true);
    }
  }, [status, version, isPropsActive]);

  const handleCopy = async () => {
    const res = await copyToClipboard(JSON.stringify(connection));
    pushNotification(res.message);
  };

  const handleClick = () => {
    setIsActive(false);
    setTimeout(() => onClick(), 200);
  };

  const handleRun = async () => {
    const {data} = await updateInstance<
      ActionMenuEnv.StatusSuccess | ActionMenuEnv.StatusError
    >(connection.chat_key, 'status', 1);

    pushNotification(
      t`Status`,
      data ? takeConnectionStatus(data) : t`Could not run the connection`
    );
  };

  return (
    <CorePopup
      width={isPropsActive ? 790 : undefined}
      isActive={isActive}
      onClick={handleClick}
    >
      <Title.H2 style={{marginBottom: 8}}>
        {`${t`Connection`} ${connection.id}`}
      </Title.H2>

      {status && (
        <Text>
          {t`Status`}: {status}
        </Text>
      )}

      {version && (
        <Text>
          {t`Version`}: {version}
        </Text>
      )}

      {isPropsActive && <Code code={JSON.stringify(connection)} />}
      <Line />

      <Wrapper gap={8}>
        {isPropsActive ? (
          <>
            <Button onClick={handleCopy}>{t`Copy`}</Button>

            <Button color="white" onClick={handleClick}>
              {t`Close`}
            </Button>
          </>
        ) : (
          <Button onClick={handleClick}>{t`OK`}</Button>
        )}

        {status && (
          <Button color="white" onClick={handleRun}>
            {t`Run`}
          </Button>
        )}
      </Wrapper>
    </CorePopup>
  );
};

export default Popup;
