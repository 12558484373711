import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const TransactionId = () => {
  const [field, , helpers] = useField<string>('transactionId');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Transaction ID`}</Title.H3>

      <Input
        {...field}
        placeholder={t`Transaction ID`}
        autoCapitalize="off"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default TransactionId;
