import React from 'react';

import {Sidebar} from 'components';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Question from './question/question';
import Answer from './answer/answer';
import Domain from './domain/domain';
import DateAddAfter from './date-add-after/date-add-after';
import DateAddBefore from './date-add-before/date-add-before';
import IsDeleted from './is-deleted/is-deleted';
import {useAutomationsContext} from '../automations-context';

interface Filter {
  filter: AppEnv.AutomationFilter;
  setFilter: AppEnv.SetState<AppEnv.AutomationFilter>;
}

const Filter = ({filter, setFilter}: Filter) => {
  const {automationList, isFilterActive, setIsFilterActive} =
    useAutomationsContext();

  const {fetchIntegrations} = useRequest();

  const handleSubmit = async (values: AppEnv.AutomationFilter) => {
    let integration_id: string[] | undefined;

    if (values.domain) {
      const {data} = values.domain
        ? await fetchIntegrations<AppEnv.Integration[]>({
            filter: {domain: {value: values.domain, type: 'like'}}
          })
        : {data: undefined};

      integration_id = data?.map(item => item.id.toString()) || ['-1'];
    }

    setFilter({...values, integration_id});
  };

  const initialValues: AppEnv.AutomationFilter = {
    is_deleted: filter.is_deleted || '-1'
  };

  return (
    <Sidebar
      filter={filter}
      initialValues={initialValues}
      isActive={isFilterActive}
      isDisabled={!automationList}
      onSubmit={handleSubmit}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
    >
      <Question />
      <Answer />
      <Domain />
      <DateAddAfter />
      <DateAddBefore />
      <IsDeleted />
    </Sidebar>
  );
};

export default Filter;
