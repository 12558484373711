import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

type Value = 'Active' | 'Cancelled' | 'Completed';

interface Option {
  label: string;
  value: Value;
}

const Status = () => {
  const [field, , helpers] = useField<Value | undefined>('status');
  const {t} = useTranslation();

  const options: Option[] = [
    {value: 'Active', label: 'Active'},
    {value: 'Cancelled', label: 'Cancelled'},
    {value: 'Completed', label: 'Completed'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Status`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Status`}
        isClearable
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Status;
