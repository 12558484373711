import React from 'react';
import {Checkbox} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const IsReview = () => {
  const [field, , helpers] = useField('is_review');
  const {t} = useTranslation();

  return (
    <Checkbox
      {...field}
      label={t`Feedback`}
      onChange={value => helpers.setValue(value ? 2 : 0)}
    />
  );
};

export default IsReview;
