import React from 'react';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {Title as CoreTitle, SingleSelect} from 'whatcrm-core';

const IsRegistration = () => {
  const [field, , helpers] = useField('is_registration');
  const {t} = useTranslation();

  const options = [
    {label: t`Moderation`, value: 0},
    {label: t`Registration is authorized`, value: 1},
    {label: t`Published`, value: 2}
  ];

  return (
    <div>
      <CoreTitle.H3 style={{marginBottom: 8}}>{t`Status`}</CoreTitle.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Status`}
        isMaxWidth
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default IsRegistration;
