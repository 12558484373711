import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useBlockListContext} from 'pages/block-list/block-list-context';

import Blocked from './blocked/blocked';

const BlockedList = () => {
  const {timezone} = useAppContext();
  const {blockedList} = useBlockListContext();

  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell>{`${t`Date added`} (${timezone})`}</TableCell>
        <TableCell>{t`Domain`}</TableCell>
        <TableCell>{t`Contact`}</TableCell>
        <TableCell isIcon />
        <TableCell isIcon />
      </TableHead>

      <TableBody>
        {blockedList &&
          blockedList.map(item => (
            <Blocked
              key={item.id}
              blocked={item}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default BlockedList;
