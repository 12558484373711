// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="./types/index.d.ts"/>

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './utils/i18n';

import 'whatcrm-core/dist/main.css';
import './styles/main.sass';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(<App />);
