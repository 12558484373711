import React from 'react';
import {Title, Checkbox} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const DatePay = () => {
  const [field, , helpers] = useField('date_pay');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Payment`}</Title.H3>

      <Checkbox
        {...field}
        value={field.value == '0'}
        label={t`Unpaid`}
        onChange={value => helpers.setValue(value ? '0' : '-1')}
      />
    </div>
  );
};

export default DatePay;
