import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useNotificationsContext} from 'pages/notifications/notifications-context';

import Notification from './notification/notification';

const NotificationList = () => {
  const {timezone} = useAppContext();
  const {notificationList} = useNotificationsContext();

  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell isIcon />
        <TableCell>{t`Headline`}</TableCell>
        <TableCell>{`${t`Start date`} (${timezone})`}</TableCell>
        <TableCell>{`${t`End date`} (${timezone})`}</TableCell>
        <TableCell>CRM</TableCell>
        <TableCell isIcon />
        <TableCell isIcon />
      </TableHead>

      <TableBody>
        {notificationList &&
          notificationList.map(item => (
            <Notification
              key={item.id}
              notification={item}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default NotificationList;
