import React from 'react';
import {Title, Chips} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

type Value = '-1' | '0' | '1';

interface Option {
  label: string;
  value: Value;
}

const IsDeleted = () => {
  const [field, , helpers] = useField<Value>('is_deleted');
  const {t} = useTranslation();

  const options: Option[] = [
    {label: t`All payments`, value: '-1'},
    {label: t`Active`, value: '0'},
    {label: t`Refund`, value: '1'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Status`}</Title.H3>

      <Chips
        {...field}
        options={options}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default IsDeleted;
