import React, {useState} from 'react';
import {Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../editor/editor';

const Creator = () => {
  const {pushNotification} = useAppContext();
  const {setCrmList} = useWorkspaceContext();

  const [isEditorActive, setIsEditorActive] = useState(false);

  const {postCrmTypes} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.CrmForm) => {
    const {data} = await postCrmTypes(values);

    if (data) {
      setIsEditorActive(false);

      setCrmList(prevValue =>
        update(prevValue, {
          $push: [data]
        })
      );

      pushNotification(data.title, t`A new CRM has been created`);
    }

    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsEditorActive(true)}>
        <Icons.Plus />
        {t`Create`}
      </Button>

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default Creator;
