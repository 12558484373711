import React from 'react';
import {Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {IntegrationSearchSelect} from 'components';

interface IntegrationId {
  domain: string | undefined;
}

const IntegrationId = ({domain}: IntegrationId) => {
  const [field, meta, helpers] = useField<number>('integration_id');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Integration`}</Title.H3>

      <IntegrationSearchSelect
        {...field}
        domain={domain}
        isValid={isValid}
        onChange={value => helpers.setValue(value.id)}
      />
    </div>
  );
};

export default IntegrationId;
