import {useGetTariffName} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const useGetRateList = () => {
  const {fetchTariffs} = useRequest();
  const {i18n} = useTranslation();
  const getTariffName = useGetTariffName();

  const getTariffs = async (
    integration_type_id: number,
    tariffs: AppEnv.Rate[] = [],
    page = 1
  ): Promise<AppEnv.Rate[]> => {
    const {data, headers} = await fetchTariffs(page, {
      integration_type_id: {value: integration_type_id.toString()},
      is_deleted: {value: '0'}
    });

    const totalCount = headers?.['x-pagination-total-count'];
    if (!data || !totalCount) return tariffs;

    const newTariffs = [...tariffs, ...data];

    if (newTariffs.length >= parseInt(totalCount)) return newTariffs;
    return getTariffs(integration_type_id, newTariffs, page + 1);
  };

  const getRateList = async (integration_type_id: number) => {
    const rateList = await getTariffs(integration_type_id);

    const options = rateList.map(item => {
      const label = `${item.plane}, ${getTariffName(
        item.quantity,
        item.period,
        i18n.language
      )}, ${item.country}`;

      return {label, value: item.id};
    });

    return {rateList, options};
  };

  return getRateList;
};

export default useGetRateList;
