import React from 'react';
import {Wrapper, Pagination, Preloader, EmptyBox, CoreEnv} from 'whatcrm-core';

import {EntriesNumber} from 'components';

import Header from './header/header';
import EmployeeList from './employee-list/employee-list';
import {useStaffContext} from '../staff-context';

interface Content {
  pagination: CoreEnv.Pagination;
}

const Content = ({pagination}: Content) => {
  const {employeeList} = useStaffContext();

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <Header />

        {employeeList ? (
          employeeList.length ? (
            <div>
              <EntriesNumber
                number={pagination.totalCount}
                style={{marginBottom: 8}}
              />

              <EmployeeList />

              <Pagination {...pagination} isSticky />
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default Content;
