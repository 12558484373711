import React from 'react';
import {useScrollClassName} from 'whatcrm-core';
import cn from 'classnames';

import Group, {ParsedCode} from './group/group';
import Row from './components/row/row';

interface Code {
  code: string;
  oldCode?: string;
}

const Code = ({code, oldCode}: Code) => {
  const scrollClassName = useScrollClassName();

  const parsedCode = JSON.parse(code) as ParsedCode;
  const parsedOldCode = oldCode ? (JSON.parse(oldCode) as ParsedCode) : null;

  return (
    <div className={cn('code', {[scrollClassName]: scrollClassName})}>
      <Row>{'{'}</Row>

      {Object.entries(parsedCode).map(([key, value]) => (
        <Group
          key={key}
          keyName={key}
          parsedCode={parsedCode}
          parsedOldCode={parsedOldCode}
          value={value}
        />
      ))}

      <Row>{'}'}</Row>
    </div>
  );
};

export default Code;
