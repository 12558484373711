import React from 'react';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {Title as CoreTitle, Textarea} from 'whatcrm-core';

const Description = () => {
  const [field, , helpers] = useField<string>('description');
  const {t} = useTranslation();

  return (
    <div>
      <CoreTitle.H3 style={{marginBottom: 8}}>{t`Description`}</CoreTitle.H3>

      <Textarea
        {...field}
        placeholder={t`Description`}
        width="max"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Description;
