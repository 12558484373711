import React from 'react';
import {Popup, Title, Wrapper, Line, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import * as yup from 'yup';

import TariffId from '../tariff_id/tariff_id';
import {useAppContext} from 'app-context';
import {usePaymentsContext} from '../payments-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import DatePay from './date-pay/date-pay';

interface ManualPayer {
  isManualPayerActive: boolean;
  setConnection: AppEnv.SetState<AppEnv.Instance | undefined>;
  setIsManualPayerActive: AppEnv.SetState<boolean>;
}

interface Values {
  date_pay: string;
  tariff_id?: number;
}

const ManualPayer = ({
  isManualPayerActive,
  setConnection,
  setIsManualPayerActive
}: ManualPayer) => {
  const {pushNotification} = useAppContext();
  const {connection} = usePaymentsContext();

  const {putInstances} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    const date_pay = parseInt(moment.utc(values.date_pay).format('X'));

    const date_period = parseInt(
      moment.utc(`${values.date_pay} 23:59:59`).format('X')
    );

    const {data} = await putInstances(connection.id, {
      date_pay,
      date_period,
      tariff_id: values.tariff_id
    });

    if (data) {
      setConnection(data);
      setIsManualPayerActive(false);
      pushNotification(t`The connection has been paid for`);
    }

    return true;
  };

  const initialValues: Values = {
    date_pay: moment.utc().format('yyyy-MM-DD'),
    tariff_id: undefined
  };

  const validationSchema = yup.object().shape({
    date_pay: yup.string().required(),
    tariff_id: yup.number().required()
  });

  return (
    <Popup
      isActive={isManualPayerActive}
      onClick={() => setIsManualPayerActive(false)}
    >
      <Title.H2 style={{marginBottom: 16}}>{t`Pay`}</Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <DatePay />
            <TariffId />
          </Wrapper>

          <Line />

          <Wrapper gap={16}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsManualPayerActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default ManualPayer;
