import React from 'react';
import {Popup, Title, Line, Wrapper, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';

import Id from './id/id';
import {usePartnerContext} from '../../partner-context';
import {useClientsContext} from '../clients-context';

interface Editor {
  isActive: boolean;
  onClick: () => void;
}

const Editor = ({isActive, onClick}: Editor) => {
  const {pushNotification} = useAppContext();
  const {partner} = usePartnerContext();
  const {getClientList} = useClientsContext();

  const {putIntegrations} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: {id?: number}) => {
    if (values.id) {
      const {data} = await putIntegrations(values.id, {
        date_partner: Math.round(+new Date() / 1000),
        partner_id: partner.id
      });

      if (data) {
        onClick();
        pushNotification(data.domain, t`The client has been added`);
        getClientList();
      }
    }

    return true;
  };
  const validationSchema = yup.object().shape({
    id: yup.number().required()
  });

  return (
    <Popup isActive={isActive} onClick={onClick}>
      <Title.H2 style={{marginBottom: 16}}>{t`Add a client`}</Title.H2>

      <Formik
        initialValues={{id: undefined}}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Id />
          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={onClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
