import React, {useState, useEffect} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import Content from './content/content';
import Filter from './filter/filter';
import PromoCodesContext from './promo-codes-context';
import filterToRequest from './filter-to-request';

const PromoCodes = () => {
  const [promoCodeList, setPromoCodeList] = useState<AppEnv.PromoCode[]>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.PromoCodeFilter> = useFilter();

  const {t} = useTranslation();
  const {page = '1'} = useSearchParams();
  const {fetchPromoCodes} = useRequest();
  const pagination = usePagination(parseInt(page));

  usePaginationParam(pagination.currentPage);

  useEffect(() => {
    document.title = `${t`Promo codes`} - Whatcrm Console`;
  }, []);

  const getPromoCodeList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchPromoCodes(
      pagination.currentPage,
      filterToRequest(filter)
    );

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setPromoCodeList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getPromoCodeList();
  }, [filter, pagination.currentPage]);

  return (
    <PromoCodesContext.Provider
      value={{
        promoCodeList,
        pagination,
        isFilterActive,
        setPromoCodeList,
        setIsFilterActive,
        getPromoCodeList
      }}
    >
      <Content />

      <Filter filter={filter} setFilter={setFilter} />

      {isLoading && <Preloader isFullScreen />}
    </PromoCodesContext.Provider>
  );
};

export default PromoCodes;
