import React from 'react';
import {Checkbox} from 'whatcrm-core';
import {useField} from 'formik';

const Cors = () => {
  const [field, , helpers] = useField<0 | 1>('cors');
  return <Checkbox {...field} label={'CORS'} onChange={helpers.setValue} />;
};

export default Cors;
