import React from 'react';
import {Wrapper, Title, Button} from 'whatcrm-core';
import {Link} from 'react-router-dom';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface HomeItem {
  homeItem: AppEnv.Page;
}

const HomeItem = ({homeItem}: HomeItem) => {
  const {client} = useAppContext();

  return (
    <div className="home__item">
      <Link
        className="home__link"
        to={`${homeItem.pathname}${homeItem.search ? homeItem.search : ''}`}
      >
        <Wrapper alignItems="center" gap={8} noWrap>
          {homeItem.icon}
          <Title.H2>{homeItem.label}</Title.H2>
        </Wrapper>
      </Link>

      {homeItem.subpages && (
        <Wrapper gap="8-16" style={{paddingLeft: 40}}>
          {homeItem.subpages.map((item, i) => (
            <React.Fragment key={i}>
              {((client?.role && item.access?.includes(client.role)) ||
                !item.access) && (
                <Link key={i} to={item.pathname}>
                  <Button color="transparent">{item.label}</Button>
                </Link>
              )}
            </React.Fragment>
          ))}
        </Wrapper>
      )}
    </div>
  );
};

export default HomeItem;
