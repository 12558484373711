import React, {useContext} from 'react';

interface SystemOptionListContext {
  getSystemOptionList: () => Promise<void>;
}

const SystemOptionListContext = React.createContext<SystemOptionListContext>(
  {} as SystemOptionListContext
);

export const useSystemOptionListContext = () =>
  useContext(SystemOptionListContext);

export default SystemOptionListContext;
