import React, {useState} from 'react';
import {ContextMenu, TableCell} from 'whatcrm-core';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {Roles} from 'app-enums';
import * as AppEnv from 'app-env';

import Mover from './mover/mover';

interface ContextMenuProvider {
  crm: AppEnv.Crm | undefined;
  integration: AppEnv.Integration;
}

const ContextMenuProvider = ({crm, integration}: ContextMenuProvider) => {
  const {client} = useAppContext();
  const [isMoverActive, setIsMoverActive] = useState(false);

  const {t} = useTranslation();
  const navigate = useNavigate();

  const isEventsShown =
    !!client?.access_level && client.access_level >= Roles.MODER;

  const isMoverShown =
    !!client?.access_level &&
    client.access_level >= Roles.PARTNER &&
    integration.instances_count > 0;

  const isQueuesShown = ['AMO', 'B24', 'TELEGRAM', 'TELPHGRAM'].includes(
    crm?.title || ''
  );

  const isSourcesShown = ['AMO', 'TELEGRAM'].includes(crm?.title || '');
  const options = [];
  const referrer = window.location.pathname + window.location.search;

  if (isEventsShown)
    options.push({
      value: t`Open events`,
      onClick: () =>
        navigate(
          `/events?filter_category=integrations&filter_category_id=${integration.id}`,
          {state: {referrer}}
        )
    });

  if (isQueuesShown)
    options.push({
      value: t`Open queues`,
      onClick: () =>
        navigate(`/integrations/${integration.id}/queues`, {state: {referrer}})
    });

  if (isSourcesShown)
    options.push({
      value: t`Open sources`,
      onClick: () =>
        navigate(`/integrations/${integration.id}/sources`, {state: {referrer}})
    });

  if (isMoverShown)
    options.push({
      value: t`Move connections`,
      onClick: () => setIsMoverActive(true)
    });

  return (
    <TableCell isIcon>
      <ContextMenu options={options} isDisabled={!options.length} />

      <Mover
        isMoverActive={isMoverActive}
        setIsMoverActive={setIsMoverActive}
      />
    </TableCell>
  );
};

export default ContextMenuProvider;
