import React from 'react';
import {Input} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

const Username = () => {
  const {t} = useTranslation();

  const [field, meta, helpers] = useField<string>('username');
  const isValid = !(meta.touched && meta.error);

  return (
    <Input
      {...field}
      isValid={isValid}
      placeholder={t`Username`}
      autoCapitalize="off"
      onChange={helpers.setValue}
    />
  );
};

export default Username;
