import React from 'react';
import {Button, Line, Popup, SaveButton, Title, Wrapper} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../../../connection-context';
import {useHomeContext} from 'pages/connections/home/home-context';
import {useRequest} from 'common/hooks';
import {Roles} from 'app-enums';
import * as AppEnv from 'app-env';

import DatePay from './date-pay/date-pay';
import IsImport from './is-import/is-import';
import Label from './label/label';
import TariffId from './tariff-id/tariff-id';
import Webhook from './webhook/webhook';

interface Editor {
  isEditorActive: boolean;
  setIsEditorActive: AppEnv.SetState<boolean>;
}

const Editor = ({isEditorActive, setIsEditorActive}: Editor) => {
  const {client, pushNotification} = useAppContext();
  const {connectionList, setConnectionList} = useHomeContext();
  const {connection} = useConnectionContext();

  const {putInstances} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.InstanceForm) => {
    const datePeriod = values.date_pay
      ? new Date(values.date_pay * 1000)
      : undefined;

    if (datePeriod) {
      datePeriod.setUTCHours(23, 59, 59, 59);
      values.date_period = parseInt(moment(datePeriod).format('X'));
    }

    const {data} = await putInstances(connection.id, values);
    if (!data) return true;

    setIsEditorActive(false);

    pushNotification(
      `${t`Connection`} ${data.id}`,
      t`The connection has been updated`
    );

    const index = connectionList?.findIndex(item => item.id == connection.id);
    if (index == undefined || index < 0) return true;

    setConnectionList(prevValue => update(prevValue, {[index]: {$set: data}}));
    return true;
  };

  const initialValues: AppEnv.InstanceForm = _.pick(connection, [
    'date_pay',
    'is_import',
    'label',
    'tariff_id',
    'webhook'
  ]);

  const isWebhookShown =
    client?.access_level && client.access_level >= Roles.ADMIN;

  return (
    <Popup isActive={isEditorActive} onClick={() => setIsEditorActive(false)}>
      <Title.H2 style={{marginBottom: 16}}>{t`Update the connection`}</Title.H2>

      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <DatePay />
            <TariffId />
            <Label />
            {isWebhookShown && <Webhook />}
            <IsImport />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsEditorActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
