import React, {useState, useEffect} from 'react';
import {
  Wrapper,
  Title,
  Text,
  // Button,
  // Icons,
  Preloader,
  EmptyBox,
  usePagination
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Payments from './payments/payments';
import Editor from './editor/editor';
import ClientList from './client-list/client-list';
import ClientsContext from './clients-context';
import {usePartnerContext} from '../partner-context';

const Clients = () => {
  const {partner} = usePartnerContext();

  const [clientList, setClientList] = useState<AppEnv.Integration[]>();
  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>();

  const {fetchPartners} = useRequest();
  const pagination = usePagination();
  const {t} = useTranslation();

  const getClientList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchPartners<AppEnv.Integration[]>({
      id: partner.id,
      isClients: true
    });

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setClientList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getClientList();
  }, []);

  return (
    <ClientsContext.Provider value={{getClientList}}>
      <Title.H1 style={{marginBottom: 8}}>{partner.fullname}</Title.H1>

      <Text weight="bold" color="dark" isParagraph style={{marginBottom: 24}}>
        {t`Clients`}
      </Text>

      <Wrapper gap={32} isColumn>
        <Payments />

        <div>
          <Title.H2 style={{marginBottom: 16}}>{t`Clients`}</Title.H2>

          {/* <Button
            color="white"
            style={{marginBottom: 16}}
            onClick={() => setIsEditorActive(true)}
          >
            <Icons.Plus />
            {t`Add`}
          </Button> */}

          {clientList ? (
            clientList.length ? (
              <div>
                <EntriesNumber
                  number={pagination.totalCount}
                  style={{marginBottom: 8}}
                />

                <ClientList clientList={clientList} />
              </div>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )}
        </div>
      </Wrapper>

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
      />

      {isLoading && <Preloader isFullScreen />}
    </ClientsContext.Provider>
  );
};

export default Clients;
