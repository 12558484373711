import React from 'react';
import {
  Title,
  Button,
  Icons,
  Pagination,
  Preloader,
  EmptyBox,
  CoreEnv
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';

import BlockedList from './blocked-list/blocked-list';
import {useBlockListContext} from '../block-list-context';

interface Content {
  pagination: CoreEnv.Pagination;
  isFilterActive: boolean;
}

const Content = ({pagination, isFilterActive}: Content) => {
  const {blockedList, setIsFilterActive} = useBlockListContext();
  const {t} = useTranslation();

  return (
    <div className="content">
      <Title.H1 style={{marginBottom: 16}}>{t`Block list`}</Title.H1>

      <Button
        color={isFilterActive ? 'active' : 'white'}
        style={{marginBottom: 16}}
        onClick={() => setIsFilterActive(prevValue => !prevValue)}
      >
        <Icons.Filter />
        {t`Filter`}
      </Button>

      {blockedList ? (
        blockedList.length ? (
          <>
            <EntriesNumber
              number={pagination.totalCount}
              style={{marginBottom: 8}}
            />

            <BlockedList />

            <Pagination {...pagination} isSticky />
          </>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default Content;
