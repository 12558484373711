import React, {useState} from 'react';
import {Button, Line, Popup, SaveButton, Title, Wrapper} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import moment from 'moment';

import {useAppContext} from 'app-context';
import {usePaymentListContext} from '../../payment-list-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Column from './column/column';
import Comment from './comment/comment';
import DateYslug from './date-yslug/date-yslug';
import IntegrationId from './integration-id/integration-id';
import Quantity from './quantity/quantity';
import Summa from './summa/summa';
import TariffId from './tariff-id/tariff-id';

interface Editor {
  isEditorActive: boolean;
  setIsEditorActive: AppEnv.SetState<boolean>;
}

const Editor = ({isEditorActive, setIsEditorActive}: Editor) => {
  const {getPaymentList} = usePaymentListContext();
  const {pushNotification} = useAppContext();

  const [crmId, setCrmId] = useState<number>();

  const {postBillings} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.PaymentForm) => {
    const date = parseInt(moment.utc().format('X'));

    const {data} = await postBillings({
      ...values,
      date_add: date
    });

    if (!data) return true;

    getPaymentList();
    setIsEditorActive(false);
    pushNotification(t`A payment has been added`);
  };

  const initialValues: AppEnv.PaymentForm = {
    column: 'whatcrm',
    comment: '',
    currency: undefined,
    date_yslug: parseInt(moment.utc().format('X')),
    integration_id: undefined,
    quantity: 1,
    summa: undefined,
    tariff_id: undefined
  };

  const validationSchema = yup.object().shape({
    column: yup.string().required(),
    comment: yup.string(),
    currency: yup.string().required(),
    date_yslug: yup.string().required(),
    integration_id: yup.number().required(),
    quantity: yup.number().positive(),
    summa: yup.string().required(),
    tariff_id: yup.number().required()
  });

  return (
    <Popup isActive={isEditorActive} onClick={() => setIsEditorActive(false)}>
      <Title.H2 style={{marginBottom: 16}}>{t`Add a payment`}</Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <DateYslug />
            <IntegrationId setCrmId={setCrmId} />
            <Column />
            <TariffId crmId={crmId} />
            <Comment />
            <Quantity />
            <Summa />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsEditorActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
