import React from 'react';
import {Text} from 'whatcrm-core';

interface Count {
  count: number;
  rate: string;
}

const Count = ({count, rate}: Count) => (
  <>
    {count > 0 && (
      <Text color="dark" isParagraph>
        {rate}
        {count > 1 && ` x${count}`}
      </Text>
    )}
  </>
);

export default Count;
