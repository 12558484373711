import React, {useState, useEffect} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import Content from './content/content';
import Filter from './filter/filter';
import NotificationsContext from './notifications-context';
import filterToRequest from './filter-to-request';

const Notifications = () => {
  const [notificationList, setNotificationList] =
    useState<AppEnv.Notification[]>();

  const [isLoading, setIsLoading] = useState<boolean>();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.NotificationFilter> = useFilter();

  const {t} = useTranslation();
  const {page = '1'} = useSearchParams();
  const {fetchNotifications} = useRequest();
  const pagination = usePagination(parseInt(page));

  usePaginationParam(pagination.currentPage);

  useEffect(() => {
    document.title = `${t`Notifications`} - Whatcrm Console`;
  }, []);

  const getNotificationList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchNotifications(
      pagination.currentPage,
      filterToRequest(filter)
    );

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setNotificationList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getNotificationList();
  }, [filter, pagination.currentPage]);

  return (
    <NotificationsContext.Provider
      value={{
        notificationList,
        pagination,
        isFilterActive,
        setNotificationList,
        setIsFilterActive,
        getNotificationList
      }}
    >
      <Content />

      <Filter filter={filter} setFilter={setFilter} />

      {isLoading && <Preloader isFullScreen />}
    </NotificationsContext.Provider>
  );
};

export default Notifications;
