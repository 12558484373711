import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Summa = () => {
  const [field, meta, helpers] = useField<string>('summa');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Amount`}</Title.H3>

      <Input
        {...field}
        inputMode="decimal"
        placeholder={t`Amount`}
        type="number"
        width="max"
        isValid={isValid}
        onChange={value => helpers.setValue(value)}
      />
    </div>
  );
};

export default Summa;
