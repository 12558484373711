import React from 'react';

interface Key {
  keyValue: string;
}

const Key = ({keyValue}: Key) => (
  <>
    <span>{keyValue}:</span>{' '}
  </>
);

export default Key;
