import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface ConnectionsContext {
  stats: AppEnv.InstanceStats;
}

const ConnectionsContext = React.createContext<ConnectionsContext>(
  {} as ConnectionsContext
);

export const useConnectionsContext = () => useContext(ConnectionsContext);
export default ConnectionsContext;
