import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Home from './home/home';
import Payments from './payments/payments';

const Connections = () => {
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Connections`} - Whatcrm Console`;
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<Home />}
      />

      <Route
        path=":id"
        element={<Payments />}
      />
    </Routes>
  );
};

export default Connections;
