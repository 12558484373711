import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useRatesContext} from 'pages/rates/rates-context';
import Rate from './rate/rate';

const RateList = () => {
  const {rateList} = useRatesContext();
  const {t} = useTranslation();

  return (
    <Table style={{marginBottom: 8}}>
      <TableHead>
        <TableCell>{'ID'}</TableCell>
        <TableCell>{t`Plan`}</TableCell>
        <TableCell>{t`Period`}</TableCell>
        <TableCell>{t`Price with no feedback`}</TableCell>
        <TableCell>{t`Price with feedback`}</TableCell>
        <TableCell>{'CRM'}</TableCell>
        <TableCell>{t`Archived`}</TableCell>
        <TableCell isIcon />
        <TableCell isIcon />
      </TableHead>

      <TableBody>
        {rateList &&
          rateList.map(item => (
            <Rate
              key={item.id}
              rate={item}
              rateList={rateList}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default RateList;
