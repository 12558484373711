import React, {useContext} from 'react';
import * as AppEnv from 'app-env';
import Rate from './i-rate';

interface PaymentsContext {
  rateList: Rate[];
  connection: AppEnv.Instance;
}

const PaymentsContext = React.createContext<PaymentsContext>(
  {} as PaymentsContext
);

export const usePaymentsContext = () => useContext(PaymentsContext);
export default PaymentsContext;
