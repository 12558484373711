import React from 'react';

import {Sidebar} from 'components';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import DateAddAfter from './date-add-after/date-add-after';
import DateAddBefore from './date-add-before/date-add-before';
import Domain from './domain/domain';
import Phone from './phone/phone';
import {useBlockListContext} from '../block-list-context';

interface Filter {
  filter: AppEnv.BlockedFilter;
  isActive: boolean;
  setFilter: AppEnv.SetState<AppEnv.BlockedFilter>;
}

const Filter = ({filter, isActive, setFilter}: Filter) => {
  const {blockedList, setIsFilterActive} = useBlockListContext();
  const {fetchIntegrations} = useRequest();

  const handleSubmit = async (values: AppEnv.BlockedFilter) => {
    let integration_id: string[] | undefined;

    if (values.domain) {
      const {data} = await fetchIntegrations<AppEnv.Integration[]>({
        filter: {domain: {value: values.domain, type: 'like'}}
      });

      integration_id = data?.map(item => item.id.toString()) || ['-1'];
    }

    setFilter({...values, integration_id});
  };

  return (
    <Sidebar
      initialValues={filter}
      isActive={isActive}
      isDisabled={!blockedList}
      onSubmit={handleSubmit}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
    >
      <DateAddAfter />
      <DateAddBefore />
      <Domain />
      <Phone />
    </Sidebar>
  );
};

export default Filter;
