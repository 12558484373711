import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

type PartnerList = AppEnv.Partner[] | undefined;

interface HomeContext {
  isFilterActive: boolean;
  partnerList: PartnerList;
  setIsFilterActive: AppEnv.SetState<boolean>;
  setPartnerList: AppEnv.SetState<PartnerList>;
}

const HomeContext = React.createContext<HomeContext>({} as HomeContext);

export const useHomeContext = () => useContext(HomeContext);
export default HomeContext;
