import React from 'react';
import {Closer, Title} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

interface Header {
  onClick: () => void;
}

const Header = ({onClick}: Header) => {
  const {t} = useTranslation();

  return (
    <div className="sidebar__header">
      <Closer onClick={onClick} />
      <Title.H2>{t`Filter`}</Title.H2>
    </div>
  );
};

export default Header;
