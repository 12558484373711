import React from 'react';
import {Popup, Wrapper, Title, Line, Button} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

import Theme from './theme/theme';
import Lang from './lang/lang';
import IsAltTableView from './is-alt-table-view/is-alt-table-view';
import IsMenuItemsAlphabetically from './is-menu-items-alphabetically/is-menu-items-alphabetically';
import UtcOffset from './utc-offset/utc-offset';

interface Settings {
  isSettingsActive: boolean;
  setIsSettingsActive: AppEnv.SetState<boolean>;
}

const Settings = ({isSettingsActive, setIsSettingsActive}: Settings) => {
  const {t} = useTranslation();

  return (
    <Popup
      isActive={isSettingsActive}
      onClick={() => setIsSettingsActive(false)}
    >
      <Wrapper
        gap={16}
        isColumn
      >
        <Title.H2>{t`Settings`}</Title.H2>
        <Theme />
        <Lang />
        <IsAltTableView />
        <IsMenuItemsAlphabetically />
        <UtcOffset />
      </Wrapper>

      <Line />
      <Button onClick={() => setIsSettingsActive(false)}>{t`OK`}</Button>
    </Popup>
  );
};

export default Settings;
