export const getFilterParams = (object?: object) => {
  if (!object) return {};

  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      const requestKey = `[${key
        .replace('_after', '')
        .replace('_before', '')}]`;

      const arrayMarker = Array.isArray(value?.value) ? '[in][]' : '';
      const type = value?.type ? `[${value.type}]` : '';

      return [`filter${requestKey}${arrayMarker}${type}`, value?.value];
    })
  );
};
