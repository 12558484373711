import React, {useState, useEffect} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import Content from './content/content';
import Filter from './filter/filter';
import HomeContext from './home-context';
import filterToRequest from './filter-to-request';

const Home = () => {
  const [connectionList, setConnectionList] = useState<AppEnv.Instance[]>();

  const [selectedConnectionIdList, setSelectedConnectionIdList] =
    useState<number[]>();

  const [isLoading, setIsLoading] = useState<boolean>();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.InstanceFilter> = useFilter();

  const {page = '1'}: {page?: string} = useSearchParams();
  const pagination = usePagination(parseInt(page));
  const {fetchInstances} = useRequest();

  usePaginationParam(pagination.currentPage);

  const getConnectionList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchInstances<AppEnv.Instance[]>({
      filter: filterToRequest(filter),
      isExpand: true,
      page: pagination.currentPage
    });

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setConnectionList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getConnectionList();
    setSelectedConnectionIdList(undefined);
  }, [filter, pagination.currentPage]);

  return (
    <HomeContext.Provider
      value={{
        connectionList,
        selectedConnectionIdList,
        isFilterActive,
        setConnectionList,
        setSelectedConnectionIdList,
        setIsFilterActive,
        getConnectionList
      }}
    >
      <Content pagination={pagination} />

      <Filter filter={filter} setFilter={setFilter} />

      {isLoading && <Preloader isFullScreen />}
    </HomeContext.Provider>
  );
};

export default Home;
