import React from 'react';
import {Wrapper, Title, Text, Button} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../connection-context';

const IntegrationInfo = () => {
  const {timezone, preferences} = useAppContext();
  const {connection} = useConnectionContext();

  const {t} = useTranslation();

  return (
    <>
      {connection.integration && (
        <Wrapper gap={8} isColumn>
          <Title.H3>{t`Integration`}</Title.H3>

          {connection.integration.domain && (
            <Text whiteSpace="hide-overflow" isParagraph>
              {t`Domain`}:{' '}
              <Link
                state={{
                  referrer: window.location.pathname + window.location.search
                }}
                style={{maxWidth: '100%'}}
                to={`/integrations?filter_domain=${connection.integration.domain}`}
              >
                <Button
                  color="transparent"
                  style={{
                    maxWidth: '100%',
                    textAlign: 'left',
                    wordBreak: 'break-all'
                  }}
                  isLink
                >
                  {connection.integration.domain}
                </Button>
              </Link>
            </Text>
          )}

          {!!connection.date_update && (
            <Text isParagraph>
              {t`Date added`}
              {': '}
              {moment
                .unix(connection.date_update)
                .utcOffset(preferences.utcOffset)
                .format('L, LT')}
              {` ${timezone}`}
            </Text>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default IntegrationInfo;
