import React, {useContext} from 'react';
import {CoreEnv} from 'whatcrm-core';

import * as AppEnv from 'app-env';

interface TelphinContext {
  pagination: CoreEnv.Pagination;
  setSubscriptionList: AppEnv.SetState<AppEnv.Telphin[] | undefined>;
  getSubscriptionList: (arg?: number) => Promise<void>;
}

const TelphinContext = React.createContext<TelphinContext>(
  {} as TelphinContext
);

export const useTelphinContext = () => useContext(TelphinContext);
export default TelphinContext;
