import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const AlertType = () => {
  const [field, , helpers] = useField('alert_type');
  const {t} = useTranslation();

  const options = [
    {
      value: 0,
      label: t`Info`
    },
    {
      value: 1,
      label: t`Warning`
    },
    {
      value: 2,
      label: t`Danger`
    },
    {
      value: 3,
      label: t`Success`
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Type`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Type`}
        isMaxWidth
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default AlertType;
