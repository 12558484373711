import React from 'react';
import {Wrapper, Button, Icons} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import cn from 'classnames';

import {useAppContext} from 'app-context';
import Account from './account/account';

const logo = <div className="header__logo" />;

const Header = () => {
  const {isNavActive, setIsNavActive} = useAppContext();
  const location = useLocation();

  const isHomePage = location.pathname == '/';

  const handleClick = () => {
    document.body.style.overflow =
      window.innerWidth <= 768 && !isNavActive ? 'hidden' : '';

    setIsNavActive(prevValue => !prevValue);
  };

  return (
    <header className="header">
      <div
        className={cn('header__wrapper', {header__wrapper_nav: !isHomePage})}
      >
        <Wrapper
          alignItems="center"
          gap={20}
          noWrap
        >
          {!isHomePage && (
            <Button
              color="transparent"
              isActive={isNavActive}
              onClick={handleClick}
            >
              <Icons.Menu color="light" />
            </Button>
          )}

          {isHomePage ? logo : <Link to="/">{logo}</Link>}
        </Wrapper>

        <Account />
      </div>
    </header>
  );
};

export default Header;
