import moment from 'moment';
import * as AppEnv from 'app-env';

const filterToRequest = (
  filter: AppEnv.PromoCodeFilter
): AppEnv.PromoCodeRequest => ({
  code: filter.code ? {value: filter.code, type: 'like'} : undefined,
  date_end_after: filter.date_end_after
    ? {value: moment.utc(filter.date_end_after).format('X'), type: '>'}
    : undefined,
  date_end_before: filter.date_end_before
    ? {
        value: moment.utc(`${filter.date_end_before} 23:59:59`).format('X'),
        type: '<'
      }
    : undefined,
  date_start_after: filter.date_start_after
    ? {value: moment.utc(filter.date_start_after).format('X'), type: '>'}
    : undefined,
  date_start_before: filter.date_start_before
    ? {
        value: moment.utc(`${filter.date_start_before} 23:59:59`).format('X'),
        type: '<'
      }
    : undefined,
  integration_id: filter.integration_id
    ? {value: filter.integration_id}
    : undefined,
  is_deleted:
    filter.is_deleted && filter.is_deleted != '-1'
      ? {value: filter.is_deleted}
      : undefined
});

export default filterToRequest;
