import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface InvoicesContext {
  invoiceList: AppEnv.Invoice[] | undefined;
  setInvoiceList: AppEnv.SetState<AppEnv.Invoice[] | undefined>;
}

const InvoicesContext = React.createContext<InvoicesContext>(
  {} as InvoicesContext
);

export const useInvoicesContext = () => useContext(InvoicesContext);
export default InvoicesContext;
