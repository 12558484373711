import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const QuantityPeriod = () => {
  const [field, meta, quantityHelpers] = useField('quantity');
  const [, , periodHelpers] = useField('period');
  const {t} = useTranslation();

  const handleChange = (value: number | string | undefined) => {
    quantityHelpers.setValue(value);
    periodHelpers.setValue(value == 30 ? 'day' : 'month');
  };

  const options = [
    {
      value: 30,
      label: t`30 days`
    },
    {
      value: 3,
      label: t`3 months`
    },
    {
      value: 6,
      label: t`6 months`
    },
    {
      value: 12,
      label: t`12 months`
    }
  ];

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Period`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        isValid={isValid}
        placeholder={t`Period`}
        isMaxWidth
        onChange={handleChange}
      />
    </div>
  );
};

export default QuantityPeriod;
