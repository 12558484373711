import React from 'react';
import {Text} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

export interface Key {
  color: string;
  label?: string;
  value: number;
}

interface Payload {
  currency: string | undefined;
  rechartsKey: Key | undefined;
  value: number | undefined;
}

const Payload = ({currency, rechartsKey, value}: Payload) => {
  const {i18n} = useTranslation();

  return (
    <Text color="dark" weight="bold" isParagraph>
      {rechartsKey && `${rechartsKey.label} — `}

      {value?.toLocaleString(i18n.resolvedLanguage, {
        currency,
        style: currency ? 'currency' : undefined
      })}
    </Text>
  );
};

export default Payload;
