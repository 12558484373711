import React, {useState} from 'react';
import {Popup, Title, Wrapper, Line, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {useConnectionContext} from '../../../connection-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Http from './http/http';
import Method from './method/method';
import Params from './params/params';
import Res from './res/res';
import * as ApiEnv from './api-env';

interface Api {
  isApiActive: boolean;
  setIsApiActive: AppEnv.SetState<boolean>;
}

const Api = ({isApiActive, setIsApiActive}: Api) => {
  const {connection} = useConnectionContext();
  const [data, setData] = useState<string>();

  const {requestInstance} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: ApiEnv.Values) => {
    const params = Object.fromEntries(
      values.params.filter(item => item[0] && item[1])
    );

    const {data} = await requestInstance(
      values.http,
      connection.chat_key,
      values.method,
      params
    );

    setData(data ? JSON.stringify(data) : undefined);
    return true;
  };

  const initialValues: ApiEnv.Values = {
    http: 'get',
    method: '',
    params: [['', '']]
  };

  const validationSchema = yup.object().shape({
    http: yup.string(),
    method: yup.string().trim().required(),
    params: yup.array()
  });

  return (
    <Popup
      width={616}
      isActive={isApiActive}
      onClick={() => setIsApiActive(false)}
    >
      <Title.H2 style={{marginBottom: 16}}>{t`Execute a method`}</Title.H2>

      <Wrapper gap={16} isColumn>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <Wrapper gap={16} isColumn>
              <Http />
              <Method />
              <Params />
            </Wrapper>

            <Line />

            <Wrapper gap={8}>
              <SaveButton>{t`Execute`}</SaveButton>

              <Button color="white" onClick={() => setIsApiActive(false)}>
                {t`Cancel`}
              </Button>
            </Wrapper>
          </Form>
        </Formik>

        <Res data={data} setData={setData} />
      </Wrapper>
    </Popup>
  );
};

export default Api;
