import React from 'react';
import {getCountryName, SingleSelect, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import countries from 'i18n-iso-countries';

const Country = () => {
  const [field, , helpers] = useField<string | undefined>('country');
  const {i18n, t} = useTranslation();

  countries.registerLocale(
    require(`i18n-iso-countries/langs/${i18n.resolvedLanguage}.json`)
  );

  const options = Object.keys(countries.getAlpha2Codes()).map(value => ({
    label: getCountryName(value, i18n.resolvedLanguage) || value,
    value
  }));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Country`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Country`}
        isClearable
        isSearchAvailable
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Country;
