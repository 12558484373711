import React, {useState, useEffect} from 'react';
import {
  Wrapper,
  Pagination,
  Preloader,
  EmptyBox,
  usePagination
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import Template from '../template/template';
import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import Header from './header/header';
import SubscriptionList from './subscription-list/subscription-list';
import Filter from './filter/filter';
import TelphinContext from './telphin-context';
import filterToRequest from './filter-to-request';

const Telphin = () => {
  const [subscriptionList, setSubscriptionList] = useState<AppEnv.Telphin[]>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {t} = useTranslation();
  const {fetchTelphins} = useRequest();
  const {page = '1'} = useSearchParams();
  const pagination = usePagination(parseInt(page));

  usePaginationParam(pagination.currentPage);

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.TelphinFilter> = useFilter();

  useEffect(() => {
    document.title = `${t`Telphin subscriptions`} - Whatcrm Console`;
  }, []);

  const getSubscriptionList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchTelphins<AppEnv.Telphin[]>({
      filter: filterToRequest(filter),
      page: pagination.currentPage
    });

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setSubscriptionList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getSubscriptionList();
  }, [filter, pagination.currentPage]);

  return (
    <Template
      filter={
        <Filter
          filter={filter}
          isActive={isFilterActive}
          isDisabled={!subscriptionList}
          setFilter={setFilter}
          setIsFilterActive={setIsFilterActive}
        />
      }
    >
      <TelphinContext.Provider
        value={{pagination, setSubscriptionList, getSubscriptionList}}
      >
        <Wrapper gap={16} isColumn>
          <Header
            isFilterActive={isFilterActive}
            setIsFilterActive={setIsFilterActive}
          />

          {subscriptionList ? (
            subscriptionList.length ? (
              <div>
                <EntriesNumber
                  number={pagination.totalCount}
                  style={{marginBottom: 8}}
                />

                <SubscriptionList subscriptionList={subscriptionList} />

                <Pagination {...pagination} isSticky />
              </div>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )}

          {isLoading && <Preloader isFullScreen />}
        </Wrapper>
      </TelphinContext.Provider>
    </Template>
  );
};

export default Telphin;
