import React from 'react';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import {Title as CoreTitle, Input} from 'whatcrm-core';

const WebhookChat = () => {
  const [field, , helpers] = useField<string>('webhook_chat');
  const {t} = useTranslation();

  return (
    <div>
      <CoreTitle.H3 style={{marginBottom: 8}}>
        {t`Chat webhook address`}
      </CoreTitle.H3>

      <Input
        {...field}
        type="url"
        placeholder={t`Chat webhook address`}
        width="max"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default WebhookChat;
