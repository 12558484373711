import React, {useState} from 'react';
import {Button, Icons, TableCell, Wrapper} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import IntegrationFinder, {
  Values
} from 'pages/billing/integration-finder/integration-finder';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useSubscriptionContext} from '../subscription-context';
import {useSubscriptionListContext} from '../../subscription-list-context';

const Domain = () => {
  const {pushNotification} = useAppContext();
  const {getSubscriptionList, paymentSystem} = useSubscriptionListContext();
  const {subscription} = useSubscriptionContext();

  const [isIntegrationFinderActive, setIsIntegrationFinderActive] =
    useState(false);

  const {putSubscriptions} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    if (!values.integration_id) return true;

    const {data} = await putSubscriptions(paymentSystem, subscription.id, {
      integration_id: values.integration_id
    });

    if (!data) return true;

    setIsIntegrationFinderActive(false);
    pushNotification(t`The integration has been changed`);
    getSubscriptionList();

    return true;
  };

  return (
    <TableCell>
      {subscription.integration?.domain ? (
        <Wrapper alignItems="center" noWrap>
          <Link
            to={`/connections?&filter_domain=${subscription.integration.domain}&filter_crm_id=${subscription.integration_id}`}
            state={{
              referrer: window.location.pathname + window.location.search
            }}
          >
            <Button color="transparent" height={16} tip={t`Connections`} isLink>
              {subscription.integration.domain}
            </Button>
          </Link>

          <Button
            color="transparent"
            height={16}
            tip={t`Change`}
            onClick={() => setIsIntegrationFinderActive(true)}
          >
            <Icons.Edit />
          </Button>
        </Wrapper>
      ) : (
        <Button
          color="transparent"
          onClick={() => setIsIntegrationFinderActive(true)}
        >
          {t`Select`}
        </Button>
      )}

      <IntegrationFinder
        domain={subscription.integration?.domain}
        integration_id={subscription.integration_id}
        isActive={isIntegrationFinderActive}
        onClick={() => setIsIntegrationFinderActive(false)}
        onSubmit={handleSubmit}
      />
    </TableCell>
  );
};

export default Domain;
