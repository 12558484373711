import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';

const Id = () => {
  const [field, , helpers] = useField<string>('id');

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>ID</Title.H3>

      <Input
        {...field}
        type="number"
        inputMode="numeric"
        placeholder="ID"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Id;
