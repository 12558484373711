import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Lang = () => {
  const [field, , helpers] = useField('lang');
  const {t} = useTranslation();

  const options = [
    {
      value: 'en',
      label: 'English'
    },
    {
      value: 'de',
      label: 'Deutsche'
    },
    {
      value: 'es',
      label: 'Español'
    },
    {
      value: 'pt',
      label: 'Português'
    },
    {
      value: 'ru',
      label: 'Русский'
    },
    {
      value: 'tr',
      label: 'Türkçe'
    },
    {
      value: 'uk',
      label: 'Українська'
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Language`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Language`}
        isClearable
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Lang;
