import React from 'react';
import {Toggle} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';

const IsMenuItemsAlphabetically = () => {
  const {preferences, setPreferences} = useAppContext();
  const {t} = useTranslation();

  const handleChange = (value: boolean) =>
    setPreferences(prevValue =>
      update(prevValue, {isMenuItemsAlphabetically: {$set: value}})
    );

  return (
    <Toggle
      label={`${t`Menu items alphabetically`} (English)`}
      value={!!preferences?.isMenuItemsAlphabetically}
      onChange={handleChange}
    />
  );
};

export default IsMenuItemsAlphabetically;
