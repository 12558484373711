import React, {useState} from 'react';
import {useParams} from 'react-router-dom';

import {useFilter} from 'common/hooks';
import * as AppEnv from 'app-env';

import Content from './content/content';
import Filter from './filter/filter';
import StatsContext from './stats-context';

const Stats = () => {
  const [isFilterDisabled, setIsFilterDisabled] = useState(true);
  const {['*']: splat} = useParams();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.StatsFilter> = useFilter();

  const isFilterAvailableAndActive =
    isFilterActive &&
    ['integrations', 'payment-geography', 'payments'].includes(splat || '');

  return (
    <StatsContext.Provider
      value={{
        filter,
        isFilterAvailableAndActive,
        isFilterDisabled,
        setIsFilterActive,
        setIsFilterDisabled
      }}
    >
      <Content />
      <Filter splat={splat} setFilter={setFilter} />
    </StatsContext.Provider>
  );
};

export default Stats;
