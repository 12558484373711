import React from 'react';
import {Button, Icons, Title, Wrapper} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

interface State {
  referrer?: string;
}

const Header = () => {
  const {state}: AppEnv.Location<State> = useLocation();
  const {t} = useTranslation();

  const href = state?.referrer || '/integrations';

  return (
    <Wrapper justifyContent="space-between" alignItems="center">
      <Link to={href}>
        <Button color="white">
          <Icons.AngleLeft />
          {t`Back`}
        </Button>
      </Link>

      <Title.H3>{t`Integrations`}</Title.H3>

      <div />
    </Wrapper>
  );
};

export default Header;
