import React, {useEffect} from 'react';
import {Title, Input} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';
import generator from 'generate-password-browser';

import {useAppContext} from 'app-context';

interface Password {
  isUpdate: boolean;
}

const Password = ({isUpdate}: Password) => {
  const {pushNotification} = useAppContext();
  const [field, meta, helpers] = useField<string>('password');
  const {t} = useTranslation();

  useEffect(() => {
    if (!isUpdate) {
      let password = generator.generate({
        length: 18,
        strict: true,
        numbers: true,
        excludeSimilarCharacters: true
      });

      password =
        password.substring(0, 6) +
        '-' +
        password.substring(6, 12) +
        '-' +
        password.substring(12);

      helpers.setValue(password);
    }
  }, []);

  const label = isUpdate ? t`New password` : t`Password`;
  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{label}</Title.H3>

      <Input
        {...field}
        name="new-password"
        type="password"
        placeholder={label}
        width="max"
        autoCapitalize="off"
        autoComplete="off"
        isValid={isValid}
        onCopy={({message}) => pushNotification(message)}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Password;
