import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {Error404} from 'components';

import PaymentList from './payment-list/payment-list';
import SubscriptionList from './subscription-list/subscription-list';
import Telphin from './telphin/telphin';

const Billing = () => (
  <Routes>
    <Route path="/payments" element={<PaymentList />} />

    <Route
      path="/cloudpayments"
      element={<SubscriptionList key="cloudpayments" />}
    />

    <Route path="/stripe" element={<SubscriptionList key="stripe" />} />
    <Route path="/telphin" element={<Telphin />} />
    <Route path="/tinkoff" element={<SubscriptionList key="tinkoff" />} />
    <Route path="/" element={<Navigate to="/billing/payments" />} />
    <Route path="*" element={<Error404 />} />
  </Routes>
);

export default Billing;
