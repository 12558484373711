import React, {useState} from 'react';
import {TableCell, Button, Mark} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useHomeContext} from 'pages/integrations/home/home-context';
import {useIntegrationContext} from '../integration-context';
import {useRequest} from 'common/hooks';

const IsPremium = () => {
  const {setIntegrationList} = useHomeContext();
  const {integration} = useIntegrationContext();

  const [isFetching, setIsFetching] = useState(false);

  const {putIntegrations} = useRequest();
  const {t} = useTranslation();

  const handleClick = async () => {
    setIsFetching(true);

    const {data} = await putIntegrations(integration.id, {
      is_premium: integration.is_premium ? 0 : 1
    });

    if (data) {
      setIntegrationList(prevValue => {
        const index = prevValue?.findIndex(item => item.id == integration.id);

        if (typeof index == 'number' && index > -1) {
          return update(prevValue, {
            [index]: {$set: data}
          });
        }
      });
    }

    setIsFetching(false);
  };

  return (
    <TableCell isIcon>
      <Button
        color="transparent"
        tip={t`Change`}
        isDisabled={isFetching}
        onClick={handleClick}
      >
        <Mark mark={integration.is_premium} />
      </Button>
    </TableCell>
  );
};

export default IsPremium;
