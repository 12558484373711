import React from 'react';
import {Wrapper, Pagination, Preloader, EmptyBox, CoreEnv} from 'whatcrm-core';

import {EntriesNumber} from 'components';

import Header from './header/header';
import IntegrationList from './integration-list/integration-list';
import {useHomeContext} from '../home-context';

interface Content {
  pagination: CoreEnv.Pagination;
}

const Content = ({pagination}: Content) => {
  const {integrationList} = useHomeContext();

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <Header />

        {integrationList ? (
          integrationList.length ? (
            <div>
              <EntriesNumber
                number={pagination.totalCount}
                style={{marginBottom: 8}}
              />

              <IntegrationList />

              <Pagination {...pagination} isSticky />
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default Content;
