import React from 'react';
import {Table, TableBody, TableCell, TableHead} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {Roles} from 'app-enums';
import * as AppEnv from 'app-env';

import Queue from './queue/queue';

interface QueueList {
  queueList: AppEnv.Queue[];
}

const QueueList = ({queueList}: QueueList) => {
  const {client} = useAppContext();
  const {t} = useTranslation();

  const isDeleteShown =
    !!client?.access_level && client.access_level >= Roles.MODER;

  return (
    <Table>
      <TableHead>
        <TableCell>{t`Name`}</TableCell>
        <TableCell>{t`Messages`}</TableCell>
        {isDeleteShown && <TableCell />}
      </TableHead>

      <TableBody>
        {queueList.map((item, i) => (
          <Queue key={i} queue={item} isDeleteShown={isDeleteShown} />
        ))}
      </TableBody>
    </Table>
  );
};

export default QueueList;
