import React from 'react';
import {Input, isolateNumbers, Title} from 'whatcrm-core';
import {useField} from 'formik';

const Id = () => {
  const [field, , helpers] = useField<string>('id');

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{'ID'}</Title.H3>

      <Input
        {...field}
        inputMode="numeric"
        placeholder={'ID'}
        onChange={value => helpers.setValue(isolateNumbers(value))}
      />
    </div>
  );
};

export default Id;
