import React from 'react';
import {
  Popup,
  Title,
  Line,
  Wrapper,
  Button,
  useCopyToClipboard
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Code} from 'components';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface Res {
  event: AppEnv.Event;
  isActive: boolean;
  setIsActive: AppEnv.SetState<boolean>;
}

const Res = ({event, isActive, setIsActive}: Res) => {
  const {pushNotification} = useAppContext();

  const copyToClipboard = useCopyToClipboard();
  const {t} = useTranslation();

  const handleClick = async () => {
    const res = await copyToClipboard(event.new);
    pushNotification(res.message);
  };

  return (
    <Popup width={790} isActive={isActive} onClick={() => setIsActive(false)}>
      <Title.H2 style={{marginBottom: 16}}>{t`Changes`}</Title.H2>

      <Code code={event.new} oldCode={event.old} />

      <Line />

      <Wrapper gap={8}>
        <Button onClick={handleClick}>{t`Copy`}</Button>

        <Button color="white" onClick={() => setIsActive(false)}>
          {t`Close`}
        </Button>
      </Wrapper>
    </Popup>
  );
};

export default Res;
