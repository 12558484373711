import React, {useState} from 'react';
import {Box, Wrapper, Icons, Title, Text, Button, Line} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';
import Editor from './editor/editor';

interface Server {
  server: AppEnv.Server;
}

const Server = ({server}: Server) => {
  const [isEditorActive, setIsEditorActive] = useState(false);
  const {t} = useTranslation();

  const unavailableContainersPercentage = server.stats.timeout / server.q;

  const color =
    server.q > 0
      ? unavailableContainersPercentage >= 0.5
        ? 'accent'
        : unavailableContainersPercentage >= 0.25
          ? 'yellow'
          : undefined
      : undefined;

  return (
    <>
      <Box color={color}>
        <Wrapper
          justifyContent="space-between"
          isColumn
          style={{height: '100%'}}
        >
          <div>
            <Wrapper
              alignItems="center"
              gap={12}
              noWrap
              style={{marginBottom: 12}}
            >
              {server.type == 'whatcrm' ? (
                <Icons.WhatsApp />
              ) : (
                <Icons.Telegram />
              )}
              <Title.H2>{server.s}</Title.H2>
            </Wrapper>

            <Wrapper gap={8} isColumn style={{marginBottom: 12}}>
              {server.version && (
                <Text>
                  {t`Version`}: {server.version}
                </Text>
              )}

              <Wrapper>
                <Text>
                  {t`Priority`}: {server.priority || 0}
                </Text>

                <Button
                  color="transparent"
                  height={16}
                  onClick={() => setIsEditorActive(true)}
                >
                  <Icons.Edit />
                </Button>
              </Wrapper>
            </Wrapper>

            <Wrapper gap={8} isColumn>
              <Title.H3>{t`Containers`}</Title.H3>

              <Text>
                {t`Total`}: {server.q}
              </Text>

              {server.q > 0 && (
                <>
                  <Text>
                    {t`Available`}: {server.stats.free}
                  </Text>

                  <Text>
                    {t`Busy`}: {server.stats.crm}
                  </Text>

                  <Text>
                    {t`Turned off`}: {server.stats.off}
                  </Text>

                  <Text>
                    {t`Workable`}: {server.q - server.stats.timeout} (
                    {((1 - unavailableContainersPercentage) * 100).toFixed(0)}%)
                  </Text>

                  <Text>
                    {t`Timeout`}: {server.stats.timeout} (
                    {(unavailableContainersPercentage * 100).toFixed(0)}%)
                  </Text>
                </>
              )}
            </Wrapper>
          </div>

          <div>
            <Line color={color} />

            <Link to={`/servers/${server.s}`}>
              <Button color="transparent">{t`View all`}</Button>
            </Link>
          </div>
        </Wrapper>
      </Box>

      <Editor
        server={server}
        isActive={isEditorActive}
        setIsEditorActive={setIsEditorActive}
      />
    </>
  );
};

export default Server;
