import React, {useState} from 'react';
import {
  Button,
  Checkbox,
  Confirmation,
  Icons,
  Mark,
  TableCell,
  TableRow
} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useGetTariffName} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import update from 'immutability-helper';

import {useRequest} from 'common/hooks';
import {useSubscriptionListContext} from '../subscription-list-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import Domain from './domain/domain';
import Editor from './editor/editor';
import SubscriptionContext from './subscription-context';
import Transaction from './transaction/transaction';

interface Subscription {
  subscription: AppEnv.SubscriptionExpand;
}

const Subscription = ({subscription}: Subscription) => {
  const {crmList} = useWorkspaceContext();

  const {
    paymentSystem,
    selectedSubscriptionIdList,
    setSelectedSubscriptionIdList,
    setSubscriptionList
  } = useSubscriptionListContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);
  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isTransactionActive, setIsTransactionActive] = useState(false);

  const {deleteSubscriptions} = useRequest();
  const {i18n, t} = useTranslation();
  const getTariffName = useGetTariffName();

  const handleChange = () =>
    setSelectedSubscriptionIdList(prevValue =>
      update(prevValue, {
        ...(prevValue.includes(subscription.id)
          ? {$splice: [[prevValue.indexOf(subscription.id), 1]]}
          : {$push: [subscription.id]})
      })
    );

  const deleteSubscription = async () => {
    const {data} = await deleteSubscriptions(paymentSystem, subscription.id);
    if (!data) return true;

    setSubscriptionList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == data.id);

      if (typeof index == 'number' && index > -1)
        return update(prevValue, {[index]: {status: {$set: data.status}}});
    });

    setIsConfirmationActive(false);
    return true;
  };

  const handleConfirm = (res: boolean) => {
    if (res) return deleteSubscription();
    setIsConfirmationActive(false);
  };

  const crm = crmList.find(
    item => item.id == subscription.integration?.integration_type_id
  );

  const date_add = moment(subscription.date_add);

  return (
    <SubscriptionContext.Provider value={{subscription}}>
      <TableRow>
        <TableCell>
          <Checkbox
            value={selectedSubscriptionIdList.includes(subscription.id)}
            onChange={handleChange}
          />
        </TableCell>

        <TableCell>{subscription.id}</TableCell>

        <TableCell>
          {date_add.format('L')}
          <br />
          {date_add.format('LT')}
        </TableCell>

        <Domain />
        <TableCell>{crm?.title}</TableCell>

        <TableCell>
          {subscription.tariff && (
            <>
              {subscription.tariff.plane},{' '}
              {getTariffName(
                subscription.tariff.quantity,
                subscription.tariff.period,
                i18n.language
              )}
              , {subscription.tariff.country}
            </>
          )}
        </TableCell>

        <TableCell>{subscription.quantity}</TableCell>

        <TableCell>
          <Link
            to={`/billing/payments?filter_comment=${subscription.subscription_id}`}
            state={{
              referrer: window.location.pathname + window.location.search
            }}
          >
            <Button color="transparent" isLink>
              {subscription.subscription_id}
            </Button>
          </Link>
        </TableCell>

        <TableCell>{subscription.status}</TableCell>

        <TableCell isIcon>
          <Mark mark={subscription.partner_id} />
        </TableCell>

        <TableCell>
          <Button
            color="transparent"
            onClick={() => setIsTransactionActive(true)}
          >
            {t`Transaction`}
          </Button>

          <Transaction
            isTransactionActive={isTransactionActive}
            setIsTransactionActive={setIsTransactionActive}
          />
        </TableCell>

        <TableCell isIcon>
          <Button
            color="transparent"
            tip={t`Update`}
            onClick={() => setIsEditorActive(true)}
          >
            <Icons.Edit />
          </Button>

          <Editor
            initialValues={subscription}
            isEditorActive={isEditorActive}
            setIsEditorActive={setIsEditorActive}
          />
        </TableCell>

        <TableCell isIcon>
          <Button
            color="transparent"
            tip={t`Cancel`}
            isDisabled={subscription.status == 'Cancelled'}
            onClick={() => setIsConfirmationActive(true)}
          >
            <Icons.Trash />
          </Button>

          <Confirmation
            title={t`Are you sure you want to cancel this subscription?`}
            isActive={isConfirmationActive}
            onClick={handleConfirm}
          />
        </TableCell>
      </TableRow>
    </SubscriptionContext.Provider>
  );
};

export default Subscription;
