import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';

import {useWorkspaceContext} from 'workspace/workspace-context';

const IntegrationTypeId = () => {
  const {crmList} = useWorkspaceContext();
  const [field, meta, helpers] = useField('integration_type_id');

  const options = crmList
    .filter(item => item.is_registration == 1 && item.description)
    .map(item => ({value: item.id, label: item.description || ''}));

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>CRM</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder="CRM"
        isClearable
        isMaxWidth
        isValid={isValid}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default IntegrationTypeId;
