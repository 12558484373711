import {useEffect} from 'react';
import {getSearchParams} from 'common/actions';

const usePaginationParam = (page: number) => {
  useEffect(() => {
    const {searchParams, filterParamsWithPrefix} = getSearchParams(
      window.location.search
    );

    const updateSearchParams = () => {
      if (page == 1) {
        delete searchParams.page;
        return;
      }

      searchParams.page = page.toString();
    };

    updateSearchParams();

    const urlSearchParams = new URLSearchParams({
      ...searchParams,
      ...filterParamsWithPrefix
    } as Record<string, string>).toString();

    history.pushState(
      '',
      '',
      urlSearchParams ? `?${urlSearchParams}` : window.location.pathname
    );
  }, [page]);
};

export default usePaginationParam;
