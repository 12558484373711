import React, {useState} from 'react';
import {TableRow, TableCell, Button, Icons, Confirmation} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import Editor from '../editor/editor';
import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import IsMain from './is-main/is-main';

interface Crm {
  crm: AppEnv.Crm;
}

const Crm = ({crm}: Crm) => {
  const {pushNotification} = useAppContext();
  const {crmList, setCrmList} = useWorkspaceContext();

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {deleteCrmTypes, putCrmTypes} = useRequest();
  const {t} = useTranslation();

  const index = crmList?.findIndex(item => item.id == crm.id);

  const updateCrm = async (values: AppEnv.CrmForm) => {
    const {data} = await putCrmTypes(crm.id, values);

    if (data && index > -1) {
      setIsEditorActive(false);
      pushNotification(crm.title, t`The CRM has been updated`);

      setCrmList(prevValue =>
        update(prevValue, {
          [index]: {$set: data}
        })
      );
    }

    return true;
  };

  const deleteCrm = async () => {
    const {data} = await deleteCrmTypes(crm.id);

    if (typeof data == 'string' && index > -1) {
      pushNotification(crm.title, t`The CRM has been deleted`);

      setCrmList(prevValue =>
        update(prevValue, {
          $splice: [[index, 1]]
        })
      );
    }

    return true;
  };

  const handleConfirm = async (res: boolean) => {
    if (res) {
      return deleteCrm();
    }

    setIsConfirmationActive(false);
    return true;
  };

  const isActive = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>
        <Link
          to={`/integrations?filter_integration_type_id=${crm.id}`}
          state={{referrer: window.location.pathname}}
        >
          <Button color="transparent" isLink>
            {crm.title}
          </Button>
        </Link>
      </TableCell>

      <TableCell>{crm.description}</TableCell>

      <TableCell>
        {crm.is_registration == 0
          ? t`Moderation`
          : crm.is_registration == 1
            ? t`Registration is authorized`
            : crm.is_registration == 2 && t`Published`}
      </TableCell>

      <IsMain crm={crm} />

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={crm}
          isActive={isEditorActive}
          isUpdate
          onClick={() => setIsEditorActive(false)}
          onSubmit={updateCrm}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Delete`}
          onClick={() => setIsConfirmationActive(true)}
        >
          <Icons.Trash />
        </Button>

        <Confirmation
          title={t`Are you sure you want to delete this CRM?`}
          isActive={isConfirmationActive}
          onClick={handleConfirm}
        />
      </TableCell>
    </TableRow>
  );
};

export default Crm;
