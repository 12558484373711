import React from 'react';
import {useTranslation} from 'react-i18next';

import {PieGraph} from 'components';
import {useConnectionsContext} from '../connections-context';

const Integrations = () => {
  const {stats} = useConnectionsContext();
  const {t} = useTranslation();

  const colors = ['#56caf0', '#a792e7', '#83d11f', '#ff8242', '#f9e24d'];

  const data = Object.entries(stats.integrations).map(([key, value], i) => ({
    value: value.count,
    label: key,
    color: colors[i % colors.length]
  }));

  return <PieGraph title={t`Integrations`} data={data} />;
};

export default Integrations;
