import React from 'react';
import {Button, Icons, Nav, NavBar, Title} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

interface Template {
  children: React.ReactNode;
  filter?: React.ReactNode;
}

interface State {
  referrer?: string;
}

const Template = ({children, filter}: Template) => {
  const {pages} = useWorkspaceContext();

  const {pathname, state}: AppEnv.Location<State> = useLocation();
  const {t} = useTranslation();

  const options = [
    {value: '/billing/payments', label: t`Payments`},
    {value: '/billing/cloudpayments', label: t`CloudPayments subscriptions`},
    {value: '/billing/stripe', label: t`Stripe subscriptions`},
    {value: '/billing/telphin', label: t`Telphin subscriptions`}
    // {value: '/billing/tinkoff', label: t`Tinkoff subscriptions`}
  ].filter(item => 'billing' in pages || item.value == pathname);

  if (process.env.REACT_APP_ENV == 'development')
    options.push({value: '/billing/tinkoff', label: t`Tinkoff subscriptions`});

  return (
    <>
      <div className="content">
        {state?.referrer && (
          <Link to={state.referrer} style={{marginBottom: 16}}>
            <Button color="white">
              <Icons.AngleLeft />
              {t`Back`}
            </Button>
          </Link>
        )}

        <Title.H1 style={{marginBottom: 8}}>{t`Billing`}</Title.H1>

        <Nav options={options} value={pathname}>
          <NavBar isLink style={{marginBottom: 16}} />
          {children}
        </Nav>
      </div>

      {filter}
    </>
  );
};

export default Template;
