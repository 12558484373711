import React from 'react';
import {SingleSelect, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useWorkspaceContext} from 'workspace/workspace-context';

const UserId = () => {
  const {userList} = useWorkspaceContext();

  const [field, , helpers] = useField<string | undefined>('user_id');
  const {t} = useTranslation();

  const options = userList?.map(item => ({
    label: item.username,
    value: item.id.toString()
  }));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`User`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`User`}
        isClearable
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default UserId;
