import React from 'react';
import {Title, Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAutomationsContext} from 'pages/automations/automations-context';

const Header = () => {
  const {isFilterActive, setIsFilterActive} = useAutomationsContext();
  const {t} = useTranslation();

  return (
    <>
      <Title.H1>{t`Automations`}</Title.H1>

      <Button
        color={isFilterActive ? 'active' : 'white'}
        onClick={() => setIsFilterActive(prevValue => !prevValue)}
      >
        <Icons.Filter />
        {t`Filter`}
      </Button>
    </>
  );
};

export default Header;
