import React from 'react';
import {Wrapper} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {PieGraph} from 'components';
import {useConnectionsContext} from '../connections-context';

const InTermsOf = () => {
  const {stats} = useConnectionsContext();
  const {t} = useTranslation();

  const integrations = Object.fromEntries(
    Object.entries(stats.integrations)
      .filter(([, value]) => value.count > 0)
      .map(([key, value]) => [
        key,
        [
          {value: value.test, label: t`Trial`, color: '#56caf0'},
          {value: value.pay, label: t`Paid`, color: '#83d11f'},
          {value: value.test_3, label: t`Trial is over`, color: '#ff8242'}
        ]
      ])
  );

  return (
    <Wrapper gap={32}>
      {Object.entries(integrations).map(([key, value]) => (
        <PieGraph key={key} title={key} data={value} />
      ))}
    </Wrapper>
  );
};

export default InTermsOf;
