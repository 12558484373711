import React from 'react';
import {
  Popup,
  Wrapper,
  Icons,
  Title,
  Line,
  Button,
  SaveButton
} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';
import * as yup from 'yup';

import {useAppContext} from 'app-context';
import {useHomeContext} from 'pages/servers/home/home-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Priority from './priority/priority';

interface Editor {
  server: AppEnv.Server;
  isActive: boolean;
  setIsEditorActive: AppEnv.SetState<boolean>;
}

const Editor = ({server, isActive, setIsEditorActive}: Editor) => {
  const {pushNotification} = useAppContext();
  const {setServerList} = useHomeContext();

  const {putServerPriority} = useRequest();
  const {t} = useTranslation();

  const validationSchema = yup.object().shape({
    priority: yup.number().min(-10).max(10)
  });

  const handleSubmit = async (values: {priority: number | null}) => {
    if (server.priority == values.priority) {
      setIsEditorActive(false);
      return;
    }

    const {data} = await putServerPriority(server.s, values.priority || 0);

    if (data) {
      setServerList(prevValue => {
        const index = prevValue?.findIndex(item => item.s == server.s);

        if (typeof index == 'number' && index > -1) {
          return update(prevValue, {
            [index]: {priority: {$set: data.priority}}
          });
        }
      });

      pushNotification(t`The priority has been updated`);
    }

    setIsEditorActive(false);
    return true;
  };

  return (
    <Popup isActive={isActive} onClick={() => setIsEditorActive(false)}>
      <Wrapper alignItems="center" gap={12} noWrap style={{marginBottom: 12}}>
        {server.type == 'whatcrm' ? <Icons.WhatsApp /> : <Icons.Telegram />}
        <Title.H2>{server.s}</Title.H2>
      </Wrapper>

      <Formik
        initialValues={{priority: server.priority}}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Priority />
          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsEditorActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
