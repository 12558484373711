import React from 'react';
import {Title, Wrapper, Input, Text} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Sleep = () => {
  const [field, , helpers] = useField<number | undefined>('sleep');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Delay`}</Title.H3>

      <Wrapper
        alignItems="center"
        gap={8}
        noWrap
      >
        <Input
          {...field}
          type="number"
          placeholder={t`Delay`}
          width="max"
          onChange={value => helpers.setValue(parseInt(value) || undefined)}
        />

        <Text color="dark">{t`secs`}</Text>
      </Wrapper>
    </div>
  );
};

export default Sleep;
