const normalizeDomain = (str: string) => {
  try {
    const url = new URL(str);
    return url.host;
  } catch {
    return str;
  }
};

export default normalizeDomain;
