import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

import {useWorkspaceContext} from 'workspace/workspace-context';

const CodeClient = () => {
  const {pages} = useWorkspaceContext();

  const [field, , helpers] = useField<string>('code_client');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Client code`}</Title.H3>

      <Input
        {...field}
        placeholder={t`Client code`}
        maxLength={8}
        autoCapitalize="off"
        width="max"
        isDisabled={!('billing' in pages)}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default CodeClient;
