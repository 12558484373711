import React from 'react';
import {CoreEnv, EmptyBox, Pagination, Preloader, Wrapper} from 'whatcrm-core';

import {EntriesNumber} from 'components';
import {useHomeContext} from '../home-context';

import Header from './header/header';
import PartnerList from './partner-list/partner-list';

interface Content {
  pagination: CoreEnv.Pagination;
}

const Content = ({pagination}: Content) => {
  const {partnerList} = useHomeContext();

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <Header />

        {partnerList ? (
          partnerList.length ? (
            <div>
              <EntriesNumber
                number={pagination.totalCount}
                style={{marginBottom: 8}}
              />

              <PartnerList />
              <Pagination {...pagination} isSticky />
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default Content;
