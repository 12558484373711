import React, {useState, useEffect} from 'react';
import {Wrapper, Preloader, EmptyBox} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Messengers from './messengers/messengers';
import Whatsapp from './whatsapp/whatsapp';
import Telegram from './telegram/telegram';
import Integrations from './integrations/integrations';
import InTermsOf from './in-terms-of/in-terms-of';
import ConnectionsContext from './connections-context';

const Connections = () => {
  const [stats, setStats] = useState<AppEnv.InstanceStats | null>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {fetchStatistics} = useRequest();
  const {t} = useTranslation();

  const getStats = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {data} = await fetchStatistics<AppEnv.InstanceStats>('instances');

    setStats(data || null);
    setIsLoading(false);
  };

  useEffect(() => {
    getStats();
    document.title = `${t`Connection statistics`} - Whatcrm Console`;
  }, []);

  return (
    <div style={{paddingTop: 16}}>
      {stats != undefined ? (
        stats ? (
          <ConnectionsContext.Provider value={{stats}}>
            <Wrapper gap={32} style={{marginBottom: 32}}>
              <Messengers />
              <Whatsapp />
              <Telegram />
              <Integrations />
            </Wrapper>

            <InTermsOf />
          </ConnectionsContext.Provider>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}

      {isLoading && <Preloader isFullScreen />}
    </div>
  );
};

export default Connections;
