interface Colors {
  [index: string]: string;
}

const colors: Colors = {
  AD: '#d0103a',
  AE: '#00732f',
  AF: '#090',
  AG: '#fcd116',
  AL: '#e41e20',
  AM: '#f2a800',
  AO: '#000',
  AR: '#74acdf',
  AT: '#ed2939',
  AU: '#00008b',
  AZ: '#3f9c35',
  BA: '#002395',
  BB: '#00267f',
  BD: '#006a4e',
  BE: '#ed2939',
  BF: '#009e49',
  BG: '#00966e',
  BH: '#ce1126',
  BI: '#1eb53a',
  BJ: '#e8112d',
  BN: '#f7e017',
  BO: '#007934',
  BR: '#009b3a',
  BS: '#00abc9',
  BT: '#efb22d',
  BW: '#75aadb',
  BY: '#cd0000',
  BZ: '#ce1126',
  CA: '#f00',
  CD: '#007fff',
  CF: '#ffce00',
  CG: '#009543',
  CH: '#f00',
  CI: '#009e60',
  CL: '#d52b1e',
  CM: '#007a5e',
  CN: '#ffde00',
  CO: '#ce1126',
  CR: '#002b7f',
  CU: '#002a8f',
  CV: '#003893',
  CY: '#435125',
  CZ: '#d7141a',
  DE: '#000',
  DJ: '#6ab2e7',
  DK: '#c60c30',
  DM: '#006b3f',
  DO: '#002d62',
  DZ: '#006233',
  EC: '#fd0',
  EE: '#4891d9',
  EG: '#000',
  ER: '#ea0437',
  ES: '#c60b1e',
  ET: '#da121a',
  FI: '#003580',
  FJ: '#002868',
  FM: '#75b2dd',
  FR: '#002395',
  GA: '#009e60',
  GB: '#00247d',
  GD: '#fcd116',
  GE: '#ff0000',
  GH: '#006b3f',
  GM: '#3a7728',
  GN: '#ce1126',
  GQ: '#e32118',
  GR: '#0d5eaf',
  GT: '#4997d0',
  GW: '#ce1126',
  GY: '#009e49',
  HK: '#ee1c24',
  HN: '#0073cf',
  HR: '#171796',
  HT: '#d21034',
  HU: '#436f4d',
  ID: '#ce1126',
  IE: '#169b62',
  IL: '#0038b8',
  IN: '#f93',
  IQ: '#cf2029',
  IR: '#da0000',
  IS: '#003897',
  IT: '#009246',
  JM: '#009b3a',
  JO: '#007a3d',
  JP: '#bc002d',
  KE: '#006600',
  KG: '#e8112d',
  KH: '#032ea1',
  KI: '#ce1126',
  KM: '#3a75c4',
  KN: '#009e49',
  KP: '#024fa2',
  KR: '#c60c30',
  KW: '#007a3d',
  KZ: '#00afca',
  LA: '#ce1126',
  LB: '#ed1c24',
  LC: '#6cf',
  LI: '#002b7f',
  LK: '#ffb700',
  LR: '#bf0a30',
  LS: '#009543',
  LT: '#c1272d',
  LU: '#00a1de',
  LV: '#9e3039',
  LY: '#239e46',
  MA: '#c1272d',
  MC: '#ce1126',
  MD: '#ffd200',
  ME: '#d3ae3b',
  MG: '#007e3a',
  MH: '#003893',
  MK: '#d20000',
  ML: '#ce1126',
  MM: '#fecb00',
  MN: '#c4272f',
  MR: '#006233',
  MT: '#cf142b',
  MU: '#00a551',
  MV: '#d21034',
  MW: '#ce1126',
  MX: '#006847',
  MY: '#cc0001',
  MZ: '#000',
  NA: '#003580',
  NE: '#0db02b',
  NG: '#008751',
  NI: '#000',
  NL: '#21468b',
  NO: '#ef2b2d',
  NP: '#003893',
  NR: '#002b7f',
  NZ: '#00247d',
  OM: '#db161b',
  PA: '#005293',
  PE: '#d91023',
  PG: '#000',
  PH: '#0038a8',
  PK: '#01411c',
  PL: '#dc143c',
  PR: '#0050f0',
  PS: '#007a3d',
  PT: '#f00',
  PW: '#4aadd6',
  PY: '#0038a8',
  QA: '#8d1b3d',
  RO: '#002b7f',
  RS: '#0c4076',
  RU: '#038de9',
  RW: '#20603d',
  SA: '#006c35',
  SB: '#0051ba',
  SC: '#d62828',
  SD: '#d21034',
  SE: '#006aa7',
  SG: '#ed2939',
  SI: '#ed1c24',
  SK: '#ee1c25',
  SL: '#0072c6',
  SM: '#5eb6e4',
  SN: '#00853f',
  SO: '#4189dd',
  SR: '#377e3f',
  SS: '#078930',
  ST: '#12ad2b',
  SV: '#0f47af',
  SY: '#000',
  SZ: '#3e5eb9',
  TD: '#c60c30',
  TG: '#006a4e',
  TH: '#ed1c24',
  TJ: '#060',
  TL: '#dc241f',
  TM: '#28ae66',
  TN: '#e70013',
  TO: '#c10000',
  TR: '#e30a17',
  TT: '#ce1126',
  TV: '#ffce00',
  TZ: '#1eb53a',
  UA: '#ffd500',
  UG: '#fcdc04',
  US: '#b22234',
  UY: '#0038a8',
  UZ: '#1eb53a',
  VA: '#ffe000',
  VC: '#009e60',
  VE: '#cf142b',
  VN: '#da251d',
  VU: '#d21034',
  WS: '#ce1126',
  YE: '#ce1126',
  ZA: '#002395',
  ZM: '#198a00',
  ZW: '#319208'
};

export default colors;
