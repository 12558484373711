import React from 'react';
import {Text} from 'whatcrm-core';

import {Trans} from 'react-i18next';

interface EntriesNumber {
  number: number;
  style?: React.CSSProperties;
}

const EntriesNumber = ({number, style}: EntriesNumber) => {
  const localeNumber = number.toLocaleString();

  return (
    <Text
      size="s"
      isParagraph
      style={style}
    >
      <Trans count={number}>
        {{localeNumber}}
        {' entry'}
      </Trans>
    </Text>
  );
};

export default EntriesNumber;
