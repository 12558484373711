import React from 'react';

import {Sidebar} from 'components';
import * as AppEnv from 'app-env';

import Code from './code/code';
import DateStartAfter from './date-start-after/date-start-after';
import DateStartBefore from './date-start-before/date-start-before';
import DateEndAfter from './date-end-after/date-end-after';
import DateEndBefore from './date-end-before/date-end-before';
import IntegrationId from './integration-id/integration-id';
import IsDeleted from './is-deleted/is-deleted';
import {usePromoCodesContext} from '../promo-codes-context';

interface Filter {
  filter: AppEnv.PromoCodeFilter;
  setFilter: AppEnv.SetState<AppEnv.PromoCodeFilter>;
}

const Filter = ({filter, setFilter}: Filter) => {
  const {isFilterActive, promoCodeList, setIsFilterActive} =
    usePromoCodesContext();

  const initialValues: AppEnv.PromoCodeFilter = {
    is_deleted: filter.is_deleted || '-1'
  };

  return (
    <Sidebar
      filter={filter}
      initialValues={initialValues}
      isActive={isFilterActive}
      isDisabled={!promoCodeList}
      onSubmit={async (values: AppEnv.PromoCodeFilter) => setFilter(values)}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
    >
      <Code />
      <DateStartAfter />
      <DateStartBefore />
      <DateEndAfter />
      <DateEndBefore />
      <IntegrationId />
      <IsDeleted />
    </Sidebar>
  );
};

export default Filter;
