import React, {useState} from 'react';
import {Button, Icons, useGetTariffName} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useRatesContext} from 'pages/rates/rates-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../../editor/editor';

const Creator = () => {
  const {pushNotification} = useAppContext();
  const {getRateList} = useRatesContext();

  const [isEditorActive, setIsEditorActive] = useState(false);

  const {i18n, t} = useTranslation();
  const {postTariffs} = useRequest();
  const getTariffName = useGetTariffName();

  const handleSubmit = async (values: AppEnv.RateForm) => {
    const {data} = await postTariffs(values);

    if (data) {
      setIsEditorActive(false);

      pushNotification(
        getTariffName(data.quantity, data.period, i18n.language),
        t`A tariff has been created`
      );

      getRateList();
    }

    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsEditorActive(true)}>
        <Icons.Plus />
        {t`Create`}
      </Button>

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default Creator;
