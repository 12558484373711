import React, {useEffect} from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {normalizeDomain} from 'common/actions';

const Domain = () => {
  const [field, , helpers] = useField<string>('domain');
  const [{value: withoutDomain}] = useField<0 | 1 | undefined>(
    'without_domain'
  );
  const {t} = useTranslation();

  useEffect(() => {
    if (withoutDomain) {
      helpers.setValue('');
    }
  }, [withoutDomain]);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Domain`}</Title.H3>

      <Input
        {...field}
        inputMode="email"
        placeholder={t`Domain`}
        autoCapitalize="off"
        isDisabled={!!withoutDomain}
        onChange={value => helpers.setValue(normalizeDomain(value))}
      />
    </div>
  );
};

export default Domain;
