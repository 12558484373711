const options = [
  {value: 'AFN', label: 'AFN', comment: 'Afghanis'},
  {value: 'ALL', label: 'ALL', comment: 'Leke'},
  {value: 'ANG', label: 'ANG', comment: 'Guilders'},
  {value: 'ARS', label: 'ARS', comment: 'Pesos'},
  {value: 'AUD', label: 'AUD', comment: 'Dollars'},
  {value: 'AWG', label: 'AWG', comment: 'Guilders'},
  {value: 'AZN', label: 'AZN', comment: 'New Manats'},
  {value: 'BAM', label: 'BAM', comment: 'Convertible Marka'},
  {value: 'BBD', label: 'BBD', comment: 'Dollars'},
  {value: 'BGN', label: 'BGN', comment: 'Leva'},
  {value: 'BMD', label: 'BMD', comment: 'Dollars'},
  {value: 'BND', label: 'BND', comment: 'Dollars'},
  {value: 'BOB', label: 'BOB', comment: 'Bolivianos'},
  {value: 'BRL', label: 'BRL', comment: 'Reais'},
  {value: 'BSD', label: 'BSD', comment: 'Dollars'},
  {value: 'BWP', label: 'BWP', comment: 'Pula'},
  {value: 'BYR', label: 'BYR', comment: 'Rubles'},
  {value: 'BZD', label: 'BZD', comment: 'Dollars'},
  {value: 'CAD', label: 'CAD', comment: 'Dollars'},
  {value: 'CHF', label: 'CHF', comment: 'Switzerland Francs'},
  {value: 'CLP', label: 'CLP', comment: 'Pesos'},
  {value: 'CNY', label: 'CNY', comment: 'Yuan Renminbi'},
  {value: 'COP', label: 'COP', comment: 'Pesos'},
  {value: 'CRC', label: 'CRC', comment: 'Colón'},
  {value: 'CUP', label: 'CUP', comment: 'Pesos'},
  {value: 'CZK', label: 'CZK', comment: 'Koruny'},
  {value: 'DKK', label: 'DKK', comment: 'Kroner'},
  {value: 'DOP', label: 'DOP', comment: 'Pesos'},
  {value: 'EGP', label: 'EGP', comment: 'Pounds'},
  {value: 'EUR', label: 'EUR', comment: 'Euro'},
  {value: 'FJD', label: 'FJD', comment: 'Dollars'},
  {value: 'FKP', label: 'FKP', comment: 'Pounds'},
  {value: 'GBP', label: 'GBP', comment: 'Pounds'},
  {value: 'GGP', label: 'GGP', comment: 'Pounds'},
  {value: 'GHC', label: 'GHC', comment: 'Cedis'},
  {value: 'GIP', label: 'GIP', comment: 'Pounds'},
  {value: 'GTQ', label: 'GTQ', comment: 'Quetzales'},
  {value: 'GYD', label: 'GYD', comment: 'Dollars'},
  {value: 'HKD', label: 'HKD', comment: 'Dollars'},
  {value: 'HNL', label: 'HNL', comment: 'Lempiras'},
  {value: 'HRK', label: 'HRK', comment: 'Kuna'},
  {value: 'HUF', label: 'HUF', comment: 'Forint'},
  {value: 'IDR', label: 'IDR', comment: 'Rupiahs'},
  {value: 'ILS', label: 'ILS', comment: 'New Shekels'},
  {value: 'IMP', label: 'IMP', comment: 'Pounds'},
  {value: 'INR', label: 'INR', comment: 'Rupees'},
  {value: 'IRR', label: 'IRR', comment: 'Rials'},
  {value: 'ISK', label: 'ISK', comment: 'Kronur'},
  {value: 'JEP', label: 'JEP', comment: 'Pounds'},
  {value: 'JMD', label: 'JMD', comment: 'Dollars'},
  {value: 'JPY', label: 'JPY', comment: 'Yen'},
  {value: 'KGS', label: 'KGS', comment: 'Soms'},
  {value: 'KHR', label: 'KHR', comment: 'Riels'},
  {value: 'KPW', label: 'KPW', comment: 'Won'},
  {value: 'KRW', label: 'KRW', comment: 'Won'},
  {value: 'KYD', label: 'KYD', comment: 'Dollars'},
  {value: 'KZT', label: 'KZT', comment: 'Tenge'},
  {value: 'LAK', label: 'LAK', comment: 'Kips'},
  {value: 'LBP', label: 'LBP', comment: 'Pounds'},
  {value: 'LKR', label: 'LKR', comment: 'Rupees'},
  {value: 'LRD', label: 'LRD', comment: 'Dollars'},
  {value: 'LTL', label: 'LTL', comment: 'Litai'},
  {value: 'LVL', label: 'LVL', comment: 'Lati'},
  {value: 'MKD', label: 'MKD', comment: 'Denars'},
  {value: 'MNT', label: 'MNT', comment: 'Tugriks'},
  {value: 'MUR', label: 'MUR', comment: 'Rupees'},
  {value: 'MXN', label: 'MXN', comment: 'Pesos'},
  {value: 'MYR', label: 'MYR', comment: 'Ringgits'},
  {value: 'MZN', label: 'MZN', comment: 'Meticais'},
  {value: 'NAD', label: 'NAD', comment: 'Dollars'},
  {value: 'NGN', label: 'NGN', comment: 'Nairas'},
  {value: 'NIO', label: 'NIO', comment: 'Cordobas'},
  {value: 'NOK', label: 'NOK', comment: 'Krone'},
  {value: 'NPR', label: 'NPR', comment: 'Rupees'},
  {value: 'NZD', label: 'NZD', comment: 'Dollars'},
  {value: 'OMR', label: 'OMR', comment: 'Rials'},
  {value: 'PAB', label: 'PAB', comment: 'Balboa'},
  {value: 'PEN', label: 'PEN', comment: 'Nuevos Soles'},
  {value: 'PHP', label: 'PHP', comment: 'Pesos'},
  {value: 'PKR', label: 'PKR', comment: 'Rupees'},
  {value: 'PLN', label: 'PLN', comment: 'Zlotych'},
  {value: 'PYG', label: 'PYG', comment: 'Guarani'},
  {value: 'QAR', label: 'QAR', comment: 'Rials'},
  {value: 'RON', label: 'RON', comment: 'New Lei'},
  {value: 'RSD', label: 'RSD', comment: 'Dinars'},
  {value: 'RUB', label: 'RUB', comment: 'Rubles'},
  {value: 'SAR', label: 'SAR', comment: 'Riyals'},
  {value: 'SBD', label: 'SBD', comment: 'Dollars'},
  {value: 'SCR', label: 'SCR', comment: 'Rupees'},
  {value: 'SEK', label: 'SEK', comment: 'Kronor'},
  {value: 'SGD', label: 'SGD', comment: 'Dollars'},
  {value: 'SHP', label: 'SHP', comment: 'Pounds'},
  {value: 'SOS', label: 'SOS', comment: 'Shillings'},
  {value: 'SRD', label: 'SRD', comment: 'Dollars'},
  {value: 'SVC', label: 'SVC', comment: 'Colones'},
  {value: 'SYP', label: 'SYP', comment: 'Pounds'},
  {value: 'THB', label: 'THB', comment: 'Baht'},
  {value: 'TRL', label: 'TRL', comment: 'Liras'},
  {value: 'TRY', label: 'TRY', comment: 'Lira'},
  {value: 'TTD', label: 'TTD', comment: 'Dollars'},
  {value: 'TVD', label: 'TVD', comment: 'Dollars'},
  {value: 'TWD', label: 'TWD', comment: 'New Dollars'},
  {value: 'UAH', label: 'UAH', comment: 'Hryvnia'},
  {value: 'USD', label: 'USD', comment: 'Dollars'},
  {value: 'UYU', label: 'UYU', comment: 'Pesos'},
  {value: 'UZS', label: 'UZS', comment: 'Sums'},
  {value: 'VEF', label: 'VEF', comment: 'Bolivares Fuertes'},
  {value: 'VND', label: 'VND', comment: 'Dong'},
  {value: 'XCD', label: 'XCD', comment: 'Dollars'},
  {value: 'YER', label: 'YER', comment: 'Rials'},
  {value: 'ZAR', label: 'ZAR', comment: 'Rand'},
  {value: 'ZWD', label: 'ZWD', comment: 'Zimbabwe Dollars'}
];

export default options;
