import React, {useEffect} from 'react';
import {
  EmptyBox,
  Preloader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Title,
  Wrapper
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import {useWorkspaceContext} from 'workspace/workspace-context';

import Creator from './creator/creator';
import Token from './token/token';

const Tokens = () => {
  const {getTokenList, tokenList} = useWorkspaceContext();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Tokens`} - Whatcrm Console`;
  }, []);

  useEffect(() => {
    getTokenList();
  }, []);

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <Title.H1>{t`Tokens`}</Title.H1>
        <Creator />

        {tokenList ? (
          tokenList.length ? (
            <div>
              <EntriesNumber
                number={tokenList.length}
                style={{marginBottom: 8}}
              />

              <Table style={{marginBottom: 8}}>
                <TableHead>
                  <TableCell>{t`Name`}</TableCell>
                  <TableCell>{t`Value`}</TableCell>
                  <TableCell>{'CORS'}</TableCell>
                  <TableCell />
                  <TableCell />
                </TableHead>

                <TableBody>
                  {tokenList.map(item => (
                    <Token key={item.id} token={item} />
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default Tokens;
