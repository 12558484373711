import React from 'react';
import {Input, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Amount = () => {
  const [field, meta, helpers] = useField<string>('amount');
  const {t} = useTranslation();

  const isValid = !(meta.error && meta.touched);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Refund amount`}</Title.H3>

      <Input
        {...field}
        inputMode="decimal"
        type="number"
        isValid={isValid}
        placeholder={t`Refund amount`}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Amount;
