import React, {useState} from 'react';
import {
  Button,
  Confirmation,
  Icons,
  Mark,
  TableCell,
  TableRow,
  Text,
  Wrapper
} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import moment from 'moment';
import update from 'immutability-helper';

import Editor from '../../editor/editor';
import {useAppContext} from 'app-context';
import {useHomeContext} from 'pages/integrations/home/home-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import ContextMenuProvider from './context-menu-provider/context-menu-provider';
import IntegrationContext from './integration-context';
import IsPremium from './is-premium/is-premium';

interface Integration {
  integration: AppEnv.Integration;
  integrationList: AppEnv.Integration[];
  isDeleteShown: boolean;
  setIntegrationList: AppEnv.SetState<AppEnv.Integration[] | undefined>;
}

const Integration = ({
  integration,
  integrationList,
  isDeleteShown,
  setIntegrationList
}: Integration) => {
  const {preferences, pushNotification} = useAppContext();
  const {crmList} = useWorkspaceContext();
  const {getIntegrationList} = useHomeContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);
  const [isEditorActive, setIsEditorActive] = useState(false);

  const {deleteIntegrations, putIntegrations} = useRequest();
  const {i18n, t} = useTranslation();

  const deleteIntegration = async () => {
    const {data} = await deleteIntegrations(integration.id);
    if (typeof data != 'string') return true;

    setIsConfirmationActive(false);
    pushNotification(integration.domain, t`The integration has been deleted.`);
    setTimeout(() => getIntegrationList(), 200);

    return true;
  };

  const handleClick = async (res: boolean) => {
    if (res) return deleteIntegration();
    setIsConfirmationActive(false);
    return true;
  };

  const handleSubmit = async (values: AppEnv.IntegrationForm) => {
    const {data} = await putIntegrations(integration.id, values);

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.domain, t`The integration has been updated`);

      const index = integrationList.findIndex(item => item.id == data.id);

      setIntegrationList(prevValue =>
        update(prevValue, {
          [index]: {$set: data}
        })
      );
    }

    return true;
  };

  const {instances_count, managers_count} = integration;
  const crm = crmList?.find(item => item.id == integration.integration_type_id);

  const date_add = integration.date_add
    ? moment.unix(integration.date_add).utcOffset(preferences.utcOffset)
    : null;

  const urlSearchParams = crm?.title
    ? new URLSearchParams({
        crm: crm.title,
        domain: integration.domain,
        lang: integration.lang?.toLowerCase() || i18n.language.slice(0, 2)
      }).toString()
    : undefined;

  const href = `${process.env.REACT_APP_WHATCRM}${
    crm?.title == 'CHECKER' ? '/checker' : ''
  }?${urlSearchParams}`;

  return (
    <IntegrationContext.Provider value={{integration}}>
      <TableRow isActive={isEditorActive}>
        <TableCell>
          {date_add && (
            <>
              {date_add.format('L')}
              <br />
              {date_add.format('LT')}
            </>
          )}
        </TableCell>

        <TableCell>
          {urlSearchParams ? (
            <a href={href} target="_blank" rel="noreferrer">
              <Button color="transparent" isLink>
                {integration.domain}
              </Button>
            </a>
          ) : (
            integration.domain
          )}
        </TableCell>

        <TableCell>{integration.lang?.toUpperCase()}</TableCell>
        <TableCell>{crm?.title}</TableCell>
        <TableCell>{integration.widget_code}</TableCell>

        <TableCell>
          <Link
            to={`/billing/telphin?filter_code_client=${integration.telphin_code}`}
            state={{
              referrer: window.location.pathname + window.location.search
            }}
          >
            <Button color="transparent" isLink>
              {integration.telphin_code}
            </Button>
          </Link>
        </TableCell>

        <TableCell>
          <Wrapper alignItems="center" noWrap>
            {integration.date_trial > 0 &&
              moment.unix(integration.date_trial).utc().format('L')}
          </Wrapper>
        </TableCell>

        <TableCell>
          <Wrapper gap={4} isColumn>
            {instances_count > 0 && (
              <Text isParagraph>
                <Link
                  to={`/connections?filter_crm_id=${integration.id}&filter_domain=${integration.domain}`}
                  state={{
                    referrer: window.location.pathname + window.location.search
                  }}
                >
                  <Button color="transparent" isLink>
                    <Trans count={instances_count}>
                      {{instances_count}}
                      {' connection'}
                    </Trans>
                  </Button>
                </Link>
              </Text>
            )}

            {managers_count > 0 && (
              <Text isParagraph>
                <Link
                  to={`/staff?filter_domain=${integration.domain}&filter_integration_id=${integration.id}`}
                  state={{
                    referrer: window.location.pathname + window.location.search
                  }}
                >
                  <Button color="transparent" isLink>
                    <Trans count={managers_count}>
                      {{managers_count}}
                      {' employee'}
                    </Trans>
                  </Button>
                </Link>
              </Text>
            )}
          </Wrapper>
        </TableCell>

        <IsPremium />

        <TableCell isIcon>
          {integration.is_partner > 1 ? (
            <Link
              to={`/partners?filter_id=${integration.is_partner}`}
              state={{
                referrer: window.location.pathname + window.location.search
              }}
            >
              <Button color="transparent" tip={t`Open the partner`}>
                <Mark mark={integration.is_partner} />
              </Button>
            </Link>
          ) : (
            <Mark mark={integration.is_partner} />
          )}
        </TableCell>

        <TableCell isIcon>
          <Mark mark={integration.is_review} />
        </TableCell>

        <TableCell isIcon>
          <Mark mark={integration.is_deleted} />
        </TableCell>

        <TableCell isIcon>
          <Button
            color="transparent"
            tip={t`Update`}
            onClick={() => setIsEditorActive(true)}
          >
            <Icons.Edit />
          </Button>

          <Editor
            integration={integration}
            isActive={isEditorActive}
            isUpdate
            onClick={() => setIsEditorActive(false)}
            onSubmit={handleSubmit}
          />
        </TableCell>

        {isDeleteShown && (
          <TableCell isIcon>
            <Button
              color="transparent"
              onClick={() => setIsConfirmationActive(true)}
            >
              <Icons.Trash />
            </Button>

            <Confirmation
              title={t`Are you sure you want to delete this integration?`}
              isActive={isConfirmationActive}
              onClick={handleClick}
            />
          </TableCell>
        )}

        <ContextMenuProvider crm={crm} integration={integration} />
      </TableRow>
    </IntegrationContext.Provider>
  );
};

export default Integration;
