import React, {useEffect} from 'react';
import {Wrapper} from 'whatcrm-core';
import {Link} from 'react-router-dom';

const Error404 = () => {
  useEffect(() => {
    document.title = '404 - Whatcrm Console';
  }, []);

  return (
    <div className="content">
      <Wrapper
        justifyContent="center"
        alignItems="center"
        style={{height: '100%'}}
      >
        <Link to="/">
          <img
            src="/static/img/404.svg"
            alt="404"
          />
        </Link>
      </Wrapper>
    </div>
  );
};

export default Error404;
