import React, {useState} from 'react';
import {Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {usePromoCodesContext} from 'pages/promo-codes/promo-codes-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../../editor/editor';

const Creator = () => {
  const {pushNotification} = useAppContext();
  const {getPromoCodeList} = usePromoCodesContext();

  const [isEditorActive, setIsEditorActive] = useState(false);

  const {postPromoCodes} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.PromoCodeForm) => {
    const {data} = await postPromoCodes(values);

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.code, t`A promo code has been created`);
      getPromoCodeList();
    }

    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsEditorActive(true)}>
        <Icons.Plus />
        {t`Create`}
      </Button>

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default Creator;
