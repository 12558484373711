import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

const DateYslug = () => {
  const [field, meta, helpers] = useField<number | undefined>('date_yslug');
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    helpers.setValue(
      parseInt(moment(value).utcOffset(3).format('X')) || undefined
    );

  const htmlValue =
    field.value != undefined
      ? moment.unix(field.value).utcOffset(3).format('yyyy-MM-DD HH:mm:ss')
      : undefined;

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{`${t`Date`} (UTC+3)`}</Title.H3>

      <Input
        {...field}
        type="datetime-local"
        value={htmlValue}
        width="max"
        isValid={isValid}
        onChange={handleChange}
      />
    </div>
  );
};

export default DateYslug;
