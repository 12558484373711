import React from 'react';
import {Button, Line, Popup, SaveButton, Title, Wrapper} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';
import * as yup from 'yup';

import * as AppEnv from 'app-env';

import Column from './column/column';
import Comment from './comment/comment';
import DateYslug from './date-yslug/date-yslug';
import Quantity from './quantity/quantity';
import Summa from './summa/summa';
import TariffId from './tariff-id/tariff-id';

interface Editor {
  isActive: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.PaymentForm>;
  payment: AppEnv.Payment;
}

const Editor = ({isActive, onClick, onSubmit, payment}: Editor) => {
  const {t} = useTranslation();

  const initialValues = _.pick(payment, [
    'column',
    'comment',
    'date_yslug',
    'quantity',
    'summa',
    'tariff_id'
  ]);

  const validationSchema = yup.object().shape({
    column: yup.string().trim().required(),
    comment: yup.string().nullable(),
    date_yslug: yup.number(),
    quantity: yup.number().positive(),
    summa: yup.string().trim().required(),
    tariff_id: yup.number()
  });

  return (
    <Popup isActive={isActive} onClick={onClick}>
      <Title.H2 style={{marginBottom: 16}}>{t`Update the payment`}</Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <DateYslug />
            <Column />
            <TariffId payment={payment} />
            <Comment />
            <Quantity />
            <Summa />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={onClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
