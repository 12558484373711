import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Param = () => {
  const [field, meta, helpers] = useField<string>('param');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Parameter`}</Title.H3>

      <Input
        {...field}
        placeholder={t`Parameter`}
        width="max"
        autoCapitalize="off"
        isValid={isValid}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Param;
