import React, {useState} from 'react';
import {Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../editor/editor';

const Creator = () => {
  const {pushNotification} = useAppContext();
  const {setTokenList} = useWorkspaceContext();

  const [isEditorActive, setIsEditorActive] = useState(false);

  const {postTokens} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.TokenForm) => {
    const {data} = await postTokens(values);

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.name, t`A token has been created`);

      setTokenList(prevValue =>
        update(prevValue, {
          $push: [data]
        })
      );
    }

    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsEditorActive(true)}>
        <Icons.Plus />
        {t`Create`}
      </Button>

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default Creator;
