import React from 'react';
import {Wrapper, Title, Text} from 'whatcrm-core';

interface Detail {
  icon: React.ReactNode;
  label?: React.ReactNode;
  value: React.ReactNode;
}

interface Details {
  details: Detail[];
  isAutoWidth?: boolean;
  style?: React.CSSProperties;
}

const Details = ({details, isAutoWidth, style}: Details) => (
  <Wrapper gap={8} style={style}>
    {details.map((item, i) => (
      <div
        className="detail"
        key={i}
        style={isAutoWidth ? {width: 'auto'} : undefined}
      >
        {item.icon}
        <Title.H2>{item.value}</Title.H2>

        {item.label && (
          <Text size="s" isParagraph>
            {item.label}
          </Text>
        )}
      </div>
    ))}
  </Wrapper>
);

export default Details;
