import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

const Username = () => {
  const [field, meta, helpers] = useField<string>('username');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Username`}</Title.H3>

      <Input
        {...field}
        name="new-username"
        placeholder={t`Username`}
        width="max"
        autoCapitalize="off"
        autoComplete="off"
        isValid={isValid}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Username;
