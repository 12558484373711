import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Quantity = () => {
  const [field, meta, helpers] = useField<number | undefined>('quantity');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Quantity`}</Title.H3>

      <Input
        {...field}
        type="number"
        placeholder={t`Quantity`}
        isValid={isValid}
        onChange={value => helpers.setValue(parseInt(value) || undefined)}
      />
    </div>
  );
};

export default Quantity;
