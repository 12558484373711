import React, {useEffect, useState} from 'react';
import {Button, Confirmation, TableCell} from 'whatcrm-core';
import * as Unicons from '@iconscout/react-unicons';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import PopupProvider from './popup-provider/popup-provider';

interface Refund {
  payment: AppEnv.Payment;
  setIsActive: AppEnv.SetState<boolean>;
  updatePayment: (arg: AppEnv.Payment) => void;
}

const Refund = ({payment, setIsActive, updatePayment}: Refund) => {
  const {pushNotification} = useAppContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);

  const {putBillings} = useRequest();
  const {t} = useTranslation();

  useEffect(() => {
    setIsActive(isConfirmationActive);
  }, [isConfirmationActive]);

  const cancelRefund = async () => {
    const {data} = await putBillings(payment.id, {is_deleted: 0});

    if (!data) return true;

    updatePayment(data);
    setIsConfirmationActive(false);
    pushNotification(t`The refund was canceled.`);

    return true;
  };

  const handleClick = () => {
    if (payment.is_deleted) return setIsConfirmationActive(true);
    setIsPopupActive(true);
  };

  const handleConfirm = async (res: boolean) => {
    if (res) return cancelRefund();

    setIsConfirmationActive(false);
    return true;
  };

  return (
    <TableCell isIcon>
      <Button
        color="transparent"
        style={{height: 32, justifyContent: 'center', width: 32}}
        tip={
          payment.is_deleted ? t`Cancel refund` : t('Refund', {context: 'verb'})
        }
        onClick={handleClick}
      >
        {payment.is_deleted ? (
          <Unicons.UilSyncSlash size={16} />
        ) : (
          <Unicons.UilSync size={16} />
        )}
      </Button>

      <Confirmation
        title={t`Are you sure you want to cancel this refund?`}
        isActive={isConfirmationActive}
        onClick={handleConfirm}
      />

      <PopupProvider
        payment={payment}
        isPopupActive={isPopupActive}
        setIsPopupActive={setIsPopupActive}
        updatePayment={updatePayment}
      />
    </TableCell>
  );
};

export default Refund;
