import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {normalizeDomain} from 'common/actions';

const Domain = () => {
  const [field, meta, helpers] = useField<string>('domain');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Domain`}</Title.H3>

      <Input
        {...field}
        inputMode="email"
        placeholder={t`Domain`}
        width="max"
        autoCapitalize="off"
        isValid={isValid}
        onChange={value => helpers.setValue(normalizeDomain(value))}
      />
    </div>
  );
};

export default Domain;
