import React from 'react';
import {useTranslation} from 'react-i18next';

import {PieGraph} from 'components';
import {useConnectionsContext} from '../connections-context';

const Whatsapp = () => {
  const {stats} = useConnectionsContext();
  const {t} = useTranslation();

  const {whatsapp} = stats;

  const data = [
    {color: '#56caf0', label: t`In use`, value: parseInt(whatsapp.busy)},
    {color: '#83d11f', label: t`Free`, value: parseInt(whatsapp.free)},
    {color: '#ff8242', label: t`Hanging`, value: whatsapp.timeout}
  ];

  return <PieGraph data={data} title="WhatsApp" />;
};

export default Whatsapp;
