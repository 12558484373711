import React, {useState, useEffect} from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useWorkspaceContext} from 'workspace/workspace-context';
import {useGetRateList} from 'common/hooks';
import * as AppEnv from 'app-env';

interface TariffId {
  payment: AppEnv.Payment;
}

interface Rate {
  value: number;
  label: string;
}

const TariffId = ({payment}: TariffId) => {
  const {crmList} = useWorkspaceContext();
  const [rateList, setRateList] = useState<Rate[]>([]);

  const [field, , helpers] = useField<number | undefined>('tariff_id');
  const getRateList = useGetRateList();
  const {t} = useTranslation();

  const fetchOptions = async () => {
    const crmInstance = crmList.find(item => item.title == payment.crm);

    if (crmInstance) {
      const {options} = await getRateList(crmInstance.id);
      setRateList(options);
      return;
    }

    setRateList([]);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Rate`}</Title.H3>

      <SingleSelect
        {...field}
        options={rateList}
        placeholder={t`Rate`}
        isDisabled={!payment.crm}
        isMaxWidth
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default TariffId;
