import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface SubscriptionListContext {
  getSubscriptionList: () => Promise<void>;
  paymentSystem: AppEnv.PaymentSystem;
  selectedSubscriptionIdList: number[];
  setSelectedSubscriptionIdList: AppEnv.SetState<number[]>;
  setSubscriptionList: AppEnv.SetState<AppEnv.SubscriptionExpand[] | undefined>;
}

const SubscriptionListContext = React.createContext<SubscriptionListContext>(
  {} as SubscriptionListContext
);

export const useSubscriptionListContext = () =>
  useContext(SubscriptionListContext);

export default SubscriptionListContext;
