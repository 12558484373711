import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {useFilter} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import Content from './content/content';
import Filter from './filter/filter';
import UsersContext from './users-context';

const Users = () => {
  const {getUserList} = useWorkspaceContext();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.UserFilter> = useFilter();

  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Users`} - Whatcrm Console`;
  }, []);

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <UsersContext.Provider value={{isFilterActive, setIsFilterActive}}>
      <Content filter={filter} />
      <Filter filter={filter} setFilter={setFilter} />
    </UsersContext.Provider>
  );
};

export default Users;
