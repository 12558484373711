import React from 'react';
import {Grid} from 'whatcrm-core';
import Fuse from 'fuse.js';

import {EntriesNumber} from 'components';
import * as AppEnv from 'app-env';

import Server from './server/server';

interface ServerList {
  search: string;
  serverList: AppEnv.Server[];
}

const ServerList = ({search, serverList}: ServerList) => {
  const fuse = new Fuse(serverList, {keys: ['s'], threshold: 0});

  const fusedServerList = search
    ? fuse.search(search).map(item => item.item)
    : serverList;

  return (
    <div>
      <EntriesNumber
        number={fusedServerList.length}
        style={{marginBottom: 8}}
      />

      <Grid scheme={[1, 2, 3, 4]} gap={16}>
        {fusedServerList.map((item, i) => (
          <Server key={i} server={item} />
        ))}
      </Grid>
    </div>
  );
};

export default ServerList;
