import React, {useState, useEffect} from 'react';
import {
  Popup,
  Title,
  Line,
  Wrapper,
  Button,
  Preloader,
  EmptyBox,
  useCopyToClipboard
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Code} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Transaction {
  id: number;
  isTransactionActive: boolean;
  setIsTransactionActive: AppEnv.SetState<boolean>;
}

const Transaction = ({
  id,
  isTransactionActive,
  setIsTransactionActive
}: Transaction) => {
  const {pushNotification} = useAppContext();
  const [transaction, setTransaction] = useState<string | null>();

  const copyToClipboard = useCopyToClipboard();
  const {fetchTelphins} = useRequest();
  const {t} = useTranslation();

  const getTransaction = async () => {
    const {data} = await fetchTelphins<AppEnv.TelphinWithSubscription>({id});

    if (data) {
      setTransaction(JSON.stringify(data.subscription));
      return;
    }

    setTransaction(null);
  };

  useEffect(() => {
    if (isTransactionActive) {
      getTransaction();
    }
  }, [isTransactionActive]);

  const handleClick = async () => {
    if (transaction) {
      const res = await copyToClipboard(transaction);
      pushNotification(res.message);
    }
  };

  return (
    <Popup
      width={790}
      isActive={isTransactionActive}
      onClick={() => setIsTransactionActive(false)}
    >
      <Title.H2 style={{marginBottom: 16}}>{t`Transaction`}</Title.H2>

      {transaction != undefined ? (
        transaction ? (
          <Code code={transaction} />
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}

      <Line />

      <Wrapper gap={8}>
        <Button isDisabled={!transaction} onClick={handleClick}>
          {t`Copy`}
        </Button>

        <Button color="white" onClick={() => setIsTransactionActive(false)}>
          {t`Close`}
        </Button>
      </Wrapper>
    </Popup>
  );
};

export default Transaction;
