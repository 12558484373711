import React, {useEffect} from 'react';
import {Input, normalizePhoneNumber, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';
import * as EditorEnv from '../editor-env';

interface Phone {
  type: EditorEnv.Type;
  setType: AppEnv.SetState<EditorEnv.Type>;
}

const Phone = ({type, setType}: Phone) => {
  const [field, meta, helpers] = useField<string>('phone');
  const {t} = useTranslation();

  useEffect(() => {
    setType(
      parseInt(field.value?.[0]) >= 0 || field.value?.[0] == '+'
        ? 'tel'
        : 'text'
    );
  }, [field.value]);

  const handleChange = (value: string) => {
    if (type == 'tel') {
      helpers.setValue(normalizePhoneNumber(value));
      return;
    }

    helpers.setValue(value);
  };

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>
        {t`Phone number or username`}
      </Title.H3>

      <Input
        {...field}
        type={type}
        placeholder={t`Phone number or username`}
        autoCapitalize="off"
        isValid={isValid}
        onChange={handleChange}
      />
    </div>
  );
};

export default Phone;
