import React from 'react';
import {Text} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

interface Item {
  color: string;
  label: string;
  value: number;
}

interface LegendItem<T> {
  item: Item;
  itemKey: string;
  max: number;
  value: string | undefined;
  isIndependent: boolean | undefined;
  onClick: ((arg: T) => void) | undefined;
}

const LegendItem = <T extends string>({
  item,
  itemKey,
  max,
  value,
  isIndependent,
  onClick
}: LegendItem<T>) => {
  const {i18n} = useTranslation();
  const width = isIndependent ? 100 : (item.value / max) * 100;

  return (
    <div
      className={cn('legend', {legend_inactive: value && itemKey != value})}
      style={{borderColor: item.color}}
      onClick={() => (onClick ? onClick(itemKey as T) : undefined)}
    >
      <div
        className="legend__bg"
        style={{width: `${width}%`, background: item.color}}
      />

      <div className="legend__wrapper">
        <Text color="dark">{item.label}</Text>
        <Text color="dark">{item.value.toLocaleString(i18n.language)}</Text>
      </div>
    </div>
  );
};

export default LegendItem;
