import React, {useState} from 'react';
import {Wrapper, Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import Editor from '../editor/editor';
import {useAppContext} from 'app-context';
import {useTelphinContext} from '../telphin-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Header {
  isFilterActive: boolean;
  setIsFilterActive: AppEnv.SetState<boolean>;
}

const Header = ({isFilterActive, setIsFilterActive}: Header) => {
  const {pushNotification} = useAppContext();
  const {getSubscriptionList} = useTelphinContext();

  const [isEditorActive, setIsEditorActive] = useState(false);

  const {postTelphins} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.TelphinForm) => {
    const {data} = await postTelphins(values);

    if (data) {
      setIsEditorActive(false);
      pushNotification(t`A subscription has been created`);
      getSubscriptionList();
    }

    return true;
  };

  return (
    <>
      <Wrapper gap={8}>
        <Button color="white" onClick={() => setIsEditorActive(true)}>
          <Icons.Plus />
          {t`Add`}
        </Button>

        <Button
          color={isFilterActive ? 'active' : 'white'}
          onClick={() => setIsFilterActive(prevValue => !prevValue)}
        >
          <Icons.Filter />
          {t`Filter`}
        </Button>
      </Wrapper>

      <Editor
        isActive={isEditorActive}
        onSubmit={handleSubmit}
        onClick={() => setIsEditorActive(false)}
      />
    </>
  );
};

export default Header;
