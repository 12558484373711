interface FilterParams {
  [index: string]: string | string[];
}

interface SearchParamsSearchParams {
  page?: string;
  version?: string;
}

interface SearchParams {
  filterParams: FilterParams;
  filterParamsWithPrefix: FilterParams;
  searchParams: SearchParamsSearchParams;
}

const getSearchParams = (search: string): SearchParams => {
  const urlSearchParams = new URLSearchParams(search);

  const searchEntries = Object.entries(Object.fromEntries(urlSearchParams)).map(
    ([key, value]) => [key, value.includes(',') ? value.split(',') : value]
  );

  const filterEntries = searchEntries.filter(([key]) =>
    key.includes('filter_')
  );

  const searchParams = Object.fromEntries(
    searchEntries.filter(([key]) => !key.includes('filter_'))
  );

  const filterParams = Object.fromEntries(
    filterEntries.map(([key, value]) => [
      (key as string).replace('filter_', ''),
      value
    ])
  );

  const filterParamsWithPrefix = Object.fromEntries(filterEntries);
  return {searchParams, filterParams, filterParamsWithPrefix};
};

export default getSearchParams;
