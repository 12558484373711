import React, {useEffect, useState} from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useSubscriptionContext} from '../../subscription-context';
import {useGetRateList} from 'common/hooks';

interface Rate {
  value: number;
  label: string;
}

const TariffId = () => {
  const {subscription} = useSubscriptionContext();
  const [rateList, setRateList] = useState<Rate[]>([]);

  const [field, , helpers] = useField<number | undefined>('tariff_id');
  const getRateList = useGetRateList();
  const {t} = useTranslation();

  const fetchOptions = async () => {
    const {options} = await getRateList(
      subscription.integration?.integration_type_id || 0
    );

    setRateList(options);
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Rate`}</Title.H3>

      <SingleSelect
        {...field}
        options={rateList}
        placeholder={t`Rate`}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default TariffId;
