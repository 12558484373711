import moment from 'moment';
import * as AppEnv from 'app-env';

const filterToRequest = (
  filter: AppEnv.PaymentFilter
): AppEnv.PaymentRequest => ({
  column: filter.column ? {value: filter.column} : undefined,
  comment: filter.comment ? {value: filter.comment, type: 'like'} : undefined,
  country: filter.country ? {value: filter.country} : undefined,
  date_yslug_after: filter.date_yslug_after
    ? {value: moment.utc(filter.date_yslug_after).format('X'), type: '>'}
    : undefined,
  date_yslug_before: filter.date_yslug_before
    ? {
        value: moment.utc(`${filter.date_yslug_before} 23:59:59`).format('X'),
        type: '<'
      }
    : undefined,
  integration_id: filter.integration_id
    ? {value: filter.integration_id}
    : undefined,
  is_deleted:
    filter.is_deleted == '0' || filter.is_deleted == '1'
      ? {value: filter.is_deleted}
      : undefined,
  tariff_id: filter.tariff_id?.length ? {value: filter.tariff_id} : undefined,
  transactionId: filter.transactionId
    ? {value: filter.transactionId, type: 'like'}
    : undefined
});

export default filterToRequest;
