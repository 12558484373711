import React from 'react';
import {Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {IntegrationSearchSelect, IntegrationSearchSelectEnv} from 'components';

const Domain = () => {
  const [field, , helpers] = useField<string | undefined>('domain');
  const [idField, , idHelpers] = useField<string | undefined>('id');
  const {t} = useTranslation();

  const handleChange = (value: IntegrationSearchSelectEnv.Integration) => {
    helpers.setValue(value.domain);
    idHelpers.setValue(value.is_partner.toString());
  };

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Integration`}</Title.H3>

      <IntegrationSearchSelect
        domain={field.value}
        name="domain"
        value={+(idField.value || '')}
        isPartner
        onChange={handleChange}
      />
    </div>
  );
};

export default Domain;
