import React from 'react';
import {Toggle} from 'whatcrm-core';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';

const UtcOffset = () => {
  const {preferences, setPreferences} = useAppContext();

  const handleChange = (value: boolean) =>
    setPreferences(prevValue =>
      update(prevValue, {utcOffset: {$set: value ? 3 : 0}})
    );

  return (
    <Toggle
      label="UTC+3"
      value={!!preferences?.utcOffset}
      onChange={handleChange}
    />
  );
};

export default UtcOffset;
