import * as AppEnv from 'app-env';

const getConnectionPaymentStatus = (connection: AppEnv.Instance) => {
  let expirationDate: number | null = null;
  let isExpired = false;
  let isPaid = false;

  if (connection.date_pay) {
    const value = new Date(connection.date_pay * 1000);

    expirationDate =
      connection.tariff && connection.tariff.quantity != 30
        ? value.setMonth(value.getMonth() + connection.tariff.quantity) / 1000
        : value.setDate(value.getDate() + 30) / 1000;

    isExpired = !!(expirationDate && expirationDate < +new Date() / 1000);
    isPaid = !!(expirationDate || connection.tariff || connection.date_pay);
  }

  return {expirationDate, isExpired, isPaid};
};

export default getConnectionPaymentStatus;
