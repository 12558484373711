import React, {useState} from 'react';
import {TableRow, TableCell, Button, Icons, Confirmation} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';
import moment from 'moment';

import {useAppContext} from 'app-context';
import {useNotificationsContext} from 'pages/notifications/notifications-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../../editor/editor';

interface Notification {
  notification: AppEnv.Notification;
}

const Notification = ({notification}: Notification) => {
  const {preferences, pushNotification} = useAppContext();

  const {notificationList, setNotificationList, getNotificationList} =
    useNotificationsContext();

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {deleteNotifications, putNotifications} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.NotificationForm) => {
    const {data} = await putNotifications(notification.id, values);

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.title, t`The notification has been updated`);

      const index = notificationList?.findIndex(item => item.id == data.id);

      if (typeof index == 'number' && index != -1) {
        setNotificationList(prevValue =>
          update(prevValue, {
            [index]: {$set: data}
          })
        );
      }
    }

    return true;
  };

  const deleteNotification = async () => {
    const {data} = await deleteNotifications(notification.id);

    if (typeof data == 'string') {
      pushNotification(
        notification.title,
        t`The notification has been deleted`
      );

      getNotificationList();
    }

    return true;
  };

  const handleClick = async (res?: boolean) => {
    if (res) {
      return deleteNotification();
    }

    setIsConfirmationActive(false);
    return true;
  };

  const date_end =
    notification.date_end > 0
      ? moment.unix(notification.date_end).utcOffset(preferences.utcOffset)
      : undefined;

  const date_start =
    notification.date_start > 0
      ? moment.unix(notification.date_start).utcOffset(preferences.utcOffset)
      : undefined;

  const isActive = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell isIcon>
        {notification.alert_type == 0 ? (
          <Icons.NoticeInfo />
        ) : notification.alert_type == 1 ? (
          <Icons.NoticeWarning />
        ) : notification.alert_type == 2 ? (
          <Icons.NoticeDanger />
        ) : (
          notification.alert_type == 3 && <Icons.NoticeSuccess />
        )}
      </TableCell>

      <TableCell>{notification.title}</TableCell>

      <TableCell>
        {date_start && (
          <>
            {date_start.format('L')}
            <br />
            {date_start.format('LT')}
          </>
        )}
      </TableCell>

      <TableCell>
        {date_end && (
          <>
            {date_end.format('L')}
            <br />
            {date_end.format('LT')}
          </>
        )}
      </TableCell>

      <TableCell>{notification.type?.title}</TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={notification}
          isActive={isEditorActive}
          isUpdate
          onClick={() => setIsEditorActive(false)}
          onSubmit={handleSubmit}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Delete`}
          onClick={() => setIsConfirmationActive(true)}
        >
          <Icons.Trash />
        </Button>
      </TableCell>

      <Confirmation
        title={t`Are you sure you want to delete this notification?`}
        isActive={isConfirmationActive}
        onClick={handleClick}
      />
    </TableRow>
  );
};

export default Notification;
