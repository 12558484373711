import React from 'react';
import {Popup, Title, Wrapper, Line, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import * as AppEnv from 'app-env';

import Param from './param/param';
import Value from './value/value';
import Comment from './comment/comment';

interface Editor {
  initialValues?: AppEnv.SystemOptionForm;
  isActive: boolean;
  isUpdate?: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.SystemOptionForm>;
}

const Editor = ({
  initialValues = {comment: undefined, param: undefined, value: undefined},
  isActive,
  isUpdate,
  onClick,
  onSubmit
}: Editor) => {
  const {t} = useTranslation();

  const validationSchema = yup.object().shape({
    param: yup.string().trim().required()
  });

  return (
    <Popup
      isActive={isActive}
      onClick={onClick}
    >
      <Title.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Update the system option` : t`Create a system option`}
      </Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        <Form>
          <Wrapper
            gap={16}
            isColumn
          >
            <Param />
            <Value />
            <Comment />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button
              color="white"
              onClick={onClick}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
