import React from 'react';
import {Checkbox} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const IsImport = () => {
  const [field, , helpers] = useField('is_import');
  const {t} = useTranslation();

  return (
    <Checkbox
      {...field}
      label={t`Contacts are imported`}
      onChange={helpers.setValue}
    />
  );
};

export default IsImport;
