import React from 'react';
import {Wrapper, Pagination, Preloader, EmptyBox} from 'whatcrm-core';

import {EntriesNumber} from 'components';

import Header from './header/header';
import PromoCodeList from './promo-code-list/promo-code-list';
import {usePromoCodesContext} from '../promo-codes-context';

const Content = () => {
  const {promoCodeList, pagination} = usePromoCodesContext();

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <Header />

        {promoCodeList ? (
          promoCodeList.length > 0 ? (
            <div>
              <EntriesNumber
                number={pagination.totalCount}
                style={{marginBottom: 8}}
              />

              <PromoCodeList />

              <Pagination {...pagination} isSticky />
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default Content;
