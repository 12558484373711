import React from 'react';
import {Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {IntegrationSearchSelect, IntegrationSearchSelectEnv} from 'components';
import * as AppEnv from 'app-env';

interface IntegrationId {
  setCrmId: AppEnv.SetState<number | undefined>;
}

const IntegrationId = ({setCrmId}: IntegrationId) => {
  const [field, meta, helpers] = useField<number>('integration_id');
  const {t} = useTranslation();

  const handleChange = (value: IntegrationSearchSelectEnv.Integration) => {
    helpers.setValue(value.id);
    setCrmId(value.integration_type_id);
  };

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Integration`}</Title.H3>

      <IntegrationSearchSelect
        {...field}
        isMaxWidth
        isValid={isValid}
        onChange={handleChange}
      />
    </div>
  );
};

export default IntegrationId;
