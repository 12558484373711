import React from 'react';
import {
  Popup,
  Title,
  Wrapper,
  Line as CoreLine,
  Button,
  SaveButton
} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

import ActionType from './action-type/action-type';
import BonusId from './bonus-id/bonus-id';
import CodeClient from './code-client/code-client';
import CountAvitoPro from './count-avito-pro/count-avito-pro';
import CountAvitoStart from './count-avito-start/count-avito-start';
import CountEnterprise from './count-enterprise/count-enterprise';
import CountPro from './count-pro/count-pro';
import CountStart from './count-start/count-start';
import DataEvent from './data-event/data-event';
import Line from './line/line';

interface Editor {
  initialValues?: AppEnv.TelphinForm;
  isActive: boolean;
  isUpdate?: boolean;
  onSubmit: AppEnv.Submit<AppEnv.TelphinForm>;
  onClick: () => void;
}

const Editor = ({
  initialValues = {
    action_type: undefined,
    bonus_id: undefined,
    code_client: undefined,
    count_avito_pro: undefined,
    count_avito_start: undefined,
    count_enterprise: undefined,
    count_pro: undefined,
    count_start: undefined,
    data_event: undefined,
    line: undefined
  },
  isActive,
  isUpdate,
  onSubmit,
  onClick
}: Editor) => {
  const {t} = useTranslation();

  return (
    <Popup isActive={isActive} onClick={onClick}>
      <Title.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Update the subscription` : t`Add a subscription`}
      </Title.H2>

      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        <Form>
          <Wrapper gap={16} isColumn>
            <DataEvent />
            <ActionType />
            {!isUpdate && <CodeClient />}
            <BonusId />
            <Line />
            <CountStart />
            <CountPro />
            <CountEnterprise />
            <CountAvitoStart />
            <CountAvitoPro />
          </Wrapper>

          <CoreLine />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={onClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
