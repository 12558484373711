import React, {useState} from 'react';
import {
  Button,
  Confirmation,
  formatPhoneNumber,
  Icons,
  TableCell,
  TableRow
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';
import moment from 'moment';

import {Keyword} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from './editor/editor';
import {useBlockListContext} from 'pages/block-list/block-list-context';

interface Blocked {
  blocked: AppEnv.Blocked;
}

const Blocked = ({blocked}: Blocked) => {
  const {preferences, pushNotification} = useAppContext();
  const {blockedList, setBlockedList, getBlockedList} = useBlockListContext();

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {deleteBlacklists, putBlacklists} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.BlockedForm) => {
    const {data} = await putBlacklists(blocked.id, values);

    if (data) {
      setIsEditorActive(false);

      pushNotification(data.phone, t`The blocked contact has been updated`);

      const index = blockedList?.findIndex(item => item.id == data.id);

      if (typeof index == 'number' && index != -1) {
        setBlockedList(prevValue => update(prevValue, {[index]: {$set: data}}));
      }
    }

    return true;
  };

  const deleteBlocked = async () => {
    const {data} = await deleteBlacklists(blocked.id);

    if (typeof data == 'string') {
      pushNotification(blocked.phone, t`The contact has been unblocked`);
      getBlockedList();
    }

    return true;
  };

  const handleClick = async (res: boolean) => {
    if (res) {
      return deleteBlocked();
    }

    setIsConfirmationActive(false);
    return true;
  };

  const date_add = blocked.date_add
    ? moment.unix(blocked.date_add).utcOffset(preferences.utcOffset)
    : null;

  const isActive = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>
        {date_add && (
          <>
            {date_add.format('L')}
            <br />
            {date_add.format('LT')}
          </>
        )}
      </TableCell>

      <TableCell>{blocked.integration?.domain}</TableCell>

      <TableCell>
        {parseInt(blocked.phone) ? (
          <Keyword keyword={blocked.phone}>
            {formatPhoneNumber(blocked.phone)}
          </Keyword>
        ) : (
          `@${blocked.phone}`
        )}
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={blocked}
          isActive={isEditorActive}
          onClick={() => setIsEditorActive(false)}
          onSubmit={handleSubmit}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Unblock`}
          onClick={() => setIsConfirmationActive(true)}
        >
          <Icons.Trash />
        </Button>

        <Confirmation
          title={t`Are you sure you want to unblock this contact?`}
          isActive={isConfirmationActive}
          onClick={handleClick}
        />
      </TableCell>
    </TableRow>
  );
};

export default Blocked;
