import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import useCategories from 'pages/events/use-categories';

const Category = () => {
  const [field, , helpers] = useField('category');
  const categories = useCategories();
  const {t} = useTranslation();

  const options = Object.entries(categories).map(([key, value]) => ({
    value: key,
    label: value
  }));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Category`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Category`}
        isClearable
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Category;
