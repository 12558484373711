import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Country = () => {
  const [field, meta, helpers] = useField('country');
  const {t} = useTranslation();

  const options = [
    {
      value: 'RUB',
      label: 'RUB'
    },
    {
      value: 'USD',
      label: 'USD'
    }
  ];

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Currency`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        isValid={isValid}
        placeholder={t`Currency`}
        isMaxWidth
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Country;
