import React from 'react';
import {Input, isolateNumbers, Title} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

const BonusId = () => {
  const [field, , helpers] = useField<number | undefined>('bonus_id');
  const {t} = useTranslation();

  const handleChange = (value: string) =>
    helpers.setValue(parseInt(isolateNumbers(value).slice(0, 9)) || undefined);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Rate plan ID`}</Title.H3>

      <Input
        {...field}
        inputMode="numeric"
        placeholder={t`Rate plan ID`}
        width="max"
        onChange={handleChange}
      />
    </div>
  );
};

export default BonusId;
