import React from 'react';
import {Title as CoreTitle, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Title = () => {
  const [field, meta, helpers] = useField<string>('title');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <CoreTitle.H3 style={{marginBottom: 8}}>{t`Title`}</CoreTitle.H3>

      <Input
        {...field}
        isValid={isValid}
        placeholder={t`Title`}
        width="max"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Title;
