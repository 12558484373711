import React, {useState} from 'react';
import {
  TableRow,
  TableCell,
  Mark,
  Button,
  Icons,
  Confirmation
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';
import moment from 'moment';

import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../../editor/editor';

interface PromoCode {
  promoCode: AppEnv.PromoCode;
  promoCodeList: AppEnv.PromoCode[];
  setPromoCodeList: AppEnv.SetState<AppEnv.PromoCode[] | undefined>;
}

const PromoCode = ({promoCode, promoCodeList, setPromoCodeList}: PromoCode) => {
  const {preferences, pushNotification} = useAppContext();
  const {crmList} = useWorkspaceContext();

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {putPromoCodes} = useRequest();
  const {t} = useTranslation();

  const index = promoCodeList.findIndex(item => item.id == promoCode.id);

  const updatePromoCode = (data: AppEnv.PromoCode) =>
    setPromoCodeList(prevValue =>
      update(prevValue, {
        [index]: {$set: data}
      })
    );

  const handleSubmit = async (values: AppEnv.PromoCodeForm) => {
    const {data} = await putPromoCodes(promoCode.id, values);

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.code, t`The promo code has been updated`);
      updatePromoCode(data);
    }

    return true;
  };

  const deletePromoCode = async () => {
    const {data} = await putPromoCodes(promoCode.id, {is_deleted: 1});

    if (data) {
      setIsConfirmationActive(false);
      pushNotification(promoCode.code, t`The promo code has been deleted`);
      updatePromoCode(data);
    }

    return true;
  };

  const handleReturn = async () => {
    const {data} = await putPromoCodes(promoCode.id, {is_deleted: 0});

    if (data) {
      pushNotification(promoCode.code, t`The promo code has been restored`);
      updatePromoCode(data);
    }
  };

  const handleConfirm = async (res: boolean) => {
    if (res) {
      return deletePromoCode();
    }

    setIsConfirmationActive(false);
    return true;
  };

  const crm = crmList?.find(item => item.id == promoCode.integration_id);

  const date_end =
    promoCode.date_end > 0
      ? moment.unix(promoCode.date_end).utcOffset(preferences.utcOffset)
      : undefined;

  const date_start =
    promoCode.date_start > 0
      ? moment.unix(promoCode.date_start).utcOffset(preferences.utcOffset)
      : undefined;

  const isActive = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>{promoCode.code}</TableCell>

      <TableCell>
        {typeof promoCode.discount !== 'object' && <>{promoCode.discount}%</>}
      </TableCell>

      <TableCell>
        {date_start && (
          <>
            {date_start.format('L')}
            <br />
            {date_start.format('LT')}
          </>
        )}
      </TableCell>

      <TableCell>
        {date_end && (
          <>
            {date_end.format('L')}
            <br />
            {date_end.format('LT')}
          </>
        )}
      </TableCell>

      <TableCell>{crm?.title}</TableCell>

      <TableCell isIcon>
        <Mark mark={promoCode.is_use} />
      </TableCell>

      <TableCell isIcon>
        <Mark mark={promoCode.is_deleted} />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={promoCode}
          isActive={isEditorActive}
          isUpdate
          onClick={() => setIsEditorActive(false)}
          onSubmit={handleSubmit}
        />
      </TableCell>

      <TableCell isIcon>
        {promoCode.is_deleted ? (
          <Button color="transparent" tip={t`Restore`} onClick={handleReturn}>
            <Icons.Return />
          </Button>
        ) : (
          <>
            <Button
              color="transparent"
              tip={t`Remove`}
              onClick={() => setIsConfirmationActive(true)}
            >
              <Icons.Trash />
            </Button>

            <Confirmation
              title={t`Are you sure you want to remove this promo code?`}
              isActive={isConfirmationActive}
              onClick={handleConfirm}
            />
          </>
        )}
      </TableCell>
    </TableRow>
  );
};

export default PromoCode;
