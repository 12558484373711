import React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  Preloader,
  EmptyBox
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';

import Container from './container/container';
import {useContainersContext} from '../containers-context';

const ContainerList = () => {
  const {containerList} = useContainersContext();
  const {t} = useTranslation();

  return (
    <>
      {containerList ? (
        containerList.length ? (
          <div>
            <EntriesNumber
              number={containerList.length}
              style={{marginBottom: 8}}
            />

            <Table>
              <TableHead>
                <TableCell>ID</TableCell>
                <TableCell>IPv6</TableCell>
                <TableCell>{t`Status`}</TableCell>
                <TableCell>{t`Condition`}</TableCell>
                <TableCell isIcon />
                <TableCell isIcon />
              </TableHead>

              <TableBody>
                {containerList.map(item => (
                  <Container key={item.id} container={item} />
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default ContainerList;
