import React, {useEffect, useState} from 'react';
import {EmptyBox, Preloader, Title} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import {useCrmRequest} from 'common/hooks';
import {useIntegrationContext} from '../integration-context';
import * as AppEnv from 'app-env';

import QueueList from './queue-list/queue-list';

const Queues = () => {
  const {crm, integration} = useIntegrationContext();
  const [queueList, setQueueList] = useState<AppEnv.Queue[]>();

  const {fetchQueueGet} = useCrmRequest();
  const {t} = useTranslation();

  const getQueueList = async () => {
    if (!crm) return setQueueList([]);

    const res = await fetchQueueGet(crm.title, integration.domain);
    setQueueList(res || []);
  };

  useEffect(() => {
    getQueueList();
  }, []);

  return (
    <>
      <Title.H3 style={{marginBottom: 24}}>{t`Queues`}</Title.H3>

      {queueList ? (
        queueList.length ? (
          <>
            <EntriesNumber
              number={queueList.length}
              style={{marginBottom: 8}}
            />

            <QueueList queueList={queueList} />
          </>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Queues;
