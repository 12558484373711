import {useEffect} from 'react';
import {getSearchParams} from 'common/actions';

const useVersionParam = (version: string | undefined) => {
  useEffect(() => {
    const {searchParams, filterParamsWithPrefix} = getSearchParams(
      window.location.search
    );

    const updateSearchParams = () => {
      if (!version) {
        delete searchParams.version;
        return;
      }

      searchParams.version = version;
    };

    updateSearchParams();

    const urlSearchParams = new URLSearchParams({
      ...searchParams,
      ...filterParamsWithPrefix
    } as Record<string, string>).toString();

    history.pushState(
      '',
      '',
      urlSearchParams ? `?${urlSearchParams}` : window.location.pathname
    );
  }, [version]);
};

export default useVersionParam;
