import React from 'react';

import {Sidebar} from 'components';
import {useAppContext} from 'app-context';
import {useHomeContext} from '../home-context';
import {Roles} from 'app-enums';
import * as AppEnv from 'app-env';

import DateAddAfter from './date-add-after/date-add-after';
import DateAddBefore from './date-add-before/date-add-before';
import Domain from './domain/domain';
import Email from './email/email';
import Fullname from './fullname/fullname';
import Id from './id/id';
import IsDeleted from './is-deleted/is-deleted';
import Phone from './phone/phone';
import TelphinCode from './telphin-code/telphin-code';
import Type from './type/type';

interface Filter {
  filter: AppEnv.PartnerFilter;
  setFilter: AppEnv.SetState<AppEnv.PartnerFilter>;
}

const Filter = ({filter, setFilter}: Filter) => {
  const {client} = useAppContext();
  const {isFilterActive, partnerList, setIsFilterActive} = useHomeContext();

  const initialValues: AppEnv.PartnerFilter = {
    is_deleted: filter.is_deleted || '-1',
    type: filter.type || '-1'
  };

  return (
    <Sidebar
      filter={filter}
      initialValues={initialValues}
      isActive={isFilterActive}
      isDisabled={!partnerList}
      onSubmit={async (values: AppEnv.PartnerFilter) => setFilter(values)}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
    >
      {client?.access_level && client.access_level >= Roles.MODER && <Id />}
      <Fullname />
      <Email />
      <Phone />
      <TelphinCode />
      <Domain />
      <DateAddAfter />
      <DateAddBefore />
      <Type />
      <IsDeleted />
    </Sidebar>
  );
};

export default Filter;
