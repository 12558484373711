import React from 'react';
import {
  EmptyBox,
  Preloader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Wrapper
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import Header from './header/header';
import User from './user/user';

interface Content {
  filter: AppEnv.UserFilter;
}

const Content = ({filter}: Content) => {
  const {userList} = useWorkspaceContext();
  const {t} = useTranslation();

  const fusedUserList = filter.id
    ? userList?.filter(item => filter.id && item.id == parseInt(filter.id))
    : userList;

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <Header />

        {userList ? (
          fusedUserList?.length ? (
            <div>
              <EntriesNumber
                number={fusedUserList.length}
                style={{marginBottom: 8}}
              />

              <Table>
                <TableHead>
                  <TableCell>ID</TableCell>
                  <TableCell>{t`Username`}</TableCell>
                  <TableCell>{t`Role`}</TableCell>
                  <TableCell isIcon />
                  <TableCell isIcon />
                </TableHead>

                <TableBody>
                  {fusedUserList.map(item => (
                    <User key={item.id} user={item} />
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default Content;
