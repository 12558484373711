import React from 'react';
import {
  Button,
  formatPhoneNumber,
  Icons,
  Text,
  Title,
  useCopyToClipboard,
  Wrapper
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Keyword} from 'components';
import {useAppContext} from 'app-context';
import {useConnectionContext} from '../connection-context';

const UserInfo = () => {
  const {pushNotification} = useAppContext();
  const {connection} = useConnectionContext();

  const copyToClipboard = useCopyToClipboard();
  const {t} = useTranslation();

  const handleClick = async () => {
    const res = await copyToClipboard(String(connection.phone));
    pushNotification(res.message);
  };

  const isShown = !!(
    connection.name ||
    connection.phone ||
    connection.platform
  );

  return (
    <>
      {isShown && (
        <Wrapper gap={8} isColumn>
          <Title.H3>{t`User`}</Title.H3>

          {connection.name && (
            <Text isParagraph>
              {t`Name`}: {connection.name}
            </Text>
          )}

          {connection.phone && (
            <Wrapper alignItems="center" noWrap>
              <Text whiteSpace="nowrap" isParagraph>
                {t`Phone number`}:{' '}
                <Keyword keyword={connection.phone}>
                  {formatPhoneNumber(connection.phone)}
                </Keyword>
              </Text>

              <Button
                color="transparent"
                height={16}
                tip={t`Copy`}
                onClick={() => handleClick()}
              >
                <Icons.Copy />
              </Button>
            </Wrapper>
          )}

          {connection.platform && (
            <Text isParagraph>
              {t`Platform`}: {connection.platform}
            </Text>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default UserInfo;
