import React from 'react';
import {useScrollClassName} from 'whatcrm-core';
import cn from 'classnames';

import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'workspace/workspace-context';

import NavItem from './nav-item/nav-item';

const Nav = () => {
  const {isNavActive} = useAppContext();
  const {pages} = useWorkspaceContext();

  const scrollClassName = useScrollClassName();

  return (
    <div className={cn('nav', {nav_active: isNavActive})}>
      <div className={cn('nav__content', {[scrollClassName]: scrollClassName})}>
        {Object.values(pages).map((item, i) => (
          <NavItem key={i} navItem={item} />
        ))}
      </div>
    </div>
  );
};

export default Nav;
