import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface HomeContext {
  setServerList: AppEnv.SetState<AppEnv.Server[] | undefined>;
}

const HomeContext = React.createContext<HomeContext>({} as HomeContext);

export const useHomeContext = () => useContext(HomeContext);
export default HomeContext;
