import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface PaymentListContext {
  selectedPaymentIdList: number[];
  setSelectedPaymentIdList: AppEnv.SetState<number[]>;
  getPaymentList: () => Promise<void>;
}

const PaymentListContext = React.createContext<PaymentListContext>(
  {} as PaymentListContext
);

export const usePaymentListContext = () => useContext(PaymentListContext);
export default PaymentListContext;
