import React, {useState} from 'react';
import {Button, Icons, Title, Wrapper} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useHomeContext} from 'pages/connections/home/home-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface State {
  referrer?: string;
}

const Header = () => {
  const {state}: AppEnv.Location<State> = useLocation();

  const {pushNotification} = useAppContext();

  const {
    connectionList,
    selectedConnectionIdList,
    isFilterActive,
    setConnectionList,
    setSelectedConnectionIdList,
    setIsFilterActive
  } = useHomeContext();

  const [wasFilterActive, setWasFilterActive] = useState<boolean>();

  const {freeInstance, putInstances} = useRequest();
  const {t} = useTranslation();

  const handleClick = () => {
    if (selectedConnectionIdList && wasFilterActive) {
      setIsFilterActive(true);
      setWasFilterActive(undefined);
    } else if (isFilterActive) {
      setIsFilterActive(false);
      setWasFilterActive(true);
    }

    setSelectedConnectionIdList(prevValue => (prevValue ? undefined : []));
  };

  const selectAll = () =>
    setSelectedConnectionIdList(prevValue =>
      prevValue?.length == connectionList?.length
        ? []
        : connectionList?.map(item => item.id)
    );

  const commonAction = async (
    func: (id: string | number) => Promise<AppEnv.Response<AppEnv.Instance>>,
    key: 'chat_key' | 'id',
    text: string
  ) => {
    if (selectedConnectionIdList) {
      for (let i = 0; i < selectedConnectionIdList?.length; i++) {
        const connection = connectionList?.find(
          item => item.id === selectedConnectionIdList[i]
        );

        if (connection) {
          const {data} = await func(connection[key]);

          if (data) {
            pushNotification(`${t`Connection`} ${connection.id}`, text);
            const index = connectionList?.indexOf(connection);

            if (typeof index == 'number' && index != -1) {
              setConnectionList(prevValue =>
                update(prevValue, {
                  [index]: {$set: data}
                })
              );
            }
          }
        }
      }
    }
  };

  const release = async () =>
    commonAction(
      async (chat_key: string | number) => freeInstance(chat_key as string),
      'chat_key',
      t`The connection has been released`
    );

  const resetPayment = async () =>
    commonAction(
      async (id: string | number) =>
        putInstances(id as number, {
          date_pay: 0,
          date_period: 0,
          tariff_id: 0
        }),
      'id',
      t`The payment has been reset`
    );

  return (
    <>
      {state?.referrer && (
        <Link to={state.referrer}>
          <Button color="white">
            <Icons.AngleLeft />
            {t`Back`}
          </Button>
        </Link>
      )}

      <Title.H1>{t`Connections`}</Title.H1>

      <Wrapper gap={8}>
        <Button
          color={selectedConnectionIdList ? 'active' : 'white'}
          onClick={handleClick}
        >
          <Icons.Cursor />
          {t`Select`}
        </Button>

        {selectedConnectionIdList ? (
          <>
            <Button
              color={
                selectedConnectionIdList.length == connectionList?.length
                  ? 'active'
                  : 'white'
              }
              onClick={selectAll}
            >
              {t`Select all`}
            </Button>

            <Button
              color="white"
              isDisabled={selectedConnectionIdList.length < 1}
              onClick={release}
            >
              {t`Release`}
            </Button>

            <Button
              color="white"
              isDisabled={selectedConnectionIdList.length < 1}
              onClick={resetPayment}
            >
              {t`Reset payment`}
            </Button>
          </>
        ) : (
          <Button
            color={isFilterActive ? 'active' : 'white'}
            onClick={() => setIsFilterActive(prevValue => !prevValue)}
          >
            <Icons.Filter />
            {t`Filter`}
          </Button>
        )}
      </Wrapper>
    </>
  );
};

export default Header;
