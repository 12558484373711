import React, {useState, useEffect} from 'react';
import {Title, Wrapper, Icons} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {IntegrationSearchSelect} from 'components';
import {useStaffContext} from 'pages/staff/staff-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface IntegrationId {
  isUpdate: boolean | undefined;
}

const IntegrationId = ({isUpdate}: IntegrationId) => {
  const {filter} = useStaffContext();
  const [domain, setDomain] = useState<string>();

  const [field, meta, helpers] = useField<number | undefined>('integration_id');
  const {fetchIntegrations} = useRequest();
  const {t} = useTranslation();

  const getIntegration = async () => {
    const {data} = await fetchIntegrations<AppEnv.Integration>({
      id: field.value
    });

    setDomain(data?.domain || '');
  };

  useEffect(() => {
    if (isUpdate) {
      getIntegration();
      return;
    } else if (
      filter.domain &&
      filter.integration_id &&
      (filter.integration_id.length == 1 ||
        typeof filter.integration_id == 'string')
    ) {
      setDomain(filter.domain);

      helpers.setValue(
        parseInt(
          typeof filter.integration_id == 'string'
            ? filter.integration_id
            : filter.integration_id[0]
        )
      );

      return;
    }

    setDomain('');
  }, []);

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Integration`}</Title.H3>

      {typeof domain == 'string' ? (
        <IntegrationSearchSelect
          {...field}
          domain={domain}
          isMaxWidth
          isValid={isValid}
          onChange={value => helpers.setValue(value.id)}
        />
      ) : (
        <Wrapper justifyContent="center">
          <Icons.Spinner className="core-icon_spinner" />
        </Wrapper>
      )}
    </div>
  );
};

export default IntegrationId;
