import React from 'react';
import {Routes, Route} from 'react-router-dom';

import Home from './home/home';
import Integration from './integration/integration';

const Integrations = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path=":id/*" element={<Integration />} />
    </Routes>
  );
};

export default Integrations;
