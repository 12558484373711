import React from 'react';
import {Title, Radio} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Type = () => {
  const [field, , helpers] = useField('type');
  const {t} = useTranslation();

  const options = [
    {
      value: '-1',
      label: t`Any type`
    },
    {
      value: '0',
      label: t`Individual`
    },
    {
      value: '1',
      label: t`Juridical person`
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 12}}>{t`Type`}</Title.H3>

      <Radio
        {...field}
        options={options}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Type;
