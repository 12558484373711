import React from 'react';
import {Wrapper, Pagination, Preloader, EmptyBox, CoreEnv} from 'whatcrm-core';

import {EntriesNumber} from 'components';

import Header from './header/header';
import AutomationList from './automation-list/automation-list';
import {useAutomationsContext} from '../automations-context';

interface Content {
  pagination: CoreEnv.Pagination;
}

const Content = ({pagination}: Content) => {
  const {automationList} = useAutomationsContext();

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <Header />

        {automationList ? (
          automationList.length ? (
            <div>
              <EntriesNumber
                number={pagination.totalCount}
                style={{marginBottom: 8}}
              />

              <AutomationList />

              <Pagination {...pagination} isSticky />
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default Content;
