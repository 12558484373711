import React from 'react';

interface Keyword {
  children: React.ReactNode;
  keyword: string;
}

const Keyword = ({children, keyword}: Keyword) => (
  <span className="keyword">
    {children}
    <span className="keyword__keyword">{keyword}</span>
  </span>
);

export default Keyword;
