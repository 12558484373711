import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

type BlockedList = AppEnv.Blocked[] | undefined;

interface BlockListContext {
  blockedList: BlockedList;
  setBlockedList: AppEnv.SetState<BlockedList>;
  setIsFilterActive: AppEnv.SetState<boolean>;
  getBlockedList: () => Promise<void>;
}

const BlockListContext = React.createContext<BlockListContext>(
  {} as BlockListContext
);

export const useBlockListContext = () => useContext(BlockListContext);
export default BlockListContext;
