import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Name = () => {
  const [field, meta, helpers] = useField<string>('method');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div style={{flexGrow: 1}}>
      <Title.H3 style={{marginBottom: 8}}>{t`Method name`}</Title.H3>

      <Input
        {...field}
        isValid={isValid}
        placeholder={t`Method name`}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Name;
