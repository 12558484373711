import React, {useRef, useState} from 'react';
import {Formik, Form} from 'formik';
import {useOnClickOutside, useScrollClassName, Wrapper} from 'whatcrm-core';
import cn from 'classnames';

import Header from './header/header';
import Footer from './footer/footer';
import SetValues from './set-values/set-values';

interface Sidebar {
  children: React.ReactNode;
  filter?: object;
  initialValues: object;
  isActive: boolean;
  isDisabled: boolean;
  onCancel: () => void;
  onClick: () => void;
  onSubmit: (arg: any) => Promise<void>; // eslint-disable-line
}

const getChangedTouch = (e: React.TouchEvent<HTMLDivElement>) => {
  const {changedTouches} = e;
  const changedTouch = changedTouches[0];

  return changedTouch;
};

const Sidebar = ({
  children,
  filter,
  initialValues,
  isActive,
  isDisabled,
  onCancel,
  onClick,
  onSubmit
}: Sidebar) => {
  const [swipe, setSwipe] = useState<number>();
  const [touchStart, setTouchStart] = useState<React.Touch>();

  const ref = useRef<HTMLDivElement>(null);
  const scrollClassName = useScrollClassName();

  const handleClick = () => {
    if (isActive && window.screen.width <= 425) onClick();
  };

  useOnClickOutside(ref, {
    dependencies: [isActive],
    isEventStart: true,
    onClick: handleClick
  });

  // eslint-disable-next-line
  const handleSubmit = (values: any) => {
    onSubmit(values);
    if (window.screen.width <= 425) onClick();
  };

  const getIsYWithin = (changedTouch: React.Touch) => {
    if (!touchStart) return false;
    return Math.abs(changedTouch?.clientY - touchStart.clientY) < 32;
  };

  const handleTouchEnd: React.TouchEventHandler<HTMLDivElement> = e => {
    if (!touchStart) return;

    const changedTouch = getChangedTouch(e);

    const isXWithin = changedTouch.clientX - touchStart.clientX > 64;
    const isYWithin = getIsYWithin(changedTouch);

    if (isXWithin && isYWithin) onClick();

    setTouchStart(undefined);
    setTimeout(() => setSwipe(undefined), 200);
  };

  const handleTouchMoveCapture: React.TouchEventHandler<HTMLDivElement> = e => {
    if (!touchStart) return;

    const changedTouch = getChangedTouch(e);

    const isXWithin = changedTouch.clientX - touchStart.clientX > 16;
    const isYWithin = getIsYWithin(changedTouch);

    if (!isXWithin || !isYWithin) return setSwipe(undefined);

    const clientXSwipe = changedTouch.clientX - touchStart.clientX;
    if (clientXSwipe > -1) setSwipe(clientXSwipe);
  };

  const handleTouchStart: React.TouchEventHandler<HTMLDivElement> = e => {
    const changedTouch = getChangedTouch(e);
    setTouchStart(changedTouch);
  };

  const style = swipe != undefined ? {right: -swipe} : undefined;

  return (
    <div
      className={cn('sidebar', {sidebar_active: isActive})}
      ref={ref}
      style={style}
      onTouchEndCapture={handleTouchEnd}
      onTouchMoveCapture={handleTouchMoveCapture}
      onTouchStartCapture={handleTouchStart}
    >
      <div className="sidebar__content">
        <Header onClick={onClick} />

        <Formik
          initialValues={initialValues}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <div
              className={cn('sidebar__body', {
                [scrollClassName]: scrollClassName
              })}
            >
              <Wrapper gap={16} isColumn>
                {children}
              </Wrapper>
            </div>

            <Footer
              isDisabled={isDisabled}
              onCancel={onCancel}
              onClick={onClick}
            />

            {filter && <SetValues filter={filter} />}
          </Form>
        </Formik>

        <div className="sidebar__wave sidebar__wave_1" />
        <div className="sidebar__wave sidebar__wave_2" />
        <div className="sidebar__wave sidebar__wave_3" />
      </div>
    </div>
  );
};

export default Sidebar;
