import React, {useEffect, useState} from 'react';
import {Button, EmptyBox, Icons, Preloader, Title, Wrapper} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {BarGraph, Details} from 'components';
import {useRequest} from 'common/hooks';
import {useStatsContext} from 'pages/stats/stats-context';
import * as AppEnv from 'app-env';

interface Refund {
  date_period: string;
  refund_sum: number;
}

interface Stats {
  date_period: string;
  refund_sum: number;
  sum: number;
}

enum Colors {
  Blue = '#56caf0',
  Green = '#83d11f'
}

const Payments = () => {
  const {
    filter,
    isFilterAvailableAndActive,
    setIsFilterActive,
    setIsFilterDisabled
  } = useStatsContext();

  const [color, setColor] = useState<Colors>(Colors.Blue);
  const [currency, setCurrency] = useState<'rub' | 'usd'>('rub');
  const [isLoading, setIsLoading] = useState<boolean>();
  const [stats, setStats] = useState<Stats[]>();

  const {fetchStatistics} = useRequest();
  const {i18n, t} = useTranslation();

  const getStats = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);

    const [{data: payments}, {data: refunds}] = await Promise.all([
      fetchStatistics<AppEnv.PaymentStats[] | object>('payments', filter),
      fetchStatistics<AppEnv.PaymentStats[] | object>('payments', {
        ...filter,
        is_deleted: 1
      })
    ]);

    const paymentList: AppEnv.PaymentStats[] = Object.values(payments || []);

    const refundList: Refund[] = Object.values(refunds || []).map(
      (item: AppEnv.PaymentStats) => ({
        date_period: item.date_period,
        refund_sum: item.sum
      })
    );

    let res = [...paymentList, ...refundList].reduce<Stats[]>((acc, i) => {
      const index = acc.findIndex(j => j.date_period == i.date_period);

      if (index > -1) {
        if ('refund_sum' in i) acc[index].refund_sum = i.refund_sum;
      } else {
        acc.push({
          date_period: i.date_period,
          refund_sum: 'refund_sum' in i ? i.refund_sum : 0,
          sum: 'sum' in i ? i.sum : 0
        });
      }

      return acc;
    }, []);

    res = res.sort((a, b) => {
      const ats = +moment(a.date_period).format('X');
      const bts = +moment(b.date_period).format('X');

      return ats - bts;
    });

    const newCurrency =
      filter.currency == 'rub' || filter.currency == 'usd'
        ? filter.currency
        : 'rub';

    setStats(res);
    setColor(newCurrency == 'rub' ? Colors.Blue : Colors.Green);
    setCurrency(newCurrency);
    setIsLoading(false);
  };

  useEffect(() => {
    getStats();
  }, [filter]);

  useEffect(() => {
    setIsFilterDisabled(!stats);
  }, [stats]);

  useEffect(() => {
    document.title = `${t`Payment statistics`} - Whatcrm Console`;
  }, []);

  const paymentSum =
    stats?.reduce((res, item) => {
      res += item.sum;
      return res;
    }, 0) || 0;

  const refundSum =
    stats?.reduce((res, item) => {
      res += item.refund_sum;
      return res;
    }, 0) || 0;

  const averagePayments = paymentSum && stats ? paymentSum / stats.length : 0;
  const averageRefunds = refundSum && stats ? refundSum / stats.length : 0;

  const averageLabel =
    filter.report == 'month' ? t`Average per month` : t`Average per day`;

  const paymentDetails = [
    {
      icon: <Icons.Sigma />,
      label: t`Sum`,
      value: paymentSum.toLocaleString(i18n.resolvedLanguage, {
        currency,
        style: 'currency'
      })
    },
    {
      icon: <Icons.Wave />,
      label: averageLabel,
      value: averagePayments.toLocaleString(i18n.resolvedLanguage, {
        currency,
        style: 'currency'
      })
    }
  ];

  const refundDetails = [
    {
      icon: <Icons.Sigma />,
      label: t`Sum`,
      value: refundSum.toLocaleString(i18n.resolvedLanguage, {
        currency,
        style: 'currency'
      })
    },
    {
      icon: <Icons.Wave />,
      label: averageLabel,
      value: averageRefunds.toLocaleString(i18n.resolvedLanguage, {
        currency,
        style: 'currency'
      })
    }
  ];

  const keys = {
    sum: {color, label: t`Payments`, value: 0},
    refund_sum: {color: '#ff8242', label: t`Refunds`, value: 0}
  };

  return (
    <>
      <Button
        color={isFilterAvailableAndActive ? 'active' : 'white'}
        onClick={() => setIsFilterActive(prevValue => !prevValue)}
        style={{marginBottom: 16}}
      >
        {t`Filter`}
      </Button>

      {stats ? (
        stats?.length ? (
          <>
            <Wrapper gap={16} style={{marginBottom: 32}}>
              <div>
                <Title.H3 style={{marginBottom: 8}}>{t`Payments`}</Title.H3>
                <Details details={paymentDetails} isAutoWidth />
              </div>

              <div>
                <Title.H3 style={{marginBottom: 8}}>{t`Refunds`}</Title.H3>
                <Details details={refundDetails} isAutoWidth />
              </div>
            </Wrapper>

            <BarGraph currency={currency} data={stats} keys={keys} />
          </>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}

      {isLoading && <Preloader isFullScreen />}
    </>
  );
};

export default Payments;
