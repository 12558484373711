import React from 'react';
import {Button, Line, Popup, SaveButton, Title, Wrapper} from 'whatcrm-core';
import {Form, Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Amount from './amount/amount';

interface Values {
  amount: string;
}

interface PopupProvider {
  isPopupActive: boolean;
  payment: AppEnv.Payment;
  setIsPopupActive: AppEnv.SetState<boolean>;
  updatePayment: (arg: AppEnv.Payment) => void;
}

const PopupProvider = ({
  isPopupActive,
  payment,
  setIsPopupActive,
  updatePayment
}: PopupProvider) => {
  const {pushNotification} = useAppContext();

  const {putBillings} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    const {data} = await putBillings(
      payment.id,
      {is_deleted: 1},
      values.amount
    );

    if (!data) return;

    updatePayment(data);
    setIsPopupActive(false);
    pushNotification(t`The funds have been refunded`);
  };

  const initialValues: Values = {amount: payment.summa};

  const validationSchema = yup.object().shape({
    amount: yup
      .string()
      .required()
      .test(value => {
        const floatValue = parseFloat(value);
        return floatValue > 0 && floatValue <= parseFloat(payment.summa);
      })
  });

  return (
    <Popup isActive={isPopupActive} onClick={() => setIsPopupActive(false)}>
      <Title.H2 style={{marginBottom: 16}}>{t`Refund`}</Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Amount />
          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Done`}</SaveButton>

            <Button color="white" onClick={() => setIsPopupActive(false)}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default PopupProvider;
