import React, {useEffect} from 'react';
import {Wrapper} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import PartnersOne from './partners-one/partners-one';
import NewClients from './new-clients/new-clients';
import Payments from './payments/payments';

const Partners = () => {
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Partner statistics`} - Whatcrm Console`;
  }, []);

  return (
    <Wrapper
      gap={32}
      isColumn
    >
      <PartnersOne />
      <NewClients />
      <Payments />
    </Wrapper>
  );
};

export default Partners;
