import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {getCountryName} from 'whatcrm-core';

import * as AppEnv from 'app-env';
import colors from './colors';

interface CustomTreemap extends AppEnv.CountryStats {
  height: number;
  width: number;
  x: number;
  y: number;
}

const CustomTreemap = ({country, height, width, x, y}: CustomTreemap) => {
  const [isVisible, setIsVisible] = useState(false);

  const {i18n} = useTranslation();
  const ref = useRef<SVGTextElement>(null);

  useEffect(() => {
    const boundingClientRect = ref.current?.getBoundingClientRect();
    if (!boundingClientRect) return;

    setIsVisible(
      boundingClientRect?.height <= height && boundingClientRect.width <= width
    );
  }, [height, width]);

  const name = getCountryName(country, i18n.resolvedLanguage);

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          aspectRatio: '3 / 2',
          fill: colors[country],
          stroke: '#fff',
          strokeWidth: 1
        }}
      />

      <text
        ref={ref}
        x={x + width / 2}
        y={y + height / 2 + 4.5}
        textAnchor="middle"
        fill="#fff"
        fontSize={14}
        style={{lineHeight: '16px', opacity: isVisible ? 1 : 0}}
      >
        {name || country}
      </text>
    </g>
  );
};

export default CustomTreemap;
