import React from 'react';

import {Sidebar} from 'components';
import {useStatsContext} from '../stats-context';
import * as AppEnv from 'app-env';

import Currency from './currency/currency';
import End from './end/end';
import IntegrationTypeId from './integration-type-id/integration-type-id';
import Report from './report/report';
import Start from './start/start';
import TariffId from './tariff-id/tariff-id';
import Version from './version/version';

interface Filter {
  setFilter: AppEnv.SetState<AppEnv.StatsFilter>;
  splat: string | undefined;
}

const Filter = ({setFilter, splat}: Filter) => {
  const {
    filter,
    isFilterAvailableAndActive,
    isFilterDisabled,
    setIsFilterActive
  } = useStatsContext();

  return (
    <Sidebar
      initialValues={filter}
      isActive={isFilterAvailableAndActive}
      isDisabled={isFilterDisabled}
      onClick={() => setIsFilterActive(false)}
      onSubmit={async values => setFilter(values)}
      onCancel={() => setFilter({})}
    >
      <Start />
      <End />
      <IntegrationTypeId />
      {splat == 'payment-geography' && <TariffId />}
      {splat == 'payments' && <Version />}
      {splat == 'payments' && <Currency />}
      {splat == 'payments' && <Report />}
    </Sidebar>
  );
};

export default Filter;
