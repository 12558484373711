import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {usePaymentsContext} from '../payments-context';

interface TariffId {
  isMaxWidth?: boolean;
}

const TariffId = ({isMaxWidth}: TariffId) => {
  const {rateList} = usePaymentsContext();

  const [field, meta, helpers] = useField('tariff_id');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Rate`}</Title.H3>

      <SingleSelect
        {...field}
        options={rateList}
        placeholder={t`Rate`}
        isMaxWidth={isMaxWidth}
        isValid={isValid}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default TariffId;
