import React, {useEffect, useRef, useState} from 'react';
import {Button, EmptyBox, Icons, Preloader} from 'whatcrm-core';
import {ResponsiveContainer, Tooltip, Treemap} from 'recharts';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useRequest} from 'common/hooks';
import {useStatsContext} from 'pages/stats/stats-context';
import * as AppEnv from 'app-env';

import RechartsTooltip from './recharts-tooltip/recharts-tooltip';
import CustomTreemap from './custom-treemap/custom-treemap';

const PaymentGeography = () => {
  const {
    filter,
    isFilterAvailableAndActive,
    setIsFilterActive,
    setIsFilterDisabled
  } = useStatsContext();

  const [height, setHeight] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [stats, setStats] = useState<AppEnv.CountryStats[] | null>();

  const {fetchStatistics} = useRequest();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);

  const getHeight = () => {
    const boundingClientRect = ref.current?.getBoundingClientRect();
    setHeight(window.innerHeight - (boundingClientRect?.top || 0) - 16);
  };

  const getStats = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);

    const {data} = await fetchStatistics<AppEnv.CountryStats[]>(
      'country',
      filter
    );

    setStats(data?.sort((a, b) => b.c - a.c) || null);
    setIsLoading(false);
  };

  useEffect(() => {
    getStats();
  }, [filter]);

  useEffect(() => {
    setIsFilterDisabled(!stats);
  }, [stats]);

  useEffect(() => {
    getHeight();
    document.title = `${t`Geography of payments`} - Whatcrm Console`;
  }, []);

  const handleClick = (country: string) =>
    navigate(`/billing/payments?filter_country=${country}`, {
      state: {referrer: window.location.pathname + window.location.search}
    });

  const total =
    stats?.reduce((acc, item) => {
      acc += item.c;
      return acc;
    }, 0) || 0;

  return (
    <>
      <Button
        color={isFilterAvailableAndActive ? 'active' : 'white'}
        style={{marginBottom: 16}}
        onClick={() => setIsFilterActive(prevValue => !prevValue)}
      >
        <Icons.Filter />
        {t`Filter`}
      </Button>

      <div ref={ref} style={{height}}>
        {typeof stats == 'object' ? (
          stats?.length ? (
            <ResponsiveContainer width="100%" height="100%">
              <Treemap
                animationDuration={1000}
                animationEasing="ease-in-out"
                content={<CustomTreemap {...({} as any)} />} // eslint-disable-line
                data={stats}
                dataKey="c"
                fill="transparent"
                stroke="#fff"
                onClick={e => handleClick(e.country)}
              >
                <Tooltip
                  animationDuration={1000}
                  animationEasing="ease-in-out"
                  content={<RechartsTooltip total={total} />}
                />
              </Treemap>
            </ResponsiveContainer>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </div>

      {isLoading && <Preloader isFullScreen />}
    </>
  );
};

export default PaymentGeography;
