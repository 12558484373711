import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useStaffContext} from 'pages/staff/staff-context';
import Employee from './employee/employee';

const EmployeeList = () => {
  const {employeeList} = useStaffContext();
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell>{t`Name`}</TableCell>
        <TableCell>{t`Email`}</TableCell>
        <TableCell>{t`Phone number`}</TableCell>
        <TableCell>UUID</TableCell>
        <TableCell>{t`Deleted`}</TableCell>
        <TableCell isIcon />
        <TableCell isIcon />
      </TableHead>

      <TableBody>
        {employeeList &&
          employeeList.map(item => (
            <Employee
              key={item.id}
              employee={item}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default EmployeeList;
