import React from 'react';
import {Title, SingleSelect, CoreEnv} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';

const Theme = () => {
  const {theme, setTheme} = useAppContext();
  const {t} = useTranslation();

  const options = [
    {value: 'light', label: t`Light`},
    {value: 'dark', label: t`Dark`},
    {value: 'system', label: t('System', {context: 'theme'})}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Theme`}</Title.H3>

      <SingleSelect
        options={options}
        value={theme}
        onChange={value => setTheme(value as CoreEnv.Theme)}
      />
    </div>
  );
};

export default Theme;
