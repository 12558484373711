import React, {useState} from 'react';
import {Button, Icons, Title, Wrapper} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Editor from '../editor/editor';
import {useAppContext} from 'app-context';
import {useHomeContext} from 'pages/integrations/home/home-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface State {
  referrer?: string;
}

const Header = () => {
  const {pushNotification} = useAppContext();

  const {isFilterActive, setIsFilterActive, getIntegrationList} =
    useHomeContext();

  const [isEditorActive, setIsEditorActive] = useState(false);

  const {state}: AppEnv.Location<State> = useLocation();
  const {postIntegrations} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.IntegrationForm) => {
    const {data} = await postIntegrations({
      ...values,
      date_add: Math.floor(+new Date() / 1000),
      date_trial: Math.floor(Date.now() / 1000) + 86400 * 3,
      lang: 'RU'
    });

    if (data) {
      getIntegrationList();
      setIsEditorActive(false);
      pushNotification(data.domain, t`The integration has been added.`);
    }

    return true;
  };

  return (
    <>
      <div>
        {state?.referrer && (
          <Link to={state.referrer} style={{marginBottom: 16}}>
            <Button color="white">
              <Icons.AngleLeft />
              {t`Back`}
            </Button>
          </Link>
        )}

        <Title.H1 style={{marginBottom: 16}}>{t`Integrations`}</Title.H1>

        <Wrapper gap={8}>
          <Button color="white" onClick={() => setIsEditorActive(true)}>
            <Icons.Plus />
            {t`Add`}
          </Button>

          <Button
            color={isFilterActive ? 'active' : 'white'}
            onClick={() => setIsFilterActive(prevValue => !prevValue)}
          >
            <Icons.Filter />
            {t`Filter`}
          </Button>
        </Wrapper>
      </div>

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default Header;
