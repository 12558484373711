import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Phone = () => {
  const [field, , helpers] = useField<string>('phone');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Phone number`}</Title.H3>

      <Input
        {...field}
        type="tel"
        placeholder={t`Phone number`}
        width="max"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Phone;
