import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

const Lang = () => {
  const {i18n, t} = useTranslation();

  const handleChange = (value: string | undefined) => {
    i18n.changeLanguage(value);
    window.location.reload();
  };

  const options = [
    {label: 'Deutsche', value: 'de'},
    {label: 'English', value: 'en'},
    {label: 'Español', value: 'es'},
    {label: 'Português', value: 'pt'},
    {label: 'Русский', value: 'ru'},
    {label: 'Türkçe', value: 'tr'},
    {label: 'Українська', value: 'uk'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Language`}</Title.H3>

      <SingleSelect
        options={options}
        value={i18n.language}
        placeholder={t`Language`}
        onChange={handleChange}
      />
    </div>
  );
};

export default Lang;
