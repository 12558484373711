import moment from 'moment';
import * as AppEnv from 'app-env';

const filterToRequest = (
  filter: AppEnv.InstanceFilter
): AppEnv.InstanceRequest => ({
  chat_id: filter.chat_id ? {value: filter.chat_id, type: 'like'} : undefined,
  chat_key: filter.chat_key
    ? {value: filter.chat_key, type: 'like'}
    : undefined,
  crm_id: filter.crm_id ? {value: filter.crm_id} : undefined,
  date_pay:
    filter.date_pay && filter.date_pay != '-1'
      ? {value: filter.date_pay}
      : undefined,
  date_update_after: filter.date_update_after
    ? {value: moment.utc(filter.date_update_after).format('X'), type: '>'}
    : undefined,
  date_update_before: filter.date_update_before
    ? {
        value: moment.utc(`${filter.date_update_before} 23:59:59`).format('X'),
        type: '<'
      }
    : undefined,
  hostname: filter.hostname ? {value: filter.hostname} : undefined,
  id: filter.id ? {value: filter.id} : undefined,
  is_deleted:
    filter.is_deleted && filter.is_deleted != '-1'
      ? {value: filter.is_deleted}
      : undefined,
  name: filter.name ? {value: filter.name, type: 'like'} : undefined,
  phone: filter.phone ? {value: filter.phone, type: 'like'} : undefined,
  platform: filter.platform
    ? {value: filter.platform, type: 'like'}
    : undefined,
  status: filter.status ? {value: filter.status} : undefined,
  version:
    filter.version && filter.version != '-1'
      ? {value: filter.version}
      : undefined
});

export default filterToRequest;
