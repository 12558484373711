import React, {useState} from 'react';
import {
  Button,
  Confirmation,
  Icons,
  Mark,
  TableCell,
  TableRow
} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useInvoicesContext} from 'pages/invoices/invoices-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from './editor/editor';

interface Invoice {
  invoice: AppEnv.Invoice;
}

const Invoice = ({invoice}: Invoice) => {
  const {pushNotification} = useAppContext();
  const {invoiceList, setInvoiceList} = useInvoicesContext();

  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [isEditorActive, setIsEditorActive] = useState(false);

  const {i18n, t} = useTranslation();
  const {putInvoices} = useRequest();

  const handleIsDeleted = async () => {
    const {data} = await putInvoices(invoice.id, {
      is_deleted: invoice.is_deleted ? 0 : 1
    });

    if (!data) return true;
    setIsConfirmActive(false);

    const title = data.is_deleted
      ? t`The invoice has been deleted`
      : t`The invoice has been restored`;

    pushNotification(title);

    const index = invoiceList?.findIndex(item => item.id == invoice.id);
    if (index == undefined || index < 0) return true;

    setInvoiceList(prevValue => update(prevValue, {[index]: {$set: data}}));
    return true;
  };

  const handleClick = (res: boolean) => {
    if (!res) return setIsConfirmActive(false);
    return handleIsDeleted();
  };

  const confirmTitle = invoice.is_deleted
    ? t`Are you sure you want to restore this invoice?`
    : t`Are you sure you want to delete this invoice?`;

  const date_add = moment.unix(invoice.date_add).utcOffset(3);
  const summa = invoice.summa ? +invoice.summa : 0;

  return (
    <TableRow>
      <TableCell>
        {date_add.format('L')}
        <br />
        {date_add.format('LT')}
      </TableCell>

      <TableCell>
        {invoice.integration_id > 0 && (
          <Link
            state={{
              referrer: window.location.pathname + window.location.search
            }}
            to={`/integrations?filter_id=${invoice.integration_id}`}
          >
            <Button color="transparent" isLink>
              {invoice.integration_id}
            </Button>
          </Link>
        )}
      </TableCell>

      <TableCell>
        {invoice.instance_id > 0 && (
          <Link
            state={{
              referrer: window.location.pathname + window.location.search
            }}
            to={`/connections?filter_id=${invoice.instance_id}`}
          >
            <Button color="transparent" isLink>
              {invoice.instance_id}
            </Button>
          </Link>
        )}
      </TableCell>

      <TableCell>{invoice.number}</TableCell>

      <TableCell>
        {summa.toLocaleString(i18n.resolvedLanguage, {
          currency: invoice.currency,
          style: 'currency'
        })}
      </TableCell>

      <TableCell>
        <Mark mark={invoice.is_deleted} />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          invoice={invoice}
          isEditorActive={isEditorActive}
          setIsEditorActive={setIsEditorActive}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={invoice.is_deleted ? t`Restore` : t`Delete`}
          onClick={() => setIsConfirmActive(true)}
        >
          {invoice.is_deleted ? <Icons.Return /> : <Icons.Trash />}
        </Button>
      </TableCell>

      <Confirmation
        title={confirmTitle}
        isActive={isConfirmActive}
        onClick={handleClick}
      />
    </TableRow>
  );
};

export default Invoice;
