import React from 'react';
import {Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {IntegrationSearchSelect, IntegrationSearchSelectEnv} from 'components';
import {useIntegrationContext} from '../../../integration-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import instanceTypes from '../instance-types';

const NewIntegration = () => {
  const {crmList} = useWorkspaceContext();
  const {integration} = useIntegrationContext();

  const [newIdField, , newIdHelpers] = useField('new_id');
  const [, newDomainMeta, newDomainHelpers] = useField('new_domain');
  const [, newTypeCrmIdMeta, newTypeCrmIdHelpers] = useField('new_type_crm_id');

  const {t} = useTranslation();

  const handleChange = (value: IntegrationSearchSelectEnv.Integration) => {
    newIdHelpers.setValue(value.id);
    newDomainHelpers.setValue(value.domain);
    newTypeCrmIdHelpers.setValue(value.integration_type_id);
  };

  const crm = crmList.find(item => item.id == integration.integration_type_id);

  const sameTypeCrmList =
    crm?.title != 'LK'
      ? crmList.filter(
          item =>
            !instanceTypes[item.title] ||
            instanceTypes[item.title] == instanceTypes[crm?.title || '']
        )
      : undefined;

  const integrationTypeId = sameTypeCrmList?.map(item => item.id.toString());

  const isValid = !(
    newDomainMeta.touched &&
    newDomainMeta.error &&
    newTypeCrmIdMeta.touched &&
    newTypeCrmIdMeta.error
  );

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`New integration`}</Title.H3>

      <IntegrationSearchSelect
        name="new-integration"
        value={newIdField.value}
        integrationTypeId={integrationTypeId}
        isValid={isValid}
        onChange={handleChange}
      />
    </div>
  );
};

export default NewIntegration;
