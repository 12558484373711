import React, {useEffect} from 'react';
import {Routes, Route} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Home from './home/home';
import Partner from './partner/partner';

const Partners = () => {
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Partners`} - Whatcrm Console`;
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<Home />}
      />

      <Route
        path=":id/*"
        element={<Partner />}
      />
    </Routes>
  );
};

export default Partners;
