import React from 'react';
import {Wrapper, Button, Icons, Title} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const Header = () => {
  const {t} = useTranslation();

  return (
    <Wrapper
      justifyContent="space-between"
      alignItems="center"
    >
      <Link to="/servers">
        <Button color="white">
          <Icons.AngleLeft />
          {t`Back`}
        </Button>
      </Link>

      <Title.H3>{t`Servers`}</Title.H3>

      <div />
    </Wrapper>
  );
};

export default Header;
