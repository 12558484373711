import React, {useState, useEffect} from 'react';
import {
  Wrapper,
  Title,
  Table,
  TableHead,
  TableBody,
  TableCell,
  Pagination,
  Preloader,
  EmptyBox,
  usePagination
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Creator from './creator/creator';
import SystemOption from './system-option/system-option';
import SystemOptionListContext from './system-option-list-context';

const OptionList = () => {
  const [systemOptionList, setSystemOptionList] =
    useState<AppEnv.SystemOption[]>();

  const [isLoading, setIsLoading] = useState<boolean>();

  const {fetchOptions} = useRequest();
  const pagination = usePagination();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`System options`} - Whatcrm Console`;
  }, []);

  const getSystemOptionList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchOptions(pagination.currentPage);

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setSystemOptionList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getSystemOptionList();
  }, [pagination.currentPage]);

  return (
    <SystemOptionListContext.Provider value={{getSystemOptionList}}>
      <div className="content">
        <Wrapper gap={16} isColumn>
          <Title.H1>{t`System options`}</Title.H1>
          <Creator />

          {systemOptionList ? (
            systemOptionList.length > 0 ? (
              <div>
                <EntriesNumber
                  number={pagination.totalCount}
                  style={{marginBottom: 8}}
                />

                <Table style={{marginBottom: 8}}>
                  <TableHead>
                    <TableCell>{t`Parameter`}</TableCell>
                    <TableCell>{t`Value`}</TableCell>
                    <TableCell>{t`Comment`}</TableCell>
                    <TableCell isIcon />
                    <TableCell isIcon />
                  </TableHead>

                  <TableBody>
                    {systemOptionList.map(item => (
                      <SystemOption
                        key={item.id}
                        systemOption={item}
                        systemOptionList={systemOptionList}
                        setSystemOptionList={setSystemOptionList}
                      />
                    ))}
                  </TableBody>
                </Table>

                <Pagination {...pagination} isSticky />
              </div>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )}
        </Wrapper>

        {isLoading && <Preloader isFullScreen />}
      </div>
    </SystemOptionListContext.Provider>
  );
};

export default OptionList;
