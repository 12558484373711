import React, {useState, useEffect} from 'react';
import {
  Wrapper,
  Substrate,
  Title,
  Button,
  Confirmation,
  Preloader,
  EmptyBox
} from 'whatcrm-core';
import {useParams, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import {useAppContext} from 'app-context';
import {useGetRateList, useRequest} from 'common/hooks';
import getConnectionPaymentStatus from '../get-connection-payment-status';
import * as AppEnv from 'app-env';

import Header from './header/header';
import PaymentList from './payment-list/payment-list';
import LinkGenerator from './link-generator/link-generator';
import PaymentsContext from './payments-context';
import ManualPayer from './manual-payer/manual-payer';

const Payments = () => {
  const {pushNotification} = useAppContext();

  const [paymentList, setPaymentList] = useState<AppEnv.Payment[]>();
  const [rateList, setRateList] = useState<{value: number; label: string}[]>();
  const [connection, setConnection] = useState<AppEnv.Instance>();

  const [isManualPayerActive, setIsManualPayerActive] = useState(false);
  const [isLinkGeneratorActive, setIsLinkGeneratorActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {fetchInstanceRebuilt, fetchInstances, fetchPayments, putInstances} =
    useRequest();

  const {id} = useParams();
  const {t} = useTranslation();
  const getRateList = useGetRateList();
  const navigate = useNavigate();

  const getPaymentList = async (instance: AppEnv.Instance | undefined) => {
    if (instance) {
      const {data: payments} = await fetchPayments<AppEnv.Payment[]>({
        integration_id: instance.crm_id
      });

      setPaymentList(payments || []);
      return;
    }

    setPaymentList([]);
  };

  const fetchOptions = async (instance: AppEnv.Instance | undefined) => {
    if (instance?.integration) {
      const {options} = await getRateList(
        instance.integration.integration_type_id
      );

      setRateList(options);
      return;
    }

    setRateList([]);
  };

  const getConnection = async () => {
    if (id) {
      const {data: instance} = await fetchInstances<AppEnv.Instance>({
        id: parseInt(id),
        isExpand: true
      });

      if (!instance) return;

      setConnection(instance);
      getPaymentList(instance);
      fetchOptions(instance);
    }
  };

  useEffect(() => {
    getConnection();
  }, []);

  const handleClick = async (res: boolean) => {
    if (res && connection) {
      const {data} = await putInstances(connection.id, {
        date_pay: null,
        date_period: null,
        tariff_id: null
      });

      if (!data) {
        return true;
      }

      setConnection(data);
      pushNotification(t`Payment has been reset`);
    }

    setIsConfirmationActive(false);
    return true;
  };

  const handleRedistribute = () => {
    if (!connection?.integration) return;

    fetchInstanceRebuilt(
      connection.integration.domain,
      connection.integration.integration_type_id
    );

    pushNotification(t`Payments have been redistributed between connections`);

    navigate(
      `/connections?filter_crm_id=${connection.integration.id}&filter_domain=${connection.integration.domain}`
    );
  };

  const {isPaid = false} = connection
    ? getConnectionPaymentStatus(connection)
    : {};

  const isContextReady = !!(paymentList && rateList && connection);

  return (
    <>
      <div className="content">
        <Wrapper gap={8} isColumn>
          <Header />

          <Substrate>
            <Title.H1 style={{marginBottom: 8}}>
              {`${t`Connection`} ${id}`}
            </Title.H1>

            <Title.H3 style={{marginBottom: 24}}>{t`Payments`}</Title.H3>

            <Wrapper gap={8} style={{marginBottom: 16}}>
              <Button
                color="white"
                isDisabled={!connection?.integration}
                onClick={() => setIsManualPayerActive(true)}
              >
                {t`Pay`}
              </Button>

              <Button
                color="white"
                isDisabled={!connection?.integration}
                onClick={() => setIsLinkGeneratorActive(true)}
              >
                {t`Generate payment link`}
              </Button>

              <Button color="white" onClick={handleRedistribute}>
                {t`Redistribute payments`}
              </Button>

              <Button
                color="white"
                isDisabled={!isPaid}
                onClick={() => setIsConfirmationActive(true)}
              >
                {t`Reset payment`}
              </Button>
            </Wrapper>

            {isContextReady ? (
              <PaymentsContext.Provider value={{rateList, connection}}>
                {paymentList.length ? (
                  <>
                    <EntriesNumber
                      number={paymentList.length}
                      style={{marginBottom: 8}}
                    />

                    <PaymentList paymentList={paymentList} />
                  </>
                ) : (
                  <EmptyBox />
                )}

                <ManualPayer
                  isManualPayerActive={isManualPayerActive}
                  setConnection={setConnection}
                  setIsManualPayerActive={setIsManualPayerActive}
                />

                <LinkGenerator
                  isLinkGeneratorActive={isLinkGeneratorActive}
                  setIsLinkGeneratorActive={setIsLinkGeneratorActive}
                />
              </PaymentsContext.Provider>
            ) : (
              <Preloader />
            )}
          </Substrate>
        </Wrapper>
      </div>

      <Confirmation
        title={t`Are you sure you want to reset payment?`}
        isActive={isConfirmationActive}
        onClick={handleClick}
      />
    </>
  );
};

export default Payments;
