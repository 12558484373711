import React from 'react';
import {Wrapper, Pagination, Preloader, EmptyBox} from 'whatcrm-core';

import {EntriesNumber} from 'components';

import Header from './header/header';
import NotificationList from './notification-list/notification-list';
import {useNotificationsContext} from '../notifications-context';

const Content = () => {
  const {notificationList, pagination} = useNotificationsContext();

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <Header />

        {notificationList ? (
          notificationList.length ? (
            <div>
              <EntriesNumber
                number={pagination.totalCount}
                style={{marginBottom: 8}}
              />

              <NotificationList />

              <Pagination {...pagination} isSticky />
            </div>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Wrapper>
    </div>
  );
};

export default Content;
