import React, {useEffect, useState} from 'react';
import {MultiSelect, SingleSelect, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useGetRateList} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';

interface Rate {
  label: string;
  value: number;
}

const TariffId = () => {
  const {getFusedCrmList} = useWorkspaceContext();
  const [rateList, setRateList] = useState<Rate[]>();

  const [crmField, , crmHelpers] = useField<string | undefined>('crm');
  const [field, , helpers] = useField<string[] | undefined>('tariff_id');
  const {t} = useTranslation();
  const getRateList = useGetRateList();

  const fetchOptions = async () => {
    helpers.setValue(undefined);

    const crmId = crmField.value;
    if (!crmId) return setRateList([]);
    setRateList(undefined);

    const {options} = await getRateList(parseInt(crmId));

    const value = options.reduce((acc, item) => {
      if (!item.label.includes('Enterprise')) acc.push(item.value.toString());
      return acc;
    }, [] as string[]);

    helpers.setValue(value);
    setRateList(options);
  };

  useEffect(() => {
    fetchOptions();
  }, [crmField.value]);

  const fusedCrmList = getFusedCrmList(1);

  const crmOptions = fusedCrmList
    .filter(crm => crm.is_main)
    .map(item => ({
      comment: item.description || undefined,
      label: item.title,
      value: item.id.toString()
    }));

  const rateOptions = rateList?.map(item => ({
    value: String(item.value),
    label: item.label
  }));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Rate`}</Title.H3>

      <SingleSelect
        {...crmField}
        options={crmOptions}
        placeholder="CRM"
        style={{marginBottom: 8}}
        isClearable
        onChange={crmHelpers.setValue}
      />

      <MultiSelect
        {...field}
        options={rateOptions}
        placeholder={t`Rate`}
        isDisabled={!crmField.value}
        isSelectAll
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default TariffId;
