import React from 'react';
import {Grid} from 'whatcrm-core';

import Connection from './connection/connection';
import {useHomeContext} from '../../home-context';

const ConnectionList = () => {
  const {connectionList} = useHomeContext();

  return (
    <div>
      <Grid
        scheme={[1, 1, 3, 3, 4]}
        gap={16}
      >
        {connectionList &&
          connectionList.map(item => (
            <Connection
              key={item.id}
              connection={item}
            />
          ))}
      </Grid>
    </div>
  );
};

export default ConnectionList;
