import React, {useState, useEffect} from 'react';
import {
  Checkbox,
  EmptyBox,
  Pagination,
  Preloader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  usePagination,
  Wrapper
} from 'whatcrm-core';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import Template from '../template/template';
import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import Filter from './filter/filter';
import Header from './header/header';
import Subscription from './subscription/subscription';
import SubscriptionListContext from './subscription-list-context';
import filterToRequest from './filter-to-request';

const SubscriptionList = () => {
  const [subscriptionList, setSubscriptionList] =
    useState<AppEnv.SubscriptionExpand[]>();

  const [isLoading, setIsLoading] = useState<boolean>();

  const [selectedSubscriptionIdList, setSelectedSubscriptionIdList] = useState<
    number[]
  >([]);

  const {fetchSubscriptions} = useRequest();
  const {page = '1'} = useSearchParams();
  const {t} = useTranslation();
  const pagination = usePagination(parseInt(page));

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.SubscriptionFilter> = useFilter();

  const {['*']: paymentSystem = 'cloudpayments'} = useParams<{
    ['*']: AppEnv.PaymentSystem;
  }>();

  usePaginationParam(pagination.currentPage);

  useEffect(() => {
    const titles = {
      cloudpayments: t`CloudPayments subscriptions`,
      stripe: t`Stripe subscriptions`,
      tinkoff: t`Tinkoff subscriptions`
    };

    const title = titles[paymentSystem] || t`Subscriptions`;
    document.title = `${title} - Whatcrm Console`;
  }, []);

  const getSubscriptionList = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);

    const {data, headers} = await fetchSubscriptions<
      AppEnv.SubscriptionExpand[]
    >(paymentSystem, {
      filter: filterToRequest(filter),
      page: pagination.currentPage
    });

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setSubscriptionList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getSubscriptionList();
  }, [filter, pagination.currentPage]);

  const handleChange = () =>
    setSelectedSubscriptionIdList(prevValue =>
      prevValue.length == subscriptionList?.length
        ? []
        : subscriptionList?.map(item => item.id) || []
    );

  return (
    <SubscriptionListContext.Provider
      value={{
        getSubscriptionList,
        paymentSystem,
        selectedSubscriptionIdList,
        setSelectedSubscriptionIdList,
        setSubscriptionList
      }}
    >
      <Template
        filter={
          <Filter
            filter={filter}
            isDisabled={!subscriptionList}
            isFilterActive={isFilterActive}
            setFilter={setFilter}
            setIsFilterActive={setIsFilterActive}
          />
        }
      >
        <Wrapper gap={16} isColumn>
          <Header
            isFilterActive={isFilterActive}
            setIsFilterActive={setIsFilterActive}
          />

          {subscriptionList ? (
            subscriptionList.length ? (
              <div>
                <EntriesNumber
                  number={pagination.totalCount}
                  style={{marginBottom: 8}}
                />

                <Table style={{marginBottom: 8}}>
                  <TableHead>
                    <TableCell>
                      <Checkbox
                        value={
                          selectedSubscriptionIdList.length ==
                          subscriptionList.length
                        }
                        onChange={handleChange}
                      />
                    </TableCell>

                    <TableCell>{`ID`}</TableCell>

                    <TableCell>{`${t`Date`} (UTC+3)`}</TableCell>
                    <TableCell>{t`Domain`}</TableCell>
                    <TableCell>CRM</TableCell>
                    <TableCell>{t`Rate`}</TableCell>
                    <TableCell>{t`Quantity`}</TableCell>
                    <TableCell>{t`Subscription ID`}</TableCell>
                    <TableCell>{t`Status`}</TableCell>
                    <TableCell>{t`Partner`}</TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableHead>

                  <TableBody>
                    {subscriptionList.map(item => (
                      <Subscription key={item.id} subscription={item} />
                    ))}
                  </TableBody>
                </Table>

                <Pagination {...pagination} isSticky />
              </div>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )}
        </Wrapper>
      </Template>

      {isLoading && <Preloader isFullScreen />}
    </SubscriptionListContext.Provider>
  );
};

export default SubscriptionList;
