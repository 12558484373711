import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

type EmployeeList = AppEnv.Employee[] | undefined;

interface StaffContext {
  employeeList: EmployeeList;
  filter: AppEnv.EmployeeFilter;
  isFilterActive: boolean;
  setEmployeeList: AppEnv.SetState<EmployeeList>;
  setIsFilterActive: AppEnv.SetState<boolean>;
  getEmployeeList: () => void;
}

const StaffContext = React.createContext<StaffContext>({} as StaffContext);

export const useStaffContext = () => useContext(StaffContext);
export default StaffContext;
