import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

type IntegrationList = AppEnv.Integration[] | undefined;

interface HomeContext {
  integrationList: IntegrationList;
  isFilterActive: boolean;
  getIntegrationList: () => Promise<void>;
  setIntegrationList: AppEnv.SetState<IntegrationList>;
  setIsFilterActive: AppEnv.SetState<boolean>;
}

const HomeContext = React.createContext<HomeContext>({} as HomeContext);

export const useHomeContext = () => useContext(HomeContext);
export default HomeContext;
