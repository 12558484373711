import React, {useEffect, useState} from 'react';
import {Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Progress from './progress/progress';

const ExportCsv = () => {
  const [isActive, setIsActive] = useState(false);
  const [partnerList, setPartnerList] = useState<AppEnv.Partner[]>();
  const [progress, setProgress] = useState(0);

  const {fetchPartners} = useRequest();
  const {t} = useTranslation();

  const exportCsv = (partners: AppEnv.Partner[]) => {
    const header = [
      'Имя',
      'Электронная почта',
      'Номер телефона',
      'Тип',
      'Код «Телфин»',
      'Количество интеграций',
      'Количество клиентов',
      'Комментарий',
      'Дата добавления (UTC±0)'
    ].join(',');

    const body = partners
      .map(partner => {
        const date_add = moment.unix(partner.date_add).utc();

        return [
          partner.fullname,
          partner.email,
          partner.phone,
          partner.type ? 'Юридическое лицо' : 'Физическое лицо',
          partner.telphin_code,
          partner.integrations_count,
          partner.customers_count,
          partner.comment,
          `${date_add.format('L')} ${date_add.format('LT')}`
        ].join(',');
      })
      .join('\n');

    const csv = [header, body].join('\n');
    const blob = new Blob([csv], {type: 'text/csv; charset=utf-8;'});
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.download = 'partners.csv';
    link.href = url;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (!partnerList) return;

    const partners = [...partnerList];
    setPartnerList(undefined);

    if (!isActive) return;
    setIsActive(false);

    exportCsv(partners);
  }, [partnerList]);

  const requestPartners = async (
    partnes: AppEnv.Partner[] = [],
    page = 1
  ): Promise<AppEnv.Partner[]> => {
    const {data, headers} = await fetchPartners<AppEnv.Partner[]>({
      filter: {is_deleted: {value: '0'}},
      page
    });

    const totalCount = headers?.['x-pagination-total-count'];
    if (!data || !totalCount) return partnes;

    const res = [...partnes, ...data];
    setProgress(res.length / parseInt(totalCount));

    if (res.length < parseInt(totalCount))
      return requestPartners(res, page + 1);

    return res;
  };

  const getPartnerList = async () => {
    const res = await requestPartners();
    setPartnerList(res);
  };

  const handleClick = () => {
    setIsActive(true);
    getPartnerList();
  };

  return (
    <>
      <Button color="white" onClick={handleClick}>
        <Icons.Sticker />
        {t`Export to CSV`}
      </Button>

      <Progress
        progress={progress}
        isActive={isActive}
        setIsActive={setIsActive}
      />
    </>
  );
};

export default ExportCsv;
