import React, {useState, useEffect} from 'react';
import {
  getFromLocalStorage,
  NotificationList,
  putInLocalStorage,
  useCountries,
  useTheme,
  useTtag
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import moment from 'moment';
import update from 'immutability-helper';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/tr';
import 'moment/locale/uk';

import Router from './router/router';
import AppContext from './app-context';
import {Roles} from 'app-enums';
import * as AppEnv from './app-env';

const App = () => {
  const [client, setClient] = useState<AppEnv.Client | null>();

  const [preferences, setPreferences] = useState<AppEnv.Preferences>({
    utcOffset: 0
  });

  const [notificationList, setNotificationList] = useState<
    AppEnv.PushNotification[]
  >([]);

  const [timezone, setTimezone] = useState<AppEnv.Timezone>('UTC±0');
  const [isNavActive, setIsNavActive] = useState(false);

  const {i18n} = useTranslation();
  const {theme, setTheme} = useTheme();

  if (
    !['de', 'en', 'es', 'pt', 'ru', 'tr', 'uk'].includes(
      i18n.language.slice(0, 2)
    )
  ) {
    i18n.changeLanguage('en');
  }

  useCountries(i18n.resolvedLanguage);
  useTtag(i18n.language);

  useEffect(() => {
    const res = getFromLocalStorage('client');
    const temp = res ? JSON.parse(res) : null;
    setClient(temp ? {...temp, access_level: Roles[temp.role]} : null);

    const storagePreferences = getFromLocalStorage('preferences');

    if (storagePreferences) {
      setPreferences(
        Object.assign(preferences, JSON.parse(storagePreferences))
      );
    }

    if (navigator && 'serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js');
    }
  }, []);

  useEffect(() => {
    setTimezone(preferences.utcOffset ? 'UTC+3' : 'UTC±0');

    if (preferences) {
      putInLocalStorage('preferences', JSON.stringify(preferences));
    }
  }, [preferences]);

  const pushNotification = (title: string, text?: string) =>
    setNotificationList(prevValue =>
      update(prevValue, {$push: [{text, title}]})
    );

  moment.locale(i18n.language);

  return (
    <div
      className={cn('app', {
        ['app_alt-table-view']: preferences?.isAltTableView
      })}
    >
      {client !== undefined && (
        <AppContext.Provider
          value={{
            client,
            preferences,
            theme,
            timezone,
            isNavActive,
            setClient,
            setPreferences,
            setTheme,
            pushNotification,
            setIsNavActive
          }}
        >
          <Router />

          <NotificationList
            notificationList={notificationList}
            setNotificationList={setNotificationList}
          />
        </AppContext.Provider>
      )}
    </div>
  );
};

export default App;
