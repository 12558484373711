import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

interface Option {
  label: string;
  value: AppEnv.InstanceStatus;
}

const Status = () => {
  const [field, , helpers] = useField('status');
  const {t} = useTranslation();

  const options: Option[] = [
    {label: 'CONNECTED', value: 'CONNECTED'},
    {label: 'LOADING_SCREEN', value: 'LOADING_SCREEN'},
    {label: 'LOGOUT', value: 'LOGOUT'},
    {label: 'PARING', value: 'PARING'},
    {label: 'PENDING', value: 'PENDING'},
    {label: 'QR_CODE', value: 'QR_CODE'},
    {label: 'READY', value: 'READY'},
    {label: 'REBOOT', value: 'REBOOT'},
    {value: '', label: t`No status`}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Status`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Status`}
        isClearable
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Status;
