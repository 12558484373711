import React from 'react';
import {Title, Chips} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const IsPartner = () => {
  const [field, , helpers] = useField('is_partner');
  const {t} = useTranslation();

  const options = [
    {
      value: '-1',
      label: t`All`
    },
    {
      value: '1',
      label: t`Yes`
    },
    {
      value: '0',
      label: t`No`
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 12}}>{t`Partner`}</Title.H3>

      <Chips {...field} options={options} onChange={helpers.setValue} />
    </div>
  );
};

export default IsPartner;
