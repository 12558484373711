import React from 'react';
import {
  Popup as CorePopup,
  Title,
  Wrapper,
  Line,
  Button,
  SaveButton
} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import * as AppEnv from 'app-env';

import Code from './code/code';
import Discount from './discount/discount';
import DateStart from './date-start/date-start';
import DateEnd from './date-end/date-end';
import IntegrationId from './integration-id/integration-id';

interface Popup {
  initialValues?: AppEnv.PromoCodeForm;
  isActive: boolean;
  isUpdate?: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.PromoCodeForm>;
}

const Editor = ({
  initialValues = {
    code: undefined,
    date_end: undefined,
    date_start: undefined,
    discount: undefined,
    integration_id: undefined
  },
  isActive,
  isUpdate,
  onClick,
  onSubmit
}: Popup) => {
  const {t} = useTranslation();

  const validationSchema = yup.object().shape({
    code: yup.string().trim().required()
  });

  return (
    <CorePopup
      isActive={isActive}
      onClick={onClick}
    >
      <Title.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Update the promo code` : t`Create a promo code`}
      </Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        <Form>
          <Wrapper
            gap={16}
            isColumn
          >
            <Code />
            <Discount />
            <DateStart />
            <DateEnd />
            <IntegrationId />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button
              color="white"
              onClick={onClick}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </CorePopup>
  );
};

export default Editor;
