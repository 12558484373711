import moment from 'moment';
import * as AppEnv from 'app-env';

const filterToRequest = (
  filter: AppEnv.TelphinFilter
): AppEnv.TelphinRequest => ({
  action_type: filter.action_type ? {value: filter.action_type} : undefined,
  bonus_id: filter.bonus_id ? {value: filter.bonus_id} : undefined,
  bonuses: filter.bonuses ? {value: filter.bonuses} : undefined,
  code_client: filter.code_client
    ? {value: filter.code_client, type: 'like'}
    : undefined,
  data_event_after: filter.data_event_after
    ? {
        value: moment(filter.data_event_after).format('YYYY-MM-DD HH:mm:ss'),
        type: '>'
      }
    : undefined,
  data_event_before: filter.data_event_before
    ? {
        value: moment(`${filter.data_event_before} 23:59:59`).format(
          'YYYY-MM-DD HH:mm:ss'
        ),
        type: '<'
      }
    : undefined,
  line: filter.line ? {value: filter.line, type: 'like'} : undefined
});

export default filterToRequest;
