import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAutomationsContext} from 'pages/automations/automations-context';
import Automation from './automation/automation';

const AutomationList = () => {
  const {automationList} = useAutomationsContext();
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell>{t`Question`}</TableCell>
        <TableCell>{t`Answer`}</TableCell>
        <TableCell>{t`File`}</TableCell>
        <TableCell>{t`Delay`}</TableCell>
        <TableCell>{t`Integration`}</TableCell>
        <TableCell isIcon />
        <TableCell isIcon />
        <TableCell isIcon />
      </TableHead>

      <TableBody>
        {automationList &&
          automationList.map(item => (
            <Automation
              key={item.id}
              automation={item}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default AutomationList;
