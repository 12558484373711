import React from 'react';
import Row from '../components/row/row';

import Key from './key/key';
import Value from './value/value';

export interface ParsedCode {
  [index: string]: number | string | null;
}

interface Group {
  keyName: string;
  parsedCode: ParsedCode;
  parsedOldCode: ParsedCode | null;
  value: number | string | null;
}

const Group = ({keyName, parsedCode, parsedOldCode, value}: Group) => {
  const isDiff =
    !!parsedOldCode && parsedCode[keyName] != parsedOldCode?.[keyName];

  return (
    <div className="code__group">
      {isDiff && (
        <Row type="-" isTabbed>
          <Key keyValue={keyName} />
          <Value value={parsedOldCode?.[keyName]} />
        </Row>
      )}

      <Row type={isDiff ? '+' : undefined} isTabbed>
        <Key keyValue={keyName} />
        <Value value={value} />
      </Row>
    </div>
  );
};

export default Group;
