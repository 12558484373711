import React, {useState} from 'react';
import {
  Button,
  Confirmation,
  formatPhoneNumber,
  Icons,
  Mark,
  TableCell,
  TableRow
} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {Keyword} from 'components';
import {useCrmRequest} from 'common/hooks';
import {useAppContext} from 'app-context';
import {useIntegrationContext} from 'pages/integrations/integration/integration-context';
import * as AppEnv from 'app-env';

interface Source {
  isDeleteShown: boolean;
  setSourceList: AppEnv.SetState<AppEnv.Source[] | undefined>;
  source: AppEnv.Source;
  sourceList: AppEnv.Source[];
}

const Source = ({isDeleteShown, setSourceList, source, sourceList}: Source) => {
  const {pushNotification} = useAppContext();
  const {crm, integration} = useIntegrationContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {deleteSources} = useCrmRequest();
  const {t} = useTranslation();

  const deleteSource = async () => {
    if (!crm) return true;

    const res = await deleteSources(crm.title, integration.domain, source.id);
    if (!res) return true;

    setIsConfirmationActive(false);
    pushNotification(source.name, t`The source has been deleted.`);

    await new Promise(resolve => setTimeout(resolve, 200));

    const index = sourceList.findIndex(item => item.id == source.id);
    setSourceList(prevValue => update(prevValue, {$splice: [[index, 1]]}));

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return deleteSource();
    setIsConfirmationActive(false);
  };

  const phoneNumber = parseInt(source.name)
    ? formatPhoneNumber(source.name)
    : undefined;

  const service = source.services[0]?.pages[0].id;

  const servicePhoneNumber =
    service && parseInt(service) ? formatPhoneNumber(service) : undefined;

  const isServicePhoneNumberShown = !!(service && servicePhoneNumber);

  return (
    <TableRow isActive={isConfirmationActive}>
      <TableCell>
        <Link
          state={{referrer: window.location.pathname + window.location.search}}
          to={`/connections?filter_chat_key=${source.external_id}`}
        >
          <Button color="transparent" isLink>
            {phoneNumber ? (
              <Keyword keyword={source.name}>{phoneNumber}</Keyword>
            ) : (
              source.name
            )}
          </Button>
        </Link>
      </TableCell>

      <TableCell>
        {isServicePhoneNumberShown ? (
          <Keyword keyword={service}>{servicePhoneNumber}</Keyword>
        ) : (
          service
        )}
      </TableCell>

      <TableCell>{source.external_id}</TableCell>

      <TableCell>
        <Mark mark={source.default} />
      </TableCell>

      {isDeleteShown && (
        <TableCell isIcon>
          <Button
            color="transparent"
            tip={t`Delete`}
            onClick={() => setIsConfirmationActive(true)}
          >
            <Icons.Trash />
          </Button>

          <Confirmation
            title={t`Are you sure you want to delete this source?`}
            isActive={isConfirmationActive}
            onClick={handleClick}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default Source;
