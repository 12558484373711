import React, {useState, useEffect} from 'react';
import {Popup, Title, Line, Wrapper, Button} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useConnectionContext} from '../../connection-context';
import * as AppEnv from 'app-env';

type ScreenshotImage = string | undefined;

interface Screenshot {
  screenshot: ScreenshotImage;
  isScreenshoting: boolean;
  setScreenshot: AppEnv.SetState<ScreenshotImage>;
  getScreenshot: () => void;
}

const Screenshot = ({
  screenshot,
  isScreenshoting,
  setScreenshot,
  getScreenshot
}: Screenshot) => {
  const {connection} = useConnectionContext();
  const [isActive, setIsActive] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    if (screenshot) {
      setIsActive(true);
    }
  }, [screenshot]);

  const handleClick = () => {
    setIsActive(false);
    setTimeout(() => setScreenshot(undefined), 200);
  };

  return (
    <Popup
      width={790}
      isActive={isActive}
      onClick={handleClick}
    >
      <Title.H2 style={{marginBottom: 16}}>
        {`${t`Connection`} ${connection.id}`}
      </Title.H2>

      {screenshot && (
        <div
          className="screenshot"
          dangerouslySetInnerHTML={{__html: screenshot}}
        />
      )}

      <Line />

      <Wrapper gap={8}>
        <Button
          isDisabled={isScreenshoting}
          onClick={getScreenshot}
        >
          {t`Refresh`}
        </Button>

        <Button
          color="white"
          onClick={handleClick}
        >
          {t`Close`}
        </Button>
      </Wrapper>
    </Popup>
  );
};

export default Screenshot;
