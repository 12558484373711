import React, {useState, useEffect} from 'react';
import {Title, MultiSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useIntegrationContext} from '../../../integration-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';
import instanceTypes from '../instance-types';
import * as AppEnv from 'app-env';

interface Option {
  value: number;
  label: string;
}

const Connections = () => {
  const {crmList} = useWorkspaceContext();
  const {integration} = useIntegrationContext();

  const [instanceList, setInstanceList] = useState<AppEnv.Instance[]>();
  const [options, setOptions] = useState<Option[]>();

  const [allField] = useField<boolean>('all');
  const [field, meta, helpers] = useField<number[]>('connections');
  const [newTypeCrmIdField] = useField<number | undefined>('new_type_crm_id');
  const {fetchInstances} = useRequest();
  const {t} = useTranslation();

  const getConnectionList = async () => {
    const connectionList = [];
    let page = 1;

    while (connectionList.length < integration.instances_count) {
      const {headers, data} = await fetchInstances<AppEnv.Instance[]>({
        filter: {crm_id: {value: String(integration.id)}},
        page
      });

      if (data) connectionList.push(...data);

      if (
        headers?.['x-pagination-current-page'] ==
        headers?.['x-pagination-page-count']
      )
        break;

      page++;
    }

    setInstanceList(connectionList);
  };

  const crm = crmList.find(item => item.id == newTypeCrmIdField.value);

  const updateOptions = () => {
    if (!instanceList) return;

    const fusedInstanceList =
      !crm || crm.title == 'LK'
        ? instanceList
        : instanceList.filter(item => item.version == instanceTypes[crm.title]);

    setOptions(
      fusedInstanceList.map(item => ({
        value: item.id,
        label: `${t`Connection`} ${item.id}`
      }))
    );
  };

  useEffect(() => {
    updateOptions();
  }, [instanceList, newTypeCrmIdField.value]);

  useEffect(() => {
    if (crm?.title != 'LK') helpers.setValue([]);
  }, [newTypeCrmIdField.value]);

  useEffect(() => {
    getConnectionList();
  }, []);

  useEffect(() => {
    if (allField.value) helpers.setValue([]);
  }, [allField.value]);

  const isDisabled = allField.value || !instanceList;
  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Connections`}</Title.H3>

      <MultiSelect
        {...field}
        options={options || []}
        placeholder={instanceList ? t`Connections` : t`Loading...`}
        isDisabled={isDisabled}
        isValid={isValid}
        onChange={value => helpers.setValue(value || [])}
      />
    </div>
  );
};

export default Connections;
