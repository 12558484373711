import React from 'react';
import {Table, TableBody, TableCell, TableHead} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';
import Subscription from './subscription/subscription';

interface SubscriptionList {
  subscriptionList: AppEnv.Telphin[];
}

const SubscriptionList = ({subscriptionList}: SubscriptionList) => {
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell>{`${t`Date`} (UTC+3)`}</TableCell>
        <TableCell>{t`Type`}</TableCell>
        <TableCell>{t`Client code`}</TableCell>
        <TableCell>{t`Rate plan ID`}</TableCell>
        <TableCell>{t`Rate plan`}</TableCell>
        <TableCell>{t`Line`}</TableCell>
        <TableCell>{t`Quantity`}</TableCell>
        <TableCell isIcon />
        <TableCell isIcon />
      </TableHead>

      <TableBody>
        {subscriptionList.map(item => (
          <Subscription key={item.id} subscription={item} />
        ))}
      </TableBody>
    </Table>
  );
};

export default SubscriptionList;
