import React, {useState} from 'react';
import {Button, Icons} from 'whatcrm-core';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Editor from '../../editor/editor';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const Creator = () => {
  const {pushNotification} = useAppContext();

  const [isEditorActive, setIsEditorActive] = useState(false);

  const {postPartners} = useRequest();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleSubmit = async (values: AppEnv.PartnerForm) => {
    const {data} = await postPartners({
      ...values,
      date_add: Math.floor(+new Date() / 1000),
      date_update: Math.floor(+new Date() / 1000)
    });

    if (!data) return true;

    navigate(`/partners/${data.id}/integrations`, {
      state: {trigger: 'creator'}
    });

    pushNotification(data.fullname, t`A partner has been created`);

    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsEditorActive(true)}>
        <Icons.Plus />
        {t`Create`}
      </Button>

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default Creator;
