import React from 'react';
import {Popup, Title, Line, Wrapper, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';
import * as yup from 'yup';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';

import Id from './id/id';
import {usePartnerContext} from '../../partner-context';

interface Editor {
  isActive: boolean;
  onClick: () => void;
}

interface Values {
  id?: number;
}

const Editor = ({isActive, onClick}: Editor) => {
  const {pushNotification} = useAppContext();
  const {partner, setPartner} = usePartnerContext();

  const {putIntegrations} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: {id?: number}) => {
    if (values.id) {
      const {data} = await putIntegrations(values.id, {is_partner: partner.id});

      if (data) {
        pushNotification(data.domain, t`The integration has been attached`);

        let index = partner.integrations?.findIndex(item => item.id > data.id);
        index = index != -1 ? index : partner.integrations?.length;

        setPartner(prevValue => {
          if (typeof index == 'number' && index != -1) {
            return update(prevValue, {
              integrations: {$splice: [[index, 0, data]]}
            });
          }
        });

        onClick();
      }
    }

    return true;
  };

  const initialValues: Values = {id: undefined};

  const validationSchema = yup.object().shape({
    id: yup.number().required()
  });

  return (
    <Popup isActive={isActive} onClick={onClick}>
      <Title.H2 style={{marginBottom: 16}}>{t`Add an integration`}</Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        <Form>
          <Id />
          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={onClick}>
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
