import React, {useState, useEffect} from 'react';
import {
  Popup,
  Title,
  Wrapper,
  Line,
  Button,
  useCopyToClipboard
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {Code} from 'components';
import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface Res {
  data?: string;
  setData: AppEnv.SetState<string | undefined>;
}

const Res = ({data, setData}: Res) => {
  const {pushNotification} = useAppContext();
  const [isActive, setIsActive] = useState(false);

  const copyToClipboard = useCopyToClipboard();
  const {t} = useTranslation();

  useEffect(() => {
    if (data) {
      setIsActive(true);
    }
  }, [data]);

  const handleClick = () => {
    setIsActive(false);
    setTimeout(() => setData(undefined), 200);
  };

  const handleCopy = async () => {
    const res = await copyToClipboard(JSON.stringify(data));
    pushNotification(res.message);
  };

  return (
    <Popup width={616} isActive={isActive} onClick={handleClick}>
      <Title.H2 style={{marginBottom: 16}}>{t`Result`}</Title.H2>
      {data && <Code code={data} />}
      <Line />

      <Wrapper gap={8}>
        <Button onClick={handleCopy}>{t`Copy`}</Button>

        <Button color="white" onClick={handleClick}>
          {t`Close`}
        </Button>
      </Wrapper>
    </Popup>
  );
};

export default Res;
