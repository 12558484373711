import React, {useContext} from 'react';
import {CoreEnv} from 'whatcrm-core';

import * as AppEnv from 'app-env';

type TNotificationList = AppEnv.Notification[] | undefined;

interface NotificationsContext {
  notificationList: TNotificationList;
  pagination: CoreEnv.Pagination;
  isFilterActive: boolean;
  setNotificationList: AppEnv.SetState<TNotificationList>;
  setIsFilterActive: AppEnv.SetState<boolean>;
  getNotificationList: (arg?: number) => Promise<void>;
}

const NotificationsContext = React.createContext<NotificationsContext>(
  {} as NotificationsContext
);

export const useNotificationsContext = () => useContext(NotificationsContext);
export default NotificationsContext;
