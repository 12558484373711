import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

import Client from './client/client';

interface ClientList {
  clientList: AppEnv.Integration[];
}

const ClientList = ({clientList}: ClientList) => {
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell>{t`Date added`}</TableCell>
        <TableCell>{t`Domain`}</TableCell>
        <TableCell>CRM</TableCell>
        <TableCell>{t`Connections`}</TableCell>
        <TableCell isIcon />
      </TableHead>

      <TableBody>
        {clientList.map(item => (
          <Client
            key={item.id}
            client={item}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ClientList;
