import React from 'react';

interface Row {
  children: React.ReactNode;
  type?: '+' | '-';
  isTabbed?: boolean;
}

const Row = ({children, type, isTabbed}: Row) => {
  const color =
    type == '+'
      ? 'rgba(131, 209, 32, .1)'
      : type == '-'
      ? 'rgba(255, 130, 66, .1)'
      : undefined;

  return (
    <span className="code__row" style={color ? {background: color} : undefined}>
      {isTabbed && '  '}
      {type && <span className="code__mark">{type}</span>}
      {children}
    </span>
  );
};

export default Row;
