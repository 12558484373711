import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useHomeContext} from '../../home-context';
import {Roles} from 'app-enums';

import Integration from './integration/integration';

const IntegrationList = () => {
  const {client, timezone} = useAppContext();
  const {integrationList, setIntegrationList} = useHomeContext();

  const {t} = useTranslation();

  const isDeleteShown =
    !!client?.access_level && client.access_level >= Roles.ADMIN;

  return (
    <Table style={{marginBottom: 8}}>
      <TableHead>
        <TableCell>{`${t`Installation date`} (${timezone})`}</TableCell>
        <TableCell>{t`Domain`}</TableCell>
        <TableCell>{t`Language`}</TableCell>
        <TableCell>CRM</TableCell>
        <TableCell>{t`Widget code`}</TableCell>
        <TableCell>{t`Telphin code`}</TableCell>
        <TableCell>{t`Trial`}</TableCell>
        <TableCell>{t`Entities`}</TableCell>
        <TableCell>{t`Premium`}</TableCell>
        <TableCell>{t`Partner`}</TableCell>
        <TableCell>{t`Feedback`}</TableCell>
        <TableCell>{t`Deleted`}</TableCell>
        <TableCell />
        {isDeleteShown && <TableCell />}
        <TableCell />
      </TableHead>

      <TableBody>
        {integrationList &&
          integrationList.map(item => (
            <Integration
              integration={item}
              integrationList={integrationList}
              key={item.id}
              isDeleteShown={isDeleteShown}
              setIntegrationList={setIntegrationList}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default IntegrationList;
