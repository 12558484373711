import {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {
  getFromLocalStorage,
  putInLocalStorage,
  removeFromLocalStorage
} from 'whatcrm-core';

import {getSearchParams} from 'common/actions';

interface Filter {
  [index: string]: string | string[] | undefined;
}

const useFilter = () => {
  const location = useLocation();
  const {filterParams} = getSearchParams(location.search);

  const [filter, setFilter] = useState<Filter>(filterParams);

  const getInitialIsFilterActive = () => {
    if (window.screen.width <= 425) return false;

    const isFilter = Object.keys(filter).length > 0;
    const isFilterActiveLocalStorage = !!getFromLocalStorage('isFilterActive');

    return isFilter || isFilterActiveLocalStorage;
  };

  const [isFilterActive, setIsFilterActive] = useState(
    getInitialIsFilterActive()
  );

  useEffect(() => {
    if (filter) {
      const {searchParams} = getSearchParams(window.location.search);

      const params = Object.fromEntries(
        Object.entries(filter)
          .filter(([, value]) => value)
          .map(([key, value]) => [`filter_${key}`, value])
      );

      const urlSearchParams = new URLSearchParams({
        ...searchParams,
        ...params
      } as string[][]).toString();

      history.pushState(
        '',
        '',
        urlSearchParams ? `?${urlSearchParams}` : window.location.pathname
      );
    }
  }, [filter]);

  useEffect(() => {
    if (isFilterActive) {
      putInLocalStorage('isFilterActive', 'true');
      return;
    }

    removeFromLocalStorage('isFilterActive');
  }, [isFilterActive]);

  return {filter, isFilterActive, setFilter, setIsFilterActive};
};

export default useFilter;
