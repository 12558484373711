import React from 'react';
import {
  Wrapper,
  Title,
  Text,
  Button,
  Icons,
  useCopyToClipboard
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../connection-context';

const SystemInfo = () => {
  const {pushNotification} = useAppContext();
  const {connection} = useConnectionContext();

  const copyToClipboard = useCopyToClipboard();
  const {t} = useTranslation();

  const handleClick = async (text: string | number) => {
    const res = await copyToClipboard(String(text));
    pushNotification(res.message);
  };

  return (
    <Wrapper gap={8} isColumn>
      <Title.H3>{t`System`}</Title.H3>

      {connection.status && (
        <Text isParagraph>
          {t`Status`}: {connection.status}
        </Text>
      )}

      <Wrapper alignItems="center" noWrap>
        <Text isParagraph>ID: {connection.id}</Text>

        <Button
          color="transparent"
          height={16}
          tip={t`Copy`}
          onClick={() => handleClick(connection.id)}
        >
          <Icons.Copy />
        </Button>
      </Wrapper>

      <Wrapper alignItems="center" noWrap>
        <Text isParagraph>IPv6: {connection.chat_id}</Text>

        <Button
          color="transparent"
          height={16}
          tip={t`Copy`}
          onClick={() => handleClick(connection.chat_id)}
        >
          <Icons.Copy />
        </Button>
      </Wrapper>

      <Wrapper alignItems="center" noWrap>
        <Text whiteSpace="nowrap" isParagraph>
          {t`Key`}: {connection.chat_key}
        </Text>

        <Button
          color="transparent"
          height={16}
          tip={t`Copy`}
          onClick={() => handleClick(connection.chat_key)}
        >
          <Icons.Copy />
        </Button>
      </Wrapper>

      <Wrapper alignItems="center" noWrap>
        <Text whiteSpace="nowrap" isParagraph>
          {t`Hostname`}: {connection.hostname}
        </Text>

        <Button
          color="transparent"
          height={16}
          tip={t`Copy`}
          onClick={() => handleClick(connection.hostname)}
        >
          <Icons.Copy />
        </Button>
      </Wrapper>

      {connection.label && (
        <Text isParagraph>
          {t`Label`}: {connection.label}
        </Text>
      )}
    </Wrapper>
  );
};

export default SystemInfo;
