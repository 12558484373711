import React from 'react';
import {EmptyBox, Nav, NavBar, Title} from 'whatcrm-core';
import {Navigate, Route, Routes, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {Roles} from 'app-enums';

import Connections from './connections/connections';
import Integrations from './integrations/integrations';
import Partners from './partners/partners';
import PaymentGeography from './payment-geography/payment-geography';
import Payments from './payments/payments';

const Content = () => {
  const {client} = useAppContext();

  const {['*']: splat} = useParams();
  const {t} = useTranslation();

  const options = [];

  if (client?.access_level && client.access_level >= Roles.MODER)
    options.push(
      {label: t`Connections`, value: 'connections'},
      {label: t`Integrations`, value: 'integrations'},
      {label: t`Payments`, value: 'payments'},
      {label: t`Geography of payments`, value: 'payment-geography'}
    );

  options.push({value: 'partners', label: t`Partners`});

  return (
    <div className="content">
      <Title.H1 style={{marginBottom: 8}}>{t`Statistics`}</Title.H1>

      <Nav options={options} value={splat || ''}>
        <NavBar style={{marginBottom: 16}} isLink />

        <Routes>
          <Route
            path="/"
            element={<Navigate to={`/statistics/${options[0].value}`} />}
          />

          <Route path="connections" element={<Connections />} />
          <Route path="integrations" element={<Integrations />} />
          <Route path="partners" element={<Partners />} />
          <Route path="payment-geography" element={<PaymentGeography />} />
          <Route path="payments" element={<Payments />} />
          <Route path="*" element={<EmptyBox />} />
        </Routes>
      </Nav>
    </div>
  );
};

export default Content;
