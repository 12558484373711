import React from 'react';
import {
  Popup,
  Title as CoreTitle,
  Wrapper,
  Line,
  Button,
  SaveButton
} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import * as AppEnv from 'app-env';

import Title from './title/title';
import ShortDescription from './short-description/short-description';
import Description from './description/description';
import DateStart from './date-start/date-start';
import DateEnd from './date-end/date-end';
import IntegrationTypeId from './integration-type-id/integration-type-id';
import Lang from './lang/lang';
import AlertType from './alert-type/alert-type';

interface Editor {
  initialValues?: AppEnv.NotificationForm;
  isActive: boolean;
  isUpdate?: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.NotificationForm>;
}

const Editor = ({
  initialValues = {
    alert_type: 0,
    date_end: undefined,
    date_start: undefined,
    description: undefined,
    integration_type_id: undefined,
    lang: undefined,
    short_description: undefined,
    title: undefined
  },
  isActive,
  isUpdate,
  onClick,
  onSubmit
}: Editor) => {
  const {t} = useTranslation();

  const validationSchema = yup.object().shape({
    description: yup.string().trim().required(),
    short_description: yup.string().trim().required(),
    title: yup.string().trim().required()
  });

  return (
    <Popup
      isActive={isActive}
      onClick={onClick}
    >
      <CoreTitle.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Update the notification` : t`Create a notification`}
      </CoreTitle.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        <Form>
          <Wrapper
            gap={16}
            isColumn
          >
            <Title />
            <ShortDescription />
            <Description />
            <DateStart />
            <DateEnd />
            <IntegrationTypeId />
            <Lang />
            <AlertType />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button
              color="white"
              onClick={onClick}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
