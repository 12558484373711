import React from 'react';

import {Sidebar} from 'components';
import {useUsersContext} from '../users-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import Id from './id/id';

interface Filter {
  filter: AppEnv.UserFilter;
  setFilter: AppEnv.SetState<AppEnv.UserFilter>;
}

const Filter = ({filter, setFilter}: Filter) => {
  const {userList} = useWorkspaceContext();
  const {isFilterActive, setIsFilterActive} = useUsersContext();

  return (
    <Sidebar
      filter={filter}
      initialValues={filter}
      isActive={isFilterActive}
      isDisabled={!userList}
      onSubmit={async (values: AppEnv.UserFilter) => setFilter(values)}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(prevValue => !prevValue)}
    >
      <Id />
    </Sidebar>
  );
};

export default Filter;
