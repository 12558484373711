import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {Error404, Header, Nav} from 'components';
import Workspace from 'workspace/workspace';
import {useAppContext} from 'app-context';

import Auth from 'pages/auth/auth';
import Automations from 'pages/automations/automations';
import Billing from 'pages/billing/billing';
import BlockList from 'pages/block-list/block-list';
import Connections from 'pages/connections/connections';
import CrmList from 'pages/crm-list/crm-list';
import Events from 'pages/events/events';
import Home from 'pages/home/home';
import Integrations from 'pages/integrations/integrations';
import Invoices from 'pages/invoices/invoices';
import Notifications from 'pages/notifications/notifications';
import Partners from 'pages/partners/partners';
import PromoCodes from 'pages/promo-codes/promo-codes';
import Rates from 'pages/rates/rates';
import Servers from 'pages/servers/servers';
import Staff from 'pages/staff/staff';
import Stats from 'pages/stats/stats';
import SystemOptionList from 'pages/system-option-list/system-option-list';
import Tokens from 'pages/tokens/tokens';
import Users from 'pages/users/users';

import Firewall from './firewall/firewall';

const Router = () => {
  const {client} = useAppContext();

  return (
    <BrowserRouter>
      {client ? (
        <Workspace>
          <Header />

          <Routes>
            <Route path="/" element={<Home />} />

            <Route
              path="*"
              element={
                <div className="body">
                  <Nav />

                  <div className="page">
                    <Routes>
                      <Route
                        path="automations"
                        element={<Firewall element={<Automations />} />}
                      />

                      <Route
                        path="billing/*"
                        element={<Firewall element={<Billing />} />}
                      />

                      <Route
                        path="block-list"
                        element={<Firewall element={<BlockList />} />}
                      />

                      <Route
                        path="connections/*"
                        element={<Firewall element={<Connections />} />}
                      />

                      <Route
                        path="crm"
                        element={<Firewall element={<CrmList />} />}
                      />

                      <Route
                        path="events"
                        element={<Firewall element={<Events />} />}
                      />

                      <Route path="integrations/*" element={<Integrations />} />

                      <Route
                        path="invoices"
                        element={<Firewall element={<Invoices />} />}
                      />

                      <Route
                        path="notifications"
                        element={<Firewall element={<Notifications />} />}
                      />

                      <Route
                        path="partners/*"
                        element={<Firewall element={<Partners />} />}
                      />

                      <Route
                        path="promo-codes"
                        element={<Firewall element={<PromoCodes />} />}
                      />

                      <Route
                        path="rates"
                        element={<Firewall element={<Rates />} />}
                      />

                      <Route
                        path="servers/*"
                        element={<Firewall element={<Servers />} />}
                      />

                      <Route
                        path="staff"
                        element={<Firewall element={<Staff />} />}
                      />

                      <Route
                        path="statistics/*"
                        element={<Firewall element={<Stats />} />}
                      />

                      <Route
                        path="system-options"
                        element={<Firewall element={<SystemOptionList />} />}
                      />

                      <Route
                        path="tokens"
                        element={<Firewall element={<Tokens />} />}
                      />

                      <Route
                        path="users"
                        element={<Firewall element={<Users />} />}
                      />

                      <Route path="*" element={<Error404 />} />
                    </Routes>
                  </div>
                </div>
              }
            />
          </Routes>
        </Workspace>
      ) : (
        <Auth />
      )}
    </BrowserRouter>
  );
};

export default Router;
