import React from 'react';
import {useTranslation} from 'react-i18next';

import IntegrationFinder, {
  Values
} from 'pages/billing/integration-finder/integration-finder';
import {useAppContext} from 'app-context';
import {usePaymentListContext} from '../../payment-list-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface IntegrationChanger {
  isIntegrationChangerActive: boolean;
  setIsIntegrationChangerActive: AppEnv.SetState<boolean>;
}

const IntegrationChanger = ({
  isIntegrationChangerActive,
  setIsIntegrationChangerActive
}: IntegrationChanger) => {
  const {pushNotification} = useAppContext();

  const {selectedPaymentIdList, getPaymentList, setSelectedPaymentIdList} =
    usePaymentListContext();

  const {putBillings} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: Values) => {
    if (values.integration_id) {
      const promises = selectedPaymentIdList.map(async item => {
        await putBillings(item, {integration_id: values.integration_id});
      });

      await Promise.all(promises);

      setIsIntegrationChangerActive(false);
      pushNotification(t`The integration has been changed`);
      setSelectedPaymentIdList([]);
      getPaymentList();
    }

    return true;
  };

  return (
    <IntegrationFinder
      isActive={isIntegrationChangerActive}
      onClick={() => setIsIntegrationChangerActive(false)}
      onSubmit={handleSubmit}
    />
  );
};

export type {Values} from 'pages/billing/integration-finder/integration-finder';
export default IntegrationChanger;
