import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface EventsContext {
  isFilterActive: boolean;
  setIsFilterActive: AppEnv.SetState<boolean>;
}

const EventsContext = React.createContext<EventsContext>({} as EventsContext);

export const useEventsContext = () => useContext(EventsContext);
export default EventsContext;
