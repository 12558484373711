import React, {useEffect, useState} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';

import Content from './content/content';
import EventsContext from './events-context';
import Filter from './filter/filter';
import filterToRequest from './filter-to-request';

const Events = () => {
  const {getUserList} = useWorkspaceContext();

  const [eventList, setEventList] = useState<AppEnv.Event[]>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {fetchEvents} = useRequest();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.EventFilter> = useFilter();

  const {page = '1'} = useSearchParams();
  const {t} = useTranslation();
  const pagination = usePagination(parseInt(page));
  usePaginationParam(pagination.currentPage);

  const getEvents = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);

    const {headers, data} = await fetchEvents(
      pagination.currentPage,
      filterToRequest(filter)
    );

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setEventList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getEvents();
  }, [filter, pagination.currentPage]);

  useEffect(() => {
    document.title = `${t`Events`} - Whatcrm Console`;
  }, []);

  useEffect(() => {
    getUserList();
  }, []);

  return (
    <EventsContext.Provider value={{isFilterActive, setIsFilterActive}}>
      <Content eventList={eventList} pagination={pagination} />
      <Filter filter={filter} isDisabled={!eventList} setFilter={setFilter} />
      {isLoading && <Preloader isFullScreen />}
    </EventsContext.Provider>
  );
};

export default Events;
