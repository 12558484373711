import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface StatsContext {
  filter: AppEnv.StatsFilter;
  isFilterAvailableAndActive: boolean;
  isFilterDisabled: boolean;
  setIsFilterActive: AppEnv.SetState<boolean>;
  setIsFilterDisabled: AppEnv.SetState<boolean>;
}

const StatsContext = React.createContext<StatsContext>({} as StatsContext);

export const useStatsContext = () => useContext(StatsContext);
export default StatsContext;
