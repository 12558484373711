import React from 'react';
import {Wrapper, Button, Icons, Title} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

interface State {
  referrer?: string;
}

const Headers = () => {
  const {state}: AppEnv.Location<State> = useLocation();
  const {t} = useTranslation();

  return (
    <Wrapper
      justifyContent="space-between"
      alignItems="center"
    >
      <Link to={state?.referrer || '/partners'}>
        <Button color="white">
          <Icons.AngleLeft />
          {t`Back`}
        </Button>
      </Link>

      <Title.H3>{t`Partners`}</Title.H3>
      <div />
    </Wrapper>
  );
};

export default Headers;
