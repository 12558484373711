import React, {useState, useEffect} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useFilter, useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Content from './content/content';
import Filter from './filter/filter';
import BlockListContext from './block-list-context';
import filterToRequest from './filter-to-request';

const BlockList = () => {
  const [blockedList, setBlockedList] = useState<AppEnv.Blocked[]>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.BlockedFilter> = useFilter();

  const {fetchBlacklists} = useRequest();
  const pagination = usePagination();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Block list`} - Whatcrm Console`;
  }, []);

  const getBlockedList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchBlacklists(
      pagination.currentPage,
      filterToRequest(filter)
    );

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setBlockedList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getBlockedList();
  }, [filter, pagination.currentPage]);

  return (
    <BlockListContext.Provider
      value={{blockedList, setBlockedList, setIsFilterActive, getBlockedList}}
    >
      <Content pagination={pagination} isFilterActive={isFilterActive} />
      <Filter filter={filter} isActive={isFilterActive} setFilter={setFilter} />
      {isLoading && <Preloader isFullScreen />}
    </BlockListContext.Provider>
  );
};

export default BlockList;
