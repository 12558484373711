import React from 'react';
import {Title, Chips} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

type Value = 'connection' | 'delete' | null;

interface Option {
  label: string;
  value: Value;
}

const ActionType = () => {
  const [field, , helpers] = useField<Value>('action_type');
  const {t} = useTranslation();

  const options: Option[] = [
    {
      value: null,
      label: t`Any type`
    },
    {
      value: 'connection',
      label: t`Connecting`
    },
    {
      value: 'delete',
      label: t`Deleting`
    }
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Type`}</Title.H3>

      <Chips
        {...field}
        options={options}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default ActionType;
