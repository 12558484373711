import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const TelphinCode = () => {
  const [field, , helpers] = useField<string>('telphin_code');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Telphin code`}</Title.H3>

      <Input
        {...field}
        placeholder={t`Telphin code`}
        maxLength={8}
        autoCapitalize="off"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default TelphinCode;
