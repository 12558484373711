import React, {useEffect, useState} from 'react';
import {
  Chips,
  EmptyBox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Title,
  Wrapper
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import {useWorkspaceContext} from 'workspace/workspace-context';

import Creator from './creator/creator';
import Crm from './crm/crm';
import {IsMain} from './crm-list-env';

interface Option {
  label: string;
  value: IsMain;
}

const CrmList = () => {
  const {getFusedCrmList, getTokenList} = useWorkspaceContext();
  const [isMain, setIsMain] = useState<IsMain>(-1);

  const {t} = useTranslation();

  useEffect(() => {
    document.title = `CRM - Whatcrm Console`;
  }, []);

  useEffect(() => {
    getTokenList();
  }, []);

  const fusedCrmList = getFusedCrmList(isMain);

  const options: Option[] = [
    {label: t`All CRM`, value: -1},
    {label: 'Whatcrm', value: 1},
    {label: t`Others`, value: 0}
  ];

  return (
    <div className="content">
      <Wrapper gap={16} isColumn>
        <Title.H1>{`CRM`}</Title.H1>
        <Creator />
        <Chips options={options} value={isMain} onChange={setIsMain} />

        {fusedCrmList.length ? (
          <div>
            <EntriesNumber
              number={fusedCrmList.length}
              style={{marginBottom: 8}}
            />

            <Table style={{marginBottom: 8}}>
              <TableHead>
                <TableCell>{t`Name`}</TableCell>
                <TableCell>{t`Description`}</TableCell>
                <TableCell>{t`Status`}</TableCell>
                <TableCell>{t`Whatcrm integration`}</TableCell>
                <TableCell isIcon />
                <TableCell isIcon />
              </TableHead>

              <TableBody>
                {fusedCrmList.map(item => (
                  <Crm crm={item} key={item.id} />
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <EmptyBox />
        )}
      </Wrapper>
    </div>
  );
};

export default CrmList;
