import React, {useState} from 'react';
import {Button, TableCell, TableRow} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {useAppContext} from 'app-context';
import {useWorkspaceContext} from 'workspace/workspace-context';
import useCategories from 'pages/events/use-categories';
import * as AppEnv from 'app-env';

import Res from './res/res';

interface Event {
  event: AppEnv.Event;
}

const Event = ({event}: Event) => {
  const {preferences} = useAppContext();
  const {userList} = useWorkspaceContext();

  const [isActive, setIsActive] = useState(false);

  const {t} = useTranslation();
  const categories = useCategories();

  const links = {
    application: undefined,
    instances: 'connections',
    integrations: 'integrations',
    partners: 'partners',
    potreblen_yslug: undefined,
    users: 'users'
  };

  const log_time = moment.unix(event.log_time).utcOffset(preferences.utcOffset);
  const user = userList?.find(item => item.id == event.user_id);

  return (
    <TableRow isActive={isActive}>
      <TableCell>
        {log_time.format('L')}
        <br />
        {log_time.format('LT')}
      </TableCell>

      <TableCell>{categories[event.category]}</TableCell>

      <TableCell>
        {links[event.category] ? (
          <Link
            state={{
              referrer: window.location.pathname + window.location.search
            }}
            to={`/${links[event.category]}?filter_id=${event.category_id}`}
          >
            <Button color="transparent" isLink>
              {event.category_id}
            </Button>
          </Link>
        ) : (
          event.category_id
        )}
      </TableCell>

      <TableCell>{!!event.integration_id && event.integration_id}</TableCell>

      <TableCell>
        {user?.username || (event.user_id != 0 && event.user_id)}
      </TableCell>

      <TableCell>{event.ip != '-' && event.ip}</TableCell>
      <TableCell>{event.diff.length > 2 && event.diff}</TableCell>

      <TableCell>
        <Button
          color="transparent"
          isDisabled={event.new.length < 3}
          onClick={() => setIsActive(true)}
        >
          {t`Open`}
        </Button>

        <Res event={event} isActive={isActive} setIsActive={setIsActive} />
      </TableCell>
    </TableRow>
  );
};

export default Event;
