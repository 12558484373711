import {useTranslation} from 'react-i18next';
import axios, {AxiosResponse} from 'axios';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface RequestParams {
  crm: string;
  data?: object;
  method?: 'delete';
}

const getApi = (crm: string): [string | null | undefined, object | null] => {
  if (crm == 'AMO')
    return [
      process.env.REACT_APP_AMOCRM,
      {'X-Amo-Token': process.env.REACT_APP_AMOCRM_TOKEN}
    ];
  else if (crm == 'B24')
    return [
      process.env.REACT_APP_BITRIX24,
      {'X-Api-Token': process.env.REACT_APP_BITRIX24_TOKEN}
    ];
  else if (crm == 'TELEGRAM')
    return [
      process.env.REACT_APP_TELEGRAM,
      {'X-Amo-Token': process.env.REACT_APP_AMOCRM_TOKEN}
    ];
  else if (crm == 'TELPHGRAM')
    return [
      process.env.REACT_APP_TELPHGRAM,
      {'X-Api-Token': process.env.REACT_APP_TELPHGRAM_TOKEN}
    ];

  return [null, null];
};

const useCrmRequest = () => {
  const {pushNotification} = useAppContext();
  const {t} = useTranslation();

  const handleThen = <T>(res: AxiosResponse): T | null => {
    if (!('detail' in res.data) && !('error' in res.data)) return res.data;

    const text = res.data.detail || res.data.detail || t`Something went wrong.`;
    pushNotification(t`Error`, text);

    return null;
  };

  // eslint-disable-next-line
  const handleCatch = (e: any) => {
    const text = e.response.data?.detail || t`Something went wrong.`;
    pushNotification(t`Error`, text);

    return null;
  };

  const request = async <T>(
    path: string,
    {crm, data, method}: RequestParams
  ): Promise<T | null> => {
    const [baseURL, headers] = getApi(crm);
    if (!baseURL || !headers) return null;

    const instance = axios.create({baseURL, headers, method});

    const res = await instance(path, {data})
      .then(handleThen<T>)
      .catch(handleCatch);

    return res;
  };

  const deleteSources = (crm: string, domain: string, id: number) =>
    request<object>(`api/${domain}/api/v4/sources`, {
      crm,
      data: [{id}],
      method: 'delete'
    });

  interface FetchQueueСlear {
    success: 'ok';
  }

  const fetchQueueСlear = (crm: string, queue_key: string) =>
    request<FetchQueueСlear>(`queue_clear/${queue_key}`, {crm});

  interface FetchQueueGet {
    data: AppEnv.Queue[];
  }

  const fetchQueueGet = async (crm: string, domain: string) => {
    const res = await request<FetchQueueGet>(`queue_get/${domain}`, {crm});
    return res?.data || null;
  };

  interface _Embedded {
    sources: AppEnv.Source[];
  }

  interface FetchSources {
    _embedded?: _Embedded;
  }

  const fetchSources = async (crm: string, domain: string) => {
    const res = await request<FetchSources>(`api/${domain}/api/v4/sources`, {
      crm
    });

    return res?._embedded?.sources || null;
  };

  return {deleteSources, fetchQueueСlear, fetchQueueGet, fetchSources};
};

export default useCrmRequest;
