import * as AppEnv from 'app-env';
import moment from 'moment';

const filterToRequest = (
  filter: AppEnv.PartnerFilter
): AppEnv.PartnerRequest => ({
  date_add_after: filter.date_add_after
    ? {value: moment.utc(filter.date_add_after).format('X'), type: '>'}
    : undefined,
  date_add_before: filter.date_add_before
    ? {
        value: moment.utc(`${filter.date_add_before} 23:59:59`).format('X'),
        type: '<'
      }
    : undefined,
  email: filter.email ? {value: filter.email, type: 'like'} : undefined,
  fullname: filter.fullname
    ? {value: filter.fullname, type: 'like'}
    : undefined,
  id: filter.id ? {value: filter.id} : undefined,
  is_deleted:
    filter.is_deleted && filter.is_deleted != '-1'
      ? {value: filter.is_deleted}
      : undefined,
  phone: filter.phone ? {value: filter.phone, type: 'like'} : undefined,
  telphin_code: filter.telphin_code
    ? {value: filter.telphin_code, type: 'like'}
    : undefined,
  type: filter.type && filter.type != '-1' ? {value: filter.type} : undefined
});

export default filterToRequest;
