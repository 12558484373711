import React, {useState} from 'react';
import {TableCell, Button, Mark} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useWorkspaceContext} from 'workspace/workspace-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface IsMain {
  crm: AppEnv.Crm;
}

const IsMain = ({crm}: IsMain) => {
  const {crmList, setCrmList} = useWorkspaceContext();
  const [isFetching, setIsFetching] = useState(false);

  const {putCrmTypes} = useRequest();
  const {t} = useTranslation();

  const handleClick = async () => {
    setIsFetching(true);

    const {data} = await putCrmTypes(crm.id, {is_main: crm.is_main ? 0 : 1});
    if (!data) return setIsFetching(false);

    const index = crmList.findIndex(crm => crm.id == data.id);
    if (index < 0) return;

    setCrmList(prevValue => update(prevValue, {[index]: {$set: data}}));
    setIsFetching(false);
  };

  return (
    <TableCell isIcon>
      <Button
        color="transparent"
        tip={t`Change`}
        isDisabled={isFetching}
        onClick={handleClick}
      >
        <Mark mark={crm.is_main} />
      </Button>
    </TableCell>
  );
};

export default IsMain;
