import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';

const ChatId = () => {
  const [field, , helpers] = useField<string>('chat_id');

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>IPv6</Title.H3>

      <Input
        {...field}
        placeholder="IPv6"
        autoCapitalize="off"
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default ChatId;
