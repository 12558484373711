import React, {useEffect, useState} from 'react';
import {MultiSelect, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {useGetRateList} from 'common/hooks';

interface Rate {
  label: string;
  value: number;
}

const TariffId = () => {
  const [rateList, setRateList] = useState<Rate[]>();

  const [crmField] = useField<string | undefined>('integration_type_id');
  const [field, , helpers] = useField<string[] | undefined>('tariff_id');
  const {t} = useTranslation();
  const getRateList = useGetRateList();

  const fetchOptions = async () => {
    helpers.setValue(undefined);

    if (!crmField.value) return setRateList([]);
    setRateList(undefined);

    const {options} = await getRateList(parseInt(crmField.value));
    helpers.setValue(options.map(item => item.value.toString()));
    setRateList(options);
  };

  useEffect(() => {
    fetchOptions();
  }, [crmField.value]);

  const rateOptions = rateList?.map(item => ({
    label: item.label,
    value: item.value.toString()
  }));

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Rate`}</Title.H3>

      <MultiSelect
        {...field}
        options={rateOptions}
        placeholder={t`Rate`}
        isDisabled={!crmField.value}
        isSelectAll
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default TariffId;
