import React from 'react';
import Code from '../../code';

interface Value {
  value: unknown;
}

const Value = ({value}: Value) => {
  const getOutput = () => {
    if (typeof value == 'boolean') return value ? 'true' : 'false';
    else if (value == null) return null;

    return value as React.ReactNode;
  };

  const isString = typeof value == 'string';

  const color = isString
    ? '#ff8242'
    : typeof value == 'number'
      ? '#a892e7'
      : '#5bc6ea';

  const isObject =
    value && typeof value == 'object' && Object.keys(value).length;

  const output = getOutput();

  return (
    <>
      {isObject ? (
        <Code code={JSON.stringify(value)} />
      ) : (
        <>
          <span style={{color}}>
            {isString && "'"}
            {output}
            {isString && "'"}
          </span>
          ,
        </>
      )}
    </>
  );
};

export default Value;
