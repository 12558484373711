import React, {useState} from 'react';
import {
  TableRow,
  TableCell,
  Toggle,
  Anchor,
  Button,
  Icons,
  Confirmation
} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';
import moment from 'moment';

import {useAppContext} from 'app-context';
import {useAutomationsContext} from 'pages/automations/automations-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from './editor/editor';

interface Automation {
  automation: AppEnv.Automation;
}

const Automation = ({automation}: Automation) => {
  const {pushNotification} = useAppContext();

  const {automationList, setAutomationList} = useAutomationsContext();

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {putAutomations /* deleteAutomationsReq */} = useRequest();
  const {t} = useTranslation();

  const index = automationList?.findIndex(item => item.id == automation.id);

  const handleToggleSwitch = async (value: boolean) => {
    const {data} = await putAutomations(automation.id, {
      is_deleted: value ? 0 : 1
    });

    if (data && typeof index == 'number') {
      setAutomationList(prevValue =>
        update(prevValue, {
          [index]: {$set: data}
        })
      );
    }
  };

  const deleteAutomation = async (res: boolean) => {
    if (res) {
      // const {data} = await deleteAutomationsReq(automation.id);
      const data = true;

      if (data && typeof index == 'number') {
        pushNotification(t`The automation has been deleted`);

        setAutomationList(prevValue =>
          update(prevValue, {
            $splice: [[index, 1]]
          })
        );
      }
    }

    setIsConfirmationActive(false);
    return true;
  };

  const fileName = automation.link.slice(automation.link.lastIndexOf('/') + 1);

  const [h, m, s] = automation.sleep
    ? moment
        .utc(new Date(0).setSeconds(automation.sleep))
        .format('LTS')
        .split(':')
        .map(item => parseInt(item))
    : [undefined, undefined, undefined];

  const isActive = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>{automation.question}</TableCell>
      <TableCell>{automation.answer}</TableCell>

      <TableCell>
        <Anchor href={automation.link} target="_blank">
          {fileName}
        </Anchor>
      </TableCell>

      <TableCell>
        {!!h && `${h} ${t`h`} `}
        {(!!m || !!(h && s)) && `${m} ${t`m`} `}
        {!!s && `${s} ${t`s`}`}
      </TableCell>

      <TableCell>
        <Link
          to={`/integrations?filter_domain=${automation.integration.domain}&filter_integration_type_id=${automation.integration.integration_type_id}`}
          state={{referrer: window.location.pathname + window.location.search}}
        >
          <Button color="transparent" isLink>
            {automation.integration.domain}
          </Button>
        </Link>
      </TableCell>

      <TableCell isIcon>
        <Toggle value={!automation.is_deleted} onChange={handleToggleSwitch} />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          id={automation.id}
          initialValues={automation}
          isActive={isEditorActive}
          onClick={() => setIsEditorActive(false)}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Delete`}
          onClick={() => setIsConfirmationActive(true)}
        >
          <Icons.Trash />
        </Button>

        {isConfirmationActive && (
          <Confirmation
            title={t`Are you sure you want to delete this automation?`}
            isActive={isConfirmationActive}
            onClick={deleteAutomation}
          />
        )}
      </TableCell>
    </TableRow>
  );
};

export default Automation;
