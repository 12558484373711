import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import options from './options';

const Currency = () => {
  const [field, , helpers] = useField('currency');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Currency`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Currency`}
        isClearable
        isMaxWidth
        isSearchAvailable
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Currency;
