import React from 'react';
import {Input, Text, Title, Wrapper} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Email = () => {
  const [field, meta, helpers] = useField<string>('email');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Email`}</Title.H3>

      <Wrapper gap={4} isColumn>
        <Input
          {...field}
          type="email"
          isValid={isValid}
          placeholder={t`Email`}
          autoCapitalize="off"
          width="max"
          onChange={helpers.setValue}
        />

        {meta.error == 'exist' && (
          <Text color="orange" size="s" isParagraph>
            {t`The partner already exists`}
          </Text>
        )}
      </Wrapper>
    </div>
  );
};

export default Email;
