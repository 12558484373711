import React from 'react';
import {Popup, Title, Wrapper, Line, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import * as AppEnv from 'app-env';

import IntegrationId from './integration-id/integration-id';
import Fullname from './fullname/fullname';
import Email from './email/email';
import Phone from './phone/phone';

interface Editor {
  initialValues?: AppEnv.EmployeeForm;
  isActive: boolean;
  isUpdate?: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.EmployeeForm>;
}

const Editor = ({
  initialValues = {
    email: undefined,
    fullname: undefined,
    integration_id: undefined,
    phone: undefined
  },
  isActive,
  isUpdate,
  onClick,
  onSubmit
}: Editor) => {
  const {t} = useTranslation();

  const validationSchema = yup.object().shape({
    email: yup.string().trim().required().email(),
    fullname: yup.string().trim().required(),
    integration_id: yup.number().required()
  });

  return (
    <Popup
      isActive={isActive}
      onClick={onClick}
    >
      <Title.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Update the employee` : t`Create an employee`}
      </Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        <Form>
          <Wrapper
            gap={16}
            isColumn
          >
            <IntegrationId isUpdate={isUpdate} />
            <Fullname />
            <Email />
            <Phone />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button
              color="white"
              onClick={onClick}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
