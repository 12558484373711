import React from 'react';
import {Wrapper} from 'whatcrm-core';

import {Sidebar} from 'components';
import {useRatesContext} from '../rates-context';
import * as AppEnv from 'app-env';

import Plan from './plane/plane';
import Quantity from './quantity/quantity';
import Price from './price/price';
import IsWithFeedback from './is-with-feedback/is-with-feedback';
import Country from './country/country';
import IntegrationTypeId from './integration-type-id/integration-type-id';
import IsDeleted from './is-deleted/is-deleted';

interface Filter {
  filter: AppEnv.RateFilter;
  setFilter: AppEnv.SetState<AppEnv.RateFilter>;
}

const Filter = ({filter, setFilter}: Filter) => {
  const {isFilterActive, rateList, setIsFilterActive} = useRatesContext();

  const initialValues: AppEnv.RateFilter = {
    is_deleted: filter.is_deleted || '-1',
    isWithFeedback: filter.isWithFeedback || '0'
  };

  return (
    <Sidebar
      filter={filter}
      initialValues={initialValues}
      isActive={isFilterActive}
      isDisabled={!rateList}
      onSubmit={async (values: AppEnv.RateFilter) => setFilter(values)}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(false)}
    >
      <Plan />
      <Quantity />

      <Wrapper gap={8} isColumn>
        <Price />
        <IsWithFeedback />
      </Wrapper>

      <Country />
      <IntegrationTypeId />
      <IsDeleted />
    </Sidebar>
  );
};

export default Filter;
