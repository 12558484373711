import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface ConnectionContext {
  connection: AppEnv.Instance;
}

const ConnectionContext = React.createContext<ConnectionContext>(
  {} as ConnectionContext
);

export const useConnectionContext = () => useContext(ConnectionContext);
export default ConnectionContext;
