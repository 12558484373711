import React from 'react';
import {Title, Chips} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

const Http = () => {
  const [field, , helpers] = useField<string>('http');
  const {t} = useTranslation();

  const options: {label: string; value: AppEnv.RequestMethod}[] = [
    {label: 'GET', value: 'get'},
    {label: 'POST', value: 'post'},
    {label: 'PUT', value: 'put'},
    {label: 'DELETE', value: 'delete'}
  ];

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Request method`}</Title.H3>

      <Chips
        {...field}
        options={options}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Http;
