import React from 'react';
import {Wrapper, Title, Text, useGetTariffName} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import {useAppContext} from 'app-context';
import {useConnectionContext} from '../connection-context';
import getConnectionPaymentStatus from 'pages/connections/get-connection-payment-status';

const PaymentInfo = () => {
  const {timezone, preferences} = useAppContext();
  const {connection} = useConnectionContext();

  const {expirationDate, isExpired, isPaid} =
    getConnectionPaymentStatus(connection);

  const getTariffName = useGetTariffName();
  const {i18n, t} = useTranslation();

  const isPremium = !!connection.integration?.is_premium;

  const isTrial = !!(
    connection.integration?.date_trial &&
    connection.integration.date_trial > +new Date() / 1000
  );

  const isTrialShown = isTrial || !isPaid;

  return (
    <>
      {isPremium ? (
        <Title.H2 bg="blue">{t`Premium`}</Title.H2>
      ) : (
        <>
          {isTrialShown && (
            <Title.H2
              bg={isTrial ? 'blue' : 'red'}
              noWrap
              style={{direction: 'rtl'}}
            >
              {connection.integration?.date_trial ? (
                <>
                  {t`Trial until`}{' '}
                  {moment
                    .unix(connection.integration.date_trial)
                    .utc()
                    .format('L')}
                </>
              ) : (
                t`Trial`
              )}
            </Title.H2>
          )}

          {isPaid && (
            <Wrapper gap={8} isColumn>
              <Title.H2
                bg={isExpired ? 'red' : 'green'}
                noWrap
                style={{direction: 'rtl'}}
              >
                {expirationDate ? (
                  <>
                    {t`Paid until`}{' '}
                    {moment.unix(expirationDate).utc().format('L')}
                  </>
                ) : (
                  t`Paid`
                )}
              </Title.H2>
            </Wrapper>
          )}

          <Wrapper gap={8} isColumn>
            {!!connection.date_pay && (
              <Text isParagraph>
                {t`Payment date`}
                {': '}
                {moment
                  .unix(connection.date_pay)
                  .utcOffset(preferences.utcOffset)
                  .format('L, LT')}
                {` ${timezone}`}
              </Text>
            )}

            {connection.tariff && (
              <Text isParagraph>
                {t`Rate`}: {connection.tariff.plane},{' '}
                {getTariffName(
                  connection.tariff.quantity,
                  connection.tariff.period,
                  i18n.language
                )}
                , {connection.tariff.country}
              </Text>
            )}
          </Wrapper>
        </>
      )}
    </>
  );
};

export default PaymentInfo;
