import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

import {Roles} from 'app-enums';
import {useAppContext} from 'app-context';

interface Option {
  access_level: Roles;
  label: string;
  value: number;
}

const RoleId = () => {
  const {client} = useAppContext();

  const [field, meta, helpers] = useField('role_id');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  let options: Option[] = [
    {access_level: Roles.INTEGRATION, label: 'INTEGRATION', value: 6},
    {access_level: Roles.TELPHIN, label: 'TELPHIN', value: 4},
    {access_level: Roles.PARTNER, label: 'PARTNER', value: 3},
    {access_level: Roles.MODER, label: 'MODER', value: 1},
    {access_level: Roles.SUPMODER, label: 'SUPMODER', value: 5},
    {access_level: Roles.USER, label: 'USER', value: 0},
    {access_level: Roles.ADMIN, label: 'ADMIN', value: 2}
  ];

  options = options.filter(item =>
    client?.access_level
      ? client.access_level > item.access_level ||
        client.access_level == Roles.ADMIN
      : false
  );

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Role`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Role`}
        isMaxWidth
        isValid={isValid}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default RoleId;
