import React, {useState} from 'react';
import {TableRow, TableCell, Button, Icons, Confirmation} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../editor/editor';
import {useSystemOptionListContext} from '../system-option-list-context';

interface SystemOption {
  systemOption: AppEnv.SystemOption;
  systemOptionList: AppEnv.SystemOption[];
  setSystemOptionList: AppEnv.SetState<AppEnv.SystemOption[] | undefined>;
}

const SystemOption = ({
  systemOption,
  systemOptionList,
  setSystemOptionList
}: SystemOption) => {
  const {pushNotification} = useAppContext();
  const {getSystemOptionList} = useSystemOptionListContext();

  const [isEditorActive, setIsEditorActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const {deleteOptions, putOptions} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.SystemOptionForm) => {
    const {data} = await putOptions(systemOption.id, values);

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.param, t`The system option has been updated`);

      const index = systemOptionList.findIndex(item => item.id == data.id);

      setSystemOptionList(prevValue =>
        update(prevValue, {
          [index]: {$set: data}
        })
      );
    }

    return true;
  };

  const deleteSystemOption = async () => {
    const {data} = await deleteOptions(systemOption.id);

    if (typeof data == 'string') {
      setIsConfirmationActive(false);

      pushNotification(
        systemOption.param,
        t`The system option has been deleted`
      );

      getSystemOptionList();
    }

    return true;
  };

  const handleClick = async (res: boolean) => {
    if (res) {
      return deleteSystemOption();
    }

    setIsConfirmationActive(false);
    return true;
  };

  const isActive = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>{systemOption.param}</TableCell>
      <TableCell>{systemOption.value}</TableCell>
      <TableCell>{systemOption.comment}</TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={systemOption}
          isActive={isEditorActive}
          isUpdate
          onClick={() => setIsEditorActive(false)}
          onSubmit={handleSubmit}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Delete`}
          onClick={() => setIsConfirmationActive(true)}
        >
          <Icons.Trash />
        </Button>

        <Confirmation
          title={t`Are you sure you want to delete this system option?`}
          isActive={isConfirmationActive}
          onClick={handleClick}
        />
      </TableCell>
    </TableRow>
  );
};

export default SystemOption;
