import * as AppEnv from 'app-env';

const filterToRequest = (
  filter: AppEnv.EmployeeFilter
): AppEnv.EmployeeRequest => ({
  email: filter.email ? {value: filter.email, type: 'like'} : undefined,
  fullname: filter.fullname
    ? {value: filter.fullname, type: 'like'}
    : undefined,
  integration_id: filter.integration_id
    ? {value: filter.integration_id}
    : undefined,
  phone: filter.phone ? {value: filter.phone, type: 'like'} : undefined
});

export default filterToRequest;
