import React from 'react';
import {Popup, Title, Line, Wrapper, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import IntegrationId from './integration-id/integration-id';

interface Values {
  integration_id: number | undefined;
}

interface IntegrationFinder {
  domain?: string;
  integration_id?: number;
  isActive: boolean;
  onClick: () => void;
  onSubmit: (arg: Values) => Promise<boolean>;
}

const IntegrationFinder = ({
  domain,
  integration_id,
  isActive,
  onClick,
  onSubmit
}: IntegrationFinder) => {
  const {t} = useTranslation();

  const validationSchema = yup.object().shape({
    integration_id: yup.number().required()
  });

  return (
    <Popup
      isActive={isActive}
      onClick={onClick}
    >
      <Title.H2 style={{marginBottom: 16}}>
        {t`Change the integration`}
      </Title.H2>

      <Formik
        initialValues={{integration_id: integration_id || undefined}}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        <Form>
          <IntegrationId domain={domain} />
          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button
              color="white"
              onClick={onClick}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export type {Values};
export default IntegrationFinder;
