import React from 'react';
import {TableRow, TableCell, Button, Icons} from 'whatcrm-core';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useContainersContext} from '../../containers-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

interface Container {
  container: AppEnv.Instance;
}

const Container = ({container}: Container) => {
  const {pushNotification} = useAppContext();

  const {
    containerList,
    refreshingContainerId,
    setContainerList,
    setRefreshingContainerId,
    getIsContainerDeleted
  } = useContainersContext();

  const {putInstances} = useRequest();
  const {t} = useTranslation();

  const updateContainer = (data: AppEnv.Instance) => {
    const index = containerList.findIndex(item => item.id == data.id);

    setContainerList(prevValue =>
      update(prevValue, {
        [index]: {$set: data}
      })
    );
  };

  const refreshContainer = async () => {
    setRefreshingContainerId(container.id);
    const isDeleted = await getIsContainerDeleted(container);

    pushNotification(
      `${t`Container`} ${container.id}`,
      isDeleted == 0
        ? container.is_deleted == 0
          ? t`The container is turned on`
          : t`The container has been turned on`
        : container.is_deleted == 1
        ? t`The container is turned off`
        : t`The container is unavailable (timeout)`
    );

    if (container.is_deleted !== isDeleted) {
      updateContainer({...container, is_deleted: isDeleted});
    }

    setRefreshingContainerId(undefined);
  };

  const turnContainer = async () => {
    const {data} = await putInstances(container.id, {
      is_deleted: container.is_deleted == 1 ? 0 : 1
    });

    if (data) {
      pushNotification(
        `${t`Container`} ${data.id}`,
        data.is_deleted == 0
          ? t`The container has been turned on`
          : data.is_deleted == 1
          ? t`The container has been turned off`
          : t`The container is unavailable (timeout)`
      );

      updateContainer(data);
    }
  };

  const isRefreshing = container.id == refreshingContainerId;

  return (
    <TableRow isActive={isRefreshing}>
      <TableCell>{container.id}</TableCell>

      <TableCell>
        <Link
          to={`/connections?filter_chat_id=${container.chat_id}`}
          state={{referrer: window.location.pathname + window.location.search}}
        >
          <Button color="transparent" isLink>
            {container.chat_id}
          </Button>
        </Link>
      </TableCell>

      <TableCell>{container.status}</TableCell>

      <TableCell>
        {container.is_deleted == 0
          ? t`Turned on`
          : container.is_deleted == 1
          ? t`Turned off`
          : t`Timeout`}
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Refresh`}
          isDisabled={isRefreshing}
          onClick={refreshContainer}
        >
          <Icons.Refresh isRotate={isRefreshing} />
        </Button>
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={container.is_deleted == 1 ? t`Turn on` : t`Turn off`}
          onClick={turnContainer}
        >
          <Icons.Standby />
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default Container;
