import React, {useState, useEffect} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import Content from './content/content';
import Filter from './filter/filter';
import AutomationsContext from './automations-context';
import filterToRequest from './filter-to-request';

const Automations = () => {
  const [automationList, setAutomationList] = useState<AppEnv.Automation[]>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.AutomationFilter> = useFilter();

  const {t} = useTranslation();
  const {page = '1'} = useSearchParams();
  const {fetchAutomations} = useRequest();
  const pagination = usePagination(parseInt(page));

  usePaginationParam(pagination.currentPage);

  useEffect(() => {
    document.title = `${t`Automations`} - Whatcrm Console`;
  }, []);

  const getAutomationList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchAutomations(
      pagination.currentPage,
      filterToRequest(filter)
    );

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setAutomationList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getAutomationList();
  }, [filter, pagination.currentPage]);

  return (
    <AutomationsContext.Provider
      value={{
        automationList,
        isFilterActive,
        setAutomationList,
        setIsFilterActive,
        getAutomationList
      }}
    >
      <Content pagination={pagination} />
      <Filter filter={filter} setFilter={setFilter} />
      {isLoading && <Preloader isFullScreen />}
    </AutomationsContext.Provider>
  );
};

export default Automations;
