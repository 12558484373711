import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

const CountPro = () => {
  const [field, , helpers] = useField<number | undefined>('count_pro');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Number of Pro`}</Title.H3>

      <Input
        {...field}
        type="number"
        placeholder={t`Number of Pro`}
        width="max"
        onChange={value => helpers.setValue(parseInt(value) || 0)}
      />
    </div>
  );
};

export default CountPro;
