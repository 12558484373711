import React, {useContext} from 'react';
import {CoreEnv} from 'whatcrm-core';

import * as AppEnv from 'app-env';

type PromoCodeList = AppEnv.PromoCode[] | undefined;

interface PromoCodesContext {
  promoCodeList: PromoCodeList;
  pagination: CoreEnv.Pagination;
  isFilterActive: boolean;
  setPromoCodeList: AppEnv.SetState<PromoCodeList>;
  setIsFilterActive: AppEnv.SetState<boolean>;
  getPromoCodeList: (arg?: number) => Promise<void>;
}

const PromoCodesContext = React.createContext<PromoCodesContext>(
  {} as PromoCodesContext
);

export const usePromoCodesContext = () => useContext(PromoCodesContext);
export default PromoCodesContext;
