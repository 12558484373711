import React from 'react';

import {Sidebar} from 'components';
import {useAppContext} from 'app-context';
import {useHomeContext} from '../home-context';
import {Roles} from 'app-enums';
import * as AppEnv from 'app-env';

import Id from './id/id';
import Domain from './domain/domain';
import Lang from './lang/lang';
import IntegrationTypeId from './integration-type-id/integration-type-id';
import WidgetCode from './widget-code/widget-code';
import TelphinCode from './telphin-code/telphin-code';
import CrmParamId from './crm-param-id/crm-param-id';
import DateAddAfter from './date-add-after/date-add-after';
import DateAddBefore from './date-add-before/date-add-before';
import IsPremium from './is-premium/is-premium';
import IsPartner from './is_partner/is_partner';
import IsDeleted from './is_deleted/is_deleted';

interface Filter {
  filter: AppEnv.IntegrationFilter;
  setFilter: AppEnv.SetState<AppEnv.IntegrationFilter>;
}

const Filter = ({filter, setFilter}: Filter) => {
  const {client} = useAppContext();

  const {integrationList, isFilterActive, setIsFilterActive} = useHomeContext();

  const initialValues: AppEnv.IntegrationFilter = {
    is_deleted: filter.is_deleted || '-1',
    is_partner: filter.is_partner || '-1',
    is_premium: filter.is_premium || '-1'
  };

  return (
    <Sidebar
      filter={filter}
      initialValues={initialValues}
      isActive={isFilterActive}
      isDisabled={!integrationList}
      onSubmit={async (values: AppEnv.IntegrationFilter) => setFilter(values)}
      onCancel={() => setFilter({})}
      onClick={() => setIsFilterActive(prevValue => !prevValue)}
    >
      {client?.access_level && client?.access_level >= Roles.MODER && <Id />}
      <Domain />
      <Lang />
      <IntegrationTypeId />
      <WidgetCode />
      <TelphinCode />
      <CrmParamId />
      <DateAddAfter />
      <DateAddBefore />
      <IsPremium />
      <IsPartner />
      <IsDeleted />
    </Sidebar>
  );
};

export default Filter;
