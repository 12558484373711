import React, {useContext} from 'react';
import {CoreEnv} from 'whatcrm-core';

import * as AppEnv from 'app-env';

type RateList = AppEnv.Rate[] | undefined;

interface RatesContext {
  rateList: RateList;
  pagination: CoreEnv.Pagination;
  isFilterActive: boolean;
  setRateList: AppEnv.SetState<RateList>;
  setIsFilterActive: AppEnv.SetState<boolean>;
  getRateList: (arg?: number) => Promise<void>;
}

const RatesContext = React.createContext<RatesContext>({} as RatesContext);

export const useRatesContext = () => useContext(RatesContext);
export default RatesContext;
