import React from 'react';
import {Button, Line, Popup, ProgressBar, Title} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

interface Progress {
  isActive: boolean;
  progress: number;
  setIsActive: AppEnv.SetState<boolean>;
}

const Progress = ({isActive, progress, setIsActive}: Progress) => {
  const {i18n, t} = useTranslation();

  return (
    <Popup isActive={isActive} onClick={() => setIsActive(false)}>
      <Title.H2 style={{marginBottom: 16}}>{t`Export to CSV`}</Title.H2>
      <ProgressBar progress={progress} lang={i18n.resolvedLanguage || 'en'} />
      <Line />

      <Button color="white" onClick={() => setIsActive(false)}>
        {t`Cancel`}
      </Button>
    </Popup>
  );
};

export default Progress;
