import React, {useState, useEffect} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {
  useFilter,
  usePaginationParam,
  useRequest,
  useSearchParams
} from 'common/hooks';
import * as AppEnv from 'app-env';

import Content from './content/content';
import Filter from './filter/filter';
import RatesContext from './rates-context';
import filterToRequest from './filter-to-request';

const Rates = () => {
  const [rateList, setRateList] = useState<AppEnv.Rate[]>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {
    filter,
    isFilterActive,
    setFilter,
    setIsFilterActive
  }: AppEnv.Filter<AppEnv.RateFilter> = useFilter();

  const {t} = useTranslation();
  const {fetchTariffs} = useRequest();
  const {page = '1'} = useSearchParams();
  const pagination = usePagination(parseInt(page));

  usePaginationParam(pagination.currentPage);

  useEffect(() => {
    document.title = `${t`Rates`} - Whatcrm Console`;
  }, []);

  const getRateList = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {headers, data} = await fetchTariffs(
      pagination.currentPage,
      filterToRequest(filter)
    );

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setRateList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    getRateList();
  }, [filter, pagination.currentPage]);

  return (
    <RatesContext.Provider
      value={{
        rateList,
        pagination,
        isFilterActive,
        setRateList,
        setIsFilterActive,
        getRateList
      }}
    >
      <Content />

      <Filter filter={filter} setFilter={setFilter} />

      {isLoading && <Preloader isFullScreen />}
    </RatesContext.Provider>
  );
};

export default Rates;
