import React from 'react';
import {CoreEnv, EmptyBox, Pagination, Preloader, Title} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import {useInvoicesContext} from '../invoices-context';

import InvoiceList from './invoice-list/invoice-list';

interface Content {
  pagination: CoreEnv.Pagination;
}

const Content = ({pagination}: Content) => {
  const {invoiceList} = useInvoicesContext();
  const {t} = useTranslation();

  return (
    <div className="content">
      <Title.H1 style={{marginBottom: 16}}>{t`Invoices`}</Title.H1>

      {invoiceList ? (
        invoiceList.length ? (
          <>
            <EntriesNumber
              number={invoiceList.length}
              style={{marginBottom: 8}}
            />

            <InvoiceList />
            <Pagination {...pagination} isSticky />
          </>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default Content;
