import {useTranslation} from 'react-i18next';

const useCategories = () => {
  const {t} = useTranslation();

  return {
    application: t`App`,
    instances: t`Connections`,
    integrations: t`Integrations`,
    partners: t`Partners`,
    potreblen_yslug: t`Payments`,
    users: t`Users`
  };
};

export default useCategories;
