import React from 'react';
import {Table, TableBody, TableCell, TableHead} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useIntegrationContext} from '../../integration-context';
import {Roles} from 'app-enums';
import * as AppEnv from 'app-env';

import Source from './source/source';

interface SourceList {
  setSourceList: AppEnv.SetState<AppEnv.Source[] | undefined>;
  sourceList: AppEnv.Source[];
}

const SourceList = ({setSourceList, sourceList}: SourceList) => {
  const {client} = useAppContext();
  const {crm} = useIntegrationContext();

  const {t} = useTranslation();

  const isDeleteShown =
    !!client?.access_level && client.access_level >= Roles.ADMIN;

  return (
    <Table>
      <TableHead>
        <TableCell>{t`Source`}</TableCell>

        <TableCell>
          {crm?.title == 'AMO'
            ? t`Phone number`
            : crm?.title == 'TELEGRAM'
            ? t`Username`
            : ''}
        </TableCell>

        <TableCell>{t`Key`}</TableCell>
        <TableCell>{t`Default`}</TableCell>
        {isDeleteShown && <TableCell />}
      </TableHead>

      <TableBody>
        {sourceList.map(item => (
          <Source
            key={item.id}
            source={item}
            sourceList={sourceList}
            isDeleteShown={isDeleteShown}
            setSourceList={setSourceList}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default SourceList;
