import React, {useState} from 'react';
import {Button, EmptyBox, Icons, Text, Title} from 'whatcrm-core';
import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {usePartnerContext} from '../partner-context';
import * as AppEnv from 'app-env';

import Editor from './editor/editor';
import IntegrationList from './integration-list/integration-list';

interface State {
  trigger?: 'creator';
}

const Integrations = () => {
  const {state}: AppEnv.Location<State> = useLocation();
  const {partner} = usePartnerContext();

  const [isEditorActive, setIsEditorActive] = useState(
    state?.trigger == 'creator'
  );

  const {t} = useTranslation();

  return (
    <>
      <Title.H1 style={{marginBottom: 8}}>{partner.fullname}</Title.H1>

      <Text weight="bold" color="dark" isParagraph style={{marginBottom: 24}}>
        {t`Integrations`}
      </Text>

      <Button
        color="white"
        style={{marginBottom: 16}}
        onClick={() => setIsEditorActive(true)}
      >
        <Icons.Plus />
        {t`Add`}
      </Button>

      {partner.integrations?.length ? <IntegrationList /> : <EmptyBox />}

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
      />
    </>
  );
};

export default Integrations;
