import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

const DateStart = () => {
  const [field, , helpers] = useField('date_start');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Start date`}</Title.H3>

      <Input
        {...field}
        value={
          field.value
            ? moment.unix(field.value).utc().format('yyyy-MM-DD')
            : undefined
        }
        type="date"
        width="max"
        onChange={value =>
          helpers.setValue(value ? moment.utc(value).format('X') : undefined)
        }
      />
    </div>
  );
};

export default DateStart;
