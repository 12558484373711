import React, {useState, useEffect} from 'react';
import {Button, Icons, Preloader, EmptyBox} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import _ from 'lodash';

import {BarGraph, Legend} from 'components';
import {useStatsContext} from 'pages/stats/stats-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

const Integrations = () => {
  const {
    filter,
    isFilterAvailableAndActive,
    setIsFilterActive,
    setIsFilterDisabled
  } = useStatsContext();

  const [stats, setStats] = useState<AppEnv.IntegrationStats | null>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {fetchStatistics} = useRequest();
  const {t} = useTranslation();

  const getStats = async () => {
    if (typeof isLoading == 'boolean') {
      setIsLoading(true);
    }

    const {data} = await fetchStatistics<AppEnv.IntegrationStats>(
      'integrations',
      {
        ..._.pick(filter, ['end', 'integration_type_id', 'start'])
      }
    );

    setStats(data || null);
    setIsLoading(false);
  };

  useEffect(() => {
    getStats();
    document.title = `${t`Integration statistics`} - Whatcrm Console`;
  }, [filter]);

  useEffect(() => {
    setIsFilterDisabled(!stats);
  }, [stats]);

  const data = Array.isArray(stats) ? stats : [];

  const installed = data.reduce(
    (res, item) => res + item.integration_install,
    0
  );

  const uninstalled = data.reduce(
    (res, item) => res + item.integration_uninstall,
    0
  );

  const keys = {
    integration_install: {
      color: '#83d11f',
      label: t`Installed`,
      value: installed
    },
    integration_uninstall: {
      color: '#ff8242',
      label: t`Uninstalled`,
      value: uninstalled
    }
  };

  return (
    <>
      <Button
        color={isFilterAvailableAndActive ? 'active' : 'white'}
        style={{marginBottom: 32}}
        onClick={() => setIsFilterActive(prevValue => !prevValue)}
      >
        <Icons.Filter />
        {t`Filter`}
      </Button>

      {stats != undefined ? (
        stats ? (
          <BarGraph data={data} keys={keys}>
            <Legend data={Object.values(keys)} />
          </BarGraph>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}

      {isLoading && <Preloader isFullScreen />}
    </>
  );
};

export default Integrations;
