import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface WorkspaceContext {
  crmList: AppEnv.Crm[];
  getFusedCrmList: (arg: -1 | 0 | 1) => AppEnv.Crm[];
  getTokenList: () => Promise<void>;
  getUserList: () => Promise<void>;
  pages: AppEnv.Pages;
  setCrmList: AppEnv.SetState<AppEnv.Crm[] | undefined>;
  setTokenList: AppEnv.SetState<AppEnv.Token[] | undefined>;
  setUserList: AppEnv.SetState<AppEnv.User[] | undefined>;
  tokenList: AppEnv.Token[] | undefined;
  userList: AppEnv.User[] | undefined;
}

const WorkspaceContext = React.createContext<WorkspaceContext>(
  {} as WorkspaceContext
);

export const useWorkspaceContext = () => useContext(WorkspaceContext);
export default WorkspaceContext;
