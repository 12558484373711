import React from 'react';
import {Input, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const Summa = () => {
  const [field, , helpers] = useField<string | null>('summa');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Amount`}</Title.H3>

      <Input
        {...field}
        inputMode="decimal"
        placeholder={t`Amount`}
        type="number"
        width="max"
        onChange={value => helpers.setValue(value)}
      />
    </div>
  );
};

export default Summa;
