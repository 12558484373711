import React from 'react';
import {Text} from 'whatcrm-core';
import {Link, useLocation} from 'react-router-dom';
import cn from 'classnames';

import {useAppContext} from 'app-context';
import * as AppEnv from 'app-env';

interface NavItem {
  navItem: AppEnv.Page;
}

const NavItem = ({navItem}: NavItem) => {
  const {setIsNavActive} = useAppContext();
  const {pathname} = useLocation();

  const handleClick = () => {
    if (window.innerWidth <= 768) {
      setIsNavActive(false);
    }
  };

  const isActive =
    navItem.pathname == pathname.slice(0, navItem.pathname.length);

  return (
    <Link
      to={`${navItem.pathname}${navItem.search ? navItem.search : ''}`}
      onClick={handleClick}
    >
      <div
        className={cn('nav__item', {
          nav__item_active: isActive
        })}
      >
        {navItem.icon}

        <Text
          weight="bold"
          color={isActive ? 'dark' : 'white'}
          whiteSpace="nowrap"
        >
          {navItem.label}
        </Text>

        <svg
          className="nav__ears"
          width="4"
          height="40"
          viewBox="0 0 4 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 4V0C4 2 2 4 0 4H4ZM4 36V40C4 38 2 36 0 36H4Z"
          />
        </svg>
      </div>
    </Link>
  );
};

export default NavItem;
