import * as AppEnv from 'app-env';

const filterToRequest = (
  filter: AppEnv.SubscriptionFilter
): AppEnv.SubscriptionRequest => ({
  id: filter.id ? {value: filter.id} : undefined,
  integration_id: filter.integration_id
    ? {value: filter.integration_id}
    : undefined,
  status: filter.status ? {value: filter.status} : undefined,
  subscription_id: filter.subscription_id
    ? {value: filter.subscription_id, type: 'like'}
    : undefined,
  tariff_id: filter.tariff_id?.length ? {value: filter.tariff_id} : undefined
});

export default filterToRequest;
