import React, {useEffect, useState} from 'react';
import {Preloader, usePagination} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useRequest, useSearchParams} from 'common/hooks';
import * as AppEnv from 'app-env';

import Content from './content/content';
import InvoicesContext from './invoices-context';

const Invoices = () => {
  const [invoiceList, setInvoiceList] = useState<AppEnv.Invoice[]>();
  const [isLoading, setIsLoading] = useState<boolean>();

  const {page = '1'} = useSearchParams();
  const {fetchInvoices} = useRequest();
  const {t} = useTranslation();
  const pagination = usePagination(parseInt(page));

  const getInvoiceList = async () => {
    if (typeof isLoading == 'boolean') setIsLoading(true);

    const {data, headers} = await fetchInvoices(pagination.currentPage);

    pagination.setPagination(
      headers?.['x-pagination-current-page'],
      headers?.['x-pagination-page-count'],
      headers?.['x-pagination-total-count']
    );

    setInvoiceList(data || []);
    setIsLoading(false);
  };

  useEffect(() => {
    document.title = `${t`Invoices`} - Whatcrm Console`;
  }, []);

  useEffect(() => {
    getInvoiceList();
  }, []);

  return (
    <InvoicesContext.Provider value={{invoiceList, setInvoiceList}}>
      <Content pagination={pagination} />
      {isLoading && <Preloader isFullScreen />}
    </InvoicesContext.Provider>
  );
};

export default Invoices;
