import React, {useEffect} from 'react';
import {Formik, Form} from 'formik';
import {
  Line,
  Popup,
  putInLocalStorage,
  SaveButton,
  Text,
  Title,
  Wrapper
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import {useRequest} from 'common/hooks';
import {Roles} from 'app-enums';

import Username from './username/username';
import Password from './password/password';

interface Values {
  username: string;
  password: string;
}

const Auth = () => {
  const {t} = useTranslation();
  const {postAuthorization} = useRequest();

  useEffect(() => {
    document.title = `${t`Signing in`} - Whatcrm Console`;
  }, []);

  const handleSubmit = async (values: Values) => {
    const {data} = await postAuthorization(values.username, values.password);

    if (data?.access_token) {
      const temp = {
        access_token: data.access_token,
        access_level: Roles[data.role],
        role: data.role,
        username: values.username
      };

      putInLocalStorage('client', JSON.stringify(temp));
      window.location.reload();
    }
  };

  const initialValues: Values = {
    username: '',
    password: ''
  };

  const validationSchema = yup.object().shape({
    username: yup.string().trim().required(),
    password: yup.string().trim().required()
  });

  return (
    <Wrapper
      justifyContent="center"
      alignItems="center"
      style={{minHeight: '100vh'}}
    >
      <Popup position="static" isActive={true}>
        <Title.H2 style={{marginBottom: 8}}>
          {t`Sign in to Whatcrm Console`}
        </Title.H2>

        <Text isParagraph style={{marginBottom: 16}}>
          {t`Sign in to your account`}
        </Text>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          <Form>
            <Wrapper gap={8} isColumn>
              <Username />
              <Password />
            </Wrapper>

            <Line />
            <SaveButton>{t`Continue`}</SaveButton>
          </Form>
        </Formik>
      </Popup>
    </Wrapper>
  );
};

export default Auth;
