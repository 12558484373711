import React from 'react';
import {Text} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

interface PayloadPayload {
  label: string;
  value: number;
}

interface Payload {
  payload: PayloadPayload;
}

interface RechartsTooltip {
  active?: boolean;
  payload?: Payload[];
}

const RechartsTooltip = ({active, payload}: RechartsTooltip) => {
  const {i18n} = useTranslation();

  return (
    <>
      {active && (
        <>
          {payload?.map((item, i) => {
            const {payload: pld} = item;

            return (
              <div className="recharts-tooltip" key={i}>
                <Text color="dark">
                  {pld.label}
                  {` — `}
                  {pld.value.toLocaleString(i18n.resolvedLanguage)}
                </Text>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};

export default RechartsTooltip;
