import React, {useState} from 'react';
import {
  Button,
  Confirmation,
  Icons,
  Mark,
  TableCell,
  TableRow
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import Editor from '../editor/editor';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';
import * as AppEnv from 'app-env';
import * as Workspace from 'app-env';

interface Token {
  token: Workspace.Token;
}

const Token = ({token}: Token) => {
  const {pushNotification} = useAppContext();
  const {setTokenList, tokenList} = useWorkspaceContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);
  const [isEditorActive, setIsEditorActive] = useState(false);

  const {deleteTokens, putTokens} = useRequest();
  const {t} = useTranslation();

  const index = tokenList?.findIndex(item => item.id == token.id) || -1;

  const handleSubmit = async (values: AppEnv.TokenForm) => {
    const {data} = await putTokens(token.id, values);

    if (!data || index < 0) return true;

    setTokenList(prevValue =>
      update(prevValue, {
        [index]: {$set: data}
      })
    );

    setIsEditorActive(false);
    pushNotification(data.name, t`The token has been updated`);

    return true;
  };

  const deleteToken = async () => {
    const {data} = await deleteTokens(token.id);
    if (typeof data != 'string' || index < 0) return true;

    setTokenList(prevValue => update(prevValue, {$splice: [[index, 1]]}));
    pushNotification(token.name, t`The token has been deleted`);

    return true;
  };

  const handleClick = async (res: boolean) => {
    if (res) return deleteToken();
    setIsConfirmationActive(false);

    return true;
  };

  const isActive = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>{token.name}</TableCell>
      <TableCell>{token.token}</TableCell>

      <TableCell noPadding>
        <Mark mark={token.cors} />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={token}
          isActive={isEditorActive}
          isUpdate
          onClick={() => setIsEditorActive(false)}
          onSubmit={handleSubmit}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Delete`}
          onClick={() => setIsConfirmationActive(true)}
        >
          <Icons.Trash />
        </Button>

        <Confirmation
          title={t`Are you sure you want to delete this token?`}
          isActive={isConfirmationActive}
          onClick={handleClick}
        />
      </TableCell>
    </TableRow>
  );
};

export default Token;
