import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface SubscriptionContext {
  subscription: AppEnv.SubscriptionExpand;
}

const SubscriptionContext = React.createContext<SubscriptionContext>(
  {} as SubscriptionContext
);

export const useSubscriptionContext = () => useContext(SubscriptionContext);
export default SubscriptionContext;
