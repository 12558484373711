import React from 'react';
import {Table, TableHead, TableBody, TableCell} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useHomeContext} from '../../home-context';
import Partner from './partner/partner';

const PartnerList = () => {
  const {timezone} = useAppContext();
  const {partnerList} = useHomeContext();

  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableCell>{t`Name`}</TableCell>
        <TableCell>{t`Email`}</TableCell>
        <TableCell>{t`Phone number`}</TableCell>
        <TableCell>{t`Type`}</TableCell>
        <TableCell>{t`Telphin code`}</TableCell>
        <TableCell>{t`Entities`}</TableCell>
        <TableCell>{t`Comment`}</TableCell>
        <TableCell>{`${t`Date added`} (${timezone})`}</TableCell>
        <TableCell>{`${t`Date updated`} (${timezone})`}</TableCell>
        <TableCell>{t`Deleted`}</TableCell>
        <TableCell />
        <TableCell isIcon />
        <TableCell isIcon />
      </TableHead>

      <TableBody>
        {partnerList &&
          partnerList.map(item => (
            <Partner
              key={item.id}
              partner={item}
            />
          ))}
      </TableBody>
    </Table>
  );
};

export default PartnerList;
