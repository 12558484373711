import React from 'react';
import {Checkbox} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

const IsPartner = () => {
  const [field, , helpers] = useField('is_partner');
  const {t} = useTranslation();

  return (
    <Checkbox
      {...field}
      label={t`For partner`}
      onChange={helpers.setValue}
    />
  );
};

export default IsPartner;
