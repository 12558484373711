import React from 'react';
import {Popup, Title, Wrapper, Line, Button, SaveButton} from 'whatcrm-core';
import {Formik, Form} from 'formik';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import * as AppEnv from 'app-env';

import RoleId from './role-id/role-id';
import Username from './username/username';
import Password from './password/password';

interface Editor {
  initialValues?: AppEnv.UserForm;
  isActive: boolean;
  isUpdate?: boolean;
  onClick: () => void;
  onSubmit: AppEnv.Submit<AppEnv.UserForm>;
}

const Editor = ({
  initialValues = {
    role_id: undefined,
    password: undefined,
    username: undefined
  },
  isActive,
  isUpdate,
  onClick,
  onSubmit
}: Editor) => {
  const {t} = useTranslation();

  const validationSchema = yup.object().shape({
    role_id: yup.number().required(),
    password: isUpdate ? yup.string() : yup.string().trim().required(),
    username: yup.string().trim().required()
  });

  return (
    <Popup
      isActive={isActive}
      onClick={onClick}
    >
      <Title.H2 style={{marginBottom: 16}}>
        {isUpdate ? t`Update the user` : t`Create a user`}
      </Title.H2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={onSubmit}
      >
        <Form>
          <Wrapper
            gap={16}
            isColumn
          >
            <RoleId />
            <Username />
            <Password isUpdate={!!isUpdate} />
          </Wrapper>

          <Line />

          <Wrapper gap={8}>
            <SaveButton>{t`Save`}</SaveButton>

            <Button
              color="white"
              onClick={onClick}
            >
              {t`Cancel`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default Editor;
