import React, {useContext} from 'react';
import * as AppEnv from 'app-env';

interface PartnerContext {
  partner: AppEnv.Partner;
  setPartner: AppEnv.SetState<AppEnv.Partner | null | undefined>;
}

const PartnerContext = React.createContext<PartnerContext>(
  {} as PartnerContext
);

export const usePartnerContext = () => useContext(PartnerContext);
export default PartnerContext;
