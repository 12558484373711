import React from 'react';

import {Sidebar} from 'components';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Domain from './domain/domain';
import Id from './id/id';
import Status from './status/status';
import SubscriptionId from './subscription-id/subscription-id';
import TariffId from './tariff-id/tariff-id';

interface Filter {
  filter: AppEnv.SubscriptionFilter;
  isDisabled: boolean;
  isFilterActive: boolean;
  setFilter: AppEnv.SetState<AppEnv.SubscriptionFilter>;
  setIsFilterActive: AppEnv.SetState<boolean>;
}

const Filter = ({
  filter,
  isDisabled,
  isFilterActive,
  setFilter,
  setIsFilterActive
}: Filter) => {
  const {fetchIntegrations} = useRequest();

  const handleSubmit = async (values: AppEnv.SubscriptionFilter) => {
    let integration_id: string[] | undefined;

    if (values.domain) {
      const {data} = await fetchIntegrations<AppEnv.Integration[]>({
        filter: {domain: {value: values.domain, type: 'like'}}
      });

      integration_id = data?.map(item => item.id.toString()) || ['-1'];
    }

    setFilter({...values, integration_id});
  };

  return (
    <Sidebar
      initialValues={filter}
      isActive={isFilterActive}
      isDisabled={isDisabled}
      onClick={() => setIsFilterActive(false)}
      onSubmit={handleSubmit}
      onCancel={() => setFilter({})}
    >
      <Id />
      <Domain />
      <Status />
      <TariffId />
      <SubscriptionId />
    </Sidebar>
  );
};

export default Filter;
