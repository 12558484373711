import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

const Line = () => {
  const [field, , helpers] = useField<number | undefined>('line');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Line`}</Title.H3>

      <Input
        {...field}
        inputMode="numeric"
        placeholder={t`Line`}
        width="max"
        onChange={value => helpers.setValue(parseInt(value) || undefined)}
      />
    </div>
  );
};

export default Line;
