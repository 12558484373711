import React from 'react';
import {TableCell, Wrapper} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';
import Count from './count/count';

interface Rate {
  subscription: AppEnv.Telphin;
}

const Rates = ({subscription}: Rate) => {
  const {t} = useTranslation();

  return (
    <TableCell>
      <Wrapper gap={4} isColumn>
        <Count count={subscription.count_start} rate={'Start'} />
        <Count count={subscription.count_pro} rate={'Pro'} />
        <Count count={subscription.count_enterprise} rate={'Enterprise'} />

        <Count
          count={subscription.count_avito_start}
          rate={`Start • ${t`Avito`}`}
        />

        <Count
          count={subscription.count_avito_pro}
          rate={`Pro • ${t`Avito`}`}
        />
      </Wrapper>
    </TableCell>
  );
};

export default Rates;
