import React, {useContext} from 'react';

import * as AppEnv from 'app-env';

interface ContainersContext {
  containerList: AppEnv.Instance[];
  refreshingContainerId?: number;
  setContainerList: AppEnv.SetState<AppEnv.Instance[] | undefined>;
  setRefreshingContainerId: AppEnv.SetState<number | undefined>;
  getIsContainerDeleted: (arg: AppEnv.Instance) => Promise<0 | 1 | 2>;
}

const ContainersContext = React.createContext<ContainersContext>(
  {} as ContainersContext
);

export const useContainersContext = () => useContext(ContainersContext);
export default ContainersContext;
