import React, {useContext} from 'react';

interface ClientsContext {
  getClientList: () => void;
}

const ClientsContext = React.createContext<ClientsContext>(
  {} as ClientsContext
);

export const useClientsContext = () => useContext(ClientsContext);
export default ClientsContext;
