import React, {useState} from 'react';
import {Button, Icons} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Editor from '../editor/editor';
import {useSystemOptionListContext} from '../system-option-list-context';

const Creator = () => {
  const {pushNotification} = useAppContext();
  const {getSystemOptionList} = useSystemOptionListContext();

  const [isEditorActive, setIsEditorActive] = useState(false);

  const {postOptions} = useRequest();
  const {t} = useTranslation();

  const handleSubmit = async (values: AppEnv.SystemOptionForm) => {
    const {data} = await postOptions(values);

    if (data) {
      setIsEditorActive(false);
      pushNotification(data.param, t`A system option has been created`);
      getSystemOptionList();
    }

    return true;
  };

  return (
    <>
      <Button color="white" onClick={() => setIsEditorActive(true)}>
        <Icons.Plus />
        {t`Create`}
      </Button>

      <Editor
        isActive={isEditorActive}
        onClick={() => setIsEditorActive(false)}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default Creator;
