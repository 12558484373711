import React, {useEffect, useState} from 'react';
import {EmptyBox, Preloader, Title} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';

import {EntriesNumber} from 'components';
import {useCrmRequest} from 'common/hooks';
import {useIntegrationContext} from '../integration-context';
import * as AppEnv from 'app-env';

import SourceList from './source-list/source-list';

const Sources = () => {
  const {crm, integration} = useIntegrationContext();
  const [sourceList, setSourceList] = useState<AppEnv.Source[]>();

  const {fetchSources} = useCrmRequest();
  const {t} = useTranslation();

  const getSourceList = async () => {
    if (!crm) return setSourceList([]);

    const res = await fetchSources(crm.title, integration.domain);
    setSourceList(res || []);
  };

  useEffect(() => {
    getSourceList();
  }, []);

  return (
    <>
      <Title.H3 style={{marginBottom: 24}}>{t`Sources`}</Title.H3>

      {sourceList ? (
        sourceList.length ? (
          <>
            <EntriesNumber
              number={sourceList.length}
              style={{marginBottom: 8}}
            />

            <SourceList sourceList={sourceList} setSourceList={setSourceList} />
          </>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Sources;
