import React from 'react';
import {Title} from 'whatcrm-core';
import {PieChart, Pie, Cell, Tooltip} from 'recharts';

import {Legend} from 'components';
import * as AppEnv from 'app-env';

import RechartsTooltip from './recharts-tooltip/recharts-tooltip';

interface PieGraph {
  title: React.ReactNode;
  data: AppEnv.GraphData;
}

const PieGraph = ({title, data}: PieGraph) => (
  <div>
    <PieChart width={248} height={160} style={{marginBottom: 16}}>
      <Pie
        data={data}
        dataKey="value"
        outerRadius={80}
        innerRadius={50}
        startAngle={-270}
        endAngle={-630}
        fill="#8884d8"
        animationDuration={1000}
        animationEasing="ease-in-out"
        animationBegin={0}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color} />
        ))}
      </Pie>

      <Tooltip
        content={<RechartsTooltip />}
        animationDuration={1000}
        animationEasing="ease-in-out"
      />
    </PieChart>

    <Title.H3 style={{marginBottom: 8}}>{title}</Title.H3>
    <Legend data={data} />
  </div>
);

export default PieGraph;
