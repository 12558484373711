import React from 'react';
import {Wrapper, Pagination, Preloader, EmptyBox, CoreEnv} from 'whatcrm-core';

import {EntriesNumber} from 'components';
import * as AppEnv from 'app-env';

import Header from './header/header';
import EventList from './event-list/event-list';

interface Content {
  eventList: AppEnv.Event[] | undefined;
  pagination: CoreEnv.Pagination;
}

const Content = ({eventList, pagination}: Content) => (
  <div className="content">
    <Wrapper gap={16} isColumn>
      <Header />

      {eventList ? (
        eventList.length ? (
          <Wrapper gap={8} isColumn>
            <EntriesNumber number={pagination.totalCount} />
            <EventList eventList={eventList} />

            <Pagination {...pagination} isSticky />
          </Wrapper>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </Wrapper>
  </div>
);

export default Content;
