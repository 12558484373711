import React from 'react';
import {Title, Input} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import {useField} from 'formik';

const CountAvitoPro = () => {
  const [field, , helpers] = useField<number | undefined>('count_avito_pro');
  const {t} = useTranslation();

  return (
    <div>
      <Title.H3
        style={{marginBottom: 8}}
      >{`${t`Number of Pro`} • ${t`Avito`}`}</Title.H3>

      <Input
        {...field}
        type="number"
        placeholder={`${t`Number of Pro`} • ${t`Avito`}`}
        width="max"
        onChange={value => helpers.setValue(parseInt(value) || 0)}
      />
    </div>
  );
};

export default CountAvitoPro;
