import React, {useState, useEffect} from 'react';
import {
  Wrapper,
  Substrate,
  Title,
  Button,
  Icons,
  Preloader,
  EmptyBox
} from 'whatcrm-core';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Header from './header/header';
import ContainerList from './container-list/container-list';
import ContainersContext from './containers-context';

const Containers = () => {
  const {pushNotification} = useAppContext();

  const [containerList, setContainerList] = useState<AppEnv.Instance[]>();
  const [refreshingContainerId, setRefreshingContainerId] = useState<number>();

  const {chat_id = ''} = useParams();
  const {fetchInstances, fetchInstanceStatus} = useRequest();
  const {t} = useTranslation();

  const getContainerList = async (
    tempContainerList: AppEnv.Instance[] = [],
    totalCount = 20,
    page = 1
  ): Promise<AppEnv.Instance[] | undefined> => {
    if (totalCount && tempContainerList.length < totalCount) {
      const {headers, data} = await fetchInstances<AppEnv.Instance[]>({
        filter: {chat_id: {value: chat_id, type: 'like'}},
        page
      });

      if (data) {
        return getContainerList(
          [...tempContainerList, ...data],
          parseInt(headers?.['x-pagination-total-count'] || '20'),
          page + 1
        );
      }
    }

    setContainerList(tempContainerList);
    return;
  };

  useEffect(() => {
    if (chat_id) {
      getContainerList();
      return;
    }

    setContainerList([]);
  }, []);

  const getIsContainerDeleted = async (container: AppEnv.Instance) => {
    const {data} = await fetchInstanceStatus(container.chat_key);

    return data && !('error' in data)
      ? container.is_deleted == 2
        ? 0
        : container.is_deleted
      : 2;
  };

  const handleClick = async () => {
    if (containerList) {
      for (const i in containerList) {
        setRefreshingContainerId(containerList[i].id);
        const isDeleted = await getIsContainerDeleted(containerList[i]);

        if (isDeleted != containerList[i].is_deleted) {
          setContainerList(prevValue =>
            update(prevValue, {
              [i]: {is_deleted: {$set: isDeleted}}
            })
          );
        }
      }

      pushNotification(
        `${t`Server`} ${chat_id}`,
        t`All containers have been refreshed`
      );

      setRefreshingContainerId(undefined);
    }
  };

  const isButtonDisabled = !containerList?.length || !!refreshingContainerId;

  return (
    <Wrapper gap={8} isColumn>
      <Header />

      <Substrate>
        <Title.H1 style={{marginBottom: 8}}>{chat_id}</Title.H1>

        <Title.H3 style={{marginBottom: 24}}>{t`Containers`}</Title.H3>

        <Button
          color="white"
          style={{marginBottom: 16}}
          isDisabled={isButtonDisabled}
          onClick={handleClick}
        >
          <Icons.Refresh isRotate={!!refreshingContainerId} />
          {t`Refresh all`}
        </Button>

        {containerList ? (
          containerList.length ? (
            <ContainersContext.Provider
              value={{
                containerList,
                refreshingContainerId,
                setContainerList,
                setRefreshingContainerId,
                getIsContainerDeleted
              }}
            >
              <ContainerList />
            </ContainersContext.Provider>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}
      </Substrate>
    </Wrapper>
  );
};

export default Containers;
