import React from 'react';
import {SingleSelect, Title} from 'whatcrm-core';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import * as AppEnv from 'app-env';

interface Option {
  label: string;
  value: AppEnv.Column;
}

const Column = () => {
  const [field, meta, helpers] = useField<AppEnv.Column | undefined>('column');
  const {t} = useTranslation();

  const options: Option[] = [
    {value: 'cloudpayment', label: 'CloudPayments'},
    {value: 'stripe', label: 'Stripe'},
    {value: 'telphin', label: t`Telphin`},
    {value: 'tinkoff', label: t`Tinkoff`},
    {value: 'whatcrm', label: 'Whatcrm'}
  ];

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Payment system`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        isMaxWidth
        isValid={isValid}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Column;
