import React, {useState} from 'react';
import {Button, Confirmation, Icons, TableCell, TableRow} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import update from 'immutability-helper';

import Editor from '../editor/editor';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'workspace/workspace-context';
import {Roles} from 'app-enums';
import * as AppEnv from 'app-env';

interface User {
  user: AppEnv.User;
}

const User = ({user}: User) => {
  const {client, pushNotification} = useAppContext();
  const {setUserList, userList} = useWorkspaceContext();

  const [isConfirmationActive, setIsConfirmationActive] = useState(false);
  const [isEditorActive, setIsEditorActive] = useState(false);

  const {deleteUsers, putUsers} = useRequest();
  const {t} = useTranslation();

  const index = userList?.findIndex(item => item.id == user.id);

  const updateUser = async (values: AppEnv.UserForm) => {
    if (index == undefined || index < 0) return true;

    const {data} = await putUsers(user.id, values);
    if (!data) return true;

    setIsEditorActive(false);
    pushNotification(data.username, t`The user has been updated`);
    setUserList(prevValue => update(prevValue, {[index]: {$set: data}}));

    return true;
  };

  const deleteUser = async () => {
    if (index == undefined || index < 0) return true;

    await deleteUsers(user.id);

    pushNotification(user.role_name, t`The user has been deleted.`);
    setUserList(prevValue => update(prevValue, {$splice: [[index, 1]]}));

    return true;
  };

  const handleConfirm = async (res: boolean) => {
    if (res) {
      return deleteUser();
    }

    setIsConfirmationActive(false);
    return true;
  };

  const isEditingAvailable = client?.access_level
    ? client.access_level > Roles[user.role_name] ||
      client.access_level == Roles.ADMIN
    : false;

  const isActive = isEditorActive || isConfirmationActive;

  return (
    <TableRow isActive={isActive}>
      <TableCell>{user.id}</TableCell>
      <TableCell>{user.username}</TableCell>
      <TableCell>{user.role_name}</TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Update`}
          isDisabled={!isEditingAvailable}
          onClick={() => setIsEditorActive(true)}
        >
          <Icons.Edit />
        </Button>

        <Editor
          initialValues={user}
          isActive={isEditorActive}
          isUpdate
          onClick={() => setIsEditorActive(false)}
          onSubmit={updateUser}
        />
      </TableCell>

      <TableCell isIcon>
        <Button
          color="transparent"
          tip={t`Delete`}
          isDisabled={!isEditingAvailable}
          onClick={() => setIsConfirmationActive(true)}
        >
          <Icons.Trash />
        </Button>

        <Confirmation
          title={t`Are you sure you want to delete this user?`}
          isActive={isConfirmationActive}
          onClick={handleConfirm}
        />
      </TableCell>
    </TableRow>
  );
};

export default User;
