import React from 'react';
import {Title, SingleSelect} from 'whatcrm-core';
import {useField} from 'formik';

import {useWorkspaceContext} from 'workspace/workspace-context';

const IntegrationTypeId = () => {
  const {crmList} = useWorkspaceContext();
  const [field, meta, helpers] = useField('integration_type_id');

  const options = crmList.map(item => ({
    comment: item.description || undefined,
    label: item.title,
    value: item.id
  }));

  const isValid = !(meta.touched && meta.error);

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>CRM</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        isValid={isValid}
        placeholder="CRM"
        isMaxWidth
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default IntegrationTypeId;
