import React, {useState, useRef} from 'react';
import {
  Button,
  Confirmation,
  Icons,
  Line,
  removeFromLocalStorage,
  Text,
  Title,
  useOnClickOutside,
  Wrapper
} from 'whatcrm-core';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {useAppContext} from 'app-context';
import Settings from './settings/settings';

const Account = () => {
  const {client, setClient} = useAppContext();

  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isSettingsActive, setIsSettingsActive] = useState(false);
  const [isConfirmationActive, setIsConfirmationActive] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const {t} = useTranslation();

  useOnClickOutside(ref, {onClick: () => setIsMenuActive(false)});

  const handleClick = () => {
    setIsSettingsActive(true);
    setIsMenuActive(false);
  };

  const handleConfirm = async (res: boolean) => {
    if (res) {
      removeFromLocalStorage('client');
      setClient(null);
    }

    setIsConfirmationActive(false);
    return true;
  };

  return (
    <>
      <div
        ref={ref}
        className={cn('account', {
          account_active: isMenuActive
        })}
      >
        <div className="account__trigger" onClick={() => setIsMenuActive(true)}>
          <div className="account__button">
            <Icons.Account />
          </div>

          <Wrapper isColumn>
            <Text color="white">{client?.username}</Text>

            <Text size="s" color="white">
              {client?.role.toLowerCase()}
            </Text>
          </Wrapper>
        </div>

        <div className="account__menu">
          <Wrapper gap={4} style={{marginBottom: 16}} isColumn>
            <Text isParagraph>{client?.username}</Text>

            {process.env.REACT_APP_ENV == 'development' && (
              <Title.H3>{client?.access_token}</Title.H3>
            )}
          </Wrapper>

          <Button
            color="transparent"
            style={{marginBottom: 8}}
            onClick={handleClick}
          >
            {t`Settings`}
          </Button>

          <Button color="white" onClick={() => setIsConfirmationActive(true)}>
            {t`Sign out`}
          </Button>

          <Line />

          <Text size="s" isParagraph style={{textAlign: 'center'}}>
            {process.env.REACT_APP_VERSION}
          </Text>
        </div>
      </div>

      <Settings
        isSettingsActive={isSettingsActive}
        setIsSettingsActive={setIsSettingsActive}
      />

      <Confirmation
        title={t`Are you sure you want to sign out?`}
        isActive={isConfirmationActive}
        onClick={handleConfirm}
      />
    </>
  );
};

export default Account;
